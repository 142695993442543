import React, { useDebugValue } from "react";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useDroppable } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import Stage from "./Stage";
import SortableTaskItem from "./SortableTaskItem";
import { CSS } from "@dnd-kit/utilities";

export const Canvas = function ({
  flow,
  setStageSelected,
  setIsStageSelected,
  stageSelected,
  handleStageDelete,
}) {
  const [fixedFlow, setFixedFlow] = React.useState(null);

  React.useEffect(() => {
    console.log("FLOW IN CANVAS", flow);
    setFixedFlow(flow);
  }, [flow]);

  const { setNodeRef } = useDroppable({
    id: "playground",
  });

  return (
    <>
      {fixedFlow && fixedFlow?.length > 0 && (
        <div
          className="flex justify-center bg-slate-200"
          ref={setNodeRef}
          style={{
            background: "white",
            backgroundImage: "radial-gradient(black 1px, transparent 0)",
            backgroundSize: "30px 30px",
            backgroundPosition: "-19px -19px",
          }}
        >
          <SortableContext
            id="playground"
            items={fixedFlow}
            strategy={verticalListSortingStrategy}
          >
            <div className="mt-4">
              {fixedFlow.map((stage, index) => (
                <div className="mt-4 mb-4" key={index}>
                  <Stage
                    data={stage}
                    setIsStageSelected={setIsStageSelected}
                    setStageSelected={setStageSelected}
                    stageSelected={stageSelected}
                    handleStageDelete={handleStageDelete}
                  />
                </div>
                // <SortableTaskItem id={stage.id} key={stage.id}>

                // </SortableTaskItem>
              ))}
            </div>
          </SortableContext>
        </div>
      )}
    </>
  );
};
