import { ComponentProps, useState, useEffect } from "react";
// import formatDistanceToNow from "date-fns/formatDistanceToNow"
import { Button } from "../ui/button";
import { cn } from "@/lib/utils";
import { Badge } from "@/components/ui/badge";
import { FaWhatsapp } from "react-icons/fa";
import { Phone, Calendar, UsersRound, Headset, UserRound } from "lucide-react";
import instagram_logo from "../../assets/instagram.jpg";
import logout_logo from "../../assets/logout.png";
import chatwithio_logo from "../../assets/chatwithio.png";
import myoperator_logo from "../../assets/myoperator.png";
import website_logo from "../../assets/website.png";
import social from "@/assets/social.png";
import { FaInstagram } from "react-icons/fa";
import { statusOptions, stageOptions, ownerOptions } from "@/lib/data";
import whatsapp_icon from "@/assets/WhatsApp.svg";
import useLeadsStore from "@/store/leadsStore";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { EllipsisVertical } from "lucide-react";
import { Checkbox } from "../ui/checkbox";
import { Plus, CirclePlus, CircleCheckBig, Clock } from "lucide-react";
import LeadsFilter from "../Custom/LeadsQuickFilter";
import LeadChatModal from "./lead-chat";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Label } from "../ui/label";
import { Textarea } from "../ui/textarea";
import { DateTimePicker } from "../Custom/DateTimePicker";
import TimelineComp from "../Custom/TimelineComp";
import { Separator } from "../ui/separator";
import { Card } from "../ui/card";
import { Switch } from "../ui/switch";
import {
  format,
  isThisYear,
  isToday,
  differenceInDays,
  isYesterday,
} from "date-fns";
import axios from "axios";
import EditEnquiry from "./edit-lead";
import ChatComponent from "@/components/ui/chat"

// const formatDate = (date) => {
//   const parsedDate = new Date(date);
//   return isThisYear(parsedDate)
//     ? format(parsedDate, "EEE, d MMM, hh:mm aa") // Show only Month and Day if it's the current year
//     : format(parsedDate, "EEE, d MMM yyyy, hh:mm aa"); // Show Month, Day, and Year if it's a different year
// };

const sourceImgs = {
  instagram: (
    <img
      src="https://img.icons8.com/fluency/48/instagram-new.png"
      className="w-4 h-4"
    />
  ),
  chatbot: (
    <img
      src="https://img.icons8.com/fluency/48/instagram-new.png"
      className="w-4 h-4"
    />
  ),
  instacomment: (
    <img
      src="https://img.icons8.com/fluency/48/instagram-new.png"
      className="w-4 h-4"
    />
  ),
  whatsapp: <img src={whatsapp_icon} className="w-4 h-4" />,
  ChatWithIO: <img src={chatwithio_logo} className="w-4 h-4" />,
  website: (
    <img
      src="https://img.icons8.com/ios-glyphs/48/domain.png"
      className="w-4 h-4"
    />
  ),
  logout: <img src={logout_logo} className="w-4 h-4" />,
  facebook: (
    <img
      src="https://img.icons8.com/color/48/facebook-new.png"
      className="w-4 h-4"
    />
  ),
};

const formatDate = (date) => {
  const givenDate = new Date(date);
  const daysDiff = differenceInDays(new Date(), givenDate);

  if (isToday(givenDate)) {
    return "Today";
  } else if (isYesterday(givenDate)) {
    return "Yesterday";
  } else if (daysDiff == 2) {
    return "2 days ago";
  } else {
    return format(givenDate, "dd/MM/yyyy");
  }
};

export function MailList({ items, rowSelection, setRowSelection }) {
  const [mail, setMail] = useState({});
  const [isChatModalOpen, setIsChatModalOpen] = useState(false);
  const [isRemarksOpen, setIsRemarksOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [selectedLead, setSelectedLead] = useState(null);

  const handleLeadSelect = (value) => {
    setSelectedLead(value);
    setIsDrawerOpen(true);
  };

  const handleLeadCheck = (value) => {
    if (Object.keys(rowSelection).includes(value.toString())) {
      setRowSelection((prev) => {
        const newState = { ...prev };
        delete newState[value];
        return newState;
      });
    } else {
      setRowSelection((prev) => {
        return { ...prev, [value]: true };
      });
    }
  };

  return (
    <ScrollArea className="">
      <div className="flex flex-col gap-4 relative pt-0">
        {items.map((item, idx) => (
          <button
            key={idx}
            className={cn(
              "flex relative flex-col border-[1px] rounded-lg items-start gap-1 border-b-[1px] px-2 py-3 text-left text-sm transition-all",
              // mail.selected === item.id && "bg-muted"
              // item.status=="hot" && 'card'
              item.is_expecting_call_back && "callback-lead"
            )}
            onClick={() =>
              setMail({
                ...mail,
                selected: item.id,
              })
            }
          >
            <div className={`flex items-start gap-2 w-full`}>
              <Checkbox
                onCheckedChange={() => {
                  handleLeadCheck(item.id);
                }}
                className="w-4 h-4 rounded-sm"
              />
              <div className="flex w-full flex-col gap-1 flex-grow">
                <div className="flex w-full items-center">
                  <div className="flex w-full items-center gap-2">
                    <div className="flex justify-between leading-4 w-full">
                      <div className="font-semibold flex items-center gap-1">
                        {/* <Checkbox className="w-5 h-5 rounded-md" /> */}
                        <span
                          onClick={() => {
                            handleLeadSelect(item);
                          }}
                          className="underline leading-4 md:hidden max-w-[7rem] truncate"
                        >
                          {item.customer_profile?.fullname}
                        </span>
                        {item.source == "instagram" ||
                        item.source == "instacomment" ||
                        item.source == "chatbot" ? (
                          <div className="">
                            {/* <FaInstagram className="" size={16} /> */}
                            {sourceImgs[item.source]}
                            {/* <Badge
                          className={
                            "items-center text-[0.6rem] bg-[#e1306c] font-medium rounded-md px-1 flex gap-1"
                          }
                          variant="default"
                        >
                          
                        </Badge> */}
                          </div>
                        ) : item?.source == "whatsapp" ? (
                          <div className="">
                            {sourceImgs[item.source]}
                            {/* <Badge
                          className={
                            "items-center text-[0.6rem] bg-[#25d366] font-medium rounded-md px-1 flex gap-1"
                          }
                          variant="default"
                        >
                          
                        </Badge> */}
                          </div>
                        ) : (
                          <div className="">
                            {/* {item?.source} */}
                            {item?.source !== "website"
                              ? sourceImgs[item.source]
                              : ""}

                            {item?.source?.includes("website") ||
                            (item?.origin_domain?.includes("http") &&
                              !item?.origin_domain?.includes("/linkinbio") &&
                              !item?.origin_domain?.includes("logout.world"))
                              ? sourceImgs["website"]
                              : ""}
                            {!item?.origin_domain?.includes("/linkinbio") &&
                              item?.origin_domain?.includes("logout.world") &&
                              sourceImgs["logout"]}
                            {/* <Badge
                          className={
                            "items-center capitalize text-[0.6rem] font-medium rounded-md px-1 flex gap-1"
                          }
                          variant="outline"
                        >
                          
                        </Badge> */}
                          </div>
                        )}
                        |<div className="text-xs">ID: {item.id}</div>
                      </div>
                      <div className="flex items-start gap-2">
                        {item?.created_at && (
                          <span className="text-xs leading-4 text-muted-foreground">
                            {formatDate(item.created_at)}
                          </span>
                        )}
                      </div>
                    </div>
                    {/* {!item.read && (
                    <span className="flex h-2 w-2 rounded-full bg-blue-600" />
                  )} */}
                  </div>
                  <div
                    className={cn(
                      "ml-auto text-xs",
                      mail.selected === item.id
                        ? "text-foreground"
                        : "text-muted-foreground"
                    )}
                  >
                    {/* {formatDistanceToNow(new Date(item.date), {
                    addSuffix: true,
                  })} */}
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  {item.customer_profile?.mobile && (
                    <div className="text-[0.75rem]  flex gap-1 items-center font-medium">
                      {" "}
                      <Phone size={12} />
                      <span className={"leading-4"}>
                        {item.customer_profile?.mobile}
                      </span>
                      {/* <FaWhatsapp size={12} /> */}
                      {/* <Phone size={12} /> */}
                    </div>
                  )}
                  {item.customer_profile?.mobile && (
                    <div className="flex items-center gap-1">
                      <img
                        onClick={() => {
                          setSelectedLead(item);
                          setIsChatModalOpen(true);
                        }}
                        src={whatsapp_icon}
                        className="w-6 h-6"
                      />
                      <a className="text-primary hover:text-primary" href={`tel:${item.customer_profile.mobile}`}>
                        <Phone size={18} />
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {item.event && <Separator className="my-1" />}
            <div className="w-full">
              {item.last_remark?.follow_up_remark && (
                <div className="flex flex-col gap-1 w-full">
                  <span className="flex font-normal text-xs items-center gap-1">
                    {/* <NotebookText size={14} className="text-black" /> */}
                    Remarks:
                    <span className="font-medium text-xs text-primary w-64 truncate">
                      {item.last_remark?.follow_up_remark}
                    </span>
                  </span>
                  <Separator className="my-1" />
                </div>
              )}
            </div>

            {item.event && (
              <div className="flex justify-between items-start w-full">
                {item.event && (
                  <div className="text-sm flex gap-2 items-center font-medium">
                    <div
                      className={"w-8 h-8 relative overflow-hidden rounded-md"}
                    >
                      <img
                        src={`${import.meta.env.VITE_APP_API_URL}${
                          item.event?.pic
                        }`}
                        className="w-full h-full object-cover absolute top-0 left-0"
                      />
                    </div>
                    <div className="flex flex-col">
                      <span
                        className={"max-w-[12rem] truncate leading-4 text-xs"}
                      >
                        {item.event?.name}
                      </span>
                      <div className="flex items-center justify-between w-full">
                        <div className="flex items-center gap-2">
                          {item.no_of_guests && (
                            <div className="flex items-center gap-1">
                              <UsersRound className="font-normal" size={14} />
                              <span className="font-medium text-[10px]">
                                {item.no_of_guests} Pax
                              </span>
                            </div>
                          )}
                          {item.preferred_start_date && (
                            <div className="flex items-center gap-1">
                              <Calendar size={14} />
                              <span className="font-medium text-[10px]">
                                {format(
                                  item.preferred_start_date,
                                  "dd/MM/yyyy"
                                )}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {
                  <span
                    className={`${
                      !item.asigned_to
                        ? "opacity-0"
                        : "opacity-1 flex items-center gap-1 leading-4"
                    }`}
                  >
                    <UserRound size={14} />
                    <span className="text-primary text-xs font-medium">
                      {item?.asigned_to?.username}
                    </span>{" "}
                  </span>
                }
              </div>
            )}

            {/* <div className="flex items-center gap-0">
                        <Badge
                          className={
                            "items-center text-[0.5rem] border-black rounded-md py-[0rem] bg-[#f6c23e] px-1 flex gap-1 font-bold"
                          }
                          variant="outline"
                        >
                          <div class="circle"></div>
                          Callback
                        </Badge>
                        
                      <Checkbox className="w-5 h-5 ml-1 rounded-md" />
                      </div> */}

            {/* <div className=" w-full text-xs text-muted-foreground flex flex-col items-start gap-2">
              {item.last_remark?.follow_up_remark && (
                <span className="flex items-center gap-1">
                  <NotebookText size={14} className="text-black" />
                  <span className="font-medium w-64 truncate">
                    {item.last_remark?.follow_up_remark}
                  </span>
                </span>
              )}
              
            
            </div> */}
          </button>
        ))}
        {isDrawerOpen && (
          <LeadMobileCard
            isOpen={isDrawerOpen}
            setIsOpen={setIsDrawerOpen}
            lead={selectedLead}
          />
        )}
        {isChatModalOpen && (
          <LeadChatModal
            isOpen={isChatModalOpen}
            setIsOpen={setIsChatModalOpen}
            row={selectedLead}
          />
        )}
      </div>
    </ScrollArea>
  );
}

export function LeadMobileCard({ isOpen, setIsOpen, lead }) {
  const [remarks, setRemarks] = useState([]);
  const { teamMates, refetchApi } = useLeadsStore();
  const [remarkMessage, setRemarkMessage] = useState("");
  const [selectedTeamMate, setSelectedTeamMate] = useState(null);
  const [reminderTime, setReminderTime] = useState("");
  const [isRemindersOpen, setIsRemindersOpen] = useState(false);
  const [isReminderTrue, setIsReminderTrue] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isChatModalOpen, setIsChatModalOpen] = useState(false)

  const getRemarks = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/v3/company-enquiry-follow-up/${
          lead.id
        }/`
      );
      setRemarks(response.data);
    } catch (error) {
      console.error(
        "Error fetching team members:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const setTeamMatesState = (v) => {
    setSelectedTeamMate(teamMates.find((p) => p.label == v)?.value);
  };

  const handleReminderSave = async () => {
    setIsRemindersOpen(false);
    // Construct the formatted data
    const formattedData = {
      follow_up_remark: remarkMessage,
    };

    const formattedReminderData = {
      reminder_remark: remarkMessage,
      reminder_time: isReminderTrue && reminderTime ? reminderTime : null,
      asigned_to: selectedTeamMate,
    };
    if (isReminderTrue) {
      try {
        // Axios POST request
        const response = await axios.post(
          `${import.meta.env.VITE_APP_API_URL}/v3/edit-enquiry-reminder/${
            lead.id
          }/`,
          { enquiryReminder: formattedReminderData }
        );
      } catch (error) {
        console.error("Error saving data:", error);
      }
    } else {
      try {
        // Axios POST request
        const response = await axios.post(
          `${import.meta.env.VITE_APP_API_URL}/v3/edit-enquiry-remark/${
            lead.id
          }/`,
          { enquiryRemark: formattedData }
        );
      } catch (error) {
        console.error("Error saving data:", error);
      }
    }
    getRemarks();
    refetchApi();
  };

  useEffect(() => {
    getRemarks();
  }, []);

  return (
    <Drawer open={isOpen} onOpenChange={setIsOpen}>
      <DrawerContent className="max-h-[100vh] min-h-[85vh]">
        <ScrollArea className="p-4 max-h-[85vh] overflow-auto">
          <Tabs className="w-full mt-2" defaultValue="details">
            <TabsList className="ml-auto w-full">
              <TabsTrigger
                value="details"
                className="text-zinc-600 w-full dark:text-zinc-200"
              >
                Details
              </TabsTrigger>
              <TabsTrigger
                value="timeline"
                className="text-zinc-600 w-full dark:text-zinc-200"
              >
                Timeline
              </TabsTrigger>
              <TabsTrigger
                value="remarks"
                className="text-zinc-600 w-full dark:text-zinc-200"
              >
                Remarks
              </TabsTrigger>
              <TabsTrigger
                  value="chat"
                  className="text-zinc-600 w-full dark:text-zinc-200"
                >
                  Chat
              </TabsTrigger>
            </TabsList>
            <TabsContent value="details" className="w-full">
              <div className="flex flex-col gap-4 pt-4">
                <div className="flex items-start justify-between">
                  <div className="flex items-center gap-2">
                    <span className="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center">
                      {lead.customer_profile.fullname.split(" ").length > 1
                        ? `${lead.customer_profile.fullname.split(" ")[0][0]}${
                            lead.customer_profile.fullname.split(" ")[1][0]
                          }`
                        : lead.customer_profile.fullname[0]}
                    </span>
                    <div className="flex flex-col">
                      {" "}
                      <div className="flex items-center gap-1">
                        <h2 className="font-medium text-sm leading-4">
                          {lead.customer_profile.fullname}
                        </h2>
                        |
                        <span className="flex text-muted-foreground text-sm items-center gap-1">
                          {/* <Phone size={14} /> */}
                          {lead.customer_profile.mobile}
                          {/* <FaWhatsapp size={16} /> */}
                        </span>
                        <div className="flex items-center justify-between">
                         
                          {lead.customer_profile?.mobile && (
                            <div className="flex items-center gap-1">
                              <img
                                onClick={() => {
                                  // setSelectedLead(item);
                                  setIsChatModalOpen(true);
                                }}
                                src={whatsapp_icon}
                                className="w-6 h-6"
                              />
                              <a className="text-primary hover:text-primary" href={`tel:${lead.customer_profile.mobile}`}>
                                <Phone size={18} />
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="text-sm">
                        <span className="text-muted-foreground">ID:</span>{" "}
                        {lead.id}
                      </div>
                    </div>
                  </div>
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <EllipsisVertical className="mt-2" size={16} />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuItem
                        onClick={() => {
                          setIsEditDialogOpen(true);
                        }}
                      >
                        Edit Enquiry
                      </DropdownMenuItem>
                      {/* <DropdownMenuItem>View Details</DropdownMenuItem> */}
                      <DropdownMenuItem>Edit Customer Profile</DropdownMenuItem>
                      <DropdownMenuItem>Send Email</DropdownMenuItem>
                      <DropdownMenuItem>Delete Enquiry</DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
                <div className="flex gap-2 items-center">
                  <div className="flex gap-2 items-center">
                    {lead.event?.pic && (
                      <div className="w-8 h-8 rounded-md relative overflow-hidden">
                        <img
                          src={`${import.meta.env.VITE_APP_API_URL}${
                            lead.event?.pic
                          }`}
                          className="absolute w-full h-full object-cover top-0 left-0"
                        />
                      </div>
                    )}
                    <span className="font-normal text-sm max-w-xs">
                      {lead?.event?.name}
                    </span>
                  </div>
                </div>

                <div className="flex gap-2">
                  {lead.is_pdf_downloaded && (
                    <Badge
                      className={
                        "items-center text-[0.6rem] rounded-md font-medium px-1 flex gap-1"
                      }
                      variant="outline"
                    >
                      <CircleCheckBig size={12} />
                      PDF Downloaded
                    </Badge>
                  )}
                  <Badge
                    className={
                      "items-center text-[0.6rem] rounded-md px-1 flex gap-1 font-medium"
                    }
                    variant={"outline"}
                  >
                    {lead.source == "instagram" ||
                    lead.source == "instacomment" ||
                    lead.source == "chatbot" ? (
                      <div className="">
                        {/* <FaInstagram className="" size={16} /> */}
                        {sourceImgs[lead.source]}
                        {/* <Badge
                          className={
                            "items-center text-[0.6rem] bg-[#e1306c] font-medium rounded-md px-1 flex gap-1"
                          }
                          variant="default"
                        >
                          
                        </Badge> */}
                      </div>
                    ) : lead?.source == "whatsapp" ? (
                      <div className="">
                        {sourceImgs[lead.source]}
                        {/* <Badge
                          className={
                            "items-center text-[0.6rem] bg-[#25d366] font-medium rounded-md px-1 flex gap-1"
                          }
                          variant="default"
                        >
                          
                        </Badge> */}
                      </div>
                    ) : (
                      <div className="">
                        {/* {item?.source} */}
                        {lead?.source !== "website"
                          ? sourceImgs[lead.source]
                          : ""}

                        {lead?.source?.includes("website") ||
                        (lead?.origin_domain?.includes("http") &&
                          !lead?.origin_domain?.includes("/linkinbio") &&
                          !lead?.origin_domain?.includes("logout.world")) ? (
                          <div className="flex items-center gap-1">
                            {sourceImgs["website"]} <span>Website</span>
                          </div>
                        ) : (
                          ""
                        )}

                        {!lead?.origin_domain?.includes("/linkinbio") &&
                          lead?.origin_domain?.includes("logout.world") && (
                            <div className="flex items-center gap-1">
                              {sourceImgs["logout"]} <span>Logout</span>
                            </div>
                          )}
                        {/* <Badge
                          className={
                            "items-center capitalize text-[0.6rem] font-medium rounded-md px-1 flex gap-1"
                          }
                          variant="outline"
                        >
                          
                        </Badge> */}
                      </div>
                    )}
                    {lead.source !== "website" && (
                      <span className="capitalize">{lead.source}</span>
                    )}
                  </Badge>
                  <Badge
                    className={
                      "items-center text-[0.6rem] rounded-md px-1 flex gap-1 font-medium"
                    }
                    variant="outline"
                  >
                    <Clock size={12} />
                    {format(lead?.created_at, "dd/MM/yyyy hh:mm aa")}
                  </Badge>
                </div>
                <div className="flex items-center justify-start gap-2">
                  <span className="w-3 h-3 rounded-full bg-green-400"></span>
                  <span className="text-xs text-muted-foreground">
                    Last Activity:{" "}
                    <span className="text-xs text-primary">
                      {formatDate(lead?.modified_at)}
                    </span>
                  </span>
                </div>
                <Separator />
                <div>
                  <div className="grid grid-cols-2 gap-x-6 gap-y-4">
                    <div className="flex flex-col gap-1">
                      <span className="text-muted-foreground text-xs">
                        Preference
                      </span>
                      <span className="text-sm">
                        {lead?.preferred_start_date ? formatDate(lead?.preferred_start_date) : '--'}
                      </span>
                    </div>
                    <div className="flex flex-col gap-1">
                      <span className="text-muted-foreground text-xs">Pax</span>
                      <span className="text-sm">{lead?.no_of_guests ? lead?.no_of_guests : '--'}</span>
                    </div>
                    <div className="flex flex-col gap-1">
                      <span className="text-muted-foreground text-xs">
                        Enquiry type
                      </span>
                      <span className="text-sm">{lead?.enquiryType} Us</span>
                    </div>
                    <div className="flex flex-col gap-1">
                      <span className="text-muted-foreground text-xs">
                        Category
                      </span>
                      <span className="text-sm capitalize">
                        {lead?.preferred_event_category ? lead?.preferred_event_category?.name : '--'}
                      </span>
                    </div>
                    <div className="flex flex-col gap-1">
                      <span className="text-muted-foreground text-xs">
                        Customer Message
                      </span>
                      <span className="text-sm">{lead?.message ? lead?.message : '--'}</span>
                    </div>
                    <div className="flex flex-col gap-1">
                      <span className="text-muted-foreground text-xs">
                        Status
                      </span>
                      <span className="text-sm capitalize">{lead?.status ? lead?.status : '--'}</span>
                    </div>
                    <div className="flex flex-col gap-1">
                      <span className="text-muted-foreground text-xs">
                        Stage
                      </span>
                      <span className="text-sm capitalize">
                        {lead?.funnel_stage ? lead?.funnel_stage : '--'}
                      </span>
                    </div>
                    <div className="flex flex-col gap-1">
                      <span className="text-muted-foreground text-xs">
                        Assigned to
                      </span>
                      <span className="text-sm">
                        {lead?.asigned_to ? lead?.asigned_to?.username : '--'}
                      </span>
                    </div>
                  </div>
                </div>
                <Separator className="my-0" />
                <div>
                  <div className="grid grid-cols-2 gap-y-4 justify-start items-end gap-2 w-full">
                    {/* <DropdownMenu>
                <DropdownMenuTrigger>
                  <Button
                    variant="outline"
                    className="h-8 w-full items-center text-[0.6rem]"
                  >
                    <CirclePlus size={12} />
                    Remarks & Reminders
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem onClick={() => setIsRemarksOpen(true)}>
                    Add Remarks
                  </DropdownMenuItem>

                  <DropdownMenuItem onClick={() => setIsRemindersOpen(true)}>
                    Add Reminders
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu> */}
                    {/* <div className="flex flex-col gap-1">
                    <Label className="font-normal" htmlFor="status">
                      Status
                    </Label>
                    <ComboboxDemo id="status" options={statusOptions} />
                  </div>
                  <div className="flex flex-col gap-1">
                    <Label className="font-normal" htmlFor="status1">
                      Stage
                    </Label>
                    <ComboboxDemo id="status1" options={stageOptions} />
                  </div>
                  <div className="flex flex-col gap-1">
                    <Label className="font-normal" htmlFor="status2">
                      Owner
                    </Label>
                    <ComboboxDemo id="status2" options={ownerOptions} />
                  </div> */}
                  </div>
                </div>
              </div>
            </TabsContent>
            <TabsContent value="timeline" className=" h-full">
              <div className="max-h-screen overflow-y-auto">
                <TimelineComp lead={lead.id} />
              </div>
            </TabsContent>
            <TabsContent value="remarks" className="w-full h-full">
              <div className="mt-4">
                <div>
                  <h2>Remarks</h2>
                  <Separator />
                </div>
                <div className="mt-4 flex flex-col gap-4">
                  {remarks?.map((remark, idx) => (
                    <Card key={idx} className="p-4">
                      <h2>{remark.follow_up_remark}</h2>
                      <p className="text-gray-500">
                        by:{" "}
                        <span>
                          {remark?.created_by?.username} @{" "}
                          {formatDate(remark?.follow_up_time)}
                        </span>
                      </p>
                    </Card>
                  ))}
                  <div className="flex justify-end">
                    <Button
                      onClick={() => {
                        setIsRemindersOpen(true);
                      }}
                      className=""
                    >
                      Add Remark
                    </Button>
                  </div>
                </div>
              </div>
            </TabsContent>
            <TabsContent value="chat" className=" h-full">
              <div className="max-h-screen overflow-y-auto">
                <ChatComponent number={lead.customer_profile?.mobile}/>
              </div>
            </TabsContent>
          </Tabs>
          <Dialog open={isRemindersOpen} onOpenChange={setIsRemindersOpen}>
            <DialogContent className="lg:max-w-md">
              <DialogHeader>
                <DialogTitle className="text-lg">Add Remark</DialogTitle>
              </DialogHeader>
              <div className="flex flex-col gap-4">
                <div className="grid w-full gap-1.5">
                  <Label htmlFor="remarkmessage">Remark</Label>
                  <Textarea
                    className="h-[100px] focus-visible:ring-offset-0 focus-visible:ring-0"
                    placeholder="Type your message here."
                    id="remarkmessage"
                    onChange={(e) => setRemarkMessage(e.target.value)}
                  />
                </div>
                {isReminderTrue && (
                  <div className="flex flex-col gap-2">
                    <div>
                      <Label htmlFor="remindto">Remind to</Label>
                      <Select
                        onValueChange={(value) => {
                          setSelectedTeamMate(value);
                        }}
                        required={true}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Select Assignee" />
                        </SelectTrigger>
                        <SelectContent>
                          {teamMates?.map((team) => (
                            <SelectItem
                              key={team.value}
                              value={team.value.toString()}
                            >
                              {team.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      {/* <ComboboxDemo id="remindto" defaultValue={selectedTeamMate} options={teamMates} onChange={(value) => {console.log("combo",value);setTeamMatesState(value);}} /> */}
                    </div>
                    <div>
                      <Label htmlFor="remindto">Reminder time</Label>
                      <DateTimePicker
                        id="remindto"
                        value={reminderTime}
                        onChange={(value) => {
                          setReminderTime(value);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="w-full">
                <Button className="w-full" onClick={() => handleReminderSave()}>
                  Save
                </Button>
              </div>
            </DialogContent>
          </Dialog>
          {isEditDialogOpen && (
            <EditEnquiry
              isOpen={isEditDialogOpen}
              setIsOpen={setIsEditDialogOpen}
              lead={lead}
              refetchApi={refetchApi}
            />
          )}
           {isChatModalOpen && (
          <LeadChatModal
            isOpen={isChatModalOpen}
            setIsOpen={setIsChatModalOpen}
            row={lead}
          />
        )}
        </ScrollArea>
      </DrawerContent>
    </Drawer>
  );
}
