import React from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { DateRangePicker } from "@/components/Custom/DateRangePicker";
import { useToast } from "@/hooks/use-toast";
import { useAuthStore } from "@/store/store";
import axios from "axios";
import { format } from "date-fns";

const DownloadBookingModal = ({
  isDownloadBookingOpen,
  setisDownloadBookingOpen,
}) => {
  const { toast } = useToast();
  const { companyslug } = useAuthStore();
  const today = new Date(); // Today's date
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);

  const formReducer = (state, action) => ({
    ...state,
    [action.name]: action.value,
  });

  const [formData, dispatch] = React.useReducer(formReducer, {
    type: "payment",
    mode: "all",
    timestamp_from_date: format(sevenDaysAgo,'dd/mm/yyyy'),
    timestamp_to_date: format(today,'dd/mm/yyyy'),
    startdate_from_date: "",
    startdate_to_date: "",
  });

  const handleChange = (field, value) => {
    if (field == "booking_timeline") {
      dispatch({ name: "timestamp_from_date", value: format(value.from,'dd/mm/yyyy') });
      dispatch({ name: "timestamp_to_date", value: format(value.to,'dd/mm/yyyy') });
    } else if (field == "activity_timeline") {
      dispatch({ name: "startdate_from_date", value: format(value.from,'dd/mm/yyyy') });
      dispatch({ name: "startdate_to_date", value: format(value.to,'dd/mm/yyyy') });
    } else {
      dispatch({ name: field, value: value });
    }
  };

  const downloadBooking = async function (e) {
    e.preventDefault();

    var DATAURL = "export-all-company-payments";
    if (formData["type"] === "payment") {
      DATAURL = "export-all-company-payments";
    } else {
      DATAURL = "export-all-company-guest";
    }
    const response = await axios.get(
      `${
        import.meta.env.VITE_APP_API_URL
      }/v3/${DATAURL}/${companyslug}/?timestamp_from_date=${
        formData.timestamp_from_date || ""
      }&timestamp_to_date=${
        formData.timestamp_to_date || ""
      }&mode=${formData.mode}&startdate_from_date=${
        formData.startdate_from_date || ""
      }&startdate_to_date=${formData.startdate_to_date || ""}`,
      {
        responseType: "blob", // Ensure response is treated as a file
      }
    );
    // Create a blob from the response
    const blob = new Blob([response.data], { type: "text/csv" });

    // Create a temporary URL
    const url = window.URL.createObjectURL(blob);

    // Create an anchor element and trigger a download
    const a = document.createElement("a");
    a.href = url;
    a.download = `Booking_Report_${new Date().toISOString()}.csv`; // Custom filename
    document.body.appendChild(a);
    a.click();

    // Cleanup
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };
  return (
    <div>
      <Dialog
        open={isDownloadBookingOpen}
        onOpenChange={setisDownloadBookingOpen}
      >
        <DialogContent
          className={"lg:max-w-sm  rounded-md lg:max-h-[80vh] max-h-[70vh]"}
        >
          <DialogHeader>
            <DialogTitle>Download Booking Report</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-2 justify-center align-middle">
            <div className="space-y-2 align-middle justify-center">
              <Label>Report Type</Label>
              <RadioGroup
                defaultValue="payment"
                className="flex gap-4"
                onValueChange={(value) => handleChange("type", value)}
                required={true}
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="payment" id="payment_mode" />
                  <Label htmlFor="payment_mode">Payment</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="guest_list" id="guest_list_mode" />
                  <Label htmlFor="guest_list_mode">Guest Lists</Label>
                </div>
              </RadioGroup>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="space-y-2">
              <Label>Booking Mode</Label>
              <RadioGroup
                defaultValue="all"
                className="flex gap-4"
                onValueChange={(value) => handleChange("mode", value)}
                required={true}
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="all" id="all_bookings" />
                  <Label htmlFor="all_bookings">All</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="online" id="online_bookings" />
                  <Label htmlFor="online_bookings">Online</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="manual" id="manual_bookings" />
                  <Label htmlFor="manual_bookings">Manual</Label>
                </div>
              </RadioGroup>
            </div>
          </div>
          <div className="grid gap-x-4 gap-y-4 grid-cols-1 md:grid-cols-2">
            <div className="flex flex-col gap-1 max-w-40">
              <Label>Booking Timeline</Label>

              <DateRangePicker
                defaultValue={{ from: today, to: sevenDaysAgo }}
                onChange={(value) => handleChange("booking_timeline", value)}
              />
            </div>
          </div>
          <div className="grid gap-x-4 gap-y-2 grid-cols-1 md:grid-cols-2">
            <div className="flex flex-col gap-1">
              <Label>Activity Timeline</Label>
              <DateRangePicker
                className="max-w-2"
                defaultValue={{ from: "", to: "" }}
                onChange={(value) => handleChange("activity_timeline", value)}
              />
            </div>
          </div>
          <Button className="" onClick={downloadBooking}>
            Download
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DownloadBookingModal;
