import { create } from 'zustand';
import axios from 'axios';
import { persist, createJSONStorage } from 'zustand/middleware'

const pathSegments = window.location.pathname.split("/");
const COMPANYSLUG = pathSegments[2]; // Assuming URL structure: /dashboard/{company_slug}/...

// Function to load data from local storage
const loadFromLocalStorage = () => {
  const storedData = localStorage.getItem(`companyData_${COMPANYSLUG}`);
  return storedData ? JSON.parse(storedData) : null;
};

// Store for managing multiple data objects related to the company
const companyStore = (set, get) => ({
  companyslug: COMPANYSLUG,

  // Load initial state from local storage if available
  companyData: loadFromLocalStorage() || {
    teamMembers: [],
    events: [],
    categories: [],
    data:[],
  },

  // Function to fetch and store company data
  fetchTeamMembers: async () => {
    set({ isLoading: true, isError: false });

    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/v3/company/${COMPANYSLUG}/team-details`
      );
      set({
        companyData: {
          ...get().companyData,
          teamMembers: response.data,
        },
        isLoading: false,
      });
      // Save data to local storage after fetching
      localStorage.setItem(`companyData_${COMPANYSLUG}`, JSON.stringify(get().companyData));
    } catch (error) {
      set({ isLoading: false, isError: true, error });
    }
  },

  // Function to fetch and store team members
  fetchCompanyData: async () => {
    set({ isLoading: true, isError: false });

    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/v3/company/${COMPANYSLUG}/`
      );
      set({
        companyData: {
          ...get().companyData,
          data: response.data,
        },
        isLoading: false,
      });
      // Save data to local storage after fetching
      localStorage.setItem(`companyData_${COMPANYSLUG}`, JSON.stringify(get().companyData));
    } catch (error) {
      set({ isLoading: false, isError: true, error });
    }
  },

  

  // Function to fetch and store events
  fetchEvents: async () => {
    set({ isLoading: true, isError: false });

    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/v3/company/${COMPANYSLUG}/all-events/`
      );
      set({
        companyData: {
          ...get().companyData,
          events: response.data,
        },
        isLoading: false,
      });
      // Save data to local storage after fetching
      localStorage.setItem(`companyData`, JSON.stringify(get().companyData));
    } catch (error) {
      set({ isLoading: false, isError: true, error });
    }
  },

  // Function to fetch and store categories
  fetchCategories: async () => {
    set({ isLoading: true, isError: false });

    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/v3/company/${COMPANYSLUG}/all-categories/`
      );
      set({
        companyData: {
          ...get().companyData,
          categories: response.data,
        },
        isLoading: false,
      });
      // Save data to local storage after fetching
      localStorage.setItem(`companyData_${COMPANYSLUG}`, JSON.stringify(get().companyData));
    } catch (error) {
      set({ isLoading: false, isError: true, error });
    }
  },

  // Update specific parts of company data
  updateCompanyData: (key, data) => {
    set((state) => {
      const newData = {
        ...state.companyData,
        [key]: data,
      };
      // Save the updated data to local storage
      localStorage.setItem(`companyData_${COMPANYSLUG}`, JSON.stringify(newData));
      return { companyData: newData };
    });
  },

  // Set the company slug
  setCompanySlug: (slug) => {
    set((state) => {
      localStorage.setItem(`companyslug`, slug);
      return { companyslug: slug };
    });
  },

  // Set the loading and error states
  setLoading: (loading) => set({ isLoading: loading }),
  setError: (error) => set({ isError: error }),
});

const useCompanyStore = create(persist(companyStore, {
  name: "company",
  storage: createJSONStorage(() => localStorage),
}));

export { useCompanyStore };