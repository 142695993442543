import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useEffect, useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { TemplateForm } from "@/components/whatsapp-automation/template-form.jsx";
import { TemplateList } from "@/components/whatsapp-automation/template-list.jsx";
import { ChevronLeft } from "lucide-react";
import { FaWhatsapp } from "react-icons/fa";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "../ui/button";
import axios from "axios";
import { useAuthStore } from "@/store/store";

export const WhatsAppAutomation = ({ isOpen, setIsOpen, event }) => {
  const [activeTab, setActiveTab] = useState("");
  const { companyslug } = useAuthStore();
  const [templates, setTemplates] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [activeTemplate, setActiveTemplate] = useState(null);

  const getTemplates = async () => {
    setIsLoading(true);
    try {
      // Assuming `activeTab`, `companyslug`, and `event.slug` are already defined
      const tab =
        activeTab == "Booking Confirmation"
          ? "booking_confirmation"
          : activeTab == "Heads Up"
          ? "booking_reminder"
          : "booking_feedback";
  
      // Make the Axios request
      const res = await axios.get(
        `${
          import.meta.env.VITE_APP_API_URL
        }/v3/get-templates/${companyslug}/${tab}/${event.slug}`
      );
  
      // If request is successful (status 200)
      if (res.status === 200) {
        setTemplates(res.data.data);
      }
    } catch (error) {
      // Handle the error (e.g., log it or show an error message)
      console.error("Error fetching templates:", error);
      setTemplates(null)
    } finally {
      // Always set loading to false regardless of success or failure
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTemplates();
  }, [activeTab]);

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent
          className={
            "lg:max-w-xl p-4 lg:max-h-[80vh] overflow-y-auto max-w-[25rem] max-h-[70vh]"
          }
        >
          <DialogHeader>
            <DialogTitle className={"flex justify-between"}>
              {activeTemplate && (
                <div
                  onClick={() => setActiveTemplate(null)}
                  className={
                    "flex underline cursor-pointer items-center gap-1 text-sm"
                  }
                >
                  <ChevronLeft size={16} />
                  Go back
                </div>
              )}
              {activeTab && !activeTemplate && (
                <div
                  onClick={() => setActiveTab(null)}
                  className={
                    "flex underline cursor-pointer items-center gap-1 text-sm"
                  }
                >
                  <ChevronLeft size={16} />
                  Go back
                </div>
              )}
              <div className="flex items-center gap-2">
                {!activeTemplate && !activeTab && (
                  <FaWhatsapp className="w-5 h-5" />
                )}
                Add WhatsApp template
              </div>
              <h2 className={"opacity-0 pointer-events-none"}>Heyyyyy</h2>
            </DialogTitle>
          </DialogHeader>
          {!activeTab && (
            <div className="grid gap-x-4 gap-y-4 grid-cols-2">
              {["Booking Confirmation", "Heads Up", "After Event"]?.map(
                (item) => {
                  let description = "";

                  switch (item) {
                    case "Booking Confirmation":
                      description =
                        "Send a detailed message with booking specifics, payment info,policies, etc. immediately after payment is completed.";
                      break;
                    case "Heads Up":
                      description =
                        "Send a reminder message a few hours before the batch starts, outlining essential items to bring, prerequisites, and last-minute details";
                      break;
                    case "After Event":
                      description =
                        "Send a message after the event, requesting feedback, sharing event photos drive link/recaps, and providing additional resources or next steps.";
                      break;
                    default:
                      description =
                        "Manage and track booking updates efficiently.";
                  }

                  return (
                    <Card className="relative p-2 flex flex-col justify-between" key={item}>
                      <CardHeader className="p-2">
                        <CardTitle className="text-lg">{item}</CardTitle>
                        <CardDescription className="text-xs md:text-sm">
                          {description}
                        </CardDescription>
                      </CardHeader>

                      <CardFooter className="p-2 ">
                        <div className="w-full">
                          <Button
                            onClick={() => setActiveTab(item)}
                            className="w-full"
                          >
                            View
                          </Button>
                        </div>
                      </CardFooter>
                    </Card>
                  );
                }
              )}

              {/* <Tabs defaultValue="booking" className="w-full">
              {!activeTemplate && (
                <TabsList className={"w-full"}>
                  <TabsTrigger className={"w-full"} value="booking">
                    Booking Confirmation
                  </TabsTrigger>
                  <TabsTrigger className={"w-full"} value="heads-up">
                    Heads Up
                  </TabsTrigger>
                  <TabsTrigger className={"w-full"} value="reminder">
                    After Event
                  </TabsTrigger>
                </TabsList>
              )}

              <TabsContent value="booking">
                
              </TabsContent>
              <TabsContent value="heads-up">
                {" "}
               nt>
              <TabsContent value="reminder">
                {" "}
                {activeTemplate == null ? (
                  <TemplateList
                    templates={templates}
                    setTemplates={setTemplates}
                    setActiveTemplate={setActiveTemplate}
                  />
                ) : (
                  <TemplateForm
                    activeTemplate={templates.find(
                      (o) => o.name == activeTemplate
                    )}
                    category={"after-event"}
                    setActiveTemplate={setActiveTemplate}
                  />
                )}
              </TabsContent>
            </Tabs> */}
            </div>
          )}
          <div>
            {activeTab == "Booking Confirmation" && (
              <div>
                {activeTemplate == null ? (
                  <TemplateList
                    templates={templates}
                    setTemplates={setTemplates}
                    setActiveTemplate={setActiveTemplate}
                    eventSlug={event.slug}
                    isLoading={isLoading}
                    category={"booking_confirmation"}
                  />
                ) : (
                  <TemplateForm
                    activeTemplate={templates?.find(
                      (o) => o.id == activeTemplate
                    )}
                    category={"booking_confirmation"}
                    setActiveTemplate={setActiveTemplate}
                    event={event}
                    getTemplates={getTemplates}
                  />
                )}
              </div>
            )}

            {activeTab == "Heads Up" && (
              <div>
                {activeTemplate == null ? 
                (
                    <TemplateList
                    templates={templates}
                    setTemplates={setTemplates}
                    setActiveTemplate={setActiveTemplate}
                    eventSlug={event.slug}
                    isLoading={isLoading}
                    category={'booking_reminder'}
                    />
                  
                ) : (
                  <TemplateForm
                    activeTemplate={templates?.find(
                      (o) => o.id == activeTemplate
                    )}
                    category={"booking_reminder"}
                    setActiveTemplate={setActiveTemplate}
                    event={event}
                    getTemplates={getTemplates}
                  />
                )}
              </div>
            )}
            {activeTab == "After Event" && (
              <div>
                {activeTemplate == null ? (
                 
                    <TemplateList
                    templates={templates}
                    setTemplates={setTemplates}
                    setActiveTemplate={setActiveTemplate}
                    eventSlug={event.slug}
                    isLoading={isLoading}
                    category={'booking_feedback'}
                    />
                  )
                 : (
                  <TemplateForm
                    activeTemplate={templates?.find(
                      (o) => o.id == activeTemplate
                    )}
                    category={"booking_feedback"}
                    setActiveTemplate={setActiveTemplate}
                    event={event}
                    getTemplates={getTemplates}
                  />
                )}
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
