import React, { useState, useEffect } from "react";
import { Card } from "@/components/ui/card";
import {
  DollarSign,
  Radio,
  Users,
  CreditCard,
  Activity,
  ChevronRight,
  Wallet,
  Undo2,
  CircleDashed,
  ChevronLeft,
} from "lucide-react";
import { useAuthStore } from "@/store/store";
import useFetch from "@/hooks/useFetchLSD";
import { Skeleton } from "./ui/skeleton";

export const MetricCard = ({ title, value, change, period, icon: Icon }) => (
  <Card className="w-full p-4">
    <div className="flex justify-between items-start">
      <div>
        <p className="text-sm text-gray-500">{title}</p>
        <h3 className="text-lg md:text-lg lg:text-xl font-medium mt-2">
          {value}
        </h3>
        {/* <p className="text-xs md:text-sm text-gray-500 mt-1">
          {change > 0 ? '+' : ''}{change}% from last {period}
        </p> */}
      </div>
      {/* <Icon className="h-5 w-5 text-gray-400" /> */}
    </div>
  </Card>
);

const CardInfo = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [touchVertStart, setTouchVertStart] = useState(0);
  const [touchVertEnd, setTouchVertEnd] = useState(0);

  const minSwipeDistance = 30;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
    setTouchVertEnd(0);
    setTouchVertStart(e.targetTouches[0].clientY);
  };

  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
    setTouchVertEnd(e.targetTouches[0].clientY);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    if (!touchVertStart || !touchVertEnd) return;
    const distance = touchStart - touchEnd;
    const distanceVert = touchVertStart - touchVertEnd;
    const isUpSwipe = distanceVert > minSwipeDistance;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (
      Math.abs(touchStart - touchEnd) > Math.abs(touchVertStart - touchVertEnd)
    ) {
      // setActiveTab(tabs[tabs.indexOf(activeTab) + (isLeftSwipe ? 1 : -1)] || activeTab)
      isLeftSwipe ? goToNextSlide() : isRightSwipe ? goToPrevSlide() : null;
    }
    // add your conditional logic here
  };

  const { companyslug } = useAuthStore();

  const {
    data: eventDetails,
    error: eventError,
    isLoading: eventLoading,
  } = useFetch(
    `${
      import.meta.env.VITE_APP_API_URL
    }/v3/events-active-count/${companyslug}/`,
    {},
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        retry: 2,
        enabled: true,
      },
    }
  );

  const {
    data: bookingDetails,
    error: bookingError,
    isLoading: bookingLoading,
  } = useFetch(
    `${
      import.meta.env.VITE_APP_API_URL
    }/v3/dashboard-booking-summary/${companyslug}/`,
    {},
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        retry: 2,
        enabled: true,
      },
    }
  );


  const metrics = [
    {
      title: "Live",
      value: bookingLoading ? (
        <Skeleton className="w-32 h-4 mt-4"></Skeleton>
      ) : (
        `${eventDetails?.data?.active_events?.toLocaleString("en-IN") || "🚫"} events | ${
          eventDetails?.data?.active_batches?.toLocaleString("en-IN") || "🚫"
        } batches`
      ),
      change: 20.1,
      period: "month",
      icon: Radio,
    },
    {
      title: "Today's Revenue",
      value: bookingLoading ? (
        <Skeleton className="w-32 h-4 mt-4"></Skeleton>
      ) : (
        `
         ₹${
          bookingDetails?.data?.todays_revenue !== undefined
            ? Number((bookingDetails?.data?.todays_revenue/100).toFixed(2))?.toLocaleString("en-IN")
            : "🚫"
        } |
        ${
          bookingDetails?.data?.todays_bookings_count !== undefined
            ? bookingDetails?.data?.todays_bookings_count?.toLocaleString("en-IN")
            : "🚫"
        } 
        booked 
       
        `
      ),
      change: 180.1,
      period: "month",
      icon: Wallet,
    },
    {
      title: "Today's Refunds",
      value: bookingLoading ? (
        <Skeleton className=" mt-4 w-32 h-4"></Skeleton>
      ) : (
        `
        ₹${
          bookingDetails?.data?.todays_refund_amount !== undefined
            ? Number((bookingDetails?.data?.todays_refund_amount/100).toFixed(2))?.toLocaleString("en-IN")
            : "🚫"
        } |
        ${
          bookingDetails?.data?.todays_refund !== undefined
            ? bookingDetails?.data?.todays_refund?.toLocaleString("en-IN")
            : "🚫"
        } 
        refunds 
        
        `
      ),
      change: 19,
      period: "month",
      icon: Undo2,
    },
    {
      title: "Pending Settlement",
      value: bookingLoading ? (
        <Skeleton className="w-32 h-4 mt-4"></Skeleton>
      ) : (
        `₹${Number((bookingDetails?.data?.pending_balance/100).toFixed(2)).toLocaleString("en-IN")}`
      ),
      change: 201,
      period: "hour",
      icon: CircleDashed,
    },
  ];

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);

    return () => window.removeEventListener("resize", checkIsMobile);
  }, []);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? metrics.length - 1 : prevIndex - 1
    );
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === metrics.length - 1 ? 0 : prevIndex + 1
    );
  };

  if (!isMobile) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full  mb-5">
        {metrics.map((metric) => (
          <MetricCard key={metric.title} {...metric} />
        ))}
      </div>
    );
  }

  return (
    <div className="w-full">
      <div
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        className="mb-4 relative"
      >
        <MetricCard {...metrics[currentIndex]} />
        <div className="flex  items-center justify-between space-x-4 mt-2">
          <div className="flex  space-x-2">
            {metrics.map((_, index) => (
              <button
                key={index}
                onClick={() => goToSlide(index)}
                className={`w-2 h-2 rounded-full bg-gray-300 transition-colors duration-200 ${
                  currentIndex === index ? "bg-gray-800" : "bg-gray-300"
                }`}
                aria-label={`Go to metric ${index + 1}`}
              />
            ))}
          </div>
          <div className="flex items-center gap-1">
            <button
              onClick={goToPrevSlide}
              className="p-2 bg-gray-100 rounded-full transition-colors duration-200"
              aria-label="Next metric"
            >
              <ChevronLeft className="h-3 w-3 text-gray-600" />
            </button>
            <button
              onClick={goToNextSlide}
              className="p-2 bg-gray-100 rounded-full transition-colors duration-200"
              aria-label="Next metric"
            >
              <ChevronRight className="h-3 w-3 text-gray-600" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardInfo;
