import React from "react";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { useCompanyStore } from "@/store/companyStore";


const BookingSuccess = ({form}) => {
    const allevents = useCompanyStore().companyData.events.data;
    const event = allevents.find((o)=>o.id == form.getValues('event'))
  return (
    <div className="flex flex-col justify-center items-center">
        
      <DotLottieReact
        src="https://lottie.host/60e56c3f-b453-43e6-b698-b0b0f4908d0b/S3LPuXAaw0.lottie"
        // loop
        autoplay
        // width={80}
        // height={80}
        className="w-32 h-32"
      />
      <h1 className="text-xl mb-2 font-semibold">Booking Successful!</h1>
      <h2 className="fomt-medium"><span className="text-muted-foreground mb-1 font-normal">Event Name:</span> {event?.name}</h2>
      <h2 className="font-medium"><span className="text-muted-foreground font-normal">Booked by:</span> {form.getValues('buyerData').name}</h2>
    </div>
  );
};

export default BookingSuccess;
