import { Input } from "@/components/ui/input.jsx";
import { Label } from "@/components/ui/label.jsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button.jsx";
import { useEffect, useState, useRef } from "react";
import { CirclePlus, X } from "lucide-react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { FaWhatsapp } from "react-icons/fa";
import { Switch } from "../ui/switch";
import axios from "axios";
import { useAuthStore } from "@/store/store";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

const placeholders = [
  {
    id: 1,
    text: "name",
  },
  {
    id: 2,
    text: "booking_id",
  },
  {
    id: 3,
    text: "booked_by",
  },
  {
    id: 4,
    text: "event_date",
  },
  {
    id: 5,
    text: "event_name",
  },
  {
    id: 6,
    text: "amount_paid",
  },
  {
    id: 7,
    text: "amount_pending",
  },
  {
    id: 8,
    text: "details_link",
  },
  {
    id: 9,
    text: "booking_email",
  },
  {
    id: 10,
    text: "company_name",
  },
  {
    id: 11,
    text: "ticket_url",
  },
  {
    id: 12,
    text: "location",
  },
];

export const TemplateForm = ({
  category,
  setActiveTemplate,
  activeTemplate,
  event,
  getTemplates,
}) => {
  const [template, setTemplate] = useState(
    activeTemplate
      ? activeTemplate
      : {
          name: "",
          category: category,
          type: "TextWithImage",
          header: "",
          footer: "",
          text: "",
          template_id: "",
          attachment_from_event: false,
          header_attachment: null,
        }
  );
  const { companyslug } = useAuthStore();
  const headerRef = useRef(null);
  const messageRef = useRef(null);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [counts, setCounts] = useState({
    header: activeTemplate?.header?.length || 0,
    text: activeTemplate?.text?.length || 0,
    footer: activeTemplate?.footer?.length || 0,
  });
  const [saving, setSaving] = useState(false);

  const handleMediaChange = (e) => {
    setFileSizeError(false);
    const size = e.target.files[0].size;
    if (size > 5000000) {
      setFileSizeError(true);
      return;
    }
    setTemplate({
      ...template,
      header_attachment: e.target.files[0],
    });
  };

  const insertPlaceholder = (text, ref) => {
    if (!ref.current) return;

    let cursorPosition = ref.current.selectionStart;
    let textBeforeCursorPosition = ref.current.value.substring(
      0,
      cursorPosition
    );
    let textAfterCursorPosition = ref.current.value.substring(cursorPosition);

    // Insert the text with curly braces
    ref.current.value =
      textBeforeCursorPosition + "{" + text + "}" + textAfterCursorPosition;

    // Calculate new cursor position - should be after the inserted text including braces
    let newCursorPosition = cursorPosition + text.length + 2; // +2 for the two braces { }

    // Need to wait for React to update the DOM

    setTimeout(() => {
      ref.current.focus();
      // ref.current.onchange()
      if (ref.current.id == "message") {
        handleChange("text", ref.current.value);
      } else {
        handleChange("header", ref.current.value);
      }

      ref.current.setSelectionRange(newCursorPosition, newCursorPosition);
    }, 0);
  };

  const handleChange = (field, value) => {
    // if (field == "content" || field == "header" || field == "footer")
    //   setTemplate({
    //     ...template,
    //     message: {
    //       ...template.message,
    //       [field]: value,
    //     },
    //   });
    // else {
    // }
    setTemplate({ ...template, [field]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const formElement = e.target
    setSaving(true);
    const formData = new FormData();
    Object.keys(template).forEach(function (key, index) {
      if (key != "status") formData.append(key, template[key]);
    });
    if (activeTemplate) {
      const res = await axios.post(
        `${import.meta.env.VITE_APP_API_URL}/v3/edit-template/${companyslug}/${
          event.slug
        }/${activeTemplate.id}/`,
        formData
      );
      console.log(res);
      setSaving(false);
      setActiveTemplate(null);
      getTemplates();
      return;
    }
    const res = await axios.post(
      `${import.meta.env.VITE_APP_API_URL}/v3/create-template/${companyslug}/${
        event.slug
      }/`,
      formData
    );
    console.log(res);
    setSaving(false);
    setActiveTemplate(null);
    getTemplates();
  };

  useEffect(() => {
    console.log(template);
  }, [template]);

  return (
    <>
      <Accordion
        defaultValue={activeTemplate ? "item-1" : ""}
        type="single"
        collapsible
      >
        <AccordionItem className={"border-none"} value="item-1">
          <AccordionTrigger
            className={"py-2 my-2 bg-teal-800 text-white px-2 rounded-md"}
          >
            <div className={"flex text-sm font-semibold items-center gap-2"}>
              <FaWhatsapp size={16} />
              Message Preview
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <div className="flex justify-center shadow-md items-center ">
              <div className="w-72 h-80 bg-[#f0e6df] rounded-t-3xl overflow-hidden relative">
                {/* Status Bar */}
                <div className="bg-teal-800 text-white p-2 flex items-center justify-between text-xs">
                  <div className="flex-1">3:40</div>
                  <div className="flex items-center gap-1">
                    <div className="h-2 w-3 bg-white rounded-sm"></div>
                    <div className="h-2 w-3 bg-white rounded-sm"></div>
                    <div className="h-2 w-3 bg-white rounded-sm"></div>
                    <div className="ml-1">📶</div>
                  </div>
                </div>

                {/* Header */}

                <div className="bg-teal-800 text-white p-2 flex items-center gap-2">
                  <div className="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 19l-7-7 7-7"
                      />
                    </svg>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="w-6 h-6 bg-gray-200 rounded-full"></div>
                    <div className="text-sm font-medium">Group Name</div>
                  </div>
                </div>

                {/* Message */}

                <div className={"h-full overflow-y-auto pb-16"}>
                  <div className="p-3">
                    <div className="bg-[#dcf8c8] p-3 rounded-lg max-w-xs">
                      {template.type == "TextWithImage" && (
                        <div className="flex mb-2 items-center justify-center w-full">
                          <div className="flex items-center justify-center w-full">
                            {(template?.header_attachment ||
                              template?.attachment_from_event) && (
                              <div className="relative h-32 w-full overflow-hidden rounded-md">
                                {/* Case when header_attachment is a File */}
                                {template?.header_attachment instanceof File ? (
                                  <div className="relative h-full w-full overflow-hidden rounded-md">
                                    {template?.header_attachment?.type?.includes(
                                      "image"
                                    ) ? (
                                      <img
                                        src={URL.createObjectURL(
                                          template?.header_attachment
                                        )}
                                        className="absolute object-cover w-full h-full"
                                        alt="attachment"
                                      />
                                    ) : (
                                      <object
                                        className="overflow-hidden"
                                        width="100%"
                                        height="100%"
                                        data={URL.createObjectURL(
                                          template.header_attachment
                                        )}
                                        type="application/pdf"
                                      ></object>
                                    )}
                                    <span
                                      onClick={() =>
                                        setTemplate({
                                          ...template,
                                          header_attachment: null,
                                        })
                                      }
                                      className="p-1 bg-black/40 rounded-full flex items-center justify-center absolute top-1 right-1"
                                    ></span>
                                  </div>
                                ) : (
                                  // Case when attachment_from_event is true or header_attachment is a URL
                                  <div className="w-full h-full">
                                    {!template?.header_attachment?.includes(
                                      ".pdf"
                                    ) ? (
                                      <img
                                        src={
                                          template?.attachment_from_event
                                            ? event.cover_pic
                                            : `${
                                                import.meta.env.VITE_APP_API_URL
                                              }${template.header_attachment}`
                                        }
                                        className="absolute object-cover w-full h-full"
                                        alt="attachment"
                                      />
                                    ) : (
                                      <object
                                        className="overflow-hidden"
                                        width="100%"
                                        height="100%"
                                        data={`${
                                          import.meta.env.VITE_APP_API_URL
                                        }${template.header_attachment}`}
                                        // type="application/pdf"
                                      ></object>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {template.type != "TextWithImage" && (
                        <div className="font-semibold mb-1">
                          <pre className={"whitespace-break-spaces"}>
                            {template?.header}
                          </pre>
                        </div>
                      )}
                      <p className="text-sm mb-2">
                        <pre className={"whitespace-break-spaces"}>
                          {template.text}
                        </pre>
                      </p>
                      <p className="text-xs mb-2 text-muted-foreground">
                        <pre className={"whitespace-break-spaces"}>
                          {template?.footer}
                        </pre>
                      </p>
                    </div>
                  </div>
                </div>

                {/* Scroll indicators */}
                <div className="absolute right-1 top-1/3 bottom-1/3 flex flex-col justify-between w-4">
                  <div className="w-1 h-4 bg-gray-300 rounded-full mx-auto"></div>
                  <div className="w-1 h-4 bg-gray-300 rounded-full mx-auto"></div>
                </div>
              </div>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <form onSubmit={handleSubmit} className={"mt-4"}>
        <div className={"flex flex-col gap-6 py-2"}>
          <div className={"flex flex-col md:flex-row items-end gap-6 md:gap-4"}>
            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="name">Template Name*</Label>
              <Input
                required
                onChange={(e) => {
                  handleChange("name", e.target.value);
                }}
                className={
                  "text-sm focus:outline-none focus-visible:outline-none"
                }
                defaultValue={template?.name}
                type="text"
                id="name"
                placeholder="Enter Template Name"
              />
            </div>
            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="type">Message Type</Label>
              <Select
                className={"w-full"}
                onValueChange={(value) =>
                  setTemplate({ ...template, type: value })
                }
                id={"type"}
                value={template.type}
                defaultValue={
                  activeTemplate ? activeTemplate.type : "TextWithImage"
                }
              >
                <SelectTrigger className="w-full max-w-xl">
                  <SelectValue placeholder="Message Type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="TextWithImage">
                    Message with attachment
                  </SelectItem>
                  <SelectItem value="Text">Text Only Message</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
          {template.type == "TextWithImage" && (
            <div>
              <div className="flex mb-6 justify-between w-full">
                <span>Use event image as attachment</span>
                <Switch
                  defaultChecked={template.attachment_from_event}
                  onCheckedChange={(value) => {
                    if (value) {
                      setTemplate({
                        ...template,
                        header_attachment: event.cover_pic,
                        attachment_from_event: true,
                      });
                    } else {
                      setTemplate({
                        ...template,
                        header_attachment: null,
                        attachment_from_event: false,
                      });
                    }
                  }}
                  className="h-5"
                />
              </div>
              <div className="flex items-center justify-center w-full">
                {template?.header_attachment ||
                template?.attachment_from_event ? (
                  <div className="relative h-48 w-full overflow-hidden rounded-md">
                    {/* If header_attachment is a File */}
                    {template?.header_attachment instanceof File ? (
                      <div className="relative h-full w-full overflow-hidden rounded-md">
                        {/* If it's an image */}
                        {template?.header_attachment?.type?.includes(
                          "image"
                        ) ? (
                          <img
                            src={URL.createObjectURL(
                              template?.header_attachment
                            )}
                            className="absolute object-cover w-full h-full"
                            alt="attachment"
                          />
                        ) : (
                          // If it's a PDF
                          <object
                            className="overflow-hidden"
                            width="100%"
                            height="100%"
                            data={URL.createObjectURL(
                              template.header_attachment
                            )}
                            type="application/pdf"
                          ></object>
                        )}
                        {/* Clear attachment button */}
                        <span
                          onClick={() =>
                            setTemplate({
                              ...template,
                              header_attachment: null,
                            })
                          }
                          className="p-1 bg-black/40 rounded-full flex items-center justify-center absolute top-1 right-1"
                        >
                          <X size={16} color="#fff" />
                        </span>
                      </div>
                    ) : (
                      // If it's an image URL or attachment from event
                      <div className="h-full w-full">
                        {!template?.header_attachment?.includes(".pdf") ? (
                          <img
                            src={
                              template?.attachment_from_event
                                ? event.cover_pic
                                : `${import.meta.env.VITE_APP_API_URL}${
                                    template.header_attachment
                                  }`
                            }
                            className="absolute object-cover w-full h-full"
                            alt="attachment"
                          />
                        ) : (
                          <object
                            className="overflow-hidden"
                            width="100%"
                            height="100%"
                            data={`${import.meta.env.VITE_APP_API_URL}${
                              template.header_attachment
                            }`}
                            type="application/pdf"
                          ></object>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  // Placeholder for no attachment
                  <label
                    htmlFor="dropzone-file"
                    className="flex flex-col my-2 items-center justify-center w-full h-36 border-[1px] border-gray-300 border-dashed rounded-lg cursor-pointer"
                  >
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg
                        className="w-6 h-6 mb-4 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 16"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                        />
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">Click to upload</span>
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        PNG, JPG or PDF (MAX. 5MB)
                      </p>
                      {fileSizeError && (
                        <p className="text-sm mt-1 font-medium text-red-500 dark:text-red-400">
                          Please upload a file less than 5MB
                        </p>
                      )}
                    </div>
                    <input
                      onChange={handleMediaChange}
                      id="dropzone-file"
                      type="file"
                      className="hidden"
                      accept="image/png, image/jpeg, image/jpg, application/pdf"
                    />
                  </label>
                )}
              </div>
            </div>
          )}
          {(category == "booking_reminder" ||
            category == "booking_feedback") && (
            <div className="relative">
              <Label className={"flex relative items-center"}>
                Send this to cust.{" "}
                <Input
                  type={"number"}
                  className={"w-16 mx-2 md:w-24 h-8 text-sm"}
                  placeholder={"Minutes"}
                  onChange={(e) => {
                    setTemplate({ ...template, time: e.target.value });
                  }}
                />{" "}
                mins{" "}
                {category == "booking_reminder"
                  ? "before the event"
                  : "after the event"}
                <span className="text-xs hidden md:flex ml-1 text-muted-foreground">
                  (*24 hrs = 1440 mins)
                </span>
              </Label>
              <span className="text-xs ml-1 flex md:hidden text-muted-foreground">
                (*24 hrs = 1440 mins)
              </span>
            </div>
          )}
          {template?.type?.toLowerCase() == "text" && (
            <div className="grid w-full items-center gap-1.5">
              <div className={"flex justify-between leading-4"}>
                <Label htmlFor="header">
                  Header
                  {/* <span className="text-xs text-muted-foreground">{" "}(Max. length: 60)</span> */}
                </Label>
                <DropdownMenu>
                  <DropdownMenuTrigger
                    className={"text-sm h-3 flex items-center gap-1"}
                  >
                    <CirclePlus size={14} /> Insert Placeholders
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className={"max-h-60 overflow-y-auto"}>
                    {placeholders.map((holder) => (
                      <DropdownMenuItem
                        onSelect={() => {
                          insertPlaceholder(holder.text, headerRef);
                        }}
                        key={holder.id}
                      >
                        {holder.text}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
              <Textarea
                maxLength={60}
                onChange={(e) => {
                  handleChange("header", e.target.value);
                  const updated = { ...counts };
                  updated["header"] = e.target.value.length;
                  setCounts(updated);
                }}
                ref={headerRef}
                defaultValue={template?.header}
                id={"header"}
                className={
                  "min-h-[60px] text-sm focus:outline-none focus-visible:outline-none"
                }
                placeholder={"Enter header message"}
              />
              <div className="flex justify-end">
                <span className="text-[10px] text-muted-foreground">
                  {counts["header"]}/60 characters
                </span>
              </div>
            </div>
          )}
          <div className="grid w-full items-center gap-1.5">
            <div className={"flex justify-between leading-4"}>
              <Label htmlFor="message">
                Message Content*
                {/* <span className="text-xs text-muted-foreground">{" "}(Max. length: 1024)</span> */}
              </Label>
              <DropdownMenu>
                <DropdownMenuTrigger
                  className={"text-sm h-3 flex items-center gap-1"}
                >
                  <CirclePlus size={14} /> Insert Placeholders
                </DropdownMenuTrigger>
                <DropdownMenuContent className={"max-h-60 overflow-y-auto"}>
                  {placeholders.map((holder) => (
                    <DropdownMenuItem
                      onSelect={() => {
                        insertPlaceholder(holder.text, messageRef);
                      }}
                      key={holder.id}
                    >
                      {holder.text}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            <Textarea
              required
              maxLength={1024}
              ref={messageRef}
              id={"message"}
              onChange={(e) => {
                handleChange("text", e.target.value);
                const updated = { ...counts };
                updated["text"] = e.target.value.length;
                setCounts(updated);
              }}
              defaultValue={template?.text}
              className={
                "min-h-[100px] text-sm focus:outline-none focus-visible:outline-none"
              }
              placeholder={"Enter message"}
            />
            <div className="flex justify-end">
              <span className="text-[10px] text-muted-foreground">
                {counts["text"]}/1024 characters
              </span>
            </div>
          </div>
          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="footer">Footer Content</Label>
            <Textarea
              maxLength={60}
              onChange={(e) => {
                handleChange("footer", e.target.value);
                const updated = { ...counts };
                updated["footer"] = e.target.value.length;
                setCounts(updated);
              }}
              id={"footer"}
              defaultValue={template?.footer}
              className={
                "min-h-[60px] text-sm focus:outline-none focus-visible:outline-none"
              }
              placeholder={"Enter footer message"}
            />
            <div className="flex justify-end">
              <span className="text-[10px] text-muted-foreground">
                {counts["footer"]}/60 characters
              </span>
            </div>
          </div>
          <div className={"flex justify-end gap-2"}>
            <Button
              variant={"outline"}
              onClick={() => setActiveTemplate(null)}
              type={"button"}
              className="w-24"
            >
              Cancel
            </Button>
            <Button className="w-24" type={"submit"}>
              {saving ? <div className="dots"></div> : "Save"}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};
