import React, { useEffect } from "react";
import { timelineData } from "@/lib/data";
import { MoveRight, Check, Calendar } from "lucide-react";
import { bookingTimelineData } from "@/lib/data";
import axios from "axios";
import { useAuthStore } from "@/store/store";
import useFetch from "@/hooks/useFetchLSD";
import { format, parseISO } from "date-fns";
import { Badge } from "../ui/badge";

const TimelineComp = ({ lead }) => {
  const [timeline, setTimeline] = React.useState([]);
  function formatDateTime(dateTime) {
    if (!dateTime) {
      return null;
    }

    return new Date(dateTime).toLocaleString("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  }
  React.useEffect(() => {
    const getTimeline = async () => {
      try {
        const response = await axios.get(
          `${import.meta.env.VITE_APP_API_URL}/v3/get-enquiry-timeline/${lead}/`
        );
        setTimeline(response.data?.response);
      } catch (error) {
        console.error(
          "Error fetching Timeline:",
          error.response ? error.response.data : error.message
        );
      }
    };
    getTimeline();
  }, []);
  return (
    <div>
      <ol className="relative border-s ml-4 mt-4 border-gray-200 dark:border-gray-700">
        {timeline.map((item, index) => {
          return (
            <li className="mb-6 ms-6" key={index}>
              <span className="absolute flex items-center justify-center w-6 h-6 border-2 bg-white rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                <svg
                  className="w-2.5 h-2.5 dark:text-blue-300"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                </svg>
              </span>
              <h3 className="flex items-center mb-1 text-md font-semibold text-gray-900 dark:text-white">
                {formatDateTime(item.timestamp)}
              </h3>
              <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                {timeline.length == index + 1 ? "Created" : "Updated"}
              </time>
              {Object.entries(item.changes).map(([key, values]) => (
                <p
                  className=" text-base font-normal dark:text-gray-400"
                  key={key}
                >
                  <span className="flex items-center gap-1">
                    {key}:
                    {values.map((val, idx) => (
                      <>
                        {idx == 1 ? (
                          <>
                            <MoveRight size={14} />
                            <span className="max-w-24">{val}</span>
                          </>
                        ) : (
                          <span>{val}</span>
                        )}
                      </>
                    ))}
                  </span>
                </p>
              ))}
              <p className="flex opacity-60 items-center gap-1">
                <span>{item.actor_name}</span>
                {/* <span>|</span> */}
                <span>({item.actor_email})</span>
              </p>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default TimelineComp;

export const TransactionTimeline = ({
  booking_id,
  transactionAdded,
  newTransactionAdded,
}) => {
  const { companyslug } = useAuthStore();
  const ROOT_URL = `${
    import.meta.env.VITE_APP_API_URL
  }/v3/bookings/${companyslug}/${booking_id}/transaction-timeline/`;

  const {
    data: transactionTimelineData,
    error: transactionTimelineError,
    isLoading: transactionTimelineLoading,
    refetch: refetchTransactionTimeline,
  } = useFetch(
    ROOT_URL,
    {},
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: 1,
        enabled: true,
        keepPreviousData: true,
      },
    }
  );

  useEffect(() => {
    refetchTransactionTimeline();
  }, [transactionAdded, newTransactionAdded]);

  const setTransactionTitle = (type, category) => {
    if (type === "payment") {
      if (category === "REFUND") {
        return "REFUND GENERATED";
      } else if (
        category === "ONLINE_BOOKING" ||
        category === "MANUAL_BOOKING"
      ) {
        return "BOOKING RECEIVED";
      } else if (
        category === "RAZORPAY_PAYMENT_LINK" ||
        category === "CUSTOM_PAYMENT_LINK"
      ) {
        return "PAYMENT RECEIVED";
      } else {
        return "MANUAL PAYMENT RECEIVED";
      }
    } else {
      if (category === "REFUND") {
        return "AMOUNT SETTLED FOR REFUND";
      } else if (category === "ONLINE_BOOKING") {
        return "AMOUNT SETTLED FOR ONLINE BOOKING";
      } else if (
        category === "RAZORPAY_PAYMENT_LINK" ||
        category === "CUSTOM_PAYMENT_LINK"
      ) {
        return "AMOUNT SETTLED FOR PAYMENT LINK";
      }
    }
  };

  return (
    <div>
      <ol className="relative border-s ml-4 mt-4 border-gray-200 dark:border-gray-700">
        {transactionTimelineData &&
          transactionTimelineData?.results?.map((item, index) => {
            return (
              <li className="mb-6 ms-6" key={index}>
                <span className="absolute flex items-center justify-center w-6 h-6 border-2 bg-white rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                  <Check size={14} />
                </span>
                <h2
                  className={`flex uppercase items-center mb-1 text-sm font-semibold dark:text-white`}
                >
                  {setTransactionTitle(item.type, item.category)}
                </h2>
                <div>
                  <p className="text-xs">
                    <span className="text-muted-foreground capitalize text-xs">
                      Paid On:{" "}
                      <b>
                        {format(
                          parseISO(item.timestamp),
                          "d MMM, yyyy | hh:mm a"
                        )}
                      </b>
                    </span>
                  </p>
                  <p className="text-xs">
                    <span className="text-muted-foreground capitalize text-xs">
                      Amount: <b>{(item.amount / 100).toFixed(2)}</b>
                    </span>
                  </p>
                  {item.payment_id && item.type === "payment" && (
                    <p className="text-xs">
                      <span className="text-muted-foreground capitalize text-xs">
                        Payment Id: {item.payment_id}
                      </span>
                    </p>
                  )}
                  {item.type === "settlement" && (
                    <p className="text-xs">
                      <span className="text-muted-foreground capitalize text-xs">
                        Settlement Id: {item.settlement_id}
                      </span>
                    </p>
                  )}
                </div>
              </li>
            );
          })}
      </ol>
    </div>
  );
};
