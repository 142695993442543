"use client";

import * as React from "react";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ArrowUpDown, ChevronDown, MoreHorizontal, SquarePen } from "lucide-react";
import {DateRangePicker} from "@/components/Custom/DateRangePicker"
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Search } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { ListFilter } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

const data = [
  {
    bookingid: 1,
    id: "3u1reuv4",
    amount: 316,
    name: "Riya",
    email: "ken99@yahoo.com",
    status: "Success",
    paymentMethod: "Credit Card",
    date: "2025-01-01",
    location: "New York",
    contact: "1234567890",
    remarks:  <SquarePen />,
  },
  {
    bookingid: 2,
    id: "5b23reuv4",
    amount: 242,
    name: "Neha",
    email: "Abe45@gmail.com",
    status: "Pending",
    paymentMethod: "PayPal",
    date: "2025-01-03",
    location: "Online",
    contact: "9876543210",
    remarks: <SquarePen />,
  },
  {
    bookingid: 3,
    id: "5b23reuv4",
    amount: 242,
    name: "Ritu",
    email: "Abe45@gmail.com",
    status: "Pending",
    paymentMethod: "PayPal",
    date: "2025-01-03",
    location: "Online",
    contact: "9876543210",
    remarks: <SquarePen />,
  },
  {
    bookingid: 4,
    id: "5b23reuv4",
    amount: 242,
    name: "Akshay",
    email: "Abe45@gmail.com",
    status: "Pending",
    paymentMethod: "PayPal",
    date: "2025-01-03",
    location: "Online",
    contact: "9876543210",
    remarks:  <SquarePen />,
  },
  {
    bookingid: 5,
    id: "5b23reuv4",
    amount: 242,
    name: "SAURABH MISHRA",
    email: "Abe45@gmail.com",
    status: "Pending",
    paymentMethod: "PayPal",
    date: "2025-01-03",
    location: "Online",
    contact: "9876543210",
    remarks:  <SquarePen />,
  },
  // Add more rows as needed
];

const columns = [
  {
    accessorKey: "bookingid",
    header: "ID",
    cell: ({ row }) => <div>{row.getValue("bookingid")}</div>,
  },
  {
    accessorKey: "name",
    header: "Name",
    cell: ({ row }) => <div>{row.getValue("name")}</div>,
  },
  {
    accessorKey: "weight",
    header: "Weight",
    cell: ({ row }) => <div>{row.getValue("weight") || "N/A"}</div>,
  },
  {
    accessorKey: "city",
    header: "City",
    cell: ({ row }) => <div>{row.getValue("city") || "N/A"}</div>,
  },
  {
    accessorKey: "pendingBalance",
    header: "Pending Balance",
    cell: ({ row }) => <div>{row.getValue("pendingBalance") || "N/A"}</div>,
  },
  {
    accessorKey: "contact",
    header: "Contact",
    cell: ({ row }) => <div>{row.getValue("contact") || "N/A"}</div>,
  },
  {
    accessorKey: "eventDateTime",
    header: "Event Date/Time",
    cell: ({ row }) => <div>{row.getValue("eventDateTime") || "N/A"}</div>,
  },
  {
    accessorKey: "addOns",
    header: "Add-On(s)",
    cell: ({ row }) => <div>{row.getValue("addOns") || "N/A"}</div>,
  },
  {
    accessorKey: "location",
    header: "Location",
    cell: ({ row }) => <div>{row.getValue("location") || "N/A"}</div>,
  },
  {
    accessorKey: "remarks",
    header: "Remarks",
    cell: ({ row }) => <div>{row.getValue("remarks") ||""}</div>,
  },
  {
    accessorKey: "guestDetails",
    header: "Guest Details",
    cell: ({ row }) => <div>{row.getValue("guestDetails") || "N/A"}</div>,
  },
  {
    id: "actions",
    header: "Action",
    cell: ({ row }) => {
      return (
        <Button
          variant="outline"
          className="bg-black text-white"
          onClick={() => alert(`Reminder sent to ${row.original.name}`)}
        >
          Send Reminder
        </Button>
      );
    },
  },
];


export function DataTableDemo() {
  const [sorting, setSorting] = React.useState([]);
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [rowSelection, setRowSelection] = React.useState({});

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  return (
    <div className="w-full">
      <div>
        <h1 className="text-2xl pb-0"><b>Operation Summary</b></h1>
      </div>

      <div className="flex gap-2 items-center pb-4 pt-2">
        <div className="bg-background/95 w-full backdrop-blur supports-[backdrop-filter]:bg-background/60">
          <form>
            <div className="relative">
              <Search className="absolute left-2 top-2 h-4 w-4 text-muted-foreground" />
              <Input
                placeholder="Search"
                className="pl-8 h-8 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
              />
            </div>
          </form>
        </div>

        <Popover align="end">
          <PopoverTrigger>
            <Button className="h-8" variant="outline">
              <ListFilter size={14} />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto">
            <div className="grid gap-x-4 gap-y-4 grid-cols-1 md:grid-cols-2">
              <div className="flex flex-col gap-1">
                <Label>Booking date</Label>
                <DateRangePicker className="my-4" />
              </div>
            </div>
            <div className="flex gap-4 mt-4 md:mt-2 justify-between md:justify-end">
              <Button variant="outline">Cancel</Button>
              <Button>Apply</Button>
            </div>
          </PopoverContent>
        </Popover>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto">
              Columns <ChevronDown />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start" className="flex flex-col gap-2 p-2">
            <div className="flex gap-2">
              <Button variant="outline" className="text-sm bg-black text-white">
                Download
              </Button>
              <Button variant="outline" className="text-sm bg-black text-white">
                No Data Booking
              </Button>
            </div>
            <DropdownMenuSeparator />
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) =>
                      column.toggleVisibility(!!value)
                    }
                  >
                    {column.id}
                  </DropdownMenuCheckboxItem>
                );
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

    

      <div className="rounded-md border">
        <Table>
          <TableHeader className="bg-neutral-50">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead className="py-2" key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of {" "}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}

