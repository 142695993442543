import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Label } from "../ui/label";
import { DateTimePicker } from "../Custom/DateTimePicker";
import { Separator } from "../ui/separator";
import { Input } from "../ui/input";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Checkbox } from "../ui/checkbox";
import {
  CirclePlus,
  EllipsisVertical,
  Info,
  TicketPlus,
  Trash,
} from "lucide-react";
import { Button } from "../ui/button";
import { Switch } from "../ui/switch";
import { Plus } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import { Ticket } from "lucide-react";
import { MultiSelect } from "../ui/multi-select";
import { Textarea } from "../ui/textarea";
import { DatePicker } from "../Custom/DatePicker";
import { ComboboxDemo } from "../Custom/ComboboxInput";
import useEventStore from "@/store/eventStore";
import axios from "axios";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { add } from "lodash";
import { format } from "date-fns";
import MultipleBatches from "./add-multiple-batches";
import { useToast } from "@/hooks/use-toast";
import { useCompanyStore } from "@/store/companyStore";

const AddBatchesModal = ({
  eventSlug,
  isOpen,
  setIsOpen,
  filteredBatches,
  setFilteredBatches,
  handleBatchCreation,
}) => {
  const { toast } = useToast();
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [isAddonDropdownOpen, setIsAddonDropdownOpen] = React.useState(false);

  const [batchData, setBatchData] = React.useState([]);
  const [buttonText, setButtonText] = useState("Update");

  const [batches, setBatches] = useState([
    {
      batch_size: "",
      price: "",
      price_in_usd: "",
      min_booking_price: "",
      min_booking_price_in_usd: "",
      eventbatchprice: [],
      addonbatchprice: [],
    },
  ]);

 

  const addBatch = () => {
    setBatches((prev) => [
      ...prev,
      {
        batch_size: "",
        price: "",
        price_in_usd: "",
        min_booking_price: "",
        min_booking_price_in_usd: "",
        eventbatchprice: [],
        addonbatchprice: [],
      },
    ]);
  };

  const updateBatchField = (index, field, value) => {
    setBatches((prevBatches) => {
      const updatedBatches = [...prevBatches];
      updatedBatches[index] = {
        ...updatedBatches[index],
        [field]: value, // Dynamically update the specified field
      };
      return updatedBatches;
    });
  };

  // const handleMoreOptionsChange = (field, value) => {
  //   setMoreOptions((prev) => ({
  //     ...prev,
  //     [field]: value,
  //   }));
  // };

  useEffect(() => {
    console.log("Tickets:", batches);
  }, [batches]);

  const addTicket = (batchIndex) => {
    setBatches((prevBatches) => {
      const updatedBatches = [...prevBatches];
      updatedBatches[batchIndex].eventbatchprice.push({
        attribute: "",
        price: "",
        inventory: "",
        is_eligible_for_coupon_discount: false,
        soldOut: false,
      });
      return updatedBatches;
    });
  };

  const addAddon = (batchIndex) => {
    setBatches((prevBatches) => {
      const updatedBatches = [...prevBatches];
      updatedBatches[batchIndex].addonbatchprice.push({
        attribute: "",
        price: "",
      });
      return updatedBatches;
    });
  };

  const updateTicket = (batchIndex, ticketIndex, key, value) => {
    setBatches((prevBatches) => {
      const updatedBatches = [...prevBatches];
      updatedBatches[batchIndex].eventbatchprice[ticketIndex][key] = value;
      return updatedBatches;
    });
  };

  const updateAddon = (batchIndex, addonIndex, key, value) => {
    setBatches((prevBatches) => {
      const updatedBatches = [...prevBatches];
      updatedBatches[batchIndex].addonbatchprice[addonIndex][key] = value;
      return updatedBatches;
    });
  };

  const removeBatch = (index) => {
    setBatches((prevBatches) => prevBatches.filter((_, i) => i !== index));
  };

  const removeTicket = (batchIndex, ticketIndex) => {
    setBatches((prevBatches) => {
      const updatedBatches = [...prevBatches];
      updatedBatches[batchIndex].eventbatchprice = updatedBatches[
        batchIndex
      ].eventbatchprice.filter((_, i) => i !== ticketIndex);
      return updatedBatches;
    });
  };

  const removeAddon = (batchIndex, addonIndex) => {
    setBatches((prevBatches) => {
      const updatedBatches = [...prevBatches];
      updatedBatches[batchIndex].addonbatchprice = updatedBatches[
        batchIndex
      ].addonbatchprice.filter((_, i) => i !== addonIndex);
      return updatedBatches;
    });
  };

  function replaceEmptyStringsWithNull(data) {
    if (Array.isArray(data)) {
      return data.map((item) => replaceEmptyStringsWithNull(item));
    } else if (data !== null && typeof data === "object") {
      return Object.fromEntries(
        Object.entries(data).map(([key, value]) => [
          key,
          value === "" ? null : replaceEmptyStringsWithNull(value),
        ])
      );
    }
    return data;
  }

  const addBatches = async () => {
    toast({
      title: "Creating batch...",
      variant: "loading",
      duration: 2000,
    });
    setButtonText(<div className="dots"></div>);
    const updated = replaceEmptyStringsWithNull([...batches]);
    const updatedBatches = filteredBatches.map((batch) =>
      batch.id == updated.id ? { ...batch, ...updated } : batch
    );
    // setFilteredBatches(updatedBatches);
    // console.log("Batch Data:", tickets);
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_APP_API_URL}/v3/add-batches/${eventSlug}/`,
        { batchData: updated }
      );
      if (response.status == 200) {
        toast({
          title: "Batch Created",
          variant: "success",
          duration: 2000,
        });
        await handleBatchCreation();
        setIsOpen(false);
        setButtonText("Updated");
        setTimeout(() => {
          setButtonText("Update");
        }, 3000);
      }
      // setLoading(false)
    } catch (error) {
      toast({
        title: error?.message || "Error creating batch",
        variant: "error",
        duration: 2000,
      });
      console.error("Error updating item status:", error);
    }
  };

  return (
    <div>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent
          className={
            "lg:max-w-2xl p-4 overflow-y-scroll rounded-md lg:max-h-[80vh] max-h-[70vh]"
          }
        >
          <DialogHeader>
            <DialogTitle>Add Batches</DialogTitle>
          </DialogHeader>
          <div className="w-full">
            <Tabs defaultValue="current" className="w-full">
              <TabsList className="w-full">
                <TabsTrigger className="w-full" value="current">
                  Add Batch
                </TabsTrigger>
                <TabsTrigger className="w-full" value="multiple">
                  Add Multiple Batches
                </TabsTrigger>
              </TabsList>
              <TabsContent value="current">
                {batches.map((batch, index) => (
                  <div key={batch.id}>
                    <NewBatchForm
                      id={batch.id}
                      index={index}
                      handleTicketChange={updateTicket}
                      handleAddonChange={updateAddon}
                      handleAddTicket={addTicket}
                      handleAddAddon={addAddon}
                      handleRemoveTicket={removeTicket}
                      handleRemoveAddon={removeAddon}
                      tickets={batch.eventbatchprice}
                      addons={batch.addonbatchprice}
                      updateBatch={updateBatchField}
                      batch={batch}
                    />
                    <div className="flex mb-2 items-center gap-4 justify-end">
                      <span
                        className="cursor-pointer flex underline text-sm items-center gap-1"
                        onClick={() => {
                          removeBatch(index);
                        }}
                      >
                        <Trash size={16} />
                        Remove
                      </span>
                      {index == batches.length - 1 && (
                        <span
                          onClick={addBatch}
                          className="flex cursor-pointer underline text-sm items-center gap-1"
                        >
                          <CirclePlus size={16} />
                          Add batch
                        </span>
                        // <Button className="mb-2" onClick={addBatch}>
                        //   Add Batch
                        // </Button>
                      )}
                    </div>
                    {index >= 0 && <hr className="border-black/20 py-2" />}
                  </div>
                ))}
                <div className="flex w-full justify-end">
                  <Button className="ml-auto text-base" onClick={addBatches}>
                    Save
                  </Button>
                </div>
              </TabsContent>
              <TabsContent value="multiple">
                <MultipleBatches handleBatchCreation={handleBatchCreation} eventSlug={eventSlug} setIsOpen={setIsOpen} />
              </TabsContent>
            </Tabs>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddBatchesModal;

const NewBatchForm = ({
  batch,
  index,
  updateBatch,
  handleTicketChange,
  handleAddonChange,
  handleAddTicket,
  handleAddAddon,
  handleRemoveTicket,
  handleRemoveAddon,
  tickets,
  addons,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [isAddonDropdownOpen, setIsAddonDropdownOpen] = React.useState(false);
  const [moreOptions, setMoreOptions] = useState({});

  const [teamMates, setTeamMates] = useState([]);
  const [selectedTeamMates, setSelectedTeamMates] = useState([]);

  const team_mates = useCompanyStore().companyData.teamMembers.data.team_mates;

  const options = [
    { value: "event1", label: "Event 1 Event 1 Event 1 Event 1 Event 1" },
    { value: "event2", label: "Event 2" },
    { value: "event3", label: "Event 3 Event 3 Event 3 Event 3 Event 3" },
    { value: "event4", label: "Event 4" },
  ];

  
    useEffect(() => {
      console.log("Team Mates:", team_mates, );
      if(team_mates){
        const transformedMates = team_mates.map(mate => ({
          value: `${mate.peopleid}`,
          label: mate.username
        }));
        console.log("Transformed Mates:", transformedMates);
        setTeamMates(transformedMates);
      }
    },[team_mates])

  // useEffect(() => {
  //   setBatches((prevBatches) => {
  //     const updatedBatches = [...prevBatches];
  //     updatedBatches[index] = {
  //       ...updatedBatches[index],
  //       [field]: value, // Dynamically update the specified field
  //     };
  //     return updatedBatches;
  //   });
  // }, [selectedTeamMates]);

  return (
    <div className="pt-4 flex flex-col gap-4">
      <div className="timing_section grid grid-cols-1 gap-y-2">
        <div className="flex justify-between">
          <Label htmlFor="startdate">Starts On</Label>
          <div className="max-w-sm">
            <DateTimePicker
              onUpdate={(value) => {
                updateBatch(index, "startdate", format(value, "yyyy-MM-dd"));
                updateBatch(index, "starttime", format(value, "HH:MM"));
              }}
              id="startdate"
            />
          </div>
        </div>
        <div className="flex justify-between">
          <Label htmlFor="enddate">Ends On</Label>
          <div className="max-w-sm">
            <DateTimePicker
              onUpdate={(value) => {
                updateBatch(index, "enddate", format(value, "yyyy-MM-dd"));
                updateBatch(index, "endtime", format(value, "HH:MM"));
              }}
              id="startdate"
            />
          </div>
        </div>
        <div className="flex justify-between">
          <Label htmlFor="batch_size">Batch Size</Label>
          <Input
            className="max-w-24"
            id="batch_size"
            type="number"
            value={batch.batch_size}
            onChange={(e) => {
              updateBatch(index, "batch_size", e.target.value);
            }}
          />
        </div>

        <div className="flex items-start gap-2 justify-between">
          <Label className="flex items-center gap-1" htmlFor="price">
            <span>Base Price</span>
          </Label>
          <div className="grid grid-cols-2 gap-x-4">
            <div className="flex flex-1 items-center gap-1">
              <span>&#8377;</span>
              <Input
                id="price"
                className="max-w-24"
                type="number"
                value={batch.price}
                onChange={(e) => updateBatch(index, "price", e.target.value)}
              />
            </div>
            <div className="flex flex-1 w-full items-center gap-1">
              <span>$</span>
              <Input
                id="price_in_us"
                className="max-w-24"
                type="number"
                value={batch.price_in_usd}
                onChange={(e) =>
                  updateBatch(index, "price_in_usd", e.target.value)
                }
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between">
          <Label htmlFor="min_booking_price max-w-xs">Booking Amount</Label>
          <div className="grid grid-cols-2 gap-x-4">
            <div className="flex flex-1 items-center gap-1">
              <span>&#8377;</span>
              <Input
                id="min_booking_price"
                className="max-w-24"
                type="number"
                value={batch.min_booking_price}
                onChange={(e) =>
                  updateBatch(index, "min_booking_price", e.target.value)
                }
              />
            </div>
            <div className="flex flex-1 w-full items-center gap-1">
              <span>$</span>
              <Input
                id="min_booking_price_in_us"
                className="max-w-24"
                type="number"
                value={batch.min_booking_price_in_usd}
                onChange={(e) =>
                  updateBatch(index, "min_booking_price_in_usd", e.target.value)
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className="tickets_section">
        <Accordion className="mb-4" type="single" collapsible>
          <AccordionItem value="tickets">
            <AccordionTrigger className="pb-1 pt-0">
              <div className="flex items-center gap-1">
                <Ticket size={16} />
                Tickets
              </div>
            </AccordionTrigger>
            <AccordionContent>
              {tickets?.map((ticket, ticketIndex) => (
                <>
                  <div className="flex pt-2 flex-col px-1 w-full gap-4">
                    <div className="grid grid-cols-12 items-end gap-x-3">
                      <div className="flex col-span-6 flex-col gap-1">
                        <Label
                          className="flex items-end justify-between w-full"
                          htmlFor="event_name"
                        >
                          Name
                        </Label>
                        <Input
                          onChange={(e) =>
                            handleTicketChange(
                              index,
                              ticketIndex,
                              "attribute",
                              e.target.value
                            )
                          }
                          id={`name-${ticket.id}`}
                          type="text"
                          value={ticket.attribute}
                        />
                      </div>
                      <div className="flex col-span-3 flex-col gap-1">
                        <Label htmlFor="event_value">Price</Label>
                        <Input
                          onChange={(e) =>
                            handleTicketChange(
                              index,
                              ticketIndex,
                              "price",
                              e.target.value
                            )
                          }
                          id={`price-${ticket.id}`}
                          type="number"
                          value={ticket.price}
                        />
                      </div>
                      <div className="flex col-span-2 flex-col gap-1">
                        <Label htmlFor="event_inventory">Qty</Label>
                        <Input
                          onChange={(e) =>
                            handleTicketChange(
                              index,
                              ticketIndex,
                              "inventory",
                              e.target.value
                            )
                          }
                          id={`inventory-${ticket.id}`}
                          type="number"
                          value={ticket.inventory}
                        />
                      </div>
                      {/* <DropdownMenu
                      className="col-span-1"
                      open={isDropdownOpen}
                      onOpenChange={setIsDropdownOpen}
                    >
                      <DropdownMenuTrigger>
                        <Button
                          onClick={() => setIsDropdownOpen(true)}
                          className="w-full px-1"
                          variant="outline"
                          
                        >
                          <EllipsisVertical size={16} />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent
                        className="w-48"
                        onInteractOutside={() =>
                          setIsDropdownOpen(false)
                        }
                      >
                        <DropdownMenuItem
                          onSelect={() => setIsDropdownOpen(true)}
                        >
                          <div className="flex justify-between items-center w-full">
                            <span className="text-xs">
                              Coupon Applicable
                            </span>
                            <Switch className="h-5" id="Sale" />
                          </div>
                        </DropdownMenuItem>
                        <DropdownMenuItem>
                          <div className="flex justify-between items-center w-full">
                            <span className="text-xs">
                              Ticket Soldout
                            </span>
                            <Switch
                              className="h-5"
                              id="batchconfirm"
                            />
                          </div>
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu> */}
                      <Popover
                        open={isDropdownOpen}
                        onOpenChange={setIsDropdownOpen}
                      >
                        <PopoverTrigger asChild>
                          <Button
                            onClick={() => setIsDropdownOpen(true)}
                            className="w-full px-2"
                            variant="outline"
                          >
                            <EllipsisVertical size={16} />
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent
                          className="w-48"
                          align="end"
                          onInteractOutside={() => setIsDropdownOpen(false)}
                        >
                          <div className="flex flex-col space-y-2">
                            <div className="flex justify-between items-center w-full">
                              <span className="text-xs">Coupon Applicable</span>
                              <Switch
                                defaultChecked={
                                  ticket.is_eligible_for_coupon_discount
                                }
                                onCheckedChange={(value) =>
                                  handleTicketChange(
                                    index,
                                    ticketIndex,
                                    "is_eligible_for_coupon_discount",
                                    value
                                  )
                                }
                                className="h-5"
                                id="Sale"
                              />
                            </div>
                            <div className="flex justify-between items-center w-full">
                              <span className="text-xs">Ticket Soldout</span>
                              <Switch
                                defaultChecked={ticket.soldout}
                                onCheckedChange={(value) =>
                                  handleTicketChange(
                                    index,
                                    ticketIndex,
                                    "soldout",
                                    value
                                  )
                                }
                                className="h-5"
                                id="batchconfirm"
                              />
                            </div>
                          </div>
                        </PopoverContent>
                      </Popover>
                    </div>
                  </div>
                  <div className="flex gap-2 justify-end items-center">
                    {ticketIndex == tickets.length - 1 && (
                      <div
                        onClick={() => handleAddTicket(index)}
                        className="text-sm justify-end cursor-pointer flex items-center gap-1 mt-2"
                      >
                        <CirclePlus size={16} />
                        <span className="underline">Add another</span>
                      </div>
                    )}
                    <div
                      onClick={() => {
                        handleRemoveTicket(index, ticketIndex);
                      }}
                      className="text-sm justify-end cursor-pointer flex items-center gap-1 mt-2"
                    >
                      <Trash size={16} />
                      <span className="underline">Remove</span>
                    </div>
                  </div>
                </>
              ))}
              {(tickets == null || tickets.length == 0) && (
                <div className="flex gap-2 justify-end items-center">
                  <div
                    onClick={() => handleAddTicket(index)}
                    className="text-sm justify-end cursor-pointer flex items-center gap-1 mt-2"
                  >
                    <CirclePlus size={16} />
                    <span className="underline">Add Prices</span>
                  </div>
                </div>
              )}
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
      <div className="addons_section">
        <Accordion className="mb-4" type="single" collapsible>
          <AccordionItem value="addons">
            <AccordionTrigger className="pb-1 pt-0">
              <div className="flex items-center gap-1">
                <TicketPlus size={16} />
                Addons
              </div>
            </AccordionTrigger>
            <AccordionContent>
              {addons?.map((addon, addonIndex) => (
                <>
                  <div className="flex pt-2 px-1 flex-col w-full gap-4">
                    <div className="grid grid-cols-12 items-end gap-x-3">
                      <div className="flex col-span-8 flex-col gap-1">
                        <Label
                          className="flex items-end justify-between w-full"
                          htmlFor="attribute"
                        >
                          Name
                        </Label>
                        <Input
                          id="attribute"
                          type="text"
                          value={addon.attribute}
                          onChange={(e) =>
                            handleAddonChange(
                              index,
                              addonIndex,
                              "attribute",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="flex col-span-3 flex-col gap-1">
                        <Label htmlFor="price">Price</Label>
                        <Input
                          id="price"
                          type="number"
                          value={addon.price}
                          onChange={(e) =>
                            handleAddonChange(
                              index,
                              addonIndex,
                              "price",
                              e.target.value
                            )
                          }
                        />
                      </div>

                      <DropdownMenu
                        className="col-span-1"
                        open={isAddonDropdownOpen}
                        onOpenChange={setIsAddonDropdownOpen}
                      >
                        <DropdownMenuTrigger>
                          <Button
                            onClick={() => setIsAddonDropdownOpen(true)}
                            className="w-full px-1"
                            variant="outline"
                          >
                            <EllipsisVertical size={16} />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent
                          onInteractOutside={() =>
                            setIsAddonDropdownOpen(false)
                          }
                        >
                          <DropdownMenuItem className="text-xs">
                            Remove
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  </div>
                  <div className="flex gap-2 justify-end items-center">
                    {addonIndex == addons.length - 1 && (
                      <div
                        className="text-sm justify-end cursor-pointer flex items-center gap-1 mt-2"
                        onClick={() => handleAddAddon(index)}
                      >
                        <CirclePlus size={16} />
                        <span className="underline">Add another</span>
                      </div>
                    )}
                    <div
                      className="text-sm justify-end cursor-pointer flex items-center gap-1 mt-2"
                      onClick={() => handleRemoveAddon(index, addonIndex)}
                    >
                      <Trash size={16} />
                      <span className="underline">Remove</span>
                    </div>
                  </div>
                </>
              ))}
              {(addons == null || addons.length == 0) && (
                <div className="flex gap-2 justify-end items-center">
                  <div
                    onClick={() => handleAddAddon(index)}
                    className="text-sm justify-end cursor-pointer flex items-center gap-1 mt-2"
                  >
                    <CirclePlus size={16} />
                    <span className="underline">Add Addons</span>
                  </div>
                </div>
              )}
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
      <div className="more_options">
        <Accordion type="single" collapsible>
          <AccordionItem value="item-1">
            <AccordionTrigger className="pb-0 pt-0">
              More Options
            </AccordionTrigger>
            <AccordionContent className="px-1">
             
              <div className="py-2">
                <div className="py-2">
                  <div className="flex justify-between">
                    <Label className="flex" htmlFor="whatsapp_group_link">
                      WhatsApp Group Link
                    </Label>
                    <Input
                      className="max-w-48"
                      id="whatsapp_group_link"
                      type="text"
                      value={batch.whatsapp_group_link}
                      onChange={(e) => {
                        updateBatch(
                          index,
                          "whatsapp_group_link",
                          e.target.value
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="py-2">
                  <div className="flex justify-between">
                    <Label className="flex" htmlFor="google_drive_link">
                      Google Drive Link
                    </Label>
                    <Input
                      value={batch.google_drive_link}
                      className="max-w-48"
                      id="google_drive_link"
                      type="text"
                      onChange={(e) => {
                        updateBatch(index, "google_drive_link", e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="py-2">
                  <div className="flex justify-between">
                    <Label className="flex " htmlFor="vehicle_type">
                      Nickname
                    </Label>
                    <Input
                      value={batch.nickname}
                      className="max-w-48"
                      id="vehicle_type"
                      type="text"
                      onChange={(e) => {
                        updateBatch(index, "vehicle_type", e.target.value);
                      }}
                    />
                  </div>
                </div>
                {/* <div className="">
                <div className="flex flex-col gap-1">
                  <Label className="">Who will go</Label>
                  {teamMates && (
                    <MultiSelect
                    
                      options={teamMates}
                      defaultValue={batch?.hosts}
                      onValueChange={(value) => {
                        setSelectedTeamMates(value);
                      }}
                    />
                  )}
                </div>
              </div> */}
                <div className="flex flex-col gap-1 pt-4">
                  <Label
                    className="flex items-end justify-between w-full"
                    htmlFor="comment"
                  >
                    Comment
                  </Label>
                  <Textarea
                    value={batch.comment}
                    id="comment"
                    onChange={(e) =>
                      updateBatch(index, "comment", e.target.value)
                    }
                  />
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
      <div className="flex justify-end mt-2">
        {/* <Button className=" w-24 ">{buttonText}</Button> */}
      </div>
    </div>
  );
};
