import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { RotateCw, Download, Plus, Search, ExternalLink } from "lucide-react";

const products = [
  {
    id: 1,
    name: "VoyageGear: Essential Travel Companion",
    productCode: "OT2.0",
    quantity: "100",
    views: "160"
  }
];

const MobileCard = ({ item }) => (
  <div className="p-4 border rounded-lg mb-3 space-y-3">
    <div className="space-y-1">
      <div className="text-sm text-gray-500">Name</div>
      <div className="font-medium">{item.name}</div>
    </div>
    
    <div className="grid grid-cols-2 gap-4">
      <div>
        <div className="text-sm text-gray-500">Product code</div>
        <div>{item.productCode}</div>
      </div>
      <div>
        <div className="text-sm text-gray-500">Quantity</div>
        <div>{item.quantity}</div>
      </div>
      <div>
        <div className="text-sm text-gray-500">Views</div>
        <div>{item.views}</div>
      </div>
    </div>

    <div className="flex flex-wrap gap-2 pt-2">
      <Button variant="outline" className="text-black">
        Preview
      </Button>
      <Button variant="outline" className="text-black">
        Bookings
      </Button>
      <Button variant="outline" className="text-black">
        Edit product
      </Button>
      <Button variant="outline" className="text-red-600">
        Delete
      </Button>
    </div>
  </div>
);

export function ProductsTable() {
  return (
    <div className="w-full p-4">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <div>
          <h2 className="text-2xl font-semibold text-gray-700">Products</h2>
          <div className="mt-2 text-sm text-gray-500">
            <div className="font-medium">TOTAL</div>
            <div>1 products</div>
          </div>
        </div>
        <Button className="bg-black hover:bg-black text-white flex items-center gap-2">
          <ExternalLink className="h-4 w-4" />
          View shop
        </Button>
      </div>

      {/* Action Buttons */}
      <div className="flex flex-wrap gap-2 mb-6">
        <Button className="bg-black  text-white flex items-center gap-2">
          <Plus className="h-4 w-4" />
          Add product
        </Button>
        <Button variant="secondary" className="flex items-center gap-2">
          <Download className="h-4 w-4" />
          Download All payments
        </Button>
        <Button variant="outline" className="flex items-center gap-2">
          <RotateCw className="h-4 w-4" />
          Refresh
        </Button>
      </div>

      {/* Search */}
      <div className="relative mb-6">
        <Search className="absolute left-3 top-3 h-4 w-4 text-gray-400" />
        <Input className="pl-10" placeholder="Search..." />
      </div>

      {/* Desktop Table View */}
      <div className="hidden md:block border rounded-lg">
        <Table>
          <TableHeader>
            <TableRow className="bg-black hover:bg-black">
              <TableHead className="text-white">#</TableHead>
              <TableHead className="text-white">Name</TableHead>
              <TableHead className="text-white">Product code</TableHead>
              <TableHead className="text-white">Quantity</TableHead>
              <TableHead className="text-white">Views</TableHead>
              <TableHead className="text-white">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {products.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.id}</TableCell>
                <TableCell className="font-medium">{item.name}</TableCell>
                <TableCell>{item.productCode}</TableCell>
                <TableCell>{item.quantity}</TableCell>
                <TableCell>{item.views}</TableCell>
                <TableCell>
                  <div className="flex gap-2">
                    <Button variant="outline" size="sm">Preview</Button>
                    <Button variant="outline" size="sm">Bookings</Button>
                    <Button variant="outline" size="sm">Edit product</Button>
                    <Button variant="outline" size="sm" className="text-red-600">Delete</Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      {/* Mobile Card View */}
      <div className="md:hidden">
        {products.map((item) => (
          <MobileCard key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
}