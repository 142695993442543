import { Card } from "@/components/ui/card";
import React from "react";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Drawer, DrawerContent, DrawerFooter } from "@/components/ui/drawer";
import { Separator } from "@/components/ui/separator";
import { ComboboxDemo } from "@/components/Custom/ComboboxInput";
import { ownerOptions } from "@/lib/data";
import { Label } from "@/components/ui/label";
import { Calendar, EllipsisVertical, UsersRound, Blocks } from "lucide-react";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import { TransactionTimeline } from "@/components/Custom/TimelineComp";
import { ScrollArea } from "@/components/ui/scroll-area";
import { differenceInDays } from "date-fns";
import { useCompanyStore } from "@/store/companyStore";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { format } from "date-fns";

const Transaction = ({ booking }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const checkBookingdate = function (timestamp) {
    const date = new Date(timestamp);
    const now = new Date();

    if (differenceInDays(now, date) > 1) {
      return format(date, "dd/MM/yyyy");
    } else if (differenceInDays(now, date) === 0) {
      return "Today";
    } else {
      return "Yesterday";
    }
  };

  const eventDetails = useCompanyStore.getState().companyData.events;
  const findEventImage = function (name) {
    const event = eventDetails?.data.find((event) => event.name === name);
    if(event){
      return `${import.meta.env.VITE_APP_API_URL}${event.image}`;
    }
    return null;
  };
  return (
    <div>
      <div className="flex flex-col gap-1">
        <Card className=" py-2 relative rounded-none border-t-0 border-l-0 shadow-none border-r-0 border-b-[1px]">
          <div
            onClick={() => {
              setIsOpen(true);
            }}
            className="flex justify-between items-start"
          >
            <div className="flex items-start gap-2">
              <div className="flex flex-col gap-1">
                {/* <Badge variant="outline" className="text-[0.5rem] rounded-md">
                257078
              </Badge> */}
                {findEventImage(booking?.booking?.event_name) && <img src={findEventImage(booking?.booking?.event_name)} className="w-12 h-12 rounded-md" />}
                <span className="text-[0.6rem]">
                  ID: {booking?.booking?.id}
                </span>
              </div>
              <div className="flex flex-col">
                <div className="flex items-center gap-2">
                  <h2 className="text-sm leading-4 font-semibold mb-[0.125rem]">
                    {booking?.booking?.name}
                    <span className="text-xs mx-1">|</span>
                    {booking?.booking?.numberofpeople} bookings
                    {/* <span className="text-xs">{booking.contact}</span> */}
                  </h2>
                </div>

                <div className="flex flex-col gap-[0.05rem]">
                  <span className="text-xs flex items-center gap-1 max-w-44 text-black font-medium">
                    {/* <UsersRound size={14} />5 bookings | Jane Doe +5 */}
                    {booking?.booking?.event_name.slice(0, 80)}
                  </span>
                  <span className="text-[0.6rem] flex items-center gap-1 text-muted-foreground">
                    <Calendar size={12} />
                    {format(
                      new Date(booking?.created_at),
                      "d MMM, yyyy | hh:mm a"
                    )}
                  </span>
                </div>

                <span
                  className={`absolute ${
                    booking?.mode == "refund"
                      ? "text-red-500"
                      : "text-[#25d366]"
                  }  right-1 bottom-1 font-medium`}
                >
                  {booking?.mode == "REFUND" ? "-" : "+"} &#8377;{" "}
                  {(booking?.amount / 100).toFixed(2)}
                </span>
              </div>
            </div>

            <div className="leading-4 flex items-center gap-1">
              {/* <Badge variant={'outline'} className="px-1 gap-1 rounded-md font-normal text-[0.6rem]">
              <Blocks size={12} />
              
With Addons
            </Badge>
            */}
              <div className="flex flex-col items-end">
                <span className="text-[0.6rem]">
                  {checkBookingdate(booking?.booking?.timestamp)}
                </span>
                <Badge
                  variant="outline"
                  className="items-center capitalize py-0 gap-1 px-1 align-start text-[0.5rem] rounded-md"
                >
                  {/* <Blocks size={10} /> */}
                  {booking?.mode}
                </Badge>

                {/* <EllipsisVertical size={14} /> */}
              </div>
              {/* <div className="flex gap-1">

              <Badge variant="outline" className="text-[0.6rem] rounded-md">
                Source: Instagram
              </Badge>
            </div> */}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Transaction;
