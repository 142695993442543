import React, { useRef, useState } from "react";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Separator } from "../ui/separator";
import { Switch } from "../ui/switch";

const BannerStyling = ({ bannerStyles, setBannerStyles }) => {

  const bookNowRef = useRef(null);
  const bookNowTextRef = useRef(null);
  const itineraryRef = useRef(null);
  const itineraryTextRef = useRef(null);
  const enquireRef = useRef(null);
  const enquireTextRef = useRef(null);
  const [isCustomBtns, setIsCustomBtns] = useState(false)

  return (
    <div>
      <div className="flex px-3 text-sm rounded-md bg-gray-100 font-medium mt-4 py-3 items-center justify-between">
            Show Banner
            <Switch
              checked={bannerStyles.showEverything}
              onCheckedChange={(value) =>
                setBannerStyles({
                  ...bannerStyles,
                 showEverything: value
                })
                }
            />
          </div>
    {bannerStyles.showEverything && <div className="flex flex-col gap-0 pt-2">
      <div className="py-4">
        <h2 className="font-medium">Book Now</h2>
        <Separator className="mt-1 mb-6" />
        <div className="grid md:grid-cols-3 md:gap-x-4 gap-y-4">
          <div className="card_custom_body flex items-center gap-2">
            <div
              onClick={() => {
                bookNowRef.current.click();
              }}
              style={{ background: bannerStyles.bookNowButtonStyling.bgColor }}
              className="color_preview w-12 h-12 shrink-0 rounded-lg relative border-2"
            >
              <div className="absolute top-1/2 left-1/2">
                <input
                  ref={bookNowRef}
                  //   ref={(el) => (pickerRefs.current.cardbgpicker = el)}
                  onChange={(e) => {
                    setBannerStyles({
                      ...bannerStyles,
                      bookNowButtonStyling: {
                        ...bannerStyles.bookNowButtonStyling,
                        bgColor: e.target.value,
                      },
                    });
                  }}
                  type="color"
                  value={bannerStyles.bookNowButtonStyling.bgColor}
                  className="opacity-0"
                />
              </div>
            </div>
            <div className="grid max-w-[16.5rem] items-center gap-1.5">
              <Label htmlFor="cardtextcolor">Button Color</Label>
              <Input
                type="text"
                id="cardtextcolor"
                placeholder="#ffffff"
                value={bannerStyles.bookNowButtonStyling.bgColor}
                onChange={(e) => {
                  setBannerStyles({
                    ...bannerStyles,
                    bookNowButtonStyling: {
                      ...bannerStyles.bookNowButtonStyling,
                      bgColor: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>
          <div className="card_custom_body flex items-center gap-2">
            <div
              onClick={() => {
                bookNowTextRef.current.click();
              }}
              style={{
                background: bannerStyles.bookNowButtonStyling.textColor,
              }}
              className="color_preview w-12 shrink-0 h-12 rounded-lg relative border-2"
            >
              <div className="absolute top-1/2 left-1/2">
                <input
                  ref={bookNowTextRef}
                  //   ref={(el) => (pickerRefs.current.cardbgpicker = el)}
                  onChange={(e) => {
                    setBannerStyles({
                      ...bannerStyles,
                      bookNowButtonStyling: {
                        ...bannerStyles.bookNowButtonStyling,
                        textColor: e.target.value,
                      },
                    });
                  }}
                  type="color"
                  value={bannerStyles.bookNowButtonStyling.textColor}
                  className="opacity-0"
                />
              </div>
            </div>
            <div className="grid items-center gap-1.5">
              <Label htmlFor="cardtextcolor">Text Color</Label>
              <Input
                type="text"
                id="cardtextcolor"
                placeholder="#ffffff"
                value={bannerStyles.bookNowButtonStyling.textColor}
                onChange={(e) => {
                  setBannerStyles({
                    ...bannerStyles,
                    bookNowButtonStyling: {
                      ...bannerStyles.bookNowButtonStyling,
                      textColor: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>
          <div className="grid max-w-[16.5rem] items-center gap-1.5">
            <Label htmlFor="text">Button Text</Label>
            <Input
              type="text"
              id="text"
              // placeholder="#ffffff"
              value={bannerStyles.bookNowButtonStyling.text}
              onChange={(e) => {
                setBannerStyles({
                  ...bannerStyles,
                  bookNowButtonStyling: {
                    ...bannerStyles.bookNowButtonStyling,
                    text: e.target.value,
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
   
      <div className="py-4">
        <div className="flex items-center justify-between">
      <h2 className="font-medium">Itinerary</h2>
      <div className="flex items-center gap-2 text-sm font-medium">
            Show Button
            <Switch
              checked={bannerStyles.itineraryButtonStyling.visible}
              onCheckedChange={(value) =>
              setBannerStyles({
                ...bannerStyles,
                itineraryButtonStyling: {

                    ...bannerStyles.itineraryButtonStyling,
                    visible: value
                }
              })
              }
            />
          </div>
        
      </div>
      <Separator className="mt-1 mb-6" />
        <div className="grid md:grid-cols-3 md:gap-x-4 gap-y-4">
          <div className="card_custom_body flex items-center gap-2">
            <div
              onClick={() => {
                itineraryRef.current.click();
              }}
              style={{
                background: bannerStyles.itineraryButtonStyling.bgColor,
              }}
              className="color_preview w-12 h-12 shrink-0 rounded-lg relative border-2"
            >
              <div className="absolute top-1/2 left-1/2">
                <input
                  ref={itineraryRef}
                  //   ref={(el) => (pickerRefs.current.cardbgpicker = el)}
                  onChange={(e) => {
                    setBannerStyles({
                      ...bannerStyles,
                      itineraryButtonStyling: {
                        ...bannerStyles.itineraryButtonStyling,
                        bgColor: e.target.value,
                      },
                    });
                  }}
                  //   onChange={(e) => {

                  //     setItineraryButtonStyling({
                  //       ...itineraryButtonStyling,
                  //       bgColor: e.target.value,
                  //     });
                  //   }}
                  type="color"
                  value={bannerStyles.itineraryButtonStyling.bgColor}
                  className="opacity-0"
                />
              </div>
            </div>
            <div className="grid max-w-[16.5rem] items-center gap-1.5">
              <Label htmlFor="cardtextcolor">Button Color</Label>
              <Input
                type="text"
                id="cardtextcolor"
                placeholder="#ffffff"
                value={bannerStyles.itineraryButtonStyling.bgColor}
                onChange={(e) => {
                  setBannerStyles({
                    ...bannerStyles,
                    itineraryButtonStyling: {
                      ...bannerStyles.itineraryButtonStyling,
                      bgColor: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>
          <div className="card_custom_body flex items-center gap-2">
            <div
              onClick={() => {
                itineraryTextRef.current.click();
              }}
              style={{
                background: bannerStyles.itineraryButtonStyling.textColor,
              }}
              className="color_preview w-12 shrink-0 h-12 rounded-lg relative border-2"
            >
              <div className="absolute top-1/2 left-1/2">
                <input
                  ref={itineraryTextRef}
                  //   ref={(el) => (pickerRefs.current.cardbgpicker = el)}
                  onChange={(e) => {
                    setBannerStyles({
                      ...bannerStyles,
                      itineraryButtonStyling: {
                        ...bannerStyles.itineraryButtonStyling,
                        textColor: e.target.value,
                      },
                    });
                  }}
                  type="color"
                  value={bannerStyles.itineraryButtonStyling.textColor}
                  className="opacity-0"
                />
              </div>
            </div>
            <div className="grid items-center gap-1.5">
              <Label htmlFor="cardtextcolor">Text Color</Label>
              <Input
                type="text"
                id="cardtextcolor"
                placeholder="#ffffff"
                value={bannerStyles.itineraryButtonStyling.textColor}
                onChange={(e) => {
                  setBannerStyles({
                    ...bannerStyles,
                    itineraryButtonStyling: {
                      ...bannerStyles.itineraryButtonStyling,
                      textColor: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>
          <div className="grid max-w-[16.5rem]  items-center gap-1.5">
            <Label htmlFor="text">Button Text</Label>
            <Input
              type="text"
              id="text"
              // placeholder="#ffffff"
              value={bannerStyles.itineraryButtonStyling.text}
              onChange={(e) => {
                setBannerStyles({
                  ...bannerStyles,
                  itineraryButtonStyling: {
                    ...bannerStyles.itineraryButtonStyling,
                    text: e.target.value,
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
      
      <div className="py-4">
        <div className="flex justify-between items-center">
      <h2 className="font-medium">Enquiry</h2>
      <div className="flex text-sm font-medium items-center  gap-2">
            Show Button
            <Switch
              checked={bannerStyles.enquiryButtonStyling.visible}
              onCheckedChange={(value) =>
                setBannerStyles({
                  ...bannerStyles,
                  enquiryButtonStyling: {
                      ...bannerStyles.enquiryButtonStyling,
                      visible: value
                  }
                })
                }
            />
          </div>
      </div>
      <Separator className="mt-1 mb-6" />
        <div className="grid md:grid-cols-3 md:gap-x-4 gap-y-4">
          <div className="card_custom_body flex items-center gap-2">
            <div
              onClick={() => {
                enquireRef.current.click();
              }}
              style={{ background: bannerStyles.enquiryButtonStyling.bgColor }}
              className="color_preview w-12 h-12 shrink-0 rounded-lg relative border-2"
            >
              <div className="absolute top-1/2 left-1/2">
                <input
                  ref={enquireRef}
                  //   ref={(el) => (pickerRefs.current.cardbgpicker = el)}
                  onChange={(e) => {
                    setBannerStyles({
                      ...bannerStyles,
                      enquiryButtonStyling: {
                        ...bannerStyles.enquiryButtonStyling,
                        bgColor: e.target.value,
                      },
                    });
                  }}
                  type="color"
                  value={bannerStyles.enquiryButtonStyling.bgColor}
                  className="opacity-0"
                />
              </div>
            </div>
            <div className="grid max-w-[16.5rem] items-center gap-1.5">
              <Label htmlFor="cardtextcolor">Button Color</Label>
              <Input
                type="text"
                id="cardtextcolor"
                placeholder="#ffffff"
                value={bannerStyles.enquiryButtonStyling.bgColor}
                onChange={(e) => {
                  setBannerStyles({
                    ...bannerStyles,
                    enquiryButtonStyling: {
                      ...bannerStyles.enquiryButtonStyling,
                      bgColor: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>
          <div className="card_custom_body flex items-center gap-2">
            <div
              onClick={() => {
                enquireTextRef.current.click();
              }}
              style={{
                background: bannerStyles.enquiryButtonStyling.textColor,
              }}
              className="color_preview w-12 shrink-0 h-12 rounded-lg relative border-2"
            >
              <div className="absolute top-1/2 left-1/2">
                <input
                  ref={enquireTextRef}
                  //   ref={(el) => (pickerRefs.current.cardbgpicker = el)}
                  onChange={(e) => {
                    setBannerStyles({
                      ...bannerStyles,
                      enquiryButtonStyling: {
                        ...bannerStyles.enquiryButtonStyling,
                        textColor: e.target.value,
                      },
                    });
                  }}
                  type="color"
                  value={bannerStyles.enquiryButtonStyling.textColor}
                  className="opacity-0"
                />
              </div>
            </div>
            <div className="grid items-center gap-1.5">
              <Label htmlFor="cardtextcolor">Text Color</Label>
              <Input
                type="text"
                id="cardtextcolor"
                placeholder="#ffffff"
                value={bannerStyles.enquiryButtonStyling.textColor}
                onChange={(e) => {
                  setBannerStyles({
                    ...bannerStyles,
                    enquiryButtonStyling: {
                      ...bannerStyles.enquiryButtonStyling,
                      textColor: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>
          <div className="grid max-w-[16.5rem]  items-center gap-1.5">
            <Label htmlFor="text">Button Text</Label>
            <Input
              type="text"
              id="text"
              // placeholder="#ffffff"
              value={bannerStyles.enquiryButtonStyling.text}
              onChange={(e) => {
                setBannerStyles({
                  ...bannerStyles,
                  enquiryButtonStyling: {
                    ...bannerStyles.enquiryButtonStyling,
                    text: e.target.value,
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
      <Separator className="my-4" />
    </div>}
      <div className="flex px-3 text-sm rounded-md bg-gray-100 font-medium mt-4 py-3 items-center justify-between">
            Use Custom Buttons
            <Switch
              checked={isCustomBtns}
              onCheckedChange={(value) =>
                 setIsCustomBtns(value)
                }
            />
          </div>
      {isCustomBtns && <div className="py-4">
      {/* <div className="pb-8 grid md:grid-cols-3 md:gap-x-4 gap-y-4">
          <div className="flex text-sm font-medium items-center justify-between">
            Show Only Buttons
            <Switch
              checked={bannerStyles.showOnlyButtons}
              onCheckedChange={(value) =>
                setBannerStyles({
                  ...bannerStyles,
                  showOnlyButtons: value,
                })
              }
            />
          </div>
         
          
         
        </div> */}
        <div className="grid md:grid-cols-3 md:gap-x-4 gap-y-4">
          <div className="grid max-w-[16.5rem]  items-center gap-1.5">
            <Label htmlFor="cardtextcolor">Custom Book Now Button Id</Label>
            <Input
              type="text"
              id="cardtextcolor"
              placeholder="Enter Id"
              value={bannerStyles.bookNowButtonStyling.customId}
              onChange={(e) => {
                setBannerStyles({
                  ...bannerStyles,
                  bookNowButtonStyling: {
                    ...bannerStyles.bookNowButtonStyling,
                    customId: e.target.value,
                  },
                });
              }}
            />
          </div>
          <div className="grid max-w-[16.5rem]  items-center gap-1.5">
            <Label htmlFor="cardtextcolor">Custom Itinerary Button Id</Label>
            <Input
              type="text"
              id="cardtextcolor"
              placeholder="Enter Id"
              value={bannerStyles.itineraryButtonStyling.customId}
              onChange={(e) => {
                setBannerStyles({
                  ...bannerStyles,
                  itineraryButtonStyling: {
                    ...bannerStyles.itineraryButtonStyling,
                    customId: e.target.value,
                  },
                });
              }}
            />
          </div>
          <div className="grid max-w-[16.5rem]  items-center gap-1.5">
            <Label htmlFor="cardtextcolor">Custom Enquiry Button Id</Label>
            <Input
              type="text"
              id="cardtextcolor"
              placeholder="Enter Id"
              value={bannerStyles.enquiryButtonStyling.customId}
              onChange={(e) => {
                setBannerStyles({
                  ...bannerStyles,
                  enquiryButtonStyling: {
                    ...bannerStyles.enquiryButtonStyling,
                    text: e.target.value,
                  },
                });
              }}
            />
          </div>
       
        </div>
      </div>}
    </div>
  );
};

export default BannerStyling;
