"use client";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import Participants from "./Participants";
import { CircleChevronLeft, X } from "lucide-react";
import { Form } from "@/components/ui/form";
import { useFieldArray } from "react-hook-form";
import useFetch from "@/hooks/useFetchLSD";
import { useCompanyStore } from "@/store/companyStore";
import axios from "axios";

import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import BuyerDetails from "./BuyerDetails";
import Tickets from "./Tickets";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import AnimatedStepper from "./Stepper";
import { Separator } from "@radix-ui/react-dropdown-menu";
import { useIsMobile } from "@/hooks/use-mobile";
import BookingSuccess from "./BookingSuccess";

const step1Schema = z.object({
  event: z.string(),
  batch: z.string(),
  buyerData: z.object({
    name: z.string(),
    email: z.string(),
    number: z.string(),
  }),
});

// Step 2 Schema: Tickets and Add-ons
const step2Schema = z.object({
  tickets: z
    .array(
      z.object({
        id: z.number(),
        attribute: z.string(),
        price: z.number(),
        number_of_bookings: z.number(),
        quantity: z.number().min(0, "Quantity cannot be negative"),
        inventory: z.coerce.number().optional(),
        price_in_usd: z.number(),
        soldout: z.boolean(),
      })
    )
    .nonempty("At least one ticket must be selected"),
  addons: z
    .array(
      z.object({
        id: z.number(),
        attribute: z.string(),
        price: z.number(),
        quantity: z.number().min(0, "Quantity cannot be negative"),
        price_in_usd: z.number(),
      })
    )
    .optional(),
  recordPayment: z.string().optional(),
  gst_included: z.boolean().optional().default(true),
  amountCollected: z.coerce.number().optional(),
  discount: z.coerce.number().optional(),
  paymentMethod: z.string().optional(),
  referenceId: z.string().optional(),
  remarks: z.string().optional(),
});

// Step 3 Schema: Guest Details
const step3Schema = z.object({
  guestData: z
    .array(
      z.object({
        ticketType: z.string().nonempty("Ticket type is required").optional(),
        fullname: z.string().optional().default(""),
        email: z.string().email().nullable().optional().or(z.literal("")),
        number: z.string().nullable().optional(),
        age: z.coerce.number().nullable().optional(),
        gender: z
          .enum(["male", "female"])
          .optional()
          .nullable()
          .or(z.literal("")),
        pickupPoint: z.string().nullable().optional(),
        weight: z.coerce.number().nullable().optional(),
      })
    )
    .min(1, "At least one guest must be added"),
});

// Full Schema (if needed for combining all steps later)
const formSchema = [step1Schema, step2Schema, step3Schema];

export default function ManualBookingForm({
  isOpen,
  setIsOpen,
  eventId,
  batchId,
}) {
  console.log("batch", batchId);
  const [activeStep, setActiveStep] = useState(0);
  const validationSchema = formSchema[activeStep];
  const [totalAmount, setTotalAmount] = useState(0);
  const [numberOfGuests, setNumberOfGuests] = useState(0);
  const [billDetails, setBillDetails] = useState({});
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const [pickupPoints, setPickupPoints] = useState([]);
  const [amountToBeCollected, setAmountToBeCollected] = useState(
    billDetails.finalprice ? billDetails.finalprice : 0
  );
  const allevents = useCompanyStore().companyData.events.data;
  const [coupons, setCoupons] = useState([]);
  const [savingGuestData, setSavingGuestData] = useState(false);
  const [error, setError] = useState(false);
  const defaultGuests = [
    {
      ticketType: "",
      fullname: "",
      email: null,
      number: null,
      age: null,
      gender: null,
      pickup_point: null,
    },
  ];

  const form = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      guestData: defaultGuests,
      // buyerData: {
      //   name: "",
      //   email: "",
      //   number: "",
      // },
    },
  });

  useEffect(() => {
    console.log(billDetails);
    const collection = form.getValues("discount")
      ? billDetails?.finalprice - form.getValues("discount") * 100
      : billDetails?.finalprice;
    setAmountToBeCollected(collection);
    form.setValue("amountCollected", collection);
  }, [billDetails]);

  const {
    data: ticketsData,
    error: bookingsError,
    isLoading: bookingLoading,
    refetch: refetchBookingsData,
    isFetching: ticketsFetching,
  } = useFetch(
    `${
      import.meta.env.VITE_APP_API_URL
    }/v3/booking-batch-api-manual/${batchId}/?platform=manual`,
    {},
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: 2,
        enabled: true,
        keepPreviousData: true,
      },
    }
  );

  const { fields, replace } = useFieldArray({
    control: form.control,
    name: "tickets",
    keyName: "customId",
  });

  const { fields: addonFields, replace: addonsReplace } = useFieldArray({
    control: form.control,
    name: "addons",
    keyName: "customId",
  });

  useEffect(() => {
    const newArray = ticketsData?.data?.event_price_types?.map((obj) => {
      return { ...obj, quantity: 0 }; // Create a new object using the spread syntax
    });
    const newAddonArray = ticketsData?.data?.addon_prices?.map((obj) => {
      return { ...obj, quantity: 0 }; // Create a new object using the spread syntax
    });
    if (ticketsData?.data?.coupon_available) {
      setCoupons(ticketsData?.data?.coupons);
    }
    console.log(form.getValues("tickets"));
    if (!form.getValues("tickets") || form.getValues("tickets")?.length == 0)
      form.setValue("tickets", newArray);
    if (!form.getValues("tickets") || form.getValues("addons")?.length == 0)
      form.setValue("addons", newAddonArray);
    setPickupPoints(ticketsData?.data?.pickup_points);
  }, [ticketsData]);

  const isMobile = useIsMobile();
  const headings = [
    "Who is Booking",
    "Create Tickets & Pay",
    "Participant Details",
  ];

  const [formData, setFormData] = useState({
    selectedEvent: "",
    selectedBatch: "",
    buyerDetails: {},
    tickets: [],
    addons: [],
    guestData: [],
  });

  const { fields: guestFields } = useFieldArray({
    control: form.control,
    name: "guestData",
  });

  const saveManualBooking = async () => {
    const resp = await axios.post(
      `${import.meta.env.VITE_APP_API_URL}/v3/save-manual-booking/`,
      { bookingId: bookingId }
    );
    if (resp.status == 200) {
      setActiveStep(activeStep + 1);
    }
    setSavingGuestData(false);
  };

  // console.log(fields);

  const formStepHandler = () => {
    switch (activeStep) {
      case 0:
        return (
          <BuyerDetails
            form={form}
            formData={formData}
            setFormData={setFormData}
            eventId={eventId}
            batchId={batchId}
            allevents={allevents}
          />
        );
      case 1:
        return (
          <Tickets
            fields={fields}
            ticketsFetching={ticketsFetching}
            addonFields={addonFields}
            billDetails={billDetails}
            setBillDetails={setBillDetails}
            ticketsData={ticketsData}
            form={form}
            formData={formData}
            setFormData={setFormData}
            coupons={coupons}
            amountToBeCollected={amountToBeCollected}
            setAmountToBeCollected={setAmountToBeCollected}
            setError={setError}
            error={error}
          />
        );
      case 2:
        return (
          <Participants
            form={form}
            formData={formData}
            setFormData={setFormData}
            guestFields={guestFields}
            numberOfGuests={numberOfGuests}
            fields={fields}
            billDetails={billDetails}
            pickupPoints={pickupPoints}
            saveManualBooking={saveManualBooking}
            amountToBeCollected={amountToBeCollected}
          />
        );

      case 3:
        return <BookingSuccess form={form} />;
    }
  };

  const handleBack = () => {
    if (activeStep == 0) {
      setIsOpen(false);
      return;
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  async function onSubmit(values) {
    console.log(form.getValues());

    if (activeStep == 1) {
      const guests = values.tickets.reduce(
        (curr, ticket) => curr.qty + ticket.qty,
        0
      );
      form.setValue("price_type", form.getValues("tickets"));
      var updatedBuyerData = form.getValues("buyerData");
      updatedBuyerData["manual_amount"] = form.getValues("amountCollected");
      updatedBuyerData["manual_ref_id"] = form.getValues("referenceId");
      updatedBuyerData["payment_method"] = form.getValues("paymentMethod");
      updatedBuyerData["reference"] = "agent";
      updatedBuyerData["remark_by_host"] = form.getValues("remarks");
      form.setValue("buyerData", updatedBuyerData);
      setNumberOfGuests(guests);
    }
    try {
      console.log(values);
      toast(
        <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
          <code className="text-white">{JSON.stringify(values, null, 2)}</code>
        </pre>
      );
    } catch (error) {
      console.error("Form submission error", error);
      toast.error("Failed to submit the form. Please try again.");
    }

    if (activeStep == 2) {
      setSavingGuestData(true);
      form.setValue("platform", "manual-booking");
      form.setValue("baseprice", billDetails.baseprice);
      form.setValue(
        "total_payable_amount",
        form.getValues("recordPayment") == "full"
          ? form.getValues("amountCollected")
          : billDetails?.finalprice - form.getValues("discount") * 1000
      );
      form.setValue("payable_amount", billDetails.total_payable_amount);
      form.setValue(
        "total_payable_amount_with_gst",
        form.getValues("recordPayment") == "full"
          ? form.getValues("amountCollected")
          : billDetails?.finalprice - form.getValues("discount") * 1000
      );
      form.setValue("gst_collected", billDetails?.gst_collected);
      form.setValue(
        "couponcode",
        billDetails.couponCode ? billDetails.couponCode : ""
      );
      form.setValue(
        "member_discount",
        billDetails.member_discounted ? billDetails.member_discounted : 0
      );
      form.setValue(
        "logout_cut",
        billDetails.logout_cut ? billDetails.logout_cut : 0
      );
      form.setValue("gst_included", form.getValues("gst_included"));
      form.setValue("gateway_charges", 0);
      form.setValue(
        "discount",
        form.getValues("discount") ? form.getValues("discount") * 100 : 0
      );

      if(!form.getValues('gst_included')){
        form.setValue('total_payable_amount',form.getValues('total_payable_amount')-form.getValues('gst_collected'))
      }
      if(!form.getValues('gst_included')){
        form.setValue('total_payable_amount_with_gst',form.getValues('total_payable_amount_with_gst')-form.getValues('gst_collected'))
      }

      const res = await axios.post(
        `${
          import.meta.env.VITE_APP_API_URL
        }/v3/savebookingdata/${form.getValues("batch")}/`,
        form.getValues()
      );
      const bookingId = res.data.bookingId;

      const resp = await axios.post(
        `${import.meta.env.VITE_APP_API_URL}/v3/save-manual-booking/`,
        { bookingId: bookingId }
      );
      if (resp.status == 200) {
        setActiveStep(activeStep + 1);
      }
      setSavingGuestData(false);
    } else {
      setActiveStep(activeStep + 1);
    }
  }

  function onError(errors) {
    console.error("Validation errors:", errors);
  }

  return (
    <>
      {!isMobile && (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
          {/* <DialogTrigger>Open</DialogTrigger> */}
          <DialogContent
            className={
              "lg:max-w-sm [&>button]:hidden p-0 rounded-md lg:max-h-[90vh] overflow-y-auto"
            }
          >
            <DialogHeader className={"pl-4 pb-0 pr-2 pt-6"}>
              {activeStep !== 3 && (
                <div className="flex items-start w-full">
                  {/* <DialogTitle className="px-6">Add Manual Booking</DialogTitle> */}
                  <div className="flex-grow cursor-pointer items-start">
                    <CircleChevronLeft size={20} onClick={handleBack} />
                  </div>
                  <div className="flex flex-col mx-auto flex-grow gap-1 justify-center items-center">
                    <h1 className="text-xl leading-5 font-semibold">
                      {headings[activeStep]}
                    </h1>

                    <h3 className="text-muted-foreground text-sm leading-4">
                      Booking by: yashmeet
                    </h3>
                  </div>
                  <AnimatedStepper
                    steps={[{ number: 0 }, { number: 1 }, { number: 2 }]}
                    currentStep={activeStep}
                  />
                </div>
              )}
              {activeStep == 3 && (
                <div className="flex justify-end">
                  <X size={14} />
                </div>
              )}
            </DialogHeader>
            <div>
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit, onError)}
                  className="space-y-4 h-full flex flex-col max-w-3xl mx-auto"
                >
                  {formStepHandler()}
                  <div className="sticky bottom-0 w-full">
                    {activeStep == 0 && (
                      <div className="px-4 pb-4 text-base bg-white flex w-full">
                        <Button className="w-full text-base" type="submit">
                          Next
                        </Button>
                      </div>
                    )}
                    {activeStep == 1 && (
                      <div className="flex relative py-4 px-4 bg-white shadow-[rgba(0,0,15,0.5)_-16px_67px_38px_44px] justify-between items-center">
                        <div className="flex-[1.4]">
                          <h2 className="text-xl font-semibold">
                            &#8377;
                            {amountToBeCollected
                              ? Number(
                                  (amountToBeCollected / 100).toFixed(2)
                                ).toLocaleString("en-IN")
                              : 0}
                          </h2>
                          <span
                            onClick={() => {
                              setIsDetailsVisible(!isDetailsVisible);
                            }}
                            className="underline cursor-pointer text-xs"
                          >
                            {!isDetailsVisible ? "Show" : "Hide"} Details
                          </span>
                        </div>
                        <Button
                          disabled={
                            error ||
                            form.getValues("tickets")?.reduce((acc, curr) => {
                              acc += curr.quantity;
                              return acc;
                            }, 0) == 0
                          }
                          className="flex-grow max-w-40 py-4 text-base"
                          type="submit"
                        >
                          Next
                        </Button>
                        {isDetailsVisible && (
                          <div className="absolute border-b-2 bottom-[100%] w-full left-[0%] bg-white p-6 shadow-[0_-22px_33px_-35px_rgba(0,0,0,0.75)]">
                            <div>
                              <h2 className="text-xl font-semibold mb-2">
                                Bill Summary
                              </h2>
                            </div>
                            <div className="flex flex-col gap-2">
                              <div className="flex justify-between items-center">
                                <span className="text-muted-foreground">
                                  Total Price
                                </span>
                                <span>
                                  &#8377;
                                  {(billDetails?.baseprice / 100).toFixed(2) ||
                                    0}
                                </span>
                              </div>
                              <div className="flex justify-between items-center">
                                <span className="text-muted-foreground">
                                  Discounts
                                </span>
                                <span>
                                  &#8377;
                                  {(billDetails?.discount_given
                                    ? (
                                        billDetails?.discount_given / 100
                                      ).toFixed(2) *
                                        1 +
                                      Number(form.getValues("discount"))
                                    : form.getValues("discount")) || 0}
                                </span>
                              </div>
                              {/* <div className="flex justify-between items-center">
                                <span>Booking Amount</span>
                                <span>2222</span>
                              </div> */}
                              <div className="flex justify-between items-center">
                                <span className="text-muted-foreground">
                                  GST
                                </span>
                                <span>
                                  &#8377;
                                  {(billDetails?.gst_collected / 100).toFixed(
                                    2
                                  ) || 0}
                                </span>
                              </div>
                              <div className="flex justify-between items-center">
                                <span className="text-muted-foreground">
                                  Payable Amount
                                </span>
                                {form.getValues("recordPayment") !=
                                "partial" ? (
                                  <span>
                                    &#8377;
                                    {(amountToBeCollected / 100).toFixed(2) ||
                                      0}
                                  </span>
                                ) : (
                                  <span>
                                    &#8377;
                                    {(billDetails?.finalprice / 100).toFixed(
                                      2
                                    ) || 0}
                                  </span>
                                )}
                              </div>
                              <hr />
                              <div className="flex font-medium justify-between items-center">
                                <span>Amount Collected</span>
                                <span>
                                  &#8377;
                                  {(amountToBeCollected / 100).toFixed(2) || 0}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {activeStep == 2 && (
                      <div className="px-4 w-full pb-4 bg-white flex">
                        <Button className="w-full" type="submit">
                          {savingGuestData ? (
                            <div className="dots"></div>
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </div>
                    )}
                  </div>
                </form>
              </Form>
            </div>
          </DialogContent>
        </Dialog>
      )}
      {isMobile && (
        <Sheet open={isOpen} onOpenChange={setIsOpen}>
          {/* <SheetTrigger>Open</SheetTrigger> */}
          <SheetContent className="w-full p-0 max-h-[95vh] overflow-y-auto">
            <SheetHeader
              className={"pl-4 sticky top-0 bg-white pb-4 pr-2 pt-10"}
            >
              <div className="flex items-start w-full">
                {/* <DialogTitle className="px-6">Add Manual Booking</DialogTitle> */}
                <div className="flex-grow cursor-pointer items-start">
                  <CircleChevronLeft size={20} onClick={handleBack} />
                </div>
                <div className="flex flex-col mx-auto flex-grow gap-1 justify-center items-center">
                  <h1 className="text-xl leading-5 font-semibold">
                    {headings[activeStep]}
                  </h1>

                  <h3 className="text-muted-foreground text-sm leading-4">
                    Booking by: yashmeet
                  </h3>
                </div>
                <AnimatedStepper
                  steps={[{ number: 0 }, { number: 1 }, { number: 2 }]}
                  currentStep={activeStep}
                />
              </div>
            </SheetHeader>
            <div>
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit, onError)}
                  className="space-y-8 h-full flex flex-col max-w-3xl mx-auto"
                >
                  {formStepHandler()}
                  <div className="sticky bottom-0 w-full">
                    {activeStep == 0 && (
                      <div className="px-4 pb-4 bg-white flex w-full">
                        <Button className="w-full text-base" type="submit">
                          Next
                        </Button>
                      </div>
                    )}
                    {activeStep == 1 && (
                      <div className="flex relative py-4 px-4 bg-white shadow-[rgba(0,0,15,0.5)_-16px_67px_38px_44px] justify-between items-center">
                        <div className="absolute bg-red-400 w-full p-4 top-[100%]">
                          Details section
                        </div>
                        <div className="flex-[1.4]">
                          <h2 className="text-xl font-semibold">
                            &#8377;{form.getValues("amountCollected")}
                          </h2>
                          <span className="underline text-xs">
                            Show Details
                          </span>
                        </div>
                        <Button className="flex-grow" type="submit">
                          Next
                        </Button>
                      </div>
                    )}
                    {activeStep == 2 && (
                      <div className="px-4 pb-4 bg-white flex w-full">
                        <Button className="w-full" type="submit">
                          Submit
                        </Button>
                      </div>
                    )}
                  </div>
                </form>
              </Form>
            </div>
          </SheetContent>
        </Sheet>
      )}
    </>
  );
}
