import axios from "axios";

const fetchTickets = async function (id) {
  try {
    const response = await axios.get(
      `${import.meta.env.VITE_APP_API_URL}/v3/booking-batch-api-manual/${id}/`
    );
    return { data: response.data, isError: false, error: null };
  } catch (err) {
    return { data: null, isError: true, error: err };
  }
};

export const getEventBatches = async function (id) {
    console.log("EVENT ID",id)
    try {
        const response = await axios.get(
          `${import.meta.env.VITE_APP_API_URL}/v3/event-batch-list/${id}/`
        );
        return { data: response.data, isError: false, error: null };
      } catch (err) {
        return { data: null, isError: true, error: err };
      }
};

export default { fetchTickets, getEventBatches };
