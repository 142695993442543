import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { format } from "date-fns";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { ComboboxDemo } from "../Custom/ComboboxInput";
import { RadioGroup, RadioGroupItem } from "../ui/radio-group";
import { DatePicker } from "../Custom/DatePicker";
import { Switch } from "../ui/switch";
import { Calendar, CirclePlus, Trash } from "lucide-react";
import { Button } from "../ui/button";
import { Badge } from "../ui/badge";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import useFetch from "@/hooks/useFetchLSD";
import { useAuthStore } from "@/store/store";
import { useMutation } from "react-query";
import { deleteCoupon, editCoupon } from "@/apiService/coupons";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const EditCoupon = ({ isOpen, setIsOpen, couponLevel, eventID, apiUrl }) => {
  const [couponValidityType, setCouponValidityType] = React.useState("Date");
  const [newCoupons, setNewCoupons] = React.useState([]);
  const [coupons, setCoupons] = React.useState([]);
  const [buttonText, setButtonText] = React.useState("Update");
  const {
    data: couponsData,
    isLoading: couponsLoading,
    error: couponsError,
    isError: isCouponsError,
    refetch: couponRefetch,
  } = useFetch(
    apiUrl,
    {},
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        retry: 1,
        enabled: true,
      },
    }
  );
  const { companyslug } = useAuthStore();
  const mutation = useMutation(
    async ({ companyslug, coupon }) => {
      editCoupon(companyslug, coupon);
    },
    {
      onSuccess: (response) => {
        console.log("Coupon updated successfully:", response);
      },
      onError: (error) => {
        console.error("Error updating coupon:", error);
      },
    }
  );

  const deleteMutation = useMutation(({ companyslug, coupon }) => {
    deleteCoupon(companyslug, coupon),
      {
        onSuccess: (response) => {
          console.log("Coupon updated successfully:", response);
          // Optional: Add any success-side effects here
        },
        onError: (error) => {
          console.error("Error updating coupon:", error);
          // Optional: Handle errors
        },
      };
  });

  useEffect(() => {
    setCoupons(couponsData?.data);
  }, [couponsData]);

  const handleChange = (id, field, value, isNew) => {
    console.log("ID:", id, "Field:", field, "Value:", value);
    if (isNew) {
      setNewCoupons((prevCoupons) =>
        prevCoupons.map((coupon) =>
          coupon.id === id || (!coupon.id && id === "new")
            ? {
                ...coupon,
                [field]: value,
              }
            : coupon
        )
      );
    } else {
      console.log("here");
      setCoupons((prevCoupons) =>
        prevCoupons.map((coupon) =>
          coupon.id === id || (!coupon.id && id === "new")
            ? {
                ...coupon,
                [field]: value,
              }
            : coupon
        )
      );
    }
  };

  useEffect(() => {
    console.log("Coupons:", coupons);
  }, [coupons]);

  useEffect(() => {
    if (mutation.isLoading) {
      setButtonText(<div className="dots"></div>);
    }
    if (mutation.isSuccess) {
      setButtonText("Updated");
      setTimeout(() => {
        setButtonText("Update");
      }, 3000);
    }
  }, [mutation.isLoading, mutation.isSuccess]);

  const handleAddCoupon = () => {
    setNewCoupons((prevCoupons) => [
      ...prevCoupons,
      {
        id: prevCoupons.length + 1,
        code: "",
        discount_type: "",
        value: "",
        quantity: "",
        expiry_type: "Date",
        valid_until: "",
        valid_from: "",
        minimum_number_required: "",
        affiliate_cc_email: "",
        visible: false,
      },
    ]);
  };

  const handleRemoveCoupon = (id) => {
    if (!newCoupons.includes(id)) {
      deleteMutation.mutate({ companyslug, coupon: id });
    }
    setCoupons((prevCoupons) =>
      prevCoupons.filter((coupon) => coupon.id !== id)
    );
    setNewCoupons((prevCoupons) =>
      prevCoupons.filter((coupon) => coupon.id !== id)
    );
  };

  const handleSubmit = (id, event, isNew) => {
    event.preventDefault();
    console.log("Updated Coupons:", coupons);
    var edited = isNew
      ? newCoupons.filter((coupon) => coupon.id == id)
      : coupons.filter((coupon) => coupon.id == id);
    edited[0].couponListType = `${couponLevel}coupon`;
    edited[0].couponListCheck = eventID;
    console.log("Edited:", mutation);
    if (isNew) {
      delete edited[0].id;
      mutation.mutate({ companyslug, coupon: edited });
    } else {
      mutation.mutate({ companyslug, coupon: edited });
    }
    // Perform API call to update coupons if needed
  };

  const discOptions = [
    { value: "0", label: "Select Discount Type" },
    { value: "Percentage", label: "Percentage" },
    { value: "Amount", label: "Amount" },
  ];

  const handleTypeChange = (id, newType, isNew) => {
    if (isNew) {
      setNewCoupons((prevCoupons) =>
        prevCoupons.map((coupon) =>
          coupon.id === id ? { ...coupon, discount_type: newType } : coupon
        )
      );
    } else {
      setCoupons((prevCoupons) =>
        prevCoupons.map((coupon) =>
          coupon.id === id ? { ...coupon, discount_type: newType } : coupon
        )
      );
    }
  };
  return (
    <div>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent
          className={
            "lg:max-w-2xl overflow-y-scroll rounded-md lg:max-h-[80vh] max-h-[70vh]"
          }
        >
          <DialogHeader>
            <DialogTitle>Edit {couponLevel} coupons</DialogTitle>
          </DialogHeader>
          <div>
            <div>
              {coupons?.map((coupon, index) => (
                <Accordion type="single" collapsible key={index}>
                  <AccordionItem value="item-1">
                    <AccordionTrigger>
                      <div className="flex gap-4">
                        {" "}
                        {coupon.code} -
                        {coupon.discount_type == "Amount" ? " ₹" : ""}{" "}
                        {coupon.discount}
                        {coupon.discount_type == "Percentage" ? "%" : ""} off
                        <div className="flex items-center gap-1">
                          <Calendar size={16} />
                          <span>
                            {format(coupon.valid_from, "dd-MM-yyyy")}{" "}
                            {coupon.valid_period
                              ? `-${coupon.valid_period} days`
                              : coupon.valid_until
                              ? `-${" "}${format(
                                  coupon.valid_until,
                                  "dd-MM-yyyy"
                                )}`
                              : ""}
                          </span>
                        </div>
                      </div>
                    </AccordionTrigger>
                    <AccordionContent className="px-1">
                      <form
                        onSubmit={(event) => {
                          handleSubmit(coupon.id, event);
                        }}
                        className="space-y-4 flex flex-col"
                      >
                        <div className="grid grid-cols-1 first:pt-4 gap-2">
                          <div className="flex justify-between">
                            <Label>Code</Label>
                            <Input
                              onChange={(e) =>
                                handleChange(
                                  coupon.id || "new",
                                  "code",
                                  e.target.value
                                )
                              }
                              id={`code`}
                              className="max-w-48"
                              type="text"
                              placeholder="Enter Code"
                              defaultValue={coupon.code}
                            />
                          </div>
                          <div className="flex justify-between">
                            <Label>Type</Label>
                            <ComboboxDemo
                              defaultValue={coupon.discount_type}
                              classes={"max-w-48"}
                              fontSize={"text-sm"}
                              options={discOptions}
                              onChange={(value) =>
                                handleTypeChange(coupon.id || "new", value)
                              }
                            />
                          </div>
                          <div className="flex justify-between">
                            <Label>Value</Label>
                            <div className="flex items-center gap-1">
                              {coupon.discount_type == "Amount" ? "₹" : ""}

                              <Input
                                onChange={(e) =>
                                  handleChange(
                                    coupon.id || "new",
                                    "discount",
                                    e.target.value
                                  )
                                }
                                id={`value-${coupon.id}`}
                                className="max-w-[11rem]"
                                type="number"
                                placeholder=""
                                defaultValue={coupon.discount}
                              />
                              {coupon.discount_type == "Percentage" ? "%" : ""}
                            </div>
                          </div>
                          <div className="flex justify-between">
                            <Label>Inventory</Label>
                            <Input
                              onChange={(e) =>
                                handleChange(
                                  coupon.id || "new",
                                  "quantity",
                                  e.target.value
                                )
                              }
                              defaultValue={coupon.quantity}
                              id={`inventory-${coupon.id}`}
                              className="max-w-48"
                              type="number"
                              placeholder=""
                            />
                          </div>
                          <div className="flex justify-between">
                            <Label>Valid From</Label>
                            <div className="w-48">
                              <DatePicker
                                defaultDate={coupon.valid_from}
                                onChange={(e) => {
                                  handleChange(
                                    coupon.id || "new",
                                    "valid_from",
                                    e
                                  );
                                }}
                                id={`validFrom-${coupon.id}`}
                              />
                            </div>
                          </div>

                          <div className="flex justify-between py-1">
                            <Label htmlFor="date-type">Validity Type</Label>
                           
                            <Select value={couponValidityType} onValueChange={(value) => {
                                handleChange(
                                  coupon.id || "new",
                                  "expiry_type",
                                  value,
                                  ""
                                );
                                setCouponValidityType(value);
                              }}>
                              <SelectTrigger className="max-w-48">
                                <SelectValue placeholder="--" />
                              </SelectTrigger>
                              <SelectContent>
                                
                                <SelectItem value="Date">Fixed Date</SelectItem>
                                <SelectItem value="Period">Relative Date</SelectItem>
                                
                              </SelectContent>
                            </Select>
                          </div>
                          {couponValidityType=="Date" ? (
                            <div className="flex justify-between">
                              <Label htmlFor="valid_till">Valid Till</Label>
                              <div className="w-48">
                                <DatePicker
                                  defaultDate={coupon.valid_until}
                                  onChange={(e) => {
                                    handleChange(
                                      coupon.id || "new",
                                      "valid_until",
                                      e
                                    );
                                  }}
                                  id={`validTill-${coupon.id}`}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="flex justify-between">
                              <Label htmlFor="coupon-days">Days</Label>
                              <Input
                                id={`validTill-${coupon.id}`}
                                defaultValue={coupon.valid_period}
                                className="h-8 max-w-48"
                                type="number"
                                onChange={(e) => {
                                  handleChange(
                                    coupon.id || "new",
                                    "valid_period",
                                    e.target.value
                                  );
                                }}
                                placeholder="Days"
                              />
                            </div>
                          )}
                          <div className="flex justify-between">
                            <Label>Group Size for Discount</Label>
                            <Input
                              onChange={(e) =>
                                handleChange(
                                  coupon.id || "new",
                                  "minimum_number_required",
                                  e.target.value
                                )
                              }
                              id={`groupSize-${coupon.id}`}
                              className="max-w-48"
                              type="number"
                              placeholder=""
                              defaultValue={coupon.minimum_number_required}
                            />
                          </div>
                          <div className="flex justify-between">
                            <Label>Affiliate Email</Label>
                            <Input
                              onChange={(e) =>
                                handleChange(
                                  coupon.id || "new",
                                  "affiliate_cc_email",
                                  e.target.value
                                )
                              }
                              id={`affiliateEmail-${coupon.id}`}
                              className="max-w-48"
                              type="email"
                              placeholder=""
                              defaultValue={coupon.affiliate_cc_email}
                            />
                          </div>
                          <div className="flex justify-between">
                            <Label>Make it private</Label>
                            <div className="flex items-center gap-2">
                              <Badge className="ml-2">
                                {!coupon.visible ? "Private" : "Public"}
                              </Badge>
                              <Switch
                                className=""
                                defaultChecked={!coupon.visible}
                                onCheckedChange={(checked) => {
                                  console.log(checked);
                                  handleChange(coupon.id, "visible", !checked);
                                }}
                                id={`isPrivate-${coupon.id}`}
                              />
                            </div>
                          </div>
                          <div className="flex gap-4 mt-2 justify-end items-center">
                            <div
                              onClick={() => handleRemoveCoupon(coupon.id)}
                              className="text-sm justify-end cursor-pointer flex items-center gap-1 mt-2"
                            >
                              <Trash size={16} />
                              <span className="underline">Remove</span>
                            </div>
                            <Button className="w-20" type="submit">
                              {/* {mutation.isLoading ? <div className="dots"></div> : buttonText} */}
                              {buttonText}
                            </Button>
                          </div>
                        </div>
                      </form>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              ))}

              {newCoupons.length > 0 &&
                newCoupons.map((coupon, index) => (
                  <form
                    onSubmit={(event) => {
                      handleSubmit(coupon.id, event, true);
                    }}
                    className="space-y-4 flex flex-col"
                  >
                    <div className="grid grid-cols-1 first:pt-4 gap-2">
                      <div className="flex justify-between">
                        <Label>Code</Label>
                        <Input
                          onChange={(e) =>
                            handleChange(
                              coupon.id,
                              "code",
                              e.target.value,
                              "new"
                            )
                          }
                          id={`code`}
                          className="max-w-48"
                          type="text"
                          placeholder="Enter Code"
                          defaultValue={coupon.code}
                        />
                      </div>
                      <div className="flex justify-between">
                        <Label>Type</Label>
                        <ComboboxDemo
                          defaultValue={coupon.discount_type}
                          classes={"max-w-48"}
                          fontSize={"text-sm"}
                          options={discOptions}
                          onChange={(value) =>
                            handleTypeChange(coupon.id || "new", value, "new")
                          }
                        />
                      </div>
                      <div className="flex justify-between">
                        <Label>Value</Label>
                        <div className="flex items-center gap-1">
                          {coupon.discount_type == "Amount" ? "₹" : ""}

                          <Input
                            onChange={(e) =>
                              handleChange(
                                coupon.id || "new",
                                "discount",
                                e.target.value,
                                "new"
                              )
                            }
                            id={`value-${coupon.id}`}
                            className="max-w-[11rem]"
                            type="number"
                            placeholder=""
                            defaultValue={coupon.discount}
                          />
                          {coupon.discount_type == "Percentage" ? "%" : ""}
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <Label>Inventory</Label>
                        <Input
                          onChange={(e) =>
                            handleChange(
                              coupon.id || "new",
                              "quantity",
                              e.target.value,
                              "new"
                            )
                          }
                          defaultValue={coupon.quantity}
                          id={`inventory-${coupon.id}`}
                          className="max-w-48"
                          type="number"
                          placeholder=""
                        />
                      </div>
                      <div className="flex justify-between">
                        <Label>Valid From</Label>
                        <div className="w-48">
                          <DatePicker
                            defaultDate={coupon.valid_from}
                            onChange={(e) => {
                              handleChange(
                                coupon.id || "new",
                                "valid_from",
                                e,
                                "new"
                              );
                            }}
                            id={`validFrom-${coupon.id}`}
                          />
                        </div>
                      </div>

                      <div className="flex justify-between py-1">
                        <Label htmlFor="date-type">Validity Type</Label>
                        <Select value={couponValidityType} onValueChange={(value) => {
                                handleChange(
                                  coupon.id || "new",
                                  "expiry_type",
                                  value,
                                  ""
                                );
                                setCouponValidityType(value);
                              }}>
                              <SelectTrigger className="max-w-48">
                                <SelectValue placeholder="--" />
                              </SelectTrigger>
                              <SelectContent>
                               
                                <SelectItem value="Date">Fixed Date</SelectItem>
                                <SelectItem value="Period">Relative Date</SelectItem>
                                
                              </SelectContent>
                            </Select>
                      </div>
                      {couponValidityType == "Date" ? (
                        <div className="flex justify-between">
                          <Label htmlFor="valid_till">Valid Till</Label>
                          <div className="w-48">
                            <DatePicker
                              defaultDate={coupon.valid_until}
                              onChange={(e) => {
                                handleChange(
                                  coupon.id || "new",
                                  "valid_until",
                                  e,
                                  "new"
                                );
                              }}
                              id={`validTill-${coupon.id}`}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="flex justify-between">
                          <Label htmlFor="coupon-days">Days</Label>
                          <Input
                            id={`validTill-${coupon.id}`}
                            defaultValue={coupon.valid_period}
                            className="h-8 max-w-48"
                            type="number"
                            onChange={(e) => {
                              handleChange(
                                coupon.id || "new",
                                "valid_period",
                                e.target.value,
                                "new"
                              );
                            }}
                            placeholder="Days"
                          />
                        </div>
                      )}
                      <div className="flex justify-between">
                        <Label>Group Size for Discount</Label>
                        <Input
                          onChange={(e) =>
                            handleChange(
                              coupon.id || "new",
                              "minimum_number_required",
                              e.target.value,
                              "new"
                            )
                          }
                          id={`groupSize-${coupon.id}`}
                          className="max-w-48"
                          type="number"
                          placeholder=""
                          defaultValue={coupon.minimum_number_required}
                        />
                      </div>
                      <div className="flex justify-between">
                        <Label>Affiliate Email</Label>
                        <Input
                          onChange={(e) =>
                            handleChange(
                              coupon.id || "new",
                              "affiliate_cc_email",
                              e.target.value,
                              "new"
                            )
                          }
                          id={`affiliateEmail-${coupon.id}`}
                          className="max-w-48"
                          type="email"
                          placeholder=""
                          defaultValue={coupon.affiliate_cc_email}
                        />
                      </div>
                      <div className="flex justify-between">
                        <Label>Make it private</Label>
                        <div className="flex items-center gap-2">
                          <Badge className="ml-2">
                            {!coupon.visible ? "Private" : "Public"}
                          </Badge>
                          <Switch
                            className=""
                            defaultChecked={!coupon.visible}
                            onCheckedChange={(checked) => {
                              console.log(checked);
                              handleChange(
                                coupon.id,
                                "visible",
                                !checked,
                                "new"
                              );
                            }}
                            id={`isPrivate-${coupon.id}`}
                          />
                        </div>
                      </div>
                      <div className="flex gap-4 mt-2 justify-end items-center">
                        <div
                          onClick={() => handleRemoveCoupon(coupon.id)}
                          className="text-sm justify-end cursor-pointer flex items-center gap-1 mt-2"
                        >
                          <Trash size={16} />
                          <span className="underline">Remove</span>
                        </div>
                        <Button className="w-20" type="submit">
                          {mutation.isLoading ? (
                            <div className="dots"></div>
                          ) : (
                            "Update"
                          )}
                        </Button>
                      </div>
                    </div>
                  </form>
                ))}
              <div className="flex w-full">
                <Button
                  onClick={handleAddCoupon}
                  className="mt-4 self-end ml-auto"
                >
                  Add Coupon
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditCoupon;
