"use client";
import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "sonner";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { PhoneInput } from "@/components/ui/phone-input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";

const defaultGuests = [
  {
    ticketType: "",
    fullname: "",
    email: null,
    number: null,
    age: null,
    gender: null,
    pickup_point: null,
  },
];


const Participants = ({ form, guestFields, fields, billDetails, pickupPoints, saveManualBooking, amountToBeCollected }) => {
  const [tickets, setTickets] = useState([]);
  const [numberOfGuests, setNumberOfGuests] = useState(0);
  console.log(form.getValues())

  useEffect(() => {
    console.log(form.getValues('tickets'))
    var guests = form.getValues("tickets").reduce((acc, curr) => {
      acc += curr.quantity;
      return acc;
    }, 0);
    console.log("running",numberOfGuests);
    setNumberOfGuests(guests);
    form.setValue('guestData', Array(guests).fill(defaultGuests[0]))
    var tickets = form.getValues('tickets')
    let ticketIndex = 0; // Track which ticket is being assigned
    let remainingQuantity = tickets[ticketIndex]?.quantity || 0; // Remaining quantity of current ticket

    [...Array(guests)].map((_, index) => {
      // Move to the next ticket if the current one is exhausted
      while (remainingQuantity === 0 && ticketIndex < tickets.length - 1) {
        ticketIndex++;
        remainingQuantity = tickets[ticketIndex]?.quantity || 0;
      }

      // Assign the ticket
      const ticketType = tickets[ticketIndex]?.attribute || null;
      console.log(`Guest ${index} -> Ticket: ${ticketType}`);

      form.setValue(`guestData.${index}.ticketType`, ticketType);

      remainingQuantity--; // Reduce available tickets for the current type
    });
  }, [form]);

  useEffect(()=>{
    setTickets(form.getValues("tickets"));
  },[form])

  
  return (
    <div className="px-4 pt-4 md:h-auto flex flex-col">
      <Button className="uppercase" >Skip & Confirm</Button>
      <div className="mx-auto flex flex-col gap-1 py-2 text-center">
        <h2>Collected Amount: <span className="font-medium">&#8377;{(amountToBeCollected/100).toFixed(2)}</span></h2>
        <h2>Pending Amount: <span className="font-medium">&#8377;{form.getValues('recordPayment') == 'partial' ? ((billDetails?.finalprice - form.getValues('amountCollected'))/100).toFixed(2) : '0'}</span></h2>
        <h2>Discount: <span className="font-medium">&#8377;{form.getValues('discount') || 0.00}</span></h2>
      </div>

      {[...Array(numberOfGuests)].map((field, index) => (
        <div>
          <h2 className="mt-6 mb-2 font-medium">
            Participant {index + 1} details
          </h2>
          <div className=" flex flex-col gap-4">
            <FormField
              control={form.control}
              name={`guestData.${index}.ticketType`}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Ticket Type</FormLabel>
                  <FormControl>
                    <Input value={form.getValues(`guestData.${index}.ticketType`)} readOnly type="text" {...field} />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={`guestData.${index}.fullname`}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input defaultValue="" placeholder="Name" type="text" {...field} />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={`guestData.${index}.email`}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Email Address"
                      type="email"
                      {...field}
                    />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={`guestData.${index}.number`}
              render={({ field }) => (
                <FormItem className="flex flex-col items-start">
                  <FormLabel>Phone</FormLabel>
                  <FormControl className="w-full">
                    <PhoneInput placeholder="" {...field} defaultCountry="IN" />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex items-center gap-2">
              <FormField
                control={form.control}
                className="w-full flex-grow"
                name={`guestData.${index}.age`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Age</FormLabel>
                    <FormControl>
                      <Input
                        className="w-full"
                        placeholder="Age"
                        type="text"
                        {...field}
                      />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={`guestData.${index}.gender`}
                className="w-full"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Gender</FormLabel>
                    <Select
                      className="w-full"
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent className="w-full">
                        <SelectItem value="male">Male</SelectItem>
                        <SelectItem value="female">Female</SelectItem>
                        {/* <SelectItem value="m@support.com">m@support.com</SelectItem> */}
                      </SelectContent>
                    </Select>

                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              className="w-full"
              name={`guestData.${index}.pickup_point`}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Pickup Point</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    className="w-full"
                  >
                    <FormControl>
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select pickup point" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {
                        pickupPoints?.map((point)=>(
                          <SelectItem value={point.attribute}>{point.attribute}</SelectItem>
                        ))
                      }
                    </SelectContent>
                  </Select>

                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={`guestData.${index}.weight`}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Weight</FormLabel>
                  <FormControl>
                    <Input placeholder="Weight" type="number" {...field} />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Participants;
