import React, { useState } from "react";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { MultiSelect } from "../ui/multi-select";
import { ComboboxDemo } from "./ComboboxInput";
import { DatePicker } from "./DatePicker";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useAuthStore } from "@/store/store";
import axios from "axios";
import useFetch from "@/hooks/useFetchLSD";
import { Button } from "../ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const BulkCouponsForm = ({ flow_type, handleSubmit }) => {
  const { companyslug } = useAuthStore();
  const [options, setOptions] = useState([]);
  const [batches, setBatches] = useState([]);
  const [selectedEvents, setSelectedEvents] = React.useState([]);
  const [selectedBatches, setSelectedBatches] = React.useState([]);
  const [couponValidityType, setCouponValidityType] = useState("fixed");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    flow_type: flow_type,
    code: "",
    visible: "private",
    discount: "",
    discount_type: "Percentage",
    discount_applicable: "per_person",
    inventory: "",
    minimum_number_required: null,
    affiliate_cc_email: "",
    valid_from: null,
    valid_until: null,
    days: "",
  });

  const [errors, setErrors] = useState({});

  const discountOptions = [
    { value: "Percentage", label: "Percentage(%)" },
    { value: "Amount", label: "Amount(₹)" },
  ];

  const discountApplicable = [
    { value: "per_person", label: "Per Person" },
    { value: "flat", label: "Flat" },
  ];

  const events = useFetch(
    `${import.meta.env.VITE_APP_API_URL}/v3/events-name/${companyslug}/`,
    {},
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        retry: 2,
        enabed: true,
      },
    }
  );

  React.useEffect(() => {
    if (events?.data) setOptions(events?.data);
  }, [events]);

  const selectEvents = (e) => {
    setSelectedEvents(e);
    if (flow_type == "batch_level") {
      getBatches(e);
    }
  };

  const selectBatches = (e) => {
    setSelectedBatches(e);
  };

  const getBatches = (event_list) => {
    const response = axios.post(
      `${import.meta.env.VITE_APP_API_URL}/v3/coupon/batches/`,
      { events_list: event_list }
    );
    response
      .then((data) => {
        setBatches(data?.data?.response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.code) formErrors.code = "Coupon Code is required";
    if (formData.discount_type === "--")
      formErrors.discount_type = "Please select a discount type";
    if (!formData.inventory || formData.inventory <= 0)
      formErrors.inventory = "Inventory must be greater than 0";
    // if (!formData.minimum_number_required || formData.minimum_number_required <= 0) formErrors.minimum_number_required = "Group size must be greater than 0";
    // if (
    //   !formData.affiliate_cc_email ||
    //   !/\S+@\S+\.\S+/.test(formData.affiliate_cc_email)
    // )
    //   formErrors.affiliate_cc_email = "Valid email is required";
    // if (!formData.valid_from)
    //   formErrors.valid_from = "Valid From date is required";

    // if (couponValidityType === "fixed" && !formData.valid_until) {
    //   formErrors.valid_until = "Valid Till date is required";
    // } else if (couponValidityType === "relative" && !formData.days) {
    //   formErrors.days = "Days field is required for relative validity type";
    // }

    return formErrors;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
   
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      setLoading(true)
      console.log("Form Data:", formData);
      try {
        const response = await axios.post(
          `${
            import.meta.env.VITE_APP_API_URL
          }/v3/create-coupons/${companyslug}/`,
          {
            coupon_details: formData,
            batch_list: selectedBatches,
            event_list: selectedEvents,
          }
        );
        console.log("API Response:", response.data);
        setFormData({
          flow_type: flow_type,
          code: "",
          discount: "",
          discount_type: "",
          inventory: "",
          minimum_number_required: null,
          affiliate_cc_email: "",
          valid_from: null,
          valid_until: null,
          days: "",
        });
        setSelectedBatches([]);
        setSelectedEvents([]);
        setLoading(false)
        handleSubmit();
      } catch (error) {
        console.error("Error updating item status:", error);
      }
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <form onSubmit={handleFormSubmit} className="grid grid-cols-2 gap-4 py-4">
      <div className="grid w-full max-w-sm items-center gap-1.5">
        <Label htmlFor="code">Coupon Code</Label>
        <Input
          className="h-8"
          type="text"
          id="code"
          placeholder="Code"
          value={formData.code}
          onChange={handleInputChange}
        />
        {errors.code && <p className="text-red-500 text-sm">{errors.code}</p>}
      </div>

      <div className="grid w-full max-w-sm items-center gap-1.5">
        <Label htmlFor="disc_type">Discount Type</Label>
        {/* <ComboboxDemo
          onChange={(value) => setFormData({ ...formData, discount_type: value })}
          id="discount_type"
          options={discountOptions}
          
          selectedValue={formData.discount_type}
          fontSize={'text-sm'}
        /> */}
        <Select defaultValue={formData.discount_type} onValueChange={(value) => setFormData({ ...formData, discount_type: value })}>
          <SelectTrigger>
            <SelectValue placeholder="Discount Type" />
          </SelectTrigger>
          <SelectContent>
           {
            discountOptions.map((option) => (
              <SelectItem key={option.value} value={option.value}>{option.label}</SelectItem>
            ))
           }
          </SelectContent>
        </Select>
        {errors.discount_type && (
          <p className="text-red-500 text-sm">{errors.discount_type}</p>
        )}
      </div>

      <div className="grid w-full max-w-sm items-center gap-1.5">
        <Label htmlFor="disc_type">Discount Applicable</Label>
        {/* <ComboboxDemo
          onChange={(value) =>
            setFormData({ ...formData, discount_applicable: value })
          }
          id="discount_applicable"
          options={discountApplicable}
          selectedValue={formData.discount_applicable}
          fontSize={"text-sm"}
        /> */}
        <Select defaultValue={formData.discount_applicable} onValueChange={(value) => setFormData({ ...formData, discount_applicable: value })}>
          <SelectTrigger>
            <SelectValue placeholder="--" />
          </SelectTrigger>
          <SelectContent>
           {
            discountApplicable.map((option) => (
              <SelectItem key={option.value} value={option.value}>{option.label}</SelectItem>
            ))
           }
          </SelectContent>
        </Select>
      </div>

      <div className="grid w-full max-w-sm items-center gap-1.5">
        <Label htmlFor="code">Discount</Label>
        <Input
          className="h-8"
          type="text"
          id="discount"
          placeholder="Discount"
          value={formData.discount}
          onChange={handleInputChange}
        />
        {errors.discount && (
          <p className="text-red-500 text-sm">{errors.discount}</p>
        )}
      </div>

      <div className="grid w-full max-w-sm items-center gap-1.5">
        <Label htmlFor="inventory">Coupon Inventory</Label>
        <Input
          className="h-8"
          type="number"
          id="inventory"
          placeholder="Inventory"
          value={formData.inventory}
          onChange={handleInputChange}
        />
        {errors.inventory && (
          <p className="text-red-500 text-sm">{errors.inventory}</p>
        )}
      </div>

      <div className="grid w-full max-w-sm items-center gap-1.5">
        <Label htmlFor="group-size">Group Size for discount</Label>
        <Input
          className="h-8"
          type="number"
          id="minimum_number_required"
          placeholder="Group Size"
          value={formData.minimum_number_required}
          onChange={handleInputChange}
        />
        {errors.minimum_number_required && (
          <p className="text-red-500 text-sm">
            {errors.minimum_number_required}
          </p>
        )}
      </div>

      <div className="grid w-full max-w-sm items-center gap-1.5">
        <Label htmlFor="affiliate-email">Affiliate Email</Label>
        <Input
          className="h-8"
          type="email"
          id="affiliate_cc_email"
          placeholder="Email"
          value={formData.affiliate_cc_email}
          onChange={handleInputChange}
        />
        {errors.affiliate_cc_email && (
          <p className="text-red-500 text-sm">{errors.affiliate_cc_email}</p>
        )}
      </div>

      <div className="grid w-full max-w-sm items-center gap-1.5">
        <Label htmlFor="coupon-type">Coupon Type</Label>
        <RadioGroup
          onValueChange={(value)=>{setFormData({ ...formData, visible: value })}}
          className="grid-cols-2"
          id="date-type"
          defaultValue={formData.visible}
        >
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="private" id="private" />
            <Label htmlFor="private">Private</Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="public" id="public" />
            <Label htmlFor="public">Public</Label>
          </div>
        </RadioGroup>
      </div>

      <div className="grid w-full max-w-sm items-center gap-1.5">
        <Label htmlFor="valid_from">Valid From</Label>
        <DatePicker
          id="valid_from"
          defaultDate={formData.valid_from}
          onChange={(date) => {console.log("date: ",date);setFormData({ ...formData, valid_from: date })}}
        />
        {errors.valid_from && (
          <p className="text-red-500 text-sm">{errors.valid_from}</p>
        )}
      </div>

      <div className="grid w-full max-w-sm items-center gap-1.5">
        <Label htmlFor="date-type">Validity Type</Label>
        <RadioGroup
          onValueChange={setCouponValidityType}
          className="grid-cols-2"
          id="date-type"
          defaultValue="fixed"
        >
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="fixed" id="fixed-date" />
            <Label htmlFor="fixed-date">Fixed Date</Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="relative" id="relative-date" />
            <Label htmlFor="relative-date">Relative Date</Label>
          </div>
        </RadioGroup>
      </div>


      {couponValidityType === "fixed" ? (
        <div className="grid w-full max-w-sm items-center gap-1.5">
          <Label htmlFor="valid_until">Valid Till</Label>
          <DatePicker
            id="valid_until"
            defaultDate={formData.valid_until}
            onChange={(date) => setFormData({ ...formData, valid_until: date })}
          />
          {errors.valid_until && (
            <p className="text-red-500 text-sm">{errors.valid_until}</p>
          )}
        </div>
      ) : (
        <div className="grid w-full max-w-sm items-center gap-1.5">
          <Label htmlFor="coupon-days">Days</Label>
          <Input
            className="h-8"
            type="number"
            id="days"
            placeholder="Days"
            value={formData.days}
            onChange={handleInputChange}
          />
          {errors.days && <p className="text-red-500 text-sm">{errors.days}</p>}
        </div>
      )}
      {(flow_type == "event_level" || flow_type == "batch_level") && (
        <div className="grid w-full items-center gap-1.5 col-span-2">
          <Label htmlFor="events">Select Events</Label>
          <MultiSelect
            options={options?.map((option) => ({
              label: option.name,
              value: option.id,
            }))}
            className="w-full"
            placeholder="Select Events"
            onValueChange={selectEvents}
          />
          {errors.days && <p className="text-red-500 text-sm">{errors.days}</p>}
        </div>
      )}
      {flow_type == "batch_level" && (
        <div className="grid w-full items-center gap-1.5 col-span-2">
          <Label htmlFor="events">Select Batches</Label>
          <MultiSelect
            options={batches?.map((batch) => ({
              label: batch?.name,
              value: batch?.id,
            }))}
            className="w-full"
            placeholder="Select Batches"
            onValueChange={selectBatches}
          />
          {errors.days && <p className="text-red-500 text-sm">{errors.days}</p>}
        </div>
      )}
      <div className="col-span-2 flex justify-end">
        <Button type="submit" className="ml-auto w-24">
          {!loading ? "Submit" : <div className="dots"></div>}
        </Button>
      </div>
    </form>
  );
};

export default BulkCouponsForm;
