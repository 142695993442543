import { useMutation } from 'react-query';
import axios from 'axios';

//react-query uses useMutatation hook to make post requests
//options is an object which can have two objects
//axiosOptions: options for axios
//queryOptions: options for useQuery

//this exposes a function called mutate which takes in a url and data and makes a post request

const usePost = (url, data) => {
  // Define the mutation function for POST request
  const mutationFn = async () => {
    const response = await axios.post(url, data); // Send POST request with axios
    return response.data;
  };

  // Use React Query's useMutation hook
  const mutation = useMutation(mutationFn);

  return mutation;
};



export default usePost;

