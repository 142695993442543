import React, { useEffect } from "react";
import { Badge } from "@/components/ui/badge";
import { EllipsisVertical, CircleCheckBig, ChevronDown } from "lucide-react";
import { ComboboxDemo } from "@/components/Custom/ComboboxInput";
import { ownerOptions } from "@/lib/data";
import { Separator } from "@/components/ui/separator";
import { Textarea } from "../ui/textarea";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import { TransactionTimeline } from "@/components/Custom/TimelineComp";
import { ScrollArea } from "@/components/ui/scroll-area";
import { DateTimePicker } from "@/components/Custom/DateTimePicker";
import { Button } from "../ui/button";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { useIsMobile } from "@/hooks/use-mobile";
import { Card } from "../ui/card";
import { format } from "date-fns";
import { useAuthStore } from "@/store/store";
import axios from "axios";
import useFetch from "@/hooks/useFetchLSD";
import EditGuestDetails from "./editGuestModal";
import EditTransaction from "../transactions/edit-transaction-modal";
import CopyLink from "../events/copy-button";
import { useToast } from "@/hooks/use-toast";
import { useCompanyStore } from "@/store/companyStore";

const BookingDetails = ({ booking }) => {
  const salesTeamData = useCompanyStore.getState().companyData.teamMembers.data.sales_team_mates;
  const {toast} = useToast();
  const { companyslug } = useAuthStore();
  const isMobile = useIsMobile();
  const [isEditDialogOpen, setIsEditDialogOpen] = React.useState(false);
  const [isEditSheetOpen, setIsEditSheetOpen] = React.useState(false);
  const [addTransactionOpen, setAddTransactionOpen] = React.useState(false);
  const [isRemindersOpen, setIsRemindersOpen] = React.useState(false);
  const [saleRep, setSaleRep] = React.useState("");
  const [salesTeam,setSalesTeam] = React.useState([]);
  const [remark, setRemark] = React.useState(booking?.remark_by_host);
  const [isBreakUpOpen, setIsBreakUpOpen] = React.useState(false);
  const [isCollectionOpen, setIsCollectionOpen] = React.useState(false);
  const [isEditTransactionOpen,setIsEditTransactionOpen] = React.useState(false);
  const [pay,setPay] = React.useState({});
  const [membersInfo,setMembersInfo] = React.useState(booking?.membersinfo_set);
  const [newTransaction,setNewTransaction] = React.useState();

  const eventDetails = useCompanyStore().companyData.events;

  const findEventImage = function (name) {
    const event = eventDetails?.data?.find((event) => event.name === name);
    if(event){
      return `${import.meta.env.VITE_APP_API_URL}${event.image}`;
    }
  };

  const postRequest = async (url, requestData) => {
    try {
      const response = await axios.post(url, requestData);
      toast({
        title: "Record updated",
        variant:"success",
        duration: 2000
      });
      setAddTransactionOpen(false);
      setNewTransaction(response.data);
      return { data: response.data, isError: false, error: null };
    } catch (err) {
      toast({
        title: err?.message || "Record could not be updated",
        variant:"error",
        duration: 2000
      });

      setAddTransactionOpen(false);
      return { data: null, isError: true, error: err };
    }
  };

  useEffect(()=>{
    console.log("SALES TEAM",salesTeamData);
    setSalesTeam([
      ...salesTeamData.map((item) => ({
        value: `${item.peopleid}`,
        label: `${item.firstname || item.username}`,
      })),
    ])
  },[salesTeamData])

  const formReducer = (state, action) => ({
    ...state,
    [action.name]: action.value,
  });

  const [formData, dispatch] = React.useReducer(formReducer, {
    timestamp: format(new Date(), "dd-MM-yyyy'T'HH:mm:ss.SSS"),
  });

  const handleChange = (field, value) => {
    dispatch({ name: field, value: value });
  };

  const submitHandler = function (event) {
    event.preventDefault();
    (async () => {
      const {
        data: transactiondata,
        isError,
        error,
      } = await postRequest(
        `${import.meta.env.VITE_APP_API_URL}/v3/bookings/${companyslug}/${
          booking?.id
        }/add-manual-transaction/`,
        formData
      );
      toast({
        title: "Transaction Recorded",
        variant:"success",
        duration: 2000
      });
      setAddTransactionOpen(false);
      refetchPayment();
    })();
  };

  const assignToBooking = function (value) {
    var data = { sale_rep_id: value };
    (async () => {
      const {
        data: saleRepData,
        isError,
        error,
      } = await postRequest(
        `${import.meta.env.VITE_APP_API_URL}/v3/bookings/${companyslug}/${
          booking?.id
        }/sale-booking-map/`,
        data
      );
      refetchSalerep();
    })();
  };

  const saveBookingRemark = function (e) {
    e.preventDefault();
    const value = document.getElementById("remarkmessage").value;
    const data = { bookingRemark: value };
    (async () => {
      const {
        data: remarkData,
        isError,
        error,
      } = await postRequest(
        `${import.meta.env.VITE_APP_API_URL}/v3/bookings/${companyslug}/${
          booking?.id
        }/booking-remark/`,
        data
      );
      setRemark(remarkData?.remark);
      setIsRemindersOpen(false);
    })();

  };

  const {
    data: salerepData,
    error: salerepError,
    isLoading: salerepLoading,
    refetch: refetchSalerep,
  } = useFetch(
    `${import.meta.env.VITE_APP_API_URL}/v3/bookings/${companyslug}/${
      booking?.id
    }/sale-booking-map/`,
    {},
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: 1,
        enabled: true,
        keepPreviousData: true,
      },
    }
  );

  useEffect(() => {
    if (salerepData) {
      setSaleRep(salerepData?.data?.sale_rep_id.toString());
    }
  }, [salerepData]);

  function mergeTickets(tickets) {
    if (!tickets) {
      return [];
    }
    return Object.values(
      tickets.reduce((acc, { id, attribute, quantity, price }) => {
        if (acc[id]) {
          acc[id].total_quantity += 1;
          acc[id].total_price += price; // Sum total price
        } else {
          acc[id] = {
            id,
            attribute,
            quantity,
            price,
            total_quantity: 1,
            total_price: price, // Calculate initial price
          };
        }
        return acc;
      }, {})
    );
  }

  function mergeAddons(addons) {
    if (!addons) {
      return [];
    }
    return Object.values(
      addons.reduce((acc, { id, attribute, quantity, price }) => {
        if (acc[id]) {
          acc[id].total_quantity += 1;
          acc[id].total_price += price; // Sum total price
        } else {
          acc[id] = {
            id,
            attribute,
            quantity,
            price,
            total_quantity: 1,
            total_price: price, // Calculate initial price
          };
        }
        return acc;
      }, {})
    );
  }

  const {
    data: paymentData,
    error: paymentError,
    isLoading: paymentLoading,
    refetch: refetchPayment,
  } = useFetch(
    `${import.meta.env.VITE_APP_API_URL}/v3/bookings/${companyslug}/${
      booking?.id
    }/payments/`,
    {},
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: 1,
        enabled: true,
        keepPreviousData: true,
      },
    }
  );

  const {
    data: pendingPaymentData,
    error: pendingPaymentError,
    isLoading: pendingPaymentLoading,
    refetch: refetchPendingPayment,
  } = useFetch(
    `${import.meta.env.VITE_APP_API_URL}/v3/bookings/${companyslug}/${
      booking?.id
    }/pending-payment/`,
    {},
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: 1,
        enabled: true,
        keepPreviousData: true,
      },
    }
  );

  useEffect(()=>{
    setPay(pendingPaymentData)
  })

  return (
    <div className="max-h-[80vh] min-h-[80vh]">
      <ScrollArea className="max-h-[80vh] lg:max-h-[100vh] overflow-auto">
        <div className="p-4 flex flex-col gap-4">
          <Tabs className="w-full mt-2" defaultValue="details">
            <TabsList className="ml-auto w-full">
              <TabsTrigger
                value="details"
                className="text-zinc-600 w-full dark:text-zinc-200"
              >
                Details
              </TabsTrigger>
              <TabsTrigger
                value="timeline"
                className="text-zinc-600 w-full dark:text-zinc-200"
              >
                Payments
              </TabsTrigger>
              <TabsTrigger
                value="remarks"
                className="text-zinc-600 w-full dark:text-zinc-200"
              >
                Remarks
              </TabsTrigger>
            </TabsList>
            <TabsContent value="details" className="">
              <div className="py-4 flex flex-col gap-4">
                <div>
                  <div className="flex flex-col gap-3">
                    <div className="flex items-start justify-between">
                      <div className="flex items-start gap-2">
                        <img
                          src={findEventImage(booking.event_name)}
                          className="w-10 h-10 rounded-md"
                        />
                        <div>
                          <h2 className="text-sm leading-4 max-w-52 lg:max-w-64 font-semibold">
                            {booking.event_name}
                          </h2>
                          <h2 className="text-sm">
                            {booking.batch_startdate
                              ? format(
                                  new Date(booking.batch_startdate),
                                  "dd/MM/yyyy"
                                )
                              : ""}
                            {booking.batch_starttime != "NA" ? ` | ${" "}` : ""}
                            {booking.batch_starttime != "NA"
                              ? `${booking.batch_starttime}`
                              : ""}
                          </h2>
                        </div>
                      </div>
                      <div className="flex mt-1 text-md items-center gap-1 font-semibold">
                        {/* <span>5 bookings</span> */}
                        <Badge variant="outline" className="text-xs rounded-md">
                          {booking?.manually_booked ? "Manual" : "Online"}
                        </Badge>
                      </div>
                    </div>
                  </div>
                </div>

                <Separator className="my-2" />
                <div>
                  {/* <h2 className="text-md">Transaction Details</h2> */}
                  {/* <Separator className="mt-1 mb-2" /> */}
                  <div className="flex flex-col gap-2">
                    <div className="grid grid-cols-1 gap-y-2 place-items-start">
                      {/* <h2 className="text-sm text-center flex flex-col">Booked By: <span className="font-medium">{booking.bookedBy}</span></h2> */}
                      <h2 className="text-sm w-full flex justify-between text-muted-foreground">
                        Booked by{" "}
                        <span className="font-medium text-md text-black">
                          {booking?.name}
                        </span>
                      </h2>
                      <h2 className="text-sm w-full flex justify-between text-muted-foreground">
                        Contact{" "}
                        <span className="font-medium text-md text-black">
                          {booking.phone}
                        </span>
                        <span className="font-medium text-md text-black">
                          {booking.email}
                        </span>
                      </h2>
                      <h2 className="text-sm flex w-full justify-between text-muted-foreground">
                        Booking Date{" "}
                        <span className="font-medium text-black text-md">
                          {format(
                            new Date(booking.timestamp),
                            "d MMM, yyyy | hh:mm a"
                          )}
                        </span>
                      </h2>
                    </div>
                    <Separator className="my-2" />
                    <div>
                      {/* <h2 className="text-sm">Tickets & Addons</h2> */}
                      {/* <Separator className="mt-1 mb-2" /> */}
                      <div className="flex flex-col gap-2">
                        {mergeTickets(booking?.price_type).map(
                          (ticket, index) => {
                            return (
                              <>
                                <h2
                                  className="text-sm flex items-center justify-between"
                                  key={index}
                                >
                                  <span className="font-normal max-w-52 text-muted-foreground">
                                    {ticket.attribute}{" "}
                                    <span className="text-black">
                                      (&#8377;{(ticket.price / 100).toFixed(2)})
                                    </span>
                                    <span className="text-primary"></span>
                                  </span>
                                  <span className="font-medium">
                                    {ticket.total_quantity} | &#8377;
                                    {(ticket.total_price / 100).toFixed(2)}
                                  </span>
                                </h2>
                              </>
                            );
                          }
                        )}
                        {mergeAddons(booking?.addon_prices).map(
                          (addon, index) => {
                            return (
                              <>
                                <h2 className="text-sm flex items-center justify-between">
                                  <span className="font-normal text-muted-foreground">
                                    {addon.attribute}-{" "}
                                    <span className="text-primary">
                                      {addon.total_quantity}
                                    </span>
                                  </span>
                                  <span className="font-medium">
                                    &#8377;
                                    {(addon.total_price / 100).toFixed(2)}
                                  </span>
                                </h2>
                              </>
                            );
                          }
                        )}
                      </div>
                    </div>
                    {membersInfo.length > 0 && (
                      <div>
                        <h2 className="text-sm flex justify-between mt-2">
                          Guest Details
                          <Button
                            variant="outline"
                            onClick={() => {
                              if (isMobile) setIsEditSheetOpen(true);
                              else setIsEditDialogOpen(true);
                            }}
                            className="text-xs h-6"
                          >
                            Edit
                          </Button>
                        </h2>
                        <Separator className="mt-1 mb-2" />
                        <div className="flex flex-col gap-2">
                          {membersInfo?.map((member, index) => {
                            return (
                              <>
                                <div>
                                  <Accordion type="single" collapsible>
                                    <AccordionItem value={`item-${index}`}>
                                      <AccordionTrigger>
                                        {!member.fullname ? (
                                          <span>Guest {index + 1}</span>
                                        ) : (
                                          <div className="flex pb-2 items-center justify-between w-full">
                                            <span>
                                              {" "}
                                              {member.fullname} - {member.age}
                                              y,{" "}
                                              {member.gender
                                                ? member.gender === "Male"
                                                  ? "M"
                                                  : "F"
                                                : "-"}
                                            </span>
                                          </div>
                                        )}
                                      </AccordionTrigger>

                                      <AccordionContent>
                                        <div className="grid grid-cols-2 gap-y-4">
                                          <div className="flex flex-col gap-1">
                                            <span className="text-xs text-muted-foreground">
                                              Fullname
                                            </span>
                                            <span>
                                              {member.fullname || "-"}
                                            </span>
                                          </div>
                                          <div className="flex flex-col gap-1">
                                            <span className="text-xs text-muted-foreground">
                                              Email
                                            </span>
                                            <span>{member.email || "-"}</span>
                                          </div>
                                          <div className="flex flex-col gap-1">
                                            <span className="text-xs text-muted-foreground">
                                              Contact
                                            </span>
                                            <span>{member.number || "-"}</span>
                                          </div>
                                          <div className="flex flex-col gap-1">
                                            <span className="text-xs text-muted-foreground">
                                              Gender
                                            </span>
                                            <span>{member.gender || "-"}</span>
                                          </div>
                                          <div className="flex flex-col gap-1">
                                            <span className="text-xs text-muted-foreground">
                                              Age
                                            </span>
                                            <span>{member.age || "_"}</span>
                                          </div>
                                          <div className="flex flex-col gap-1">
                                            <span className="text-xs text-muted-foreground">
                                              Pickup Point
                                            </span>
                                            <span>
                                              {member.pickup_point || "_"}
                                            </span>
                                          </div>
                                        </div>
                                      </AccordionContent>
                                    </AccordionItem>
                                  </Accordion>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </TabsContent>
            <TabsContent value="timeline">
              <div className="py-4 text-right">
                <Dialog
                  open={addTransactionOpen}
                  onOpenChange={setAddTransactionOpen}
                >
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Add transaction</DialogTitle>
                    </DialogHeader>
                    <div className="text-right">
                      <form onSubmit={submitHandler}>
                        <div className="text-left grid grid-cols-1 lg:grid-cols-2 gap-y-4 gap-x-4">
                          <div className="grid w-full max-w-sm items-center gap-1.5">
                            <Label htmlFor="email">Amount</Label>
                            <Input
                              type="number"
                              id="amount"
                              placeholder="Amount"
                              onChange={(e) => {
                                handleChange("amount", e.target.value);
                              }}
                            />
                          </div>
                          <div className="grid w-full max-w-sm items-center gap-1.5">
                            <Label htmlFor="event">Mode</Label>
                            <Select
                              onValueChange={(value) => {
                                handleChange("mode", value);
                              }}
                              required={true}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Select Mode" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Direct Bank">
                                  Direct Bank
                                </SelectItem>
                                <SelectItem value="G Pay">G Pay</SelectItem>
                                <SelectItem value="Phone Pay">
                                  PhonePe
                                </SelectItem>
                                <SelectItem value="Other UPI">
                                  Other UPI
                                </SelectItem>
                                <SelectItem value="Cash">Cash</SelectItem>
                                <SelectItem value="Company QR">
                                  Company QR
                                </SelectItem>
                                <SelectItem value="Cheque">Cheque</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>
                          <div className="grid w-full max-w-sm items-center gap-1.5">
                            <Label htmlFor="email">Payment ID</Label>
                            <Input
                              type="text"
                              id="payment_id"
                              placeholder="Payment ID"
                              onChange={(e) => {
                                handleChange("payment_id", e.target.value);
                              }}
                            />
                          </div>
                          <div className="grid w-full max-w-sm items-center gap-1.5">
                            <Label htmlFor="email">Remarks</Label>
                            <Input
                              type="text"
                              id="remarks"
                              placeholder="Remarks"
                              onChange={(e) => {
                                handleChange("remarks", e.target.value);
                              }}
                            />
                          </div>
                          <div className="grid w-full max-w-sm items-center gap-1.5">
                            <Label htmlFor="email">Time</Label>
                            <DateTimePicker
                              onUpdate={(value)=>{
                                handleChange("time",format(value,"dd/MM/yyyy HH:mm "))
                              }}
                            />
                          </div>
                          <div className="grid w-full max-w-sm items-center gap-1.5">
                            <Label htmlFor="picture">Upload Image</Label>
                            <Input
                              id="picture"
                              className="pt-1"
                              type="file"
                              onChange={(e) => {
                                handleChange("file", e.target.files[0]);
                              }}
                            />
                          </div>
                        </div>
                        <Button className="mt-4">Submit</Button>
                      </form>
                    </div>
                  </DialogContent>
                </Dialog>

                <h2 className="text-sm text-left mt-2">Transaction Details</h2>
                <Separator className="mt-1 mb-2" />
                <div className="flex flex-col gap-2">
                  <h2 className="text-sm flex items-center justify-between">
                    <span className="font-medium">
                      <span className="font-normal text-muted-foreground">
                        Base Price
                      </span>
                    </span>
                    <span className="font-medium">
                      &#8377;
                      {(booking?.total_base_amount / 100).toFixed(2) | "-"}
                    </span>
                  </h2>
                  <h2 className="text-sm flex items-center justify-between">
                    <span className="font-medium">
                      <span className="font-normal text-muted-foreground">
                        Pending Collection
                      </span>
                    </span>
                    <div className="flex flex-col">
                      <span className="font-medium">
                        &#8377;
                        {(paymentData?.pending_amount / 100).toFixed(2) | "-"}
                      </span>
                      {paymentData?.pending_amount > 0 && (
                        
                        <span
                          onClick={() => {
                            setIsCollectionOpen(true);
                          }}
                          className="underline text-[0.65rem] "
                        >
                          Collect Online
                        </span>
                      )
                      }
                      <Dialog
                        open={isCollectionOpen}
                        onOpenChange={setIsCollectionOpen}
                      >
                        <DialogContent
                          className={
                            "lg:max-w-sm rounded-md lg:max-h-[80vh] max-h-[70vh]"
                          }
                        >
                          <DialogHeader>
                            <DialogTitle>Collect Payment</DialogTitle>
                          </DialogHeader>
                          <div className="flex flex-col gap-4">
                            <div className="flex justify-between w-full">
                              <span>Current Balance</span>
                              <span>
                                &#8377;
                                {(paymentData?.pending_amount / 100).toFixed(
                                  2
                                ) || "-"}
                              </span>
                            </div>

                            <div className="flex justify-between w-full">
                              <span>Total Gateway Charges</span>
                              <span>
                                &#8377;
                               {((pay?.charges_by_client + pay?.customer_charges)/100).toFixed(2)}
                              </span>
                            </div>
                            <div className="flex justify-between w-full">
                              <span>Paid by Customer</span>
                              <span>
                                &#8377;
                               {(pay?.customer_charges/100).toFixed(2)}
                              </span>
                            </div>
                            <div className="flex justify-between w-full">
                              <span>Paid by You</span>
                              <span>
                                &#8377;
                               {(pay?.charges_by_client/100).toFixed(2)}
                              </span>
                            </div>
                            <div className="flex items-center gap-2">
                              <Input readOnly type="text" value={pay?.payment_link} />
                              <CopyLink classes={'pb-0'} code={pay?.payment_link} />
                            </div>

                            

                            {/* <div className="flex justify-between w-full">
                              <span className="text-muted-foreground">Fees</span>
                              <span>2499</span>
                            </div>
                            <div className="flex justify-between w-full">
                              <span className="text-muted-foreground">GST</span>
                              <span>2499</span>
                            </div> */}
                            {/* <div className="flex justify-between w-full">
                              <span className="">Amount to be collected</span>
                              <span> &#8377;2499</span>
                            </div> */}
                            {/* <Button className="mt-4">Generate Payment Link</Button> */}
                          </div>
                        </DialogContent>
                      </Dialog>
                    </div>
                  </h2>
                  <h2 className="text-sm flex items-center justify-between">
                    <span className="font-medium">
                      <span className="font-normal text-muted-foreground">
                        Revenue
                      </span>
                    </span>
                    <div className="flex flex-col">
                      <span className="font-medium">
                        &#8377;
                        {(paymentData?.total_revenue / 100).toFixed(2) | "-"}
                      </span>
                      <span
                        onClick={() => setIsBreakUpOpen(true)}
                        className="text-[0.65rem] underline"
                      >
                        Show Breakup
                      </span>
                    </div>
                    <Dialog
                      open={isBreakUpOpen}
                      onOpenChange={setIsBreakUpOpen}
                    >
                      <DialogContent
                        className={
                          "lg:max-w-sm rounded-md lg:max-h-[80vh] max-h-[70vh]"
                        }
                      >
                        <DialogHeader>
                          <DialogTitle>Price Breakup</DialogTitle>
                        </DialogHeader>
                        <div>
                          <span className="uppercase">Online</span>
                          <Separator className="mt-1 mb-2" />

                          <div className="flex justify-between w-full">
                            <span className="text-muted-foreground">
                              Amount Collected
                            </span>
                            <span>
                              &#8377;
                              {(
                                paymentData?.total_online_collected / 100
                              ).toFixed(2) || "-"}
                            </span>
                          </div>
                          <div className="flex justify-between w-full">
                            <span className="text-muted-foreground">Fees</span>
                            <span>
                              &#8377;
                              {(paymentData?.total_fees / 100).toFixed(2) ||
                                "-"}
                            </span>
                          </div>
                          <div className="flex justify-between w-full">
                            <span className="text-muted-foreground">GST</span>
                            <span>
                              &#8377;
                              {(paymentData?.gst / 100).toFixed(2) || "-"}
                            </span>
                          </div>
                          <div className="flex justify-between w-full">
                            <span className="text-muted-foreground">
                              Settled In Bank
                            </span>
                            <span>
                              &#8377;
                              {(paymentData?.total_settlement / 100).toFixed(
                                2
                              ) || "-"}
                            </span>
                          </div>
                        </div>
                        <div>
                          <span className="uppercase">Manual</span>
                          <Separator className="mt-1 mb-2" />

                          <div className="flex justify-between w-full">
                            <span className="text-muted-foreground">
                              Amount Collected
                            </span>
                            <span>
                              &#8377;
                              {(
                                paymentData?.manual_transactions_amount / 100
                              ).toFixed(2) || "-"}
                            </span>
                          </div>
                        </div>
                        <div className="flex justify-between w-full">
                          <span className="">My Revenue</span>
                          <span>
                            &#8377;
                            {(paymentData?.total_revenue / 100).toFixed(2) ||
                              "-"}
                          </span>
                        </div>
                      </DialogContent>
                    </Dialog>
                  </h2>
                  <h2 className="text-sm flex items-center justify-between">
                    <span className="font-medium">
                      <span className="font-normal text-muted-foreground">
                        Discount
                      </span>{" "}
                      {booking?.couponcode}
                    </span>{" "}
                    <span className="font-medium">
                      &#8377;{(booking?.discount / 100).toFixed(2)}
                    </span>
                  </h2>
                  <h2 className="text-sm flex items-center justify-between">
                    <span className="font-medium">
                      <span className="font-normal text-muted-foreground">
                        Booking Amount Paid
                      </span>
                    </span>
                    <span className="font-medium">
                      &#8377;
                      {booking?.manually_booked
                        ? (booking.manual_amount / 100).toFixed(2)
                        : (booking.amount / 100).toFixed(2)}
                    </span>
                  </h2>
                  
                  <h2 className="text-sm flex items-center justify-between text-muted-foreground">
                    Amount Refunded{" "}
                    <span className="font-medium text-black">
                      &#8377;
                      {booking.refunded
                        ? (booking.amount_refunded / 100).toFixed(2)
                        : "0"}
                    </span>
                  </h2>
                  <Button
                    onClick={() => {
                      setAddTransactionOpen(true);
                    }}
                    className="mt-2"
                  >
                    Add transaction
                  </Button>
                </div>
              </div>
              <div>
                <h2 className="text-sm flex justify-between items-center mt-2">Timeline
                  <Button variant={'outline'} onClick={()=>setIsEditTransactionOpen(true)}>Edit</Button>
                </h2>
                <Separator className="mt-1 mb-2" />
                <TransactionTimeline booking_id={booking.id} transactionAdded={isEditTransactionOpen} newTransactionAdded={newTransaction}/>
              </div>
            </TabsContent>
            <TabsContent value="remarks" className="">
              <div className="flex flex-col py-4 gap-4">
                <div>
                  <Label
                    htmlFor="assigned"
                    className="text-sm font-normal text-muted-foreground"
                  >
                    Assigned To
                  </Label>
                  <ComboboxDemo
                    id="assigned"
                    defaultValue={saleRep}
                    onChange={(value) => {
                      assignToBooking(value);
                    }}
                    options={salesTeam}
                    width={"w-full"}
                    fontSize={"text-sm"}
                  />
                </div>
                <div>
                  <div className="mt-4">
                    <h2 className="text-sm">Remarks</h2>
                    <Separator className="mt-1 mb-4" />
                    {remark && <Card className="p-4">
                      <h2>{remark}</h2>
                    </Card>}
                    <div className="flex">
                      <Button
                        onClick={() => {
                          setIsRemindersOpen(true);
                        }}
                        className="mt-2 ml-auto"
                      >
                        {booking?.remark_by_host
                          ? "Update Remark"
                          : "Add Remark"}
                      </Button>
                    </div>
                  </div>
                </div>
                <Dialog
                  open={isRemindersOpen}
                  onOpenChange={setIsRemindersOpen}
                >
                  <DialogContent className="lg:max-w-md">
                    <DialogHeader>
                      <DialogTitle className="text-lg">Add Remark</DialogTitle>
                    </DialogHeader>
                    <div className="flex flex-col gap-4">
                      <form onSubmit={saveBookingRemark}>
                        <div className="grid w-full gap-1.5">
                          <Label htmlFor="remarkmessage">Remark</Label>
                          <Textarea
                            className="h-[100px] focus-visible:ring-offset-0 focus-visible:ring-0"
                            placeholder="Type your message here."
                            defaultValue={remark}
                            id="remarkmessage"
                          />
                        </div>
                        <Button type="submit">Save</Button>
                      </form>
                    </div>
                  </DialogContent>
                </Dialog>
              </div>
            </TabsContent>
          </Tabs>
        </div>
      </ScrollArea>
      {isEditDialogOpen && (
        <EditGuestDetails
          booking={booking}
          isEditDialogOpen={isEditDialogOpen}
          isEditSheetOpen={isEditSheetOpen}
          setIsEditDialogOpen={setIsEditDialogOpen}
          setIsEditSheetOpen={setIsEditSheetOpen}
          setMembersInfo={setMembersInfo}
        />
      )}
      {isEditSheetOpen && (
        <EditGuestDetails
          booking={booking}
          isEditDialogOpen={isEditDialogOpen}
          isEditSheetOpen={isEditSheetOpen}
          setIsEditDialogOpen={setIsEditDialogOpen}
          setIsEditSheetOpen={setIsEditSheetOpen}
          setMembersInfo={setMembersInfo}
        />
      )}
      {
        isEditTransactionOpen && <EditTransaction isOpen={isEditTransactionOpen} setIsOpen={setIsEditTransactionOpen} bookingId={booking.id} />
      }
    </div>
  );
};

export default BookingDetails;
