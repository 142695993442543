import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Bell } from "lucide-react";
import useFetch from "@/hooks/useFetchLSD";
import { useAuthStore } from "@/store/store";
import axios from 'axios';

const MobileCard = ({ lead, confirmBooking }) => {
  const formattedTimestamp = new Date(lead.timestamp).toLocaleString("en-IN", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  return (
  <div className="p-4 border rounded-lg mb-4">
    <div className="flex items-start gap-3 mb-3">
      <img src={`${import.meta.env.VITE_APP_API_URL}${lead.event_pic}`} className="w-12 h-12 rounded-md" />
      <div>
        <div className="font-medium">{lead.event_name}</div>
        <div className="text-sm text-gray-500">{lead.eventbatches}</div>
      </div>
    </div>
    <div className="space-y-2">
      <div className="text-sm text-gray-500">{lead.booked_by?.email}</div>
      <div className="text-sm text-gray-500">{lead.formattedTimestamp}</div>
      <div className="flex justify-between items-center mt-3">
        <div className="font-medium">{lead.total_payable_amount? `₹${(lead.total_payable_amount/100).toFixed(2)}` : "-"}</div>
        <Button className="bg-black hover:bg-black text-white" onClick={() => confirmBooking(lead.id)}>
          Confirm
        </Button>
      </div>
    </div>
  </div>
)};

export function MissedCheckout() {
  const { companyslug } = useAuthStore();
  const [limit, setLimit] = React.useState(10)
  const [leads, setLeads] = React.useState([])
  const [apiQuery, setApiQuery] = React.useState("")
  const { data, error, isLoading, isFetching, refetch } = useFetch(
    `${import.meta.env.VITE_APP_API_URL}/v3/company-booking-leads/${companyslug}/?limit=${limit}&offset=0&q=${apiQuery}`,
    {},
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        retry: 2,
        enabed: true
      },
    }
  );

  React.useEffect(() => {
    if(data?.results)
      setLeads(data.results);
  }, [data])


  const loadMore = async () => {
    setLimit(limit+25);
    await refetch();
  }

  const confirmBooking = async (id) => {
    const response = axios.post(
      `${import.meta.env.VITE_APP_API_URL}/v3/manually-add-booking/${id}/`,
    {},
    )
    response.then((data)=> {
      refetch();
    })
    .catch((error) => {
      console.log(error)
    })
  }

  return (
    <div className="w-full">
      <div className="flex justify-between items-center mb-4">
        <h2 className="section_heading">Missed Checkouts</h2>
        <div className="flex gap-2">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="gap-2">
                <Bell className="h-4 w-4" />
                <span className="hidden sm:inline">Notifications</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem className="cursor-pointer">
                <div className="flex flex-col gap-1">
                  <div className="font-medium">Whatsapp</div>
                </div>
              </DropdownMenuItem>
              <DropdownMenuItem className="cursor-pointer">
                <div className="flex flex-col gap-1">
                  <div className="font-medium">Email</div>
                </div>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <Button variant="outline" onClick={refetch}>
            {isFetching && !isLoading ? <div className="spinner"></div> : ''}
            Refresh</Button>
        </div>
      </div>
      <div className="hidden md:block border rounded-lg overflow-hidden">
        <div className="overflow-x-auto">
          <Table>
            <TableHeader>
              <TableRow className="bg-gray-50">
                <TableHead className="font-medium w-[50px] min-w-[50px]">#</TableHead>
                <TableHead className="font-medium min-w-[250px]">Event Details</TableHead>
                <TableHead className="font-medium min-w-[300px]">Checkout Details</TableHead>
                <TableHead className="font-medium w-[150px] min-w-[150px]">Amount payable</TableHead>
                <TableHead className="font-medium w-[150px] min-w-[150px]">Action</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {leads?.map((lead) => {
                const formattedTimestamp = new Date(lead.timestamp).toLocaleString("en-IN", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                });
                return (
                  <TableRow key={lead.id}>
                    <TableCell className="whitespace-nowrap">{lead.id}</TableCell>
                    <TableCell>
                      <div className="flex items-start gap-3">
                        <div className='relative w-12 h-12 rounded-md overflow-hidden'>
                        <img src={`${import.meta.env.VITE_APP_API_URL}${lead.event_pic}`} className="absolute top-0 left-0 w-full h-full object-cover" />
                        </div>
                        <div>
                          <div className="font-medium">{lead.event_name}</div>
                          <div className="text-sm text-gray-500">{lead.eventbatches}</div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="font-medium">{lead.booked_by?.email}</div>
                      <div className="text-sm text-gray-500">
                        {formattedTimestamp} | {lead.number}
                      </div>
                      <div className="text-sm text-gray-500">{lead.email}</div>
                      <div className="text-sm text-gray-500">For: {lead.numberofpeople} bookings</div>
                    </TableCell>
                    <TableCell className="whitespace-nowrap">{lead.total_payable_amount? `₹${(lead.total_payable_amount/100).toFixed(2)}` : "-"}</TableCell>
                    <TableCell>
                      <Button className="bg-black hover:bg-black text-white whitespace-nowrap" onClick={() => {confirmBooking(lead.id)}}>
                        Confirm Booking
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
      </div>
      <div className="md:hidden">
        {leads.map((lead) => (
          <MobileCard key={lead.id} lead={lead} confirmBooking={confirmBooking} />
        ))}
      </div>
      <div className="flex justify-center md:w-[100%] mb-4 mt-4">
        <Button className="w-28" onClick={loadMore}>
          {isLoading ? <div className="dots"></div> : 'Load More'}
        </Button>
      </div>
    </div>
  );
}

export default MissedCheckout;