"use client";

import React from "react";
import { SquareArrowOutUpRight } from "lucide-react";
import { DataTableColumnHeader } from "@/components/ui/data-table-column-header";
import { Button } from "@/components/ui/button";
import { Calendar } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { DetailsDrawer } from "@/components/ui/data-table";
import { useIsMobile } from "@/hooks/use-mobile";
import { format } from "date-fns";
import { CustomerDetails } from "./customerDetails";

export const customerColumns = [
  {
    accessorKey: "id",
    size: 60,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ID" />
    ),
    cell: ({ row }) => {
      const [isDetailsOpen, setIsDetailsOpen] = React.useState(false);
      return (
        <div className="text-xs items-center cursor-pointer flex flex-col gap-1">
          <span
            onClick={() => {
              setIsDetailsOpen(true);
            }}
            className="text-[0.65rem] underline"
          >
            {row.original.id}
          </span>
          {isDetailsOpen && (
            <CustomerDetails
              isOpen={isDetailsOpen}
              setIsOpen={setIsDetailsOpen}
              id={row.original.id}
            />
          )}
        </div>
      );
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "Name",
    size: 140,
    enableSorting: false,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Name" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex cursor-pointer gap-2 text-xs">
          <div className="flex items-center gap-2">
            <span className="w-8 h-8 bg-purple-500 rounded-md flex items-center justify-center text-white font-medium">
            {row.original.fullname.split(" ")[0][0]}{row.original.fullname?.split(" ").length>1 && row.original.fullname?.split(" ")[1][0]}
            </span>
            <span className="max-w-[10rem]">{row.original.fullname}</span>
          </div>
        </div>
      );
    },
  },
  {
    accessorKey: "Contact",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Contact" />
    ),
    size: 120,
    enableSorting: false,
    cell: ({ row }) => {
      return (
        <div className="text-xs">
          <div>
            <span>{row.original.mobile || ""}</span>
          </div>
          <div>
            <span>{row.original.email}</span>
          </div>
          {/* <h2>{email}</h2> */}
          {/* <Badge variant="outline" className={'text-[0.6rem] rounded-md mr-1 py-0'}>Bookings: 2</Badge> */}
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "Bookings",
    size: 60,
    enableSorting: false,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Bookings" />
    ),
    cell: ({ row }) => {
      return (
        <div className=" text-xs">
          <h2 className="font-medium">{row.original.total_bookings}</h2>
        </div>
      );
    },
    // filterFn: (row, id, value) => {
    //   return value.includes(row.getValue(id))
    // },
  },
  {
    accessorKey: "Last Booked",
    size: 140,
    enableSorting: false,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Last Booked" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex items-start gap-2 text-xs">
          {row.original.last_booking && (
            <>
              <div className="w-8 h-8 rounded-md overflow-hidden relative">
                <img src={`${import.meta.env.VITE_APP_API_URL}/media/${row.original.last_booking?.cover_pic}`} className="absolute top-0 left-0 w-full h-full object-cover" />
              </div>
              <div className="flex flex-col max-w-[10rem]">
                <span>{row.original.last_booking?.name}</span>
                <span>{row.original.last_booking?.start_date}</span>
              </div>
            </>
          )}
        </div>
      );
    },
    // filterFn: (row, id, value) => {
    //   return value.includes(row.getValue(id))
    // },
  },
  {
    accessorKey: "Total Revenue",
    size: 60,
    enableSorting: false,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Total Revenue" />
    ),
    cell: ({ row }) => {
      return (
        <div className=" text-xs">
          <span>&#8377;{(row.original.total_amount / 100).toFixed(2)}</span>
        </div>
      );
    },
  },

  
];
