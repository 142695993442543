import React, { useCallback, useEffect, useState } from "react";
import {
  useSensors,
  useSensor,
  PointerSensor,
  KeyboardSensor,
  DndContext,
  closestCorners,
  // DragEndEvent,
  // DragStartEvent,
  // DragOverEvent,
  DragOverlay,
  // DropAnimation,
  defaultDropAnimation,
} from "@dnd-kit/core";
import axios from "axios";
import { sortableKeyboardCoordinates, arrayMove } from "@dnd-kit/sortable";
import { ChatUI } from "./ChatUI";
import { Button } from "../ui/button";
import { Store } from "./Store";
import { ArrowLeft } from "lucide-react";
import { useAuthStore } from "@/store/store";
import { useToast } from "@/hooks/use-toast";
import { Switch } from "../ui/switch";
// import { findBoardSectionContainer, initializeBoard } from '../utils/board';
// import BoardSection from './BoardSection';
// import TaskItem from './TaskItem';

import Stage from "./Stage";
import { Sidebar } from "./Sidebar";

import { getStagebyID } from "./utils";

import { Canvas } from "./Canvas";
import CategorySelect from "./CategorySelect";
import GreetingForm from "./GreetingForm";
import EventSelect from "./EventSelect";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { getStraightPath } from "@xyflow/react";
import { update } from "lodash";

export const Chatbot = ({ flowData, setIsFlowSelected, setSelectedFlow }) => {
  const { companyslug } = useAuthStore();
  const { toast } = useToast();

  const [flowStage, setFlowStage] = useState(null);
  const [freeStage, setFreeStage] = useState(null);

  const [activeStage, setActiveStage] = useState(null);
  const [activeStageArea, setActiveStageArea] = useState(null);

  const [isStageSelected, setIsStageSelected] = useState(false);
  const [stageSelected, setStageSelected] = useState(null);

  useEffect(() => {
    setFlowStage(flowData?.flow);
    setFreeStage(flowData?.stages);
  }, [flowData]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const getUpdatedStage = (id) => {
    return getStagebyID(flowStage, freeStage, id);
  };

  const stage = activeStage
    ? getStagebyID(flowStage, freeStage, activeStage)
    : null;

  const handleDragStart = ({ active }) => {
    console.log("ON DRAG START", active);
    setActiveStage(active.id.toString());
  };

  const handleSave = function (quick_save, flowStageData) {
    var stageData;
    if (!flowStageData) {
      stageData = [...flowStage];
    } else {
      stageData = [...flowStageData];
    }
    var formData = {};
    stageData.map((stage, index) => {
      var nextStageId = null;
      if (index < stageData.length) {
        nextStageId = index + 1;
      }
      formData[stage.id] = {
        forms: stage.forms,
        next: stageData[nextStageId]?.id ? stageData[nextStageId]?.id : null,
      };
    });
    try {
      axios
        .post(
          `${import.meta.env.VITE_APP_API_URL}/v3/chatbot-flow/${companyslug}/`,
          formData
        )
        .then((response) => {
          if (!quick_save) {
            toast({
              title: "Flow Saved",
              variant: "success",
              duration: 2000,
            });
          }
          if (response?.data?.data) {
            setFlowStage(response.data?.data?.flow);
          }
          return { data: response.data, isError: false, error: null };
        });
    } catch (err) {
      return { data: null, isError: true, error: err };
    }
  };

  const changeFlowLiveStatus = function(value){
    const formData = {'status':value};
    try {
      axios
        .post(
          `${import.meta.env.VITE_APP_API_URL}/v3/chatbot-flow/${companyslug}/${flowData.id}/live-status/`,
          formData
        )
        .then((response) => {
          toast({
            title: response.data.status? "Flow enabled":"Flow disabled",
            variant: "success",
            duration: 2000,
          });
          return { data: response.data, isError: false, error: null };
        });
    } catch (err) {
      toast({
        title: "Please try again after some time",
        variant: "error",
        duration: 2000,
      });
      return { data: null, isError: true, error: err };
    }
  }

  const handleDragOver = ({ active, over }) => {
    if (!over) return;

    const activeContainer = active.data.current?.sortable?.containerId;
    const overContainer = over.data.current?.sortable?.containerId;

    if (activeContainer === overContainer) {
      return;
    } else if (activeContainer === "store" && overContainer === "playground") {
      const currentStage = activeStage
        ? getStagebyID(flowStage, freeStage, active.id)
        : null;
      console.log("CURRENT STAGE", currentStage);

      if (currentStage) {
        // Prevent duplication by checking if it already exists in flowStage
        if (!flowStage.some((stage) => stage.id === currentStage.id)) {
          setFlowStage([...flowStage, currentStage]);
        }

        // Remove from freeStage
        setFreeStage((prevStages) =>
          prevStages.filter(
            (stage) => stage.id.toString() !== currentStage.id.toString()
          )
        );
      }
    } else if (activeContainer === "playground" && overContainer === "store") {
      const currentStage = activeStage
        ? getStagebyID(flowStage, freeStage, active.id)
        : null;
      if (currentStage) {
        // Prevent duplication by checking if it already exists in freeStage
        if (!freeStage.some((stage) => stage.id === currentStage.id)) {
          setFreeStage([...freeStage, currentStage]);
        }

        // Remove from flowStage
        setFlowStage((prevStages) =>
          prevStages.filter(
            (stage) => stage.id.toString() !== currentStage.id.toString()
          )
        );
      }
    }
  };

  const handleStageDelete = function (deletedStage) {
    //const currentStage = getStagebyID(flowData,freeStage,stage_id);
    console.log("deleteion node ", deletedStage);
    setFreeStage([...freeStage, deletedStage]);

    const updatedStage = [...flowStage].filter(
      (stage) => stage.id.toString() !== deletedStage?.id.toString()
    );
    // Remove from flowStage
    setFlowStage(updatedStage);
    handleSave(true, updatedStage);
  };

  const updateStageForm = (id, form) => {
    //find in which category does the stage exists
    if (flowStage?.some((stage) => stage.id.toString() === id.toString())) {
      setFlowStage((prevStage) =>
        prevStage.map((item) =>
          item.id.toString() === id.toString() ? { ...item, forms: form } : item
        )
      );
    } else if (
      freeStage?.some((stage) => stage.id.toString() === id.toString())
    ) {
      setFreeStage((prevStage) =>
        prevStage.map((item) =>
          item.id.toString() === id.toString() ? { ...item, forms: form } : item
        )
      );
    }
  };

  const addCustomStage = function (stageData) {
    try {
      axios
        .post(
          `${
            import.meta.env.VITE_APP_API_URL
          }/v3/chatbot-flow/${companyslug}/add-stage/`,
          stageData
        )
        .then((response) => {
          toast({
            title: "Stage Updated",
            variant: "success",
            duration: 2000,
          });
          console.log("UPDATED STAGES", response.data);
          setFreeStage(response.data.data.stages);
        });
    } catch (err) {
      return { data: null, isError: true, error: err };
    }
  };

  const reorderCanvasStage = (active, over) => {
    var newArr = [...flowStage];

    if (active.id !== over.id) {
      console.log("Reordering", active.id, over.id);

      const activeStage = getStagebyID(flowStage, freeStage, active.id);
      const overStage = getStagebyID(flowStage, freeStage, over.id);
      console.log("ACTIVE", activeStage.name);
      console.log("OVER", overStage);

      if (
        overStage?.meta_data?.identifier !== "CATEGORY" &&
        !overStage?.meta_data?.editable
      ) {
        toast({
          title: `${overStage.name} order cannot be changed`,
          variant: "error",
          duration: 2000,
        });
        return;
      }

      const oldIndex = flowStage.findIndex(
        (item) => item.id == active.id.toString()
      );
      const newIndex = flowStage.findIndex(
        (item) => item.id == over.id.toString()
      );
      newArr = arrayMove(flowStage, oldIndex, newIndex);
      setFlowStage(newArr);
    }
  };

  const handleDragEnd = ({ active, over }) => {
    console.log("ON DRAG END ", active, "-", over);

    const activeContainer = active.data.current.sortable.containerId;
    const overContainer = over.data.current.sortable.containerId;
    console.log("ACTIVE CONTAINER", activeContainer);
    console.log("OVER CONTAINER", overContainer);
    if (activeContainer === overContainer) {
      reorderCanvasStage(active, over);
    }

    setActiveStage(null);
  };

  const dropAnimation = {
    ...defaultDropAnimation,
  };

  // const task = activeTaskId ? getTaskById(tasks, activeTaskId) : null;

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center gap-4 mb-4 ml-2">
        <ArrowLeft
          size={28}
          onClick={() => {
            setIsFlowSelected(false);
            setSelectedFlow(null);
          }}
        />
        <div>{flowData && flowData?.name}</div>
        <div>
          <div className="hidden md:flex items-center space-x-4">
            <Switch
              defaultChecked={flowData?.isLive}
              onCheckedChange={changeFlowLiveStatus}
              className="h-5"
              id="live-draft"
              // disabled={loadingStates}
            />
            {/* <Label htmlFor="live-draft">Live</Label> */}
          </div>
        </div>
      </div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <div
          className="flex items-start justify-between w-full"
          style={{ height: "100%" }}
        >
          <div className="reactflow-wrapper w-full overflow-y-visible">
            {flowStage && (
              <Canvas
                setStageSelected={setStageSelected}
                setIsStageSelected={setIsStageSelected}
                stageSelected={stageSelected}
                handleStageDelete={handleStageDelete}
                flow={flowStage}
              />
              // <ChatUI />
            )}
            {/* <DragOverlay dropAnimation={dropAnimation}>
            {stage ? <Stage data={stage} /> : null}
          </DragOverlay> */}
          </div>

          <Sidebar
            setStageSelected={setStageSelected}
            setIsStageSelected={setIsStageSelected}
            setFreeStage={setFreeStage}
            stageSelected={stageSelected}
            isStageSelected={isStageSelected}
            freeStage={freeStage}
            activeStageArea={activeStageArea}
            updateStageForm={updateStageForm}
            handleSave={handleSave}
            addCustomStage={addCustomStage}
            getUpdatedStage={getUpdatedStage}
          />
        </div>
      </DndContext>
    </div>
  );
};
