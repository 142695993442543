import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Custom/Layout";
import axios from "axios";
import setupAxios from "./setup/axiosSetup";
import TokenHandler from "./components/Custom/TokenHandler";


const AppRoutes = () => {

  
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/dashboard/:slug/:token" element={<TokenHandler />}></Route>
          <Route path="/dashboard/:slug/" element={<Layout />}></Route>
        </Routes>
      </Router>
    </div>
  );
};

export default AppRoutes;
