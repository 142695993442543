import React, { useEffect, useState } from "react";
import { Label } from "@/components/ui/label";
import { useCompanyStore } from "@/store/companyStore";
import { Badge } from "@/components/ui/badge";
import MultiSelectCombobox from "@/components/multi-combobox";
import { CircleX, GripVertical } from "lucide-react";
import { Separator } from "@/components/ui/separator";
import { DndContext } from "@dnd-kit/core";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { arrayMove } from "@dnd-kit/sortable";
import SortableItem from "./SortableCategory";
import { Textarea } from "../ui/textarea";


const CategorySelect = function ({data, updateStageForm}) {
  const [value, setValue] = React.useState();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const categories = useCompanyStore().companyData.categories.data;
  const [textNode, setTextNode] = useState()
  const [categoriess, setCategoriess] = useState([])

  useEffect(()=>{
    const text = data.forms.find((node)=>node.type=='text')
    setTextNode(text)
    const cats = data.forms.find((node)=>node.type == 'quick_reply')
    console.log(cats)
    const transformedArray = cats?.value?.map((fruit) => ({
          id: fruit.payload, // Using `id` as value
          name: fruit.text, // Using `name` as label
        }));
    setSelectedCategories(transformedArray)
    setCategoriess(cats.value)
    // setCategoryOptions(transformedArray)
  },[data])

  const handleCategorySelect = (value) => {
    console.log(value)
    var temp = []
    value.map((val)=>{
        var cat = categories.find((cat)=>cat.slug==val)
        if(cat){
            temp.push(cat)
        }
    })
    console.log(temp)
    const transformed = temp.map((temp)=>({
      name: temp.name,
      id: temp.slug
    }))
    const transformed2 = temp.map((temp)=>({
      text: temp.name,
      payload: temp.slug
    }))
    setSelectedCategories(transformed)
    const updated = [...data.forms]
    const text = updated.find((node)=>node.type=='quick_reply')
    text.value = transformed2
    updateStageForm(data.id, updated)
  };

  const handleHeaderChange = (e) => {
      const updated = [...data.forms]
      const text = updated.find((node)=>node.type=='text')
      text.value = e.target.value
      updateStageForm(data.id, updated)
  }
  

  const reorderLinks = async (e) => {
    var newArr = [...selectedCategories];
    if (!e.over) {
      console.log("No over");
      return;
    }

    if (e.active.id !== e.over.id) {

      console.log(e.active.id, e.over.id)
      const oldIndex = selectedCategories.findIndex(
        (item) => item.id == e.active.id.toString()
      );
      const newIndex = selectedCategories.findIndex(
        (item) => item.id == e.over.id.toString()
      );
    
      newArr = arrayMove(selectedCategories, oldIndex, newIndex);
      const transformed2 = newArr.map((temp)=>({
        text: temp.name,
        payload: temp.id
      }))
      setSelectedCategories(newArr);
      const updated = [...data.forms]
      const text = updated.find((node)=>node.type=='quick_reply')
      text.value = transformed2
      updateStageForm(data.id, updated)
    }
    
  };

  useEffect(() => {
    const transformedArray = categories.map((fruit) => ({
      value: fruit.slug, // Using `id` as value
      label: fruit.name, // Using `name` as label
    }));

    setCategoryOptions(transformedArray);
  }, [categories]);

  const getDefaultSelected = () => {
    var selected = []
    selectedCategories.map((cat)=>{
      selected.push(cat.id)
    })
    return selected
  }


  return (
    <div>
      <div className="flex flex-col gap-1">
          <div className="flex flex-col gap-1">
                <Label>Header</Label>
                <Textarea onChange={handleHeaderChange} defaultValue={textNode?.value}/>
              </div>
       
        {categoryOptions?.length > 0 && (
          <div className="my-4 w-full">
            <MultiSelectCombobox
              onUpdate={handleCategorySelect}
              options={categoryOptions}
              defaultValues={getDefaultSelected()}
            />
          </div>
        )}
      </div>

      <DndContext onDragEnd={reorderLinks}>
        <div className="flex px-2 flex-col gap-3">
          <SortableContext items={selectedCategories}>
            {selectedCategories && selectedCategories?.length > 0 &&
              selectedCategories?.map((val, index) => (
                <SortableItem key={index} setItems={setSelectedCategories} item={val} items={selectedCategories} data={data} updateStageForm={updateStageForm}/>
              ))}
          </SortableContext>
        </div>
      </DndContext>
    </div>
  );
};

export default CategorySelect;
