import React, { useEffect, useState, useCallback } from "react";
import { cn } from "@/lib/utils";
import { format } from 'date-fns';
import BatchCard from "./batch-card";
import { ArrowUpDown, CirclePlus } from "lucide-react";
import { Badge } from "../ui/badge";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "../ui/button";
import { ListFilter } from "lucide-react";
import { Calendar } from "@/components/ui/calendar";
import { CalendarDays } from "lucide-react";
import EventCard from "./event-card";
import useEventStore from "@/store/eventStore";
import useFetch from "@/hooks/useFetchLSD";
import { useAuthStore } from "@/store/store";
import { Card } from "../ui/card";
import { Skeleton } from "../ui/skeleton";
import axios from 'axios';
import AddBatchesModal from "./add-batch";

const EventList = ({ data, filters, refetchEvent }) => {
  const [events, setEvents] = useState(data.results);
  const [date, setDate] = React.useState(new Date());
  const [isDateDialogOpen, setIsDateDialogOpen] = React.useState(false);
  const [eventBatch, setEventBatch] = useState([]);
  const [filteredBatches, setFilteredBatches] = React.useState([]);
  const [isBatchesLoading,setIsBatchesLoading] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [eventBatchData, setEventBatchData] = useState([]);
  const { companyslug } = useAuthStore();
  const { eventBatches, selectedEvent, setEventBatches } = useEventStore();
  const [isAddBatchesOpen, setIsAddBatchesOpen] = useState(false);

  const onDateChange = (newDate) => {
    // You can execute other logic here if needed
    // For example, filtering batches based on the new date
    const filteredBatch = getFilteredBatches(newDate);
    setFilteredBatches(filteredBatch);
  };

  useEffect(() => {
    setEvents(data?.results);
  }, [data]);

  //function to remove the currently deleted batch
  const handleBatchDelete = function(batchID){
    const updatedBatches = eventBatches?.filter((batch)=>{
      return batch.id.toString() !== batchID.toString();
    })
    setEventBatches(updatedBatches);
  }

  // Function to filter batches based on selected date
  const getFilteredBatches = (selectedDate) => {
    if (!selectedDate) return [];
    return eventBatches?.filter((batch) => {
      const batchDate = new Date(batch.startdate); // Convert startdate to a Date object
      return batchDate.toDateString() === selectedDate.toDateString(); // Compare selected date with batch date
    });
  };

  // Monitor the date state change
  useEffect(() => {
    if (date) {
      onDateChange(date); // Trigger the callback whenever the date changes
    }
  }, [date, eventBatches]); // Dependency array ensures the effect runs when the date changes


  const {
    data: eventbatchDatas,
    error: batchError,
    isLoading: batchLoading,
    refetch: batchRefetch,
  } = useFetch(
    `${
      import.meta.env.VITE_APP_API_URL
    }/v3/events/${companyslug}/get-event-batch-card/`,
    {},
    {
      queryOptions: {
        refetchOnMount: false,
        retry: 2,
        enabled: true,
      },
    }
  );

  const handleMonthChange = (newMonth) => {
    const formattedMonth = format(newMonth, 'yyyy-MM');
    setIsBatchesLoading(true);
    fetchBatches(formattedMonth);
    setCurrentMonth(newMonth);
  };

  const handleBatchCreation = async function(){
    await fetchBatches(currentMonth);
  }


  const fetchBatches = async (month) => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/v3/event-batches/${selectedEvent.slug}?month=${month}`
      );
      setEventBatches(response.data);
      setIsBatchesLoading(false); // Return data or handle it as needed
    } catch (error) {
      console.error('Error fetching event batches:', error);
      setIsBatchesLoading(false);
      // Handle error, such as showing a message to the user
    }
  }

  useEffect(() => {
    if (eventbatchDatas?.length>0) {
      setEventBatchData(eventbatchDatas);
    }
  },[eventbatchDatas])



  return (
    <div className="">
      <div className="flex flex-col h-auto relative pt-0">
        
        <div className="flex flex-col gap-1 lg:gap-4">
          {/* <LeadsFilter /> */}
          {/* hover:bg-accent */}
          {events.map((item, index) => (
            <EventCard
            batchData = {eventBatchData?.length > 0
            ? [...(eventBatchData.find((batch) => batch.event_id == item.id)?.batch || [])]
            : []}
              loadingBatchData={batchLoading}
              key={index}
              isDateDialogOpen={isDateDialogOpen}
              setIsDateDialogOpen={setIsDateDialogOpen}
              item={item}
              setIsBatchesLoading={setIsBatchesLoading}
              refetchEvent={refetchEvent}
            />
          ))}
          <div>
            {/* <EllipsisVertical onClick={()=>setIsDropdownMenuOpen(true)} size={20} /> */}
          </div>
          <Dialog open={isDateDialogOpen} onOpenChange={setIsDateDialogOpen}>
            <DialogContent
              className={
                "lg:max-w-3xl max-w-[24rem] overflow-y-scroll rounded-md lg:max-h-screen max-h-[80vh]"
              }
            >
              <DialogHeader>
                <DialogTitle>
                  {/* All Dates */}
                  <div className="flex items-center gap-2">
                    <img
                      src={selectedEvent.cover_pic}
                      className="w-6 h-6 rounded-md"
                    />
                    <span className="uppercase text-left lg:text-lg text-sm">
                      {selectedEvent?.name}
                    </span>{" "}
                    -
                    <span className="text-left lg:text-lg text-sm">
                      Dates & Slots
                    </span>
                  </div>
                </DialogTitle>
              </DialogHeader>
              {
                isBatchesLoading ? ( <div className="flex justify-center gap-4 items-start">
                  
                  <div>
                    <Skeleton className={'w-80 h-80'} />
                  </div>
                  <div className="flex flex-col gap-2">
                    <Skeleton className={'w-80 h-40'} />
                    <Skeleton className={'w-80 h-40'} />
                  </div>
                </div>):(
              
              <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
                <div className="flex flex-col gap-2"> 
                <Calendar
                  mode="single"
                  selected={date}
                  onSelect={setDate}
                  showOutsideDays={false}
                  className={"pb-4"}
                  onMonthChange={handleMonthChange}
                  month={currentMonth}
                  components={{
                    Day: (props) => {
                      const {
                        date: day,
                        modifiers = {},
                        onSelect,
                        ...buttonProps
                      } = props; // Provide a default empty object for modifiers
                      if (day.getMonth() !== props.displayMonth.getMonth())
                        return null;
                      const isSelected =
                        date && day.toDateString() === date.toDateString();
                      const count = eventBatches?.filter((item) => {
                        const batchStartDate = new Date(item?.startdate);
                        return (
                          batchStartDate.toDateString() === day.toDateString()
                        );
                      }).length;

                      return (
                        <div
                          {...buttonProps}
                          className="flex flex-col mx-4 justify-center items-center"
                        >
                          <Button
                            variant="outline"
                            {...buttonProps}
                            onClick={() => setDate(day)}
                            // className={cn(
                            //   "w-8 h-8 my-1 rounded-full border-none flex items-center justify-center"
                            // )}
                            className={cn(
                              "w-8 h-8 my-1  hover:bg-none rounded-full border-none flex items-center justify-center",
                              isSelected && "bg-green-400 text-white" // Apply background color to selected day
                            )}
                            // onClick={() => onSelect(day)}
                          >
                            <div className="flex flex-col items-center">
                              {props.date.getDate()}
                              {/* {props.date.selected} */}
                              {/* {date.toDateString()} */}
                            </div>
                          </Button>
                          {count != 0 && (
                            <Badge
                              variant={"outline"}
                              className={
                                "flex bg-[#4e73df] text-white justify-center text-[0.65rem] py-0 h-3"
                              }
                            >
                              {count > 3 ? "+3" : count}
                            </Badge>
                          )}
                        </div>
                      );
                    },
                  }}
                />
                  <Button onClick={()=>{setIsAddBatchesOpen(true)}}><CirclePlus size={16} />
                    Add batches</Button>

                </div>
               

                <div
                  style={{ scrollbarWidth: "none" }}
                  className="max-h-[26rem] pt-3 flex flex-col gap-2 overflow-y-auto"
                >
                 
                  <div className="flex items-center gap-1">
                    <CalendarDays size={18} />
                    <span className="font-semibold">
                      {date.toLocaleDateString()}
                    </span>
                  </div>
                  {/* <BatchCard item={events[0].batches[0]} /> */}
                  {/* <BatchCard /> */}
                  {filteredBatches?.map((item, index) => (
                    <BatchCard key={index} filteredBatches={filteredBatches} setFilteredBatches={setFilteredBatches} item={item} eventId = {selectedEvent.id} handleBatchDelete={handleBatchDelete}/>
                  ))}
                  {filteredBatches.length == 0 && (
                    <div>
                      <h1>No batches available for the selected date</h1>
                    </div>
                  )}
                </div>
              </div>)}
            </DialogContent>
          </Dialog>
        </div>
      </div>
          {
                  isAddBatchesOpen && (
                    <AddBatchesModal filteredBatches={filteredBatches} setFilteredBatches={setFilteredBatches} isOpen={isAddBatchesOpen} setIsOpen={setIsAddBatchesOpen} eventSlug={selectedEvent.slug} handleBatchCreation={handleBatchCreation} />
                  )
                }
     

      {/* Sidebar Section */}
      
    </div>
  );
};

export default EventList;
