import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { CirclePlus, Plus, TicketPercent } from "lucide-react";
import { format } from "date-fns";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

import axios from "axios";

const coupons = [
  {
    id: 1,
    discount: "35% OFF",
    code: "summer35",
    description: "Save ₹0 with this coupon",
    minTickets: 1,
    expiry: "Nov. 15, 2024",
  },
  {
    id: 2,
    discount: "₹100 OFF",
    code: "VISA30",
    description: "Save ₹100.00 with this coupon",
    minTickets: null,
    expiry: "Nov. 15, 2024",
  },
];

const CouponModal = ({
  isOpen,
  onClose,
  coupons,
  handleTicketUpdate,
  form,
}) => {
  console.log(coupons);
  const [openCoupon, setOpenCoupon] = useState(null);
  const [typedCoupon, setTypedCoupon] = useState('');
  const [couponError, setCouponError] = useState(false)

  const applyCoupon = async (code) => {
    var guests = form.getValues("tickets").reduce((acc, curr) => {
      acc += curr.quantity;
      return acc;
    }, 0);
    const res = await axios.post(
      `${import.meta.env.VITE_APP_API_URL}/v3/apply-coupon/${form.getValues(
        "batch"
      )}/`,
      {
        CouponCode: code,
        numberofguest: guests,
      }
    );
    console.log(res.data);
    if (res.data.valid) {
      form.setValue("couponCode", code);
      await handleTicketUpdate();
      onClose(false);
    }
    else if(typedCoupon){
      setCouponError(true)
    }
    else{
      console.log("here")
      setCouponError(res.data.message)
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent
        className={"lg:max-w-sm rounded-md lg:max-h-[90vh] overflow-y-auto"}
      >
        <DialogHeader>
          <DialogTitle className="text-lg font-medium">
            Apply Coupon
          </DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <div className="flex items-center space-x-2">
            <Input
              type="text"
              placeholder="Enter Coupon Code"
              className="flex-1"
              onChange={(e)=>{setCouponError(false);setTypedCoupon(e.target.value)}}
            />
            <Button className="ml-2" onClick={()=>{applyCoupon(typedCoupon)}}>Apply</Button>
          </div>
          {couponError && typedCoupon && <span className="text-[10px] text-red-500">Coupon code <span className="font-medium">{typedCoupon}</span> is invalid</span>}
          {couponError && !typedCoupon && <span className="text-[10px] text-red-500">{couponError}</span>}
          <hr />
          <div className="space-y-4">
            {coupons.map((coupon) => (
              <div
                key={coupon.id}
                className="flex relative items-start pr-4  justify-between rounded-lg shadow-md cursor-pointer"
              >
                <div className="flex relative items-center space-x-4">
                  <div
                    style={{
                      writingMode: "vertical-rl",
                      textOrientation: "mixed",
                    }}
                    className="text-center max-h-[6rem] h-full text-white relative rotate-180 font-bold bg-[#1ac870] rounded-r-lg  px-4 uppercase py-3"
                  >
                    {coupon.discount_type == "Amount" ? "₹" : ""}
                    {Math.floor(coupon.discount)}
                    {coupon.discount_type == "Percentage" ? "%" : ""} off
                  </div>
                  <div className="circles flex flex-col gap-2 absolute left-[-10%]">
                    <div className="w-3 rounded-full h-3 bg-white"></div>
                    <div className="w-3 rounded-full h-3 bg-white"></div>
                    <div className="w-3 rounded-full h-3 bg-white"></div>
                  </div>
                  <div>
                    <div className="text-base mb-1 flex items-center gap-2 font-bold">
                      <TicketPercent size={20} />
                      {coupon.code}
                    </div>
                    <p className="text-xs text-[#1ac870]">
                      {coupon.discount_type == "Percentage"
                        ? "Save " + coupon.discount + " with this coupon"
                        : "Save ₹" + coupon.discount + " with this coupon"}
                    </p>
                    {/* {coupon.minTickets && (
                      <p className="text-sm text-gray-500">
                        Minimum tickets required: {coupon.minTickets}
                      </p>
                    )} */}
                    <div className="mt-3">
                      {coupon.valid_until && (
                        <p className="text-xs text-gray-500">
                          Expires on -{" "}
                          {format(coupon.valid_until, "dd/MM/yyyy")}
                        </p>
                      )}
                      {coupon.minimum_number_required && (
                        <p className="text-xs text-gray-500">
                          Minimum Bookings -{" "}
                          {coupon.minimum_number_required || "-"}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <span
                  onClick={() => applyCoupon(coupon.code)}
                  className="cursor-pointer mt-[0.35rem] font-semibold text-sm uppercase"
                >
                  Apply
                </span>
                
              </div>
            ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CouponModal;

export const CouponSuccess = ({ isOpen, setIsOpen, billDetails, form }) => {
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent
        className={"lg:max-w-sm rounded-md lg:max-h-[90vh] overflow-y-auto"}
      >
        <DialogHeader>
          <DialogTitle className="text-lg font-medium">
            Coupon Applied
          </DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
        
            <div className="flex relative items-center space-x-4">
              <div className="flex flex-col w-full justify-center gap-2 items-center">
                <DotLottieReact
                  src="https://lottie.host/embed/4151c953-b93f-44d8-a079-3e574fc7977b/vL4hljt5vL.lottie"
                  autoplay
                  className="w-32 h-32"
                />
                <div className="text-base mb-1 flex items-center gap-2">
                  Applied coupon code:
                  <span className="font-semibold">
                    {form.getValues("couponCode")}
                  </span>
                </div>

                <div
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  className="font-medium cursor-pointer"
                >
                  Yay!
                </div>
              </div>
            </div>
          
          
        </div>
      </DialogContent>
    </Dialog>
  );
};
