import React from "react";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
  } from "@/components/ui/alert-dialog"
  
  export function DeleteAlert({ open, setOpen,title, description, onConfirm, confirmArgs  }) {
    const [alertOpen, setAlertOpen] = React.useState(false)
    React.useEffect(()=>{
        setAlertOpen(open)
    }, [open])
    return (
      <AlertDialog open={alertOpen} onOpenChange={setAlertOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{title || "Are you sure?"}</AlertDialogTitle>
            <AlertDialogDescription>
              {description ||
                "This action cannot be undone. Do you want to proceed?"}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => {setAlertOpen(false);setOpen(false)}}>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                onConfirm && onConfirm(confirmArgs)
                setAlertOpen(false)
              }}
            >
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }
   