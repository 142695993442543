"use client";
import { Checkbox } from "@/components/ui/checkbox";
import React, { useState } from "react";
import { DetailsDrawer } from "../ui/data-table";
import { SquareArrowOutUpRight } from "lucide-react";
import { DataTableColumnHeader } from "../transactions/data-table-column-header";
import { Button } from "@/components/ui/button";
import {
  Calendar,
  Phone,
  Clock,
  NotebookText,
  BellRing,
  UsersRound,
  MessageSquare,
  Layers2,
} from "lucide-react";
import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ComboboxDemo } from "../Custom/ComboboxInput";
import {
  callbackOptions,
  ownerOptions,
  stageOptions,
  statusOptions,
} from "@/lib/data";
import EditEnquiry from "./edit-lead";
import CustomerProfileForm from "./edit-customer-profile";
import LeadDetails from "./lead-details";
import useLeadsStore from "@/store/leadsStore";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import axios from "axios";
import LeadChatModal from "./lead-chat";
import whatsapp_logo from "@/assets/WhatsApp.svg";
import instagram_logo from "@/assets/instagram.jpg";
import logout_logo from "@/assets/logout.png";
import chatwithio_logo from "@/assets/chatwithio.png";
import myoperator_logo from "@/assets/myoperator.png";
import website_logo from "@/assets/website.png";
import whatsapp_icon from "@/assets/WhatsApp.svg";

const sourceImgs = {
  instagram: (
    <img
      src="https://img.icons8.com/fluency/48/instagram-new.png"
      className="w-4 h-4"
    />
  ),
  chatbot: (
    <img
      src="https://img.icons8.com/fluency/48/instagram-new.png"
      className="w-4 h-4"
    />
  ),
  instacomment: (
    <img
      src="https://img.icons8.com/fluency/48/instagram-new.png"
      className="w-4 h-4"
    />
  ),
  whatsapp: <img src={whatsapp_icon} className="w-4 h-4" />,
  ChatWithIO: <img src={chatwithio_logo} className="w-4 h-4" />,
  website: <img src="https://img.icons8.com/ios-glyphs/48/domain.png" className="w-4 h-4" />,
  logout: <img src={logout_logo} className="w-4 h-4" />,
  facebook: (
    <img
      src="https://img.icons8.com/color/48/facebook-new.png"
      className="w-4 h-4"
    />
  ),
};

export const leadColumns = [
  // {
  //   accessorKey: "id",
  //   size: 60,
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="ID" />
  //   ),
  //   cell: ({ row }) => {
  //     const [isDetailsOpen, setIsDetailsOpen] = React.useState(false);
  //     return (
  //       <div className="text-xs cursor-pointer flex flex-col gap-1">
  //         <div className="flex items-center gap-2">
  //           <Checkbox
  //            {...{
  //             checked: row.getIsSelected(),
  //             disabled: !row.getCanSelect(),
  //             onCheckedChange: row.getToggleSelectedHandler(),
  //           }}
  //             className="w-5 h-5 rounded-md"
  //           />

  //           <span
  //             onClick={() => {
  //               setIsDetailsOpen(true);
  //             }}
  //             className="underline"
  //           >
  //             {row.getValue("id")}
  //           </span>
  //         </div>
  //         {row.original?.is_expecting_call_back && (
  //           <div>
  //             <div className="flex gap-1 items-center">
  //               <Badge className="text-[0.6rem] text-orange-600  bg-orange-50  gap-1">
  //                 <div className="circle"></div>
  //                 Callback
  //               </Badge>
  //             </div>
  //           </div>
  //         )}
  //         {/* {row.original.source == "instagram" ? (
  //         <div className="">
  //           <Badge
  //             className={
  //               "items-center self-start text-[0.5rem] py-0 bg-[#e1306c] font-medium rounded-md px-1 flex gap-1"
  //             }
  //             variant="default"
  //           >
  //             <FaInstagram className="" size={10} />
  //             Instagram DM
  //           </Badge>
  //         </div>
  //       ) : (
  //         <div className="">
  //           <Badge
  //             className={
  //               "items-center text-[0.5rem] py-0 bg-[#25d366] font-medium rounded-md px-1 flex gap-1"
  //             }
  //             variant="default"
  //           >
  //             <FaWhatsapp className="" size={10} />
  //             WhatsApp
  //           </Badge>
  //         </div>
  //       )} */}
  //         {isDetailsOpen && (
  //           <DetailsDrawer
  //             isDetailsOpen={isDetailsOpen}
  //             setIsDetailsOpen={setIsDetailsOpen}
  //             heading={"leads"}
  //             selected={row.original}
  //           />
  //         )}
  //       </div>
  //     );
  //   },
  //   enableSorting: false,
  //   enableHiding: false,
  // },
  {
    accessorKey: "details",
    size: 120,
    enableSorting: false,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Lead Details" />
    ),
    cell: ({ row }) => {
     
      const [optionModalOpen, setIsOptionModalOpen] = useState(false)
  
      const formattedTimestamp = new Date(
        row.original.created_at
      ).toLocaleString("en-IN", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
      const [isChatModalOpen, setIsChatModalOpen] = useState(false);
      const [isDetailsOpen, setIsDetailsOpen] = useState(false);
      return (
        <div className="flex items-start gap-2">
          <Checkbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              onCheckedChange: row.getToggleSelectedHandler(),
            }}
            className="w-4 h-4 rounded-sm"
          />
          <div className="font-semibold flex flex-col items-start gap-1">
            <div className="flex items-center gap-2">
              <span
                onClick={() => setIsDetailsOpen(true)}
                className="md:block cursor-pointer hidden underline"
              >
                {row.original.customer_profile?.fullname}
              </span>
              <span
                onClick={() => setIsDetailsOpen(true)}
                className="md:hidden cursor-pointer underline"
              >
                {row.original.customer_profile?.fullname}
              </span>
              {row.original.source == "instagram" || row.original.source == "chatbot" ? (
                <div className="">
                  {/* <FaInstagram className="" size={16} /> */}
                  {sourceImgs[row.original.source]}
                  {/* <Badge
                          className={
                            "items-center text-[0.6rem] bg-[#e1306c] font-medium rounded-md px-1 flex gap-1"
                          }
                          variant="default"
                        >
                          
                        </Badge> */}
                </div>
              ) : row.original?.source == "whatsapp" ? (
                <div className="">
                  {sourceImgs[row.original.source]}
                  {/* <Badge
                          className={
                            "items-center text-[0.6rem] bg-[#25d366] font-medium rounded-md px-1 flex gap-1"
                          }
                          variant="default"
                        >
                          
                        </Badge> */}
                </div>
              ) : (
                <div className="">
                  {/* {item?.source} */}
                  {row?.original?.source !== "website"? sourceImgs[row.original.source] : ""}

                  {row.original?.source?.includes("website") ||
                  (row.original?.origin_domain?.includes("http") &&
                    !row.original?.origin_domain?.includes("/linkinbio") &&
                    !row.original?.origin_domain?.includes("logout.world"))
                    ? sourceImgs["website"]
                    : ""}
                  {!row.original?.origin_domain?.includes("/linkinbio") &&
                    row.original?.origin_domain?.includes("logout.world") &&
                    sourceImgs["logout"]}
                  {/* <Badge
                          className={
                            "items-center capitalize text-[0.6rem] font-medium rounded-md px-1 flex gap-1"
                          }
                          variant="outline"
                        >
                          
                        </Badge> */}
                </div>
              )}
            </div>
            <span>ID: {row.original.id}</span>

            <div className="text-xs underline flex gap-1 cursor-pointer items-center font-medium">
              {" "}
              {row.original.customer_profile.mobile && <a
                className="flex items-center text-primary hover:text-primary gap-1"
                href={`tel:${row.original.customer_profile.mobile}`}
              >
                <Phone size={12} />

                {row.original.customer_profile?.mobile}
                {" "}|{" "}
              </a>}
              
              {row.original.customer_profile.mobile && <img
                onClick={() => setIsChatModalOpen(true)}
                src={whatsapp_logo}
                className="w-5 h-5 cursor-pointer"
              />}
              {/* <Phone size={12} /> */}
            </div>
            <span className="flex leading-4 items-center text-[0.6rem] gap-1">
              <Clock size={12} />
              {formattedTimestamp}
            </span>
            <div>
              {/* {row.original.customer_profile?.source == "instagram" && (
                <div className="hidden md:block">
                  <Badge
                    className={
                      "items-center  text-[0.5rem] rounded-md py-[0rem] px-1 flex gap-1 font-bold"
                    }
                    variant="outline"
                  >
                    <FaInstagram className="text-[#e1306c]" size={10} />
                    Instagram DM
                  </Badge>
                </div>
              )} */}
              {/* {row.original.customer_profile?.source == "whasapp" && (
                <div className="hidden md:block">
                  <Badge
                    className={
                      "items-center  text-[0.5rem] rounded-md py-[0rem] px-1 flex gap-1 font-bold"
                    }
                    variant="outline"
                  >
                    <FaWhatsapp className="text-[#25d366]" size={10} />
                    WhatsApp
                  </Badge>
                </div>
              )} */}
            </div>
            {isChatModalOpen && (
              <LeadChatModal
                isOpen={isChatModalOpen}
                setIsOpen={setIsChatModalOpen}
                row={row.original}
              />
            )}
            {isDetailsOpen && (
              <DetailsDrawer
                isDetailsOpen={isDetailsOpen}
                setIsDetailsOpen={setIsDetailsOpen}
                heading={"leads"}
                selected={row.original}
              />
            )}
          </div>
          {optionModalOpen && <LeadChatModal row={row} isOpen={optionModalOpen} setIsOpen={setIsOptionModalOpen} />}
        </div>
      );
    },
  },
  {
    accessorKey: "enquiry",
    size: 120,
    enableSorting: false,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Enquiry Details" />
    ),
    cell: ({ row }) => {
      let formattedPrefStartTimestamp = null;
      if (row.original.preferred_start_date) {
        formattedPrefStartTimestamp = new Date(
          row.original.preferred_start_date
        ).toLocaleString("en-IN", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
      }
      return (
        // <div className="font-semibold flex flex-col items-normal gap-2">
        //   <div className="text-sm flex gap-2 items-center font-medium">
        //     <img src="/thumbnail.png" className="h-5 w-5 rounded-md" />
        //     {row.original.eventName}
        //   </div>
        //   <div className="grid grid-cols-2 grid-rows-2 gap-x-2 gap-y-2">
        //     <span className="text-xs font-normal text-muted-foreground">
        //       Preference:{" "}
        //       <span className="text-primary font-medium">{row.original.pref_dates}</span>
        //     </span>
        //     <span className="text-xs font-normal text-muted-foreground">
        //       Pax: <span className="text-primary font-medium">{row.original.pax}</span>
        //     </span>
        //     <span className="text-xs font-normal text-muted-foreground">
        //       Enquiry Type:{" "}
        //       <span className="text-primary font-medium">{row.original.enquiryType} Us</span>
        //     </span>
        //     <span className="text-xs font-normal text-muted-foreground">
        //       Category:{" "}
        //       <span className="text-primary font-medium">{row.original.category}</span>
        //     </span>
        //   </div>
        // </div>
        <div className="flex flex-col gap-1">
          <div className="flex cursor-pointer gap-2 text-xs">
            {row.original?.event ? (
              <div className="w-8 h-8 rounded-md relative overflow-hidden">
                <img
                  src={
                    row.original?.event?.pic
                      ? `${import.meta.env.VITE_APP_API_URL}${
                          row.original.event.pic
                        }`
                      : "/thumbnail.png"
                  }
                  className="absolute top-0 left-0 w-full h-full object-cover"
                />
              </div>
            ) : (
              ""
            )}
            <div className="flex flex-col font-medium max-w-36">
              <span>{row.original.event?.name}</span>
              {/* <span className="flex items-center gap-1">
              {row.original.pref_dates}
              <SquareArrowOutUpRight size={12} />
            </span> */}
            </div>
          </div>
          <div className="flex flex-col gap-1">
            {row.original.no_of_guests && (
              <div className="flex items-center gap-3">
                <UsersRound className="font-normal" size={18} />
                <span className="font-medium text-xs">
                  {row.original.no_of_guests} Pax
                </span>
              </div>
            )}
            {formattedPrefStartTimestamp && (
              <div className="flex items-center gap-3">
                <Calendar size={18} />
                <span className="font-medium text-xs">
                  {formattedPrefStartTimestamp}
                </span>
              </div>
            )}
            {row.original.message && (
              <div className="flex items-center gap-3">
                <MessageSquare className="w-[18px] h-[18px] shrink-0" />
                <span className="font-medium text-xs line-clamp-2">
                  {row.original.message}
                </span>
              </div>
            )}
            {row.original.preferred_event_category && (
              <div className="flex items-center gap-3">
                <Layers2 size={18} />
                <span className="font-medium text-xs">
                  {row.original.preferred_event_category?.name}
                </span>
              </div>
            )}
          </div>
        </div>
      );
    },
  },
  {
    accessorKey: "remarksandreminders",
    size: 100,
    enableSorting: false,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Remarks & Reminders" />
    ),
    cell: ({ row }) => {
      return (
        <div className="font-semibold flex flex-col items-normal gap-2">
          {row.original?.last_remark?.id && (
            <span className="flex flex-col gap-1">
              <span className="text-xs text-muted-foreground">Remarks</span>
              <span className="font-medium text-xs">
                {row.original?.last_remark?.follow_up_remark}
              </span>
            </span>
          )}
          {row.original?.last_reminder?.id && (
            <span className="flex items-center gap-1">
              <BellRing size={12} className="text-black" />
              <span className="font-normal max-w-36 truncate text-xs">
                {row.original?.last_reminder?.reminder_remark}
              </span>
            </span>
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "quickactions",
    size: 80,
    enableSorting: false,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Quick Actions" />
    ),
    cell: ({ row }) => {
      const [stage, setStage] = React.useState();
      const [status, setStatus] = React.useState(statusOptions[0].value);
      React.useEffect(() => {
        setStage(row.original?.funnel_stage);
        setStatus(row.original?.status);
      }, [row]);
      const { setUpdateEnquiryField } = useLeadsStore();
      return (
        <div className="font-semibold flex flex-col items-normal gap-2">
          <div className="text-sm flex-col flex gap-2 items-center font-medium">
            {/* <ComboboxDemo options={callbackOptions} /> */}
            {/* <ComboboxDemo onChange={()=>{}} options={stageOptions} defaultValue={stage}/> */}
            <Select
              onValueChange={(value) => {
                setStage(value);
                setUpdateEnquiryField({
                  funnel_stage: value,
                  id: row.original.id,
                });
              }}
              value={stage ? stage : "Lead Stage"}
            >
              <SelectTrigger className="max-w-48">
                <SelectValue placeholder="Select Stage" />
              </SelectTrigger>
              <SelectContent>
                {stageOptions?.map((stage) => (
                  <SelectItem
                    key={stage.value}
                    value={stage.value}
                    className="max-w-48"
                  >
                    {stage.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Select
              onValueChange={(value) => {
                setStatus(value);
                setUpdateEnquiryField({ status: value, id: row.original.id });
              }}
              value={status ? status : "Lead Status"}
            >
              <SelectTrigger className="max-w-48">
                <SelectValue placeholder="Select Status" />
              </SelectTrigger>
              <SelectContent>
                {statusOptions?.map((status) => (
                  <SelectItem
                    key={status.value}
                    value={status.value.toString()}
                    className="max-w-48"
                  >
                    {status.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            {/* <ComboboxDemo onChange={()=>{}} options={statusOptions} defaultValue={status}/> */}
          </div>
        </div>
      );
    },
  },

  {
    accessorKey: "ownerandstage",
    size: 80,
    enableSorting: false,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Assigned To" />
    ),
    cell: ({ row }) => {
      // console.log(row.original);
      const { teamMates, setUpdateEnquiryField } = useLeadsStore();
      const [team, setTeam] = React.useState();
      React.useEffect(() => {
        setTeam(row.original?.asigned_to?.peopleid);
      }, [row]);
      return (
        <div className="font-semibold flex flex-col items-normal gap-2">
          <div className="text-sm flex-col flex gap-2 items-center font-medium">
            {/* <ComboboxDemo onChange={(e)=>{console.log(e)}} options={teamMates} defaultValue={row.original?.asigned_to?.peopleid} /> */}
            <Select
              onValueChange={(value) => {
                setTeam(value);
                setUpdateEnquiryField({
                  asigned_to: value,
                  id: row.original.id,
                });
              }}
              value={team}
            >
              <SelectTrigger className="max-w-48">
                <SelectValue placeholder="Select Assignee" />
              </SelectTrigger>
              <SelectContent>
                {teamMates?.map((team) => (
                  <SelectItem key={team.value} value={team.value}>
                    {team.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            {/* <ComboboxDemo options={stageOptions} /> */}
          </div>
        </div>
      );
    },
  },
  {
    accessorKey: "actions",
    size: 80,
    enableSorting: false,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Actions" />
    ),
    cell: ({ row }) => {
      const { setUpdateEnquiryField } = useLeadsStore();
      const [isEditDialogOpen, setIsEditDialogOpen] = React.useState(false);
      const [isDetailsOpen, setIsDetailsOpen] = React.useState(false);
      const [isCustomerProfileOpen, setIsCustomerProfileOpen] =
        React.useState(false);
      const { refetchApi } = useLeadsStore();
      const deleteEnquiry = async (id) => {
        try {
          const response = await axios.delete(
            `${import.meta.env.VITE_APP_API_URL}/v3/delete-enquiry/${id}/`
          );
          refetchApi();
        } catch (error) {
          console.error(
            "Error deleting enquiry:",
            error.response ? error.response.data : error.message
          );
        }
      };
      return (
        <div className="">
          <DropdownMenu>
            <DropdownMenuTrigger className="justify-start">
              <Button>Actions</Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {/* <DropdownMenuLabel>My Account</DropdownMenuLabel> */}
              {/* <DropdownMenuSeparator /> */}

              <DropdownMenuItem
                onSelect={() => {
                  setIsEditDialogOpen(true);
                }}
              >
                Edit Enquiry
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  setIsDetailsOpen(true);
                }}
              >
                View Details
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  setIsCustomerProfileOpen(true);
                }}
              >
                Edit Customer Profile
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => {setUpdateEnquiryField({
                  notify_owner: "true",
                  id: row.original.id,
                })}}>Notify Owner</DropdownMenuItem>
              <DropdownMenuItem onClick={() => deleteEnquiry(row.original?.id)}>
                Delete Enquiry
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          {isEditDialogOpen && (
            <EditEnquiry
              lead={row.original}
              isOpen={isEditDialogOpen}
              setIsOpen={setIsEditDialogOpen}
              refetch={refetchApi}
            />
          )}
          {isDetailsOpen && (
            <DetailsDrawer
              isDetailsOpen={isDetailsOpen}
              setIsDetailsOpen={setIsDetailsOpen}
              heading={"leads"}
              selected={row.original}
            />
          )}
          {isCustomerProfileOpen && (
            <CustomerProfileForm
              isOpen={isCustomerProfileOpen}
              setIsCustomerProfileOpen={setIsCustomerProfileOpen}
              customer_profile={row.original.customer_profile}
            />
          )}
        </div>
      );
    },
  },
];
