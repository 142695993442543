import Transaction from "@/components/transactions/transaction";
import React, { useState, useEffect, useCallback, useRef } from "react";

// import { transactions } from "@/components/bookings/data/tasks";
import { Badge } from "@/components/ui/badge";
import { Search } from "lucide-react";
import { Input } from "@/components/ui/input";
import { ListFilter } from "lucide-react";
import { CalendarDatePickerWithPresets } from "@/components/Custom/DateRangeWithPresets";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { format } from "date-fns";
import { Skeleton } from "@/components/ui/skeleton";
import { Card } from "@/components/ui/card";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { transactionColumns } from "@/components/transactions/columns";
import { DataTable } from "@/components/ui/data-table";
import { debounce, set } from "lodash";

import { useAuthStore } from "@/store/store";
import useFetch from "@/hooks/useFetchLSD";

import {
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { DateRangePickerPresets } from "@/components/Custom/DateRangePickerTest";
const TransactionsPage = () => {
  const { companyslug } = useAuthStore();
  const ROOT_URL = `${
    import.meta.env.VITE_APP_API_URL
  }/v3/transactions/${companyslug}`;
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [sorting, setSorting] = React.useState([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [bookingDateFilter, setBookingDateFilter] = useState({});
  const [transactionType, setTransactionType] = useState("All");
  const [transactions, setTransactions] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const loadedMoreRef = useRef(false);
  const [isFetching,setIsFetching] = useState(false);

  const [filters, setFilters] = useState({
    q: "",
    page: "",
    size: "",
    type: "",
  });

  const [apiURL, setApiURL] = useState(ROOT_URL);

  const [columnVisibility, setColumnVisibility] = React.useState({
    id: true,
    eventDetails: true,
    bookingDetails: true,
    transactionAmount: true,
    refunded: true,
    pending: true,
    fees: false,
    settlement: false,
  });

  const handleBookingDateChange = (value) => {
    setBookingDateFilter({
      bookingfilter_timestamp_from_date: format(value.range.from, "dd-MM-yyyy"),
      bookingfilter_timestamp_to_date: format(value.range.to, "dd-MM-yyyy"),
    });
  };

  const {
    data: transactionsData,
    isFetching: transactionsFetching,
    error: transactionsError,
    isLoading: transactionsLoading,
    refetch: refetchTransactionData,
  } = useFetch(
    apiURL,
    { ...filters, ...bookingDateFilter },
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: 2,
        enabled: false,
        keepPreviousData: true,
      },
    }
  );

  const handleApplyFilters = () => {
    setFilters((prev) => {
      return {
        ...prev,
        page: 1,
        size: 10,
      };
    });
    refetchTransactionData();
    setIsFiltersOpen(false);
  };

  useEffect(()=>{
    refetchTransactionData();
  },[])



  useEffect(()=>{
    setIsFetching(transactionsFetching)
    console.log(isFetching)
  },[transactionsFetching])

  const tableData = React.useMemo(
    () => (isFetching ? Array(10).fill({}) : transactions || []),
    [isFetching, transactions]
  );

  const tableColumns = React.useMemo(
    () =>
      isFetching
        ? (transactionColumns || []).map((column) => ({
            ...column,
            cell: <Skeleton className={"w-20 h-4"} />,
          }))
        : transactionColumns || [],
    [isFetching, transactionColumns]
  );

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      pagination,
    },
    pageCount: transactionsData && transactionsData.total_pages,
    manualPagination: true,

    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onPaginationChange: setPagination,
  });

   useEffect(() => {
      if (transactionsData?.results) {
        if (loadedMoreRef.current) {
          setTransactions((prevBookings) => [
            ...prevBookings,
            ...transactionsData.results,
          ]);
          loadedMoreRef.current = false;
        } else {
          console.log("here");
          setTransactions(transactionsData.results);
        }
      }
      if (transactionsData) {
        setHasMore(transactionsData.current_page < transactionsData.total_pages);
      }
    }, [transactionsData]);

  // useEffect(() => {
  //   setTransactions(transactionsData?.results);
  //   setHasMore(transactionsData?.current_page<transactionsData?.total_pages);
  // }, [transactionsData]);

  useEffect(() => {
    refetchTransactionData();
  }, [filters]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      page: 1,
      size: 10,
      type: transactionType,
    }));
  }, [transactionType]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      page: pagination.pageIndex + 1,
      size: pagination.pageSize,
      type: transactionType,
    }));
  }, [pagination]);

  const handleLoadMore = () => {
    loadedMoreRef.current = true;
    setFilters((prev) => ({
      ...prev,
      page: prev.page + 1,
    }));
    refetchTransactionData();
  }

  const debouncedSearch = useCallback(
    debounce((newSearch) => {
      setFilters((prev) => ({ ...prev, q: newSearch }));
    }, 500), // Adjust debounce delay as needed
    []
  );

  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
  };

  return (
    <div>
      <div className="flex flex-col gap-4">
        <h2 className="mb-0 section_heading">Transactions</h2>
        <div className="flex gap-2 items-center">
          <div className="bg-background/95 w-full backdrop-blur supports-[backdrop-filter]:bg-background/60">
            <form>
              <div className="relative">
                <Search className="absolute left-2 top-2 h-4 w-4 text-muted-foreground" />
                <Input
                  placeholder="Search"
                  className="pl-8 h-8 focus-visible:outline-none  focus-visible:ring-0 focus-visible:ring-offset-0"
                  onChange={handleSearchChange}
                />
              </div>
            </form>
          </div>
          <Popover
            open={isFiltersOpen}
            onOpenChange={setIsFiltersOpen}
            align="end"
          >
            <PopoverTrigger>
              <Button
                onClick={() => {
                  setIsFiltersOpen(true);
                }}
                className="h-8"
                variant="outline"
              >
                <ListFilter size={14} />
                Filters
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto">
              <div className="grid gap-x-4 gap-y-4 grid-cols-1 md:grid-cols-2">
                <div className="flex flex-col gap-1">
                  <Label>Booking date</Label>

                  <DateRangePickerPresets onUpdate={handleBookingDateChange} />
                </div>
              </div>
              <div className="flex gap-4 mt-4 md:mt-2 justify-between md:justify-end">
                <Button
                  onClick={() => {
                    setIsFiltersOpen(false);
                  }}
                  variant="outline"
                  className=""
                >
                  Cancel
                </Button>
                <Button onClick={handleApplyFilters} className="">
                  Apply
                </Button>
              </div>
            </PopoverContent>
          </Popover>
        </div>
        <div className="flex gap-2 max-w-[90vw] overflow-x-auto items-center">
          {[
            "All",
            "Online Booking",
            "Manual Booking",
            "Refund",
            "Pending Collection",
            "Payment Links",
          ].map((option, index) => (
            <Button
              variant={`${transactionType === option ? "default" : "outline"}`}
              className={"rounded-md"}
              key={index}
              onClick={() => {
                setTransactionType(option);
              }}
            >
              {option}
            </Button>
          ))}
        </div>
        <div className="flex md:hidden flex-col">
          {isFetching && !loadedMoreRef.current &&
            Array(10)
              .fill({})
              .map((task, index) => {
                return (
                  <div key={index}>
                    <div className="flex flex-col gap-1">
                      <Card className="py-2 relative rounded-none border-t-0 border-l-0 shadow-none border-r-0 border-b-[1px]">
                        <div className="flex justify-between items-start">
                          <div className="flex items-start gap-2">
                            <div className="flex items-start gap-2">
                              <div className="flex flex-col gap-1">
                                <Skeleton className="w-12 h-12 rounded-md" />
                                <Skeleton className={"w-12 h-4"} />
                              </div>
                            </div>
                            <div className="flex flex-col gap-1">
                              <Skeleton className="w-32 h-4" />
                              <Skeleton className="w-24 h-4" />
                              <Skeleton className="w-16 h-4" />
                            </div>
                          </div>
                          <div className="flex h-16 flex-col justify-between">
                            <Skeleton className="w-16 h-4" />
                            <Skeleton className="w-16 h-4" />
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                );
              })}
               {
                !isFetching && transactions?.length==0 && <h1 className="text-center mt-4">No transactions available for the selected filters</h1>
              }
          {
            transactions.map((task, index) => {
              return <Transaction key={index} booking={task} />;
            })}
            {hasMore && <Button onClick={handleLoadMore} className="w-28 my-4 self-center">
             {isFetching ? <div className="dots"></div> : 'Load More' }
              </Button>}
        </div>
        <div className="md:h-full hidden md:flex flex-col md:space-y-8">
          <div>
            {(
              <DataTable
                heading={"transactions"}
                data={transactions}
                table={table}
                columns={transactionColumns}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionsPage;
