import React, { useRef } from "react";
import { Day } from "react-day-picker";
import { Separator } from "../ui/separator";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuPortal,
    DropdownMenuSeparator,
    DropdownMenuShortcut,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
  } from "@/components/ui/dropdown-menu";
  import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"

import { Button } from "../ui/button";
import PricingModal from './pricing_dates';
import { Card } from '../ui/card';
import{
  Clock,
  ImageUp,
  EllipsisVertical,
  X,
  ArrowUpRightFromSquare,
  LocateFixed,
} from "lucide-react";
import { Checkbox } from "../ui/checkbox";
import { Switch } from "../ui/switch";
import { Badge } from '../ui/badge';
import { useNavigate } from 'react-router-dom';
import EditCoupon from './edit-coupon';
import axios from 'axios';
import ManualBookingForm from "../manual-booking/MainForm";
import useEventStore from '@/store/eventStore'
import { useToast } from "@/hooks/use-toast";

 

const BatchCard = ({item,eventId, filteredBatches, setFilteredBatches, handleBatchDelete}) => {
  const {toast} = useToast();
  const [isPricingModalOpen, setIsPricingModalOpen] = React.useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [isCouponModalOpen,setIsCouponModalOpen] = React.useState(false);
  const [isBatchLive,setIsBatchLive] = React.useState(item.publish);
  const [isOnSell, setIsOnSell] = React.useState(!item.soldout);
  const [isCancelled, setIsCancelled] = React.useState(!item.canceled);
  const { setSelectedBatch, selectedEvent } = useEventStore();
  const [isManualBookingOpen, setIsManualBookingOpen] = React.useState(false);
  const navigate = useNavigate();
  const triggerRef = useRef()

  React.useEffect(() => {
    setIsBatchLive(item.publish);
        setIsOnSell(!item.soldout);
        setIsCancelled(!item.canceled)
  }, [item]);

  const calculateUnsoldTickets = (batch) => {
    if(batch.batch_size){
      return (batch.batch_size -
        batch?.actual_numberofbookings -
        batch?.manual_numberofbookings +
        batch?.canceled_numberofbookings)
    }
    else if(batch?.eventbatchprice){
      const totalInventory = batch.eventbatchprice.reduce((sum, item) => sum + item.inventory, 0);
      return (totalInventory -
        batch?.actual_numberofbookings -
        batch?.manual_numberofbookings +
        batch?.canceled_numberofbookings)
    }
  }

      const openPricingModal = async () => {
        setIsPricingModalOpen(true)
        await fetchBatches()
      }

      const fetchBatches = async () => {
        try {
          const response = await axios.get(`${import.meta.env.VITE_APP_API_URL}/v3/batches/${item.id}/`);
          setSelectedBatch(response.data)
          return response.data
        } catch (error) {
          console.error("Error updating item status:", error);
        }
      }

      const handlePublish = async (checked) => {
        setIsBatchLive(checked); // Update the UI immediately for a better UX
    
        try {
          const response = await axios.get(`${import.meta.env.VITE_APP_API_URL}/v3/publish-batch/${item.id}/`);
    
          console.log("API Response:", response.data);
        } catch (error) {
          console.error("Error updating batch status:", error);
          setIsBatchLive(!checked); // Revert the state in case of an error
        }
      };

      const updateIsCancelled= async (isCancelled) => {
        setIsCancelled(isCancelled)
        try {
          const response = await axios.get(`${import.meta.env.VITE_APP_API_URL}/v3/cancelled-batch/${item.id}/`);
          console.log("API Response:", response.data)
        } catch (error) {
          console.error("Error updating item status:", error);
          setIsCancelled(!isCancelled); // Propagate the error
        }
      };

      const sendReviewEmail= async () => {
        try {
          const response = await axios.get(`${import.meta.env.VITE_APP_API_URL}/v3/review-mail-batch/${item.id}/`);
          console.log("API Response:", response.data)
        } catch (error) {
          console.error("Error updating item status:", error);
        }
      };

      


      const deleteBatch = async () => {
        toast({
          title: "Deleting...",
          variant:"loading",
          duration: 2000
        });
        try {
          const response = await axios.delete(`${import.meta.env.VITE_APP_API_URL}/v3/delete-batch/${item.id}/`);
          if(response.data){
            toast({
              title: "Batch Deleted Successfully",
              variant:"success",
              duration: 2000
            });
            handleBatchDelete(item.id);
          }
        } catch (error) {
          console.log(error);
          if(error.status === 404){
            toast({
              title: "Batch does not exists",
              variant:"error",
              duration: 2000
            });
          }
          else{
            toast({
              title: "Error Deleting Batch",
              variant:"error",
              duration: 2000
            });
          }
          
        }
      };


      const downloadBookingList = async () => {
        try {
          const response = await axios.get(
            `${import.meta.env.VITE_APP_API_URL}/v3/export-booking-list/${item.id}/`, // API endpoint
            {
              responseType: "blob", // Important for handling binary data
            }
          );
      
          // Create a Blob URL from the response data
          const blob = new Blob([response.data], { type: response.headers["content-type"] });
          const url = URL.createObjectURL(blob);
      
          // Create a temporary anchor tag for download
          const link = document.createElement("a");
          link.href = url;
      
          // Extract the filename from the `Content-Disposition` header
          const contentDisposition = response.headers["content-disposition"];
          const filename = contentDisposition
            ? contentDisposition.split("filename=")[1]?.replace(/"/g, "") // Remove quotes if present
            : `booking-list-${item.id}.csv`;
      
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
      
          // Clean up the URL object and remove the anchor tag
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
      
          console.log("File downloaded successfully");
        } catch (error) {
          console.error("Error downloading the booking list:", error);
        }
      };

      const updateOnSell= async (isOnSell) => {
        setIsOnSell(isOnSell)
        try {
          const response = await axios.post(`${import.meta.env.VITE_APP_API_URL}/v3/soldout-batch/${item.id}/`);
          console.log("API Response:", response.data)
        } catch (error) {
          console.error("Error updating item status:", error);
          setIsOnSell(!isOnSell); // Propagate the error
        }
      };

  const formatTime = (time) => {
    const date = new Date(time);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true }); // Format as HH:MM
  };

  return (
    <Card className="relative p-4">
      {!isBatchLive && (
        <div className="absolute bg-gray-100/25 rounded-md top-0 left-0 w-full h-full z-[5]"></div>
      )}
      <div className="flex flex-col gap-4">
        <div className="flex justify-between items-start">
          <div className="flex flex-col gap-1">
            <span
              onClick={() => {
                window.open(
                  `https://logout.world/dashboard/batch-details/${item?.id}/`
                );
              }}
              className="flex cursor-pointer underline gap-1 items-center"
            >
              <span>
                <Clock size={18} />
              </span>
              {formatTime(item.starttime)}{" "}
              {item.endtime ? ` - ${formatTime(item.endtime)}` : ""}
              <span>
                <ArrowUpRightFromSquare size={14} />
              </span>
            </span>
          </div>

          <div className="flex items-center space-x-2">
      {isBatchLive ? (
        <Badge className="items-center md:text-[0.6rem] text-[8px] text-green-600 bg-green-50 px-1 rounded-full lg:py-[0.035rem] py-0 flex gap-1 font-bold">
          <div className="circle_live"></div>
          Live
        </Badge>
      ) : (
        <Badge className="items-center text-[0.6rem] text-neutral-600 bg-neutral-50 rounded-full px-1 py-[0.035rem] flex gap-1 font-bold">
          <div className="circle_draft"></div>
          Draft
        </Badge>
      )}

      <Switch
        onCheckedChange={handlePublish}
        checked={isBatchLive}
        className="h-5 relative z-10"
        id="live-draft"
      />

      {/* Popover instead of Dropdown */}
      <Popover open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
        <PopoverTrigger ref={triggerRef} asChild>
          <button onClick={() => setIsDropdownOpen((prev) => !prev)}>
            <EllipsisVertical size={16} />
          </button>
        </PopoverTrigger>
        <PopoverContent
          className="w-48"
          align="end"
          onInteractOutside={(event) => {
            if (triggerRef.current?.contains(event.target)) {
              event.preventDefault(); // Prevent closing when clicking the trigger
            } else {
              setIsDropdownOpen(false);
            }
          }}
        >
          <div className="flex flex-col gap-4">
          <div className="flex justify-between items-center w-full">
            <span className="text-xs">On Sale</span>
            <Switch onCheckedChange={updateOnSell} checked={isOnSell} className="h-5" id="Sale" />
          </div>

          <div className="flex justify-between items-center w-full">
            <span className="text-xs">Confirmed</span>
            <Switch onCheckedChange={updateIsCancelled} checked={isCancelled} className="h-5" id="batchconfirm" />
          </div>

          <div className="text-xs cursor-pointer">Broadcast Message</div>
          <div className="text-xs cursor-pointer" onClick={sendReviewEmail}>Send Review Mail</div>
          <div className="text-xs cursor-pointer" onClick={downloadBookingList}>Download Guest List</div>
          <div className="text-xs cursor-pointer" onClick={()=>setIsManualBookingOpen(true)}>Add Manual Booking</div>
          <div className="text-xs cursor-pointer text-red-500" onClick={deleteBatch}>Delete</div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
        </div>
        <div>
          {/* <div className='flex w-full justify-between items-center'>
            <span>Bookings</span>
            <span className="font-semibold">5/22</span>
          
          </div> */}
          {item?.vehicle_type && (
            <div className="flex w-full justify-center">
              <span>{item.vehicle_type}</span>
              {/* person */}
            </div>
          )}
          <div className="flex w-full mb-1 justify-between items-center">
            <span className="text-sm">Base Price</span>
            <span className="font-semibold text-sm">&#8377;{item?.price ? item?.price : selectedEvent?.price} </span>
          
          </div>

          {item.eventbatchprice.map((batchprice, idx) => {
            return (
              <div
                key={idx}
                className="flex w-full mb-1 justify-between items-center"
              >
                <span className="text-sm">{batchprice?.attribute}</span>
                <span className="font-semibold text-sm">
                  &#8377;{batchprice?.price}{" "}
                </span>
                {/* person */}
              </div>
            );
          })}
        </div>

        <div className="flex gap-2 items-end">
          <div
            onClick={() => {
              window.open(
                `https://logout.world/dashboard/batch-details/${item?.id}/`
              );
            }}
            className="flex border p-3 cursor-pointer bg-green-50 text-green-600 items-start rounded-md flex-col"
          >
            <span className="font-semibold text-2xl">
              {item?.actual_numberofbookings +
                item?.manual_numberofbookings -
                item?.canceled_numberofbookings}
              <span className="text-sm">
                {item.batch_size && `/${item?.batch_size}`}
              </span>
            </span>
            <span className="text-xs">Bookings</span>
          </div>
          <div
            onClick={() => {
              window.open(
                `https://logout.world/dashboard/batch-details/${item?.id}/`
              );
            }}
            className="flex flex-col cursor-pointer border p-2 gap-1 text-muted-foreground items-start rounded-md"
          >
            <div className="flex items-end gap-1">
              <span className="font-semibold text-xs">
                {item?.actual_numberofbookings}
              </span>
              <span className="text-xs">Online</span>
            </div>
            <div className="flex items-end gap-1">
              <span className="font-semibold text-xs">
                {item?.manual_numberofbookings}
              </span>
              <span className="text-xs">Manual</span>
            </div>
            <div className="flex items-end gap-1">
              <span className="font-semibold text-xs">
                {item?.canceled_numberofbookings}
              </span>
              <span className="text-xs">Cancelled</span>
            </div>
          </div>
          <div
            onClick={() => {
              window.open(
                `https://logout.world/dashboard/batch-details/${item?.id}/`
              );
            }}
            className="flex ml-auto cursor-pointer border p-3 bg-amber-50 text-amber-600 items-start rounded-md flex-col"
          >
            <span className="font-semibold text-2xl">
              {calculateUnsoldTickets(item)}
              {/* <span className='text-sm'>/30</span> */}
            </span>
            <span className="text-xs">Unsold</span>
          </div>
          {/* <Separator className="h-5" orientation="vertical" /> */}

          {/* <Separator className="h-5" orientation="vertical" /> */}
          {/* <div className="flex border p-2 text-muted-foreground items-start rounded-md flex-col">
          <span className="font-semibold text-sm">1</span>
          <span className="text-xs">Cancelled</span>
        </div> */}
        </div>
        <div className="flex justify-between gap-2 pt-1">
          <div
            className="flex items-center gap-1 cursor-pointer"
            onClick={() => {
              window.open(
                `https://logout.world/tours/${item.id}/live-monitoring-admin/`
              );
            }}
          >
            <LocateFixed size={14} />
            <span className="underline text-sm">Live Monitor</span>
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Button className="h-8">Edit Batch</Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem
                onSelect={() => {
                  openPricingModal();
                }}
              >
                Pricing & Dates
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={() => {
                  setIsCouponModalOpen(true);
                }}
              >
                Coupons
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() =>
                  window.open(
                    "https://logout.world/tours/tt-gokarna-draft/create-form/"
                  )
                }
              >
                Forms
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        {/* <div>
        <h2>Slots</h2>
        <Separator />
        <div className="py-2">
          <div className="flex items-center gap-2">
            <span className="w-2 h-2 rounded-full bg-blue-500"></span>
            <span>10:00AM</span>
            <span className="font-semibold">1 / 22</span>
          </div>
        </div>
      </div> */}
      </div>
      {isPricingModalOpen && (
        <PricingModal
          open={isPricingModalOpen}
          setIsOpen={setIsPricingModalOpen}
          fetchBatches={fetchBatches}
          filteredBatches={filteredBatches}
          setFilteredBatches={setFilteredBatches}
        />
      )}
      {isCouponModalOpen && (
        <EditCoupon
          isOpen={isCouponModalOpen}
          couponLevel={"batch"}
          eventID={item.id}
          apiUrl={`${
            import.meta.env.VITE_APP_API_URL
          }/v3/get-coupons/${item.id}/?q=batch`}
          setIsOpen={setIsCouponModalOpen}
        />
      )}
      {isManualBookingOpen && (
        <ManualBookingForm
          isOpen={isManualBookingOpen}
          setIsOpen={setIsManualBookingOpen}
          eventId={eventId}
          batchId={item.id}
        />
      )}
    </Card>
  );
};

export default BatchCard;
