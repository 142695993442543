"use client";
import {
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import * as React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Line,
  LineChart,
  XAxis,
} from "recharts";
import { data } from "./analyticsData";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { analyticsColumns } from "@/components/analytics/analyticsColumns";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { DatePicker } from "@/components/Custom/DatePicker";
import { DateRangePickerPresets } from "@/components/Custom/DateRangePickerTest";
import { DataTable } from "@/components/ui/data-table";
import { Separator } from "@/components/ui/separator";
import { MetricCard } from "@/components/card-info";
import useFetch from "@/hooks/useFetchLSD";
import { useAuthStore } from "@/store/store";
import { format } from "date-fns";
import axios from "axios";

const chartConfig = {
  views: {
    label: "Page Views",
  },
  Bookings: {
    label: "Bookings",
    color: "hsl(var(--chart-1))",
  },
  Revenue: {
    label: "Revenue",
    color: "hsl(var(--chart-2))",
  },
};

export default function AnalyticsPage() {
  const { companyslug } = useAuthStore();
  const [graphData, setGraphData] = React.useState([]);
  const [summaryData, setSummaryData] = React.useState({});
  const [filters, setFilters] = React.useState({});
  const [dateFilters, setDateFilters] = React.useState({});

  const today = new Date(); // Today's date
  const sevenDaysEarlier = new Date();
  sevenDaysEarlier.setDate(today.getDate() - 7);

  const {
    data: analyticData,
    error: analyticsError,
    refetch: refetchAnalytics,
    isFetching: isAnalyticsFetching,
  } = useFetch(
    `${
      import.meta.env.VITE_APP_API_URL
    }/v3/company-booking-for-graph/${companyslug}/`,
    filters,
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: 1,
        enabled: true,
        keepPreviousData: true,
      },
    }
  );

  React.useEffect(() => {
    setGraphData(analyticData?.graph_data);
    setSummaryData({
      total_amount: analyticData?.total_amount,
      total_bookings: analyticData?.total_bookings,
      today_revenue: analyticData?.today_revenue,
    });
  }, [analyticData]);

  React.useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      timestamp_from_date: dateFilters?.timestamp_from_date,
      timestamp_to_date: dateFilters?.timestamp_to_date,
    }));
  }, [dateFilters]);

  const downloadReport = async () => {
    try {
      const response = await axios.get(
        `${
          import.meta.env.VITE_APP_API_URL
        }/v3/company-booking-for-graph/${companyslug}/?timestamp_from_date=${
          dateFilters.timestamp_from_date || ""
        }&timestamp_to_date=${
          dateFilters.timestamp_to_date || ""
        }&download=true`,
        {
          responseType: "blob", // Ensure response is treated as a file
        }
      );

      // Create a blob from the response
      const blob = new Blob([response.data], { type: "text/csv" });

      // Create a temporary URL
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element and trigger a download
      const a = document.createElement("a");
      a.href = url;
      a.download = `Booking_Analytics_${new Date().toISOString()}.csv`; // Custom filename
      document.body.appendChild(a);
      a.click();

      // Cleanup
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };

  const [activeChart, setActiveChart] = React.useState("Bookings");

  const total = React.useMemo(
    () => ({
      Bookings: graphData?.reduce((acc, curr) => acc + curr.Bookings, 0),
      Revenue: graphData?.reduce((acc, curr) => acc + curr.Revenue, 0),
    }),
    [graphData]
  );

  const table = useReactTable({
    data: graphData && graphData,
    columns: analyticsColumns,

    enableRowSelection: false,
    showPagination: false,
    showPageSizeOptions: false,
    //   onRowSelectionChange: setRowSelection,
    //   onSortingChange: setSorting,
    //   onColumnFiltersChange: setColumnFilters,
    //   onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    customParams: {
      hideSelectionInfo: true,
    },
  });

  const handleDateSelect = (dateRange) => {
    const monthDiff =
      (dateRange.range.to.getFullYear() - dateRange.range.from.getFullYear()) *
        12 +
      (dateRange.range.to.getMonth() - dateRange.range.from.getMonth());
    if (monthDiff > 3) {
      alert("month range greater than 3");
      return;
    }

    setDateFilters({
      timestamp_from_date: format(dateRange.range.from, "dd-MM-yyyy"),
      timestamp_to_date: format(dateRange.range.to, "dd-MM-yyyy"),
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <div>
        <h2 className="section_heading">Analytics</h2>
      </div>
      {summaryData && (
        <div className="flex items-center gap-4">
          <MetricCard
            title="Total Bookings"
            value={summaryData?.total_bookings?.toLocaleString("en-IN")}
          />
          <MetricCard
            title="Total Revenue"
            value={`₹${Number((summaryData?.total_amount / 100)
              .toFixed(2))
              .toLocaleString("en-IN")}`}
          />
          <MetricCard
            title="Today's Revenue"
            value={`₹${Number((summaryData?.today_revenue / 100)
              .toFixed(2))
              .toLocaleString("en-IN")}`}
          />
        </div>
      )}

      <Card>
        <CardHeader className="flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row">
          <div className="flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6">
            <CardTitle>Bookings Timeline</CardTitle>
            <CardDescription>Showing total bookings</CardDescription>
            <div>
              <DateRangePickerPresets
                align="start"
                isTodayShown={true}
                onUpdate={handleDateSelect}
                initialDateTo={today}
                initialDateFrom={sevenDaysEarlier}
              />
            </div>
          </div>
          <div className="flex">
            {["Bookings", "Revenue"].map((key) => {
              const chart = key;
              return (
                <button
                  key={chart}
                  data-active={activeChart === chart}
                  className="flex flex-1 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l data-[active=true]:bg-muted/50 sm:border-l sm:border-t-0 sm:px-8 sm:py-6"
                  onClick={() => setActiveChart(chart)}
                >
                  <span className="text-xs text-muted-foreground">
                    {chartConfig[chart].label}
                  </span>
                  <span className="text-lg font-bold leading-none sm:text-3xl">
                    {chart === "Revenue"
                      ? `₹${total[key]?.toLocaleString("en-IN")}`
                      : total[key]?.toLocaleString("en-IN")}
                  </span>
                </button>
              );
            })}
          </div>
        </CardHeader>
        <CardContent className="px-2 sm:p-6">
          <ChartContainer
            config={chartConfig}
            className="aspect-auto h-[250px] w-full"
          >
            <AreaChart data={graphData}>
              <defs>
                <linearGradient id="fillBookings" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="5%"
                    stopColor="var(--color-Bookings)"
                    stopOpacity={0.4}
                  />
                  <stop
                    offset="95%"
                    stopColor="var(--color-Bookings)"
                    stopOpacity={0.1}
                  />
                </linearGradient>
                <linearGradient id="fillRevenue" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="5%"
                    stopColor="var(--color-Revenue)"
                    stopOpacity={0.4}
                  />
                  <stop
                    offset="95%"
                    stopColor="var(--color-Revenue)"
                    stopOpacity={0.1}
                  />
                </linearGradient>
              </defs>
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="date"
                tickLine={false}
                axisLine={false}
                tickMargin={8}
                minTickGap={32}
                tickFormatter={(value) => {
                  const date = new Date(value);
                  return date.toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                  });
                }}
              />
              <ChartTooltip
                cursor={false}
                content={
                  <ChartTooltipContent
                    labelFormatter={(value) => {
                      return new Date(value).toLocaleDateString("en-US", {
                        month: "short",
                        day: "numeric",
                      });
                    }}
                    indicator="dot"
                  />
                }
              />
              <Area
                dataKey={activeChart}
                type="natural"
                fill={`url(#fill${activeChart})`}
                stroke={`var(--color-${activeChart})`}
                stackId="a"
              />

              {/* <ChartLegend content={<ChartLegendContent />} /> */}
            </AreaChart>
          </ChartContainer>
        </CardContent>
      </Card>
      <div className="mt-4">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl">Bookings Stats</h2>

          <Button onClick={downloadReport}>Download Records</Button>
        </div>
        <Separator className="mb-4 mt-2" />
        {graphData && (
          <DataTable
            data={graphData}
            table={table}
            columns={analyticsColumns}
            defaultPageSize={graphData.length}
            hidePagination={true}
          />
        )}
      </div>
    </div>
  );
}
