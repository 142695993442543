import { Card } from "@/components/ui/card";
import React from "react";
import { Badge } from "@/components/ui/badge";
import { Drawer, DrawerContent, DrawerFooter } from "@/components/ui/drawer";

import {
  Calendar,
  CircleCheckBig,
  Undo2,
} from "lucide-react";
import { format, differenceInDays } from "date-fns";
import BookingDetails from "../bookingsDetails";
import { useCompanyStore } from "@/store/companyStore";


const BookingCard = ({ booking }) => {
  const checkBookingdate = function(timestamp){
    const date = new Date(timestamp);
    const now = new Date();
  
    if(differenceInDays(now, date) > 1){
      return format(date,"dd/MM/yyyy");
    }
    else if(differenceInDays(now, date) === 0){
      return "Today"
    } 
    else{
      return "Yesterday"
    }
  
  }
  const [isOpen, setIsOpen] = React.useState(false);

  const eventDetails = useCompanyStore.getState().companyData.events;
  const findEventImage = function (name) {
    const event = eventDetails?.data.find((event) => event.name === name);
    return `${import.meta.env.VITE_APP_API_URL}${event.image}`;
  };

  return (
    <div>
      <Drawer open={isOpen} onOpenChange={setIsOpen}>
        <DrawerContent>
          <BookingDetails booking={booking} />
        </DrawerContent>
      </Drawer>
      <div className="flex flex-col gap-1">
        <Card className="py-2 relative rounded-none border-t-0 border-l-0 shadow-none border-r-0 border-b-[1px]">
          <div
            onClick={() => {
              setIsOpen(true);
            }}
            className="flex justify-between items-start"
          >
            <div className="flex items-start gap-2">
              <div className="flex flex-col gap-1">
                {/* <Badge variant="outline" className="text-[0.5rem] rounded-md">
                257078
              </Badge> */}
                <img src={findEventImage(booking?.event_name)} className="w-12 h-12 rounded-md" />
                <span className="text-[0.6rem] leading-4">
                  ID: {booking.id}
                </span>
              </div>
              <div className="flex flex-col">
                <div className="flex items-center gap-2">
                  <h2 className="text-sm leading-4 font-semibold mb-[0.125rem]">
                    {booking.name}
                    <span className="text-xs mx-1">|</span>
                    {booking.numberofpeople} booking(s)
                    {/* <span className="text-xs">{booking.contact}</span> */}
                  </h2>
                </div>

                <div className="flex flex-col gap-[0.05rem]">
                  <span className="text-xs line-clamp-2 flex max-w-44 items-center gap-1 font-medium">
                    {/* <UsersRound size={14} />5 bookings | Jane Doe +5 */}
                    {booking.event_name.slice}
                  </span>
                  <span className="text-[0.6rem] flex items-center gap-1 text-muted-foreground">
                    <Calendar size={12} />
                    {booking.batch_startdate} {booking.batch_starttime!="NA" ? `| ${booking.batch_starttime}` : ''}
                  </span>
                </div>

                <span className="absolute flex items-center gap-2 right-1 bottom-[0.3rem] font-medium">
                  {booking.settled ? (
                    <CircleCheckBig size={14} className="text-green-500" />
                  ) : booking.refunded ? (
                    <Undo2 size={14} className="text-red-500" />
                  ) : null}
                  {booking?.amount && <span>&#8377;{(booking.amount/100).toFixed(2)}</span>}
                </span>
              </div>
            </div>

            <div className="leading-4 flex items-center gap-1">
              {/* <Badge variant={'outline'} className="px-1 gap-1 rounded-md font-normal text-[0.6rem]">
              <Blocks size={12} />
              
With Addons
            </Badge>
            */}
              <div className="flex flex-col items-end">
                <span className="text-[0.6rem]">
                {
                  checkBookingdate(booking.timestamp)
                }
                </span>
                {booking?.addon_prices?.length>0 && <Badge
                  variant="outline"
                  className="items-center py-0 gap-1 px-1 align-start text-[0.5rem] rounded-md"
                >
                  {/* <Blocks size={10} /> */}{booking.addon_prices?.length || 0} Addon(s)
                </Badge>}

                {/* <EllipsisVertical size={14} /> */}
              </div>
              {/* <div className="flex gap-1">

              <Badge variant="outline" className="text-[0.6rem] rounded-md">
                Source: Instagram
              </Badge>
            </div> */}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default BookingCard;
