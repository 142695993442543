import React, { useState, useEffect } from "react";
import { Trash2, GripVertical, SquarePen, Info } from "lucide-react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Lock } from "lucide-react";

const Stage = ({
  data,
  setStageSelected,
  setIsStageSelected,
  stageSelected,
  handleStageDelete,
}) => {
  const [stageData, setStageData] = useState(null);
  const id = data?.id;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    isSorting,
  } = useSortable({ id, disabled: !data?.meta_data?.editable });

  useEffect(() => {
    setStageData(data);
  }, [data]);

  const [isSortingActive, setIsSortingActive] = useState(false);

  useEffect(() => {
    if (isSorting) setIsSortingActive(true);
    else setTimeout(() => setIsSortingActive(false), 100); // Delay to prevent flicker
  }, [isSorting]);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: isSortingActive ? "none" : transition,
    opacity: isDragging ? 0.6 : 1,
    willChange: !data?.meta_data?.editable ? "transform" : undefined,
  };

  function handleStageSelection() {
    setStageSelected(stageData);
    setIsStageSelected(true);
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="relative mb-4 w-80"
      {...listeners}
      {...attributes}
    >
      {/* Chat bubble container */}
      <div
        className={`relative p-4 bg-white rounded-xl shadow-md border ${
          stageSelected?.id === stageData?.id
            ? "border-black"
            : "border-gray-300"
        }`}
        // style={{opacity:stageData?.meta_data?.editable? '1':'0.7'}}
        //onClick={handleStageSelection}
      >
        {/* Header section */}
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            {/* <GripVertical className="cursor-grab text-gray-500" /> */}
            <button
              //onClick={(e) => {e.stopPropagation(); stageData && handleStageDelete(stageData)}}
              onPointerDown={(e) => e.stopPropagation()} 
              className="text-grey-200 hover:text-black-700 relative"
              onClick={handleStageSelection}
            >
              {<SquarePen size={18} />}
            </button>
            <span className="font-semibold text-gray-800">{data.name}</span>
          </div>
          {/* Delete Button */}
          <div className="flex gap-2">
          {stageData?.meta_data?.editable ? (
            <button
              disabled={!stageData?.meta_data?.editable}
              onClick={(e) => {e.stopPropagation(); stageData && handleStageDelete(stageData)}}
              onPointerDown={(e) => e.stopPropagation()} 
              className="text-red-500 hover:text-red-700 relative "
            >
              {<Trash2 size={18} />}
            </button>
          ) : (
            <Lock size={18} />
          )}
          
          </div>
        </div>

        {/* Message content */}
        <div className="flex gap-2 mt-3 text-gray-600 text-sm italic">
        <Info size={14} className="mt-1 "/> {data?.meta_data?.description || "This is a chat node."}
        </div>
      </div>

      {/* Connecting line effect */}

      

      {data?.next && !isSortingActive ? (
        <div
          className="absolute top-full left-1/2 transform -translate-x-1/2 w-1 h-6 border-black"
          style={{ borderLeft: "3px dotted black" }}
        ></div>
      ) : null}
      {/* {(
        <div className="mt-2 flex flex-wrap gap-2 bg-opacity-0">
          {['ABC','sdfsd'].map((reply, index) => (
            <button
              key={index}
              className="px-3 py-1 text-sm bg-blue-100 text-blue-700 rounded-full shadow-sm hover:bg-blue-200 transition"
              onClick={() => console.log(`Quick reply clicked: ${reply}`)}
            >
              {reply}
            </button>
          ))}
        </div>
      )} */}
    </div>
  );
};

export default Stage;
