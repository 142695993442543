import EventList from '@/components/events/event-list'
import EventPage from '@/pages/EventPage'
import React from 'react'

const StayInfo = () => {
  return (
    <div>
      <EventPage />
    </div>
  )
}

export default StayInfo
