import axios from 'axios';
import { delay } from 'lodash';
export const editCoupon = async(slug,data) => {
    // console.log(data)
    await axios.post(`${import.meta.env.VITE_APP_API_URL}/v3/edit-coupon/`, {coupon_list:data});
}

export const deleteCoupon = async(slug,data) => {
    // console.log(data);
    await axios.post(`${import.meta.env.VITE_APP_API_URL}/v3/delete-coupon/`, {id:data});
}