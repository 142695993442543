import React, { useCallback, useEffect } from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Skeleton } from "../ui/skeleton";
import { format } from "date-fns";
import { useState } from "react";
import {Calendar as CalendarIcon, CirclePlus, Delete, Rabbit, Squirrel} from "lucide-react";
import { cn } from "@/lib/utils";
import { Progress } from "@/components/ui/progress";
import { CalendarDays } from "lucide-react";
import { Button } from "../ui/button";
import { Badge } from "../ui/badge";
import {
  Zap,
  CalendarCheck,
  EllipsisVertical,
  ChevronRight,
  FileDown,
  FileText,
  TicketPercent,
  Map,
  SquareChartGantt,
  Download,
  ScanQrCode,
  Trash,
  Bolt,
  CircleX,
  ArrowDownWideNarrow,
  Images,
} from "lucide-react";
import { Switch } from "../ui/switch";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import UploadImageModal from "./upload-images";
import EditCoupon from "./edit-coupon";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Textarea } from "../ui/textarea";
import CopyLink from "./copy-button";
import useEventStore from "@/store/eventStore";
import usePost from "@/hooks/usePostLSD";
import useFetch from "@/hooks/useFetchLSD";
import { useAuthStore } from "@/store/store";
import { set } from "lodash";
import AddBatchesModal from "./add-batch";
import DuplicateEventModal from "./duplicate-event-modal";
import axios from "axios";
import { useToast } from "@/hooks/use-toast";
import {WhatsAppAutomation} from "@/components/whatsapp-automation/MainForm.jsx";
const EventCard = ({
  item,
  index,
  isDateDialogOpen,
  setIsDateDialogOpen,
  batchData,
  loadingBatchData,
  setIsBatchesLoading,
  refetchEvent,
}) => {
  const [date, setDate] = React.useState(new Date());
  // const [allBatchData, setAllBatchData] = useState(batchData);
  const [eventBatchData, setEventBatchData] = useState(batchData);
  const [isLive, setIsLive] = useState(item.publish);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [isCouponModalOpen, setIsCouponModalOpen] = useState(false);
  const [paymentsModalOpen, setPaymentsModalOpen] = useState(false);
  const [isAddBatchesOpen, setIsAddBatchesOpen] = useState(false);
  const [loadingStates, setLoadingStates] = React.useState(false);
  const [booknowCode, setBooknowCode] = React.useState(undefined);
  const [isDuplicateEventModalOpen, setIsDuplicateEventModalOpen] = React.useState(false);
  const [isDeleteModalOpen,setIsDeleteModalOpen] = React.useState(false);
  const [isWhatsAppOpen, setIsWhatsAppOpen] = useState(false)

  const { companyslug } = useAuthStore();
  const changeEventStatus = usePost(
    `${import.meta.env.VITE_APP_API_URL}/v3/events/${companyslug}/${
      item.id
    }/publish/`,
    {}
  );

  const {
    data: booknowcode,
    error: booknowcodeError,
    isFetching: booknowcodeIsFetching,
    refetch: refetchBooknowcode,
  } = useFetch(
    `${import.meta.env.VITE_APP_API_URL}/v3/events/${companyslug}/${
      item.id
    }/booknow-button-code/`,
    {},
    {
      // loadedMore: loadedMore,
      queryOptions: {
        refetchOnWindowFocus: false,
        retry: 1,
        enabled: false,
        keepPreviousData: true,
      },
    }
  );

  useEffect(() => {
    setBooknowCode(booknowcode?.data);
  }, [booknowcode]);

  const changeEventLiveStatus = function (id) {
    setLoadingStates(true);
    changeEventStatus.mutate(null, {
      onSuccess: (data) => {
        setIsLive(data.publish);
        setLoadingStates(false);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const handleEditEvent = (item) => {
    if (companyslug == "workshop-hive" || companyslug == "pouls-of-art" || companyslug == "chalkbooard" || companyslug == "kohli-enterprises" || companyslug == "nomofomo" || companyslug == "starscapes" || companyslug == "trove-experiences"){
      window.open(`${import.meta.env.VITE_APP_API_URL}/edit-event/${companyslug}/${item.slug}/`)
    }
    else{
      window.open(
        `${import.meta.env.VITE_APP_API_URL}/tours/${item.slug}/api-update/`
      );
    }
  };

  const downloadEventPDF = () => {
    window.location.href = `${import.meta.env.VITE_APP_API_URL}/v3/${
      item.slug
    }/pdf/`;
  };

  useEffect(() => {
    setEventBatchData(batchData);
  }, [batchData]);

  const { eventBatches, setEventBatches, setSelectedEvent, batchMonth } =
    useEventStore();
  const { data, error, isFetching, isLoading, refetch } = useFetch(
    `${import.meta.env.VITE_APP_API_URL}/v3/event-batches/${
      item.slug
    }?month=${batchMonth}`,
    {},
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        retry: 2,
        enabled: false,
      },
    },
    "batches"
  );

  useEffect(() => {
    setIsBatchesLoading(isFetching);
  }, [isFetching]);

  const setBatchData = async () => {
    const result = await refetch();
    setEventBatches(result?.data);
  };

  const selectEvent = async () => {
    setSelectedEvent(item);

    setIsDateDialogOpen(true);
    setBatchData();
  };

  return (
    <div>
      <button
        key={item.id}
        className={cn(
          `flex relative w-full pointer-events-auto  flex-col items-start gap-3 lg:p-3 pt-2 lg:px-2 rounded-none md:rounded-md lg:border border-t-none border-r-none border-l-none border-b-[1px] text-left text-sm transition-all`
        )}
      >
        {!isLive && (
          <div className="absolute bg-gray-100/35 rounded-md top-0 left-0 w-full h-full z-[5]"></div>
        )}
        <div className="flex w-full flex-col">
          <div className="absolute items-center flex gap-2 right-2 bottom-2 lg:bottom-3">
            <span
              onClick={() => {
                setIsDateDialogOpen(true);
                selectEvent();
              }}
              className="text-xs underline flex items-center gap-1 md:hidden"
            >
              <CalendarDays className="w-3 h-3" />
              Dates
            </span>
            <Button
              className=" lg:flex hidden lg:px-4 px-2 lg:h-8 h-6"
              onClick={() => selectEvent()}
            >
              <CalendarIcon className="w-3 h-3 lg:w-4 lg:h-4" />
              All Dates
            </Button>
          </div>

          <div className="flex w-full items-center">
            <div className="flex w-full items-start gap-2 lg:gap-4">
              {item.cover_pic && (
                <div className="flex flex-col items-center justify-center gap-1">
                  <div
                    onClick={() => handleEditEvent(item)}
                    className="h-auto md:w-40 lg:w-48 w-[4.5rem] flex flex-col aspect-square rounded-md relative overflow-hidden"
                  >
                    <img
                      src={item.cover_pic}
                      className="absolute top-0 left-0 w-full h-full object-cover rounded-md"
                    />
                  </div>
                </div>
              )}

              <div className="flex flex-col lg:h-[8.25rem] h-[5rem] justify-between w-full">
                <div className="flex items-start justify-between w-full">
                  <div className="font-semibold flex flex-col justify-between gap-0">
                    <div
                      onClick={() => handleEditEvent(item)}
                      className={`lg:uppercase flex ${
                        item.cover_pic ? "lg:basis-[68px]" : ""
                      } max-w-[14rem] line-clmap-2 lg:max-w-xl cursor-pointer text-sm font-normal normal-case lg:text-xl`}
                    >
                      <span className="line-clamp-2 lg:whitespace-normal">
                        {item.name}
                      </span>
                    </div>
                    <div className="flex flex-col max-w-md lg:max-w-sm lg:w-96 gap-2">
                      <div>
                        <div
                          className={`flex ${
                            item.cover_pic ? "lg:mt-8" : "lg:mt-4"
                          }
                                  justify-between flex-col lg:flex-row items-start lg:items-center`}
                        >
                          {/* ${
                                index == 1
                                  ? "lg:mt-3"
                                  : index == 2
                                  ? "lg:mt-14"
                                  : "mt-12"
                              }  */}
                          <span className="text-muted-foreground leading-4 flex gap-1 items-center font-normal">
                            {eventBatchData?.length > 0 && (
                              <CalendarCheck className="w-3 h-3 lg:w-4 lg:h-4" />
                            )}

                            <span className="font-normal text-[0.6rem] lg:text-sm">
                              {loadingBatchData ? (
                                <div className="md:mt-6 mt-4">
                                  <Skeleton className={"w-20 h-2"} />
                                </div>
                              ) : (
                                eventBatchData?.length > 0 &&
                                eventBatchData[0].start_date &&
                                format(
                                  new Date(eventBatchData[0].start_date),
                                  "EEE, d MMM, yyyy"
                                )
                              )}
                            </span>
                            {eventBatchData?.length > 0 && (
                              <>
                                <Badge
                                  className={`items-center ${
                                    !item.soldout ? "hidden" : "lg:flex hidden"
                                  } self-start text-[0.6rem] text-pink-600 bg-pink-50 rounded-full py-[0.035rem] gap-1 font-bold`}
                                >
                                  Sold Out
                                </Badge>
                                <Badge
                                  className={`items-center ${
                                    item.soldout ? "hidden" : "flex"
                                  } self-start md:text-[0.6rem] text-[8px] text-green-600 bg-green-50 rounded-full py-[0.035rem] gap-1 font-bold`}
                                >
                                  On Sale
                                </Badge>
                              </>
                            )}
                          </span>

                          {loadingBatchData ? (
                            <div className="flex items-center gap-2">
                              <Skeleton className="w-16 h-2 mt-1" />
                            </div>
                          ) : (
                            eventBatchData?.length > 0 && (
                              <h2 className="text-muted-foreground text-xs lg:text-sm font-semibold lg:font-normal">
                                <span className="md:text-xl text-xs text-primary font-semibold lg:font-normal">
                                  {eventBatchData[0]?.total_bookings}
                                </span>
                                {eventBatchData[0].batch_size &&
                                  `/${eventBatchData[0].batch_size}`}{" "}
                                booked
                              </h2>
                            )
                          )}
                        </div>
                        {!loadingBatchData && eventBatchData?.length > 0 && (
                          <Progress
                            className="mt-1 hidden md:flex"
                            value={eventBatchData[0]?.total_bookings}
                          />
                        )}
                      </div>

                      {/* <Badge className="self-start">20 bookings</Badge> */}

                      {!loadingBatchData ? (
                        eventBatchData?.length > 1 && (
                          <div className="md:flex hidden gap-2 items-center">
                            {eventBatchData.slice(1).map((item, index) => (
                              <span
                                className="h-12 text-muted-foreground font-normal border-primary border-[1px] flex flex-col w-18 rounded-md p-2 text-xs"
                                key={index}
                                onClick={()=>window.open(`${import.meta.env.VITE_APP_API_URL}/dashboard/batch-details/${item.batch_id}`)}
                              >
                                <span className="flex items-center gap-1">
                                  <CalendarIcon size={12} />
                                  {/* {item.start_date} */}
                                  {item.start_date &&
                                    format(
                                      new Date(item.start_date),
                                      "EEE, d MMM"
                                    )}
                                </span>
                                <span>
                                  {item?.total_bookings}
                                  {item.batch_size &&
                                    `/${item.batch_size}`}{" "}
                                  booked
                                </span>
                              </span>
                            ))}
                          </div>
                        )
                      ) : (
                        <div className="md:flex hidden items-center gap-2">
                          <Skeleton className="w-20 h-12" />
                          <Skeleton className="w-20 h-12" />
                          <Skeleton className="w-20 h-12" />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex lg:gap-2 gap-1 relative z-10 items-center">
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <Download
                            className="hidden md:flex"
                            size={16}
                            onClick={downloadEventPDF}
                          />
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Download PDF</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                    {isLive ? (
                      <Badge
                        className={
                          "items-center md:text-[0.6rem] text-[8px] text-green-600 bg-green-50 rounded-full lg:py-[0.035rem] py-0 flex gap-1 font-bold"
                        }
                      >
                        <div class="circle_live"></div>
                        Live
                      </Badge>
                    ) : (
                      <Badge
                        className={
                          "items-center text-[0.6rem] text-neutral-600 bg-neutral-50 rounded-full py-[0.035rem] flex gap-1 font-bold"
                        }
                      >
                        <div class="circle_draft"></div>
                        Draft
                      </Badge>
                    )}

                    <div className="hidden md:flex items-center space-x-2">
                      <Switch
                        defaultChecked={isLive}
                        onCheckedChange={changeEventLiveStatus}
                        className="h-5"
                        id="live-draft"
                        disabled={loadingStates}
                      />
                      {/* <Label htmlFor="live-draft">Live</Label> */}
                    </div>

                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <EllipsisVertical className="w-4 h-4 md:w-5 md:h-5" />
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end" className="w-56">
                        <DropdownMenuItem className="md:hidden flex justify-between">
                          Draft Event
                          <Switch
                            defaultChecked={!isLive}
                            onCheckedChange={changeEventLiveStatus}
                          />
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onSelect={() => handleEditEvent(item)}
                        >
                          <Map />
                          Edit Event
                        </DropdownMenuItem>

                        <DropdownMenuSub>
                          <DropdownMenuSubTrigger>
                            <FileText />
                            <span>Download PDF</span>
                          </DropdownMenuSubTrigger>
                          <DropdownMenuPortal>
                            <DropdownMenuSubContent>
                              <DropdownMenuItem onClick={downloadEventPDF}>
                                {/* <MessageSquare /> */}
                                <span>Download</span>
                              </DropdownMenuItem>
                              <DropdownMenuItem
                                onClick={() => {
                                  window.open(
                                    `${
                                      import.meta.env.VITE_APP_API_URL
                                    }/tours/${item.slug}/pdf/`,
                                    "_blank"
                                  );
                                }}
                              >
                                {/* <Mail /> */}
                                <span>Preview</span>
                              </DropdownMenuItem>

                              <DropdownMenuItem>
                                {/* <PlusCircle /> */}
                                <span onClick={() => {navigator.clipboard.writeText( `${
                                      import.meta.env.VITE_APP_API_URL
                                    }/tours/${item.slug}/pdf/?download=1`)}}>Copy Link</span>
                              </DropdownMenuItem>
                            </DropdownMenuSubContent>
                          </DropdownMenuPortal>
                        </DropdownMenuSub>
                        <DropdownMenuItem
                          onClick={() => setIsGalleryOpen(true)}
                        >
                          <Images />
                          Manage Gallery
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onSelect={() => setIsCouponModalOpen(true)}
                        >
                          {/* <MessageSquare /> */}
                          <TicketPercent />
                          <span>Edit Coupons</span>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onClick={() => {
                            window.open(
                              `${import.meta.env.VITE_APP_API_URL}/tours/${
                                item.slug
                              }/create-form/`,
                              "_blank"
                            );
                          }}
                        >
                          {/* <PlusCircle /> */}
                          <SquareChartGantt />
                          <span>Edit Forms</span>
                        </DropdownMenuItem>
                        {(companyslug == 'logoutworld' || companyslug == 'trove-experiences') && <DropdownMenuSub>
                          <DropdownMenuSubTrigger>
                            <Squirrel />
                            <span>Automations</span>
                          </DropdownMenuSubTrigger>
                          <DropdownMenuPortal>
                            <DropdownMenuSubContent>
                              <DropdownMenuItem onSelect={()=>setIsWhatsAppOpen((true))}>
                                {/* <MessageSquare /> */}
                                <span>WhatsApp</span>
                              </DropdownMenuItem>
                            </DropdownMenuSubContent>
                          </DropdownMenuPortal>
                        </DropdownMenuSub>}

                        <DropdownMenuSeparator />
                        <DropdownMenuItem
                          onSelect={() => {refetchBooknowcode();setPaymentsModalOpen(true);}}
                        >
                          {/* <PlusCircle /> */}
                          <ScanQrCode />
                          <span>Integrate Payments</span>
                        </DropdownMenuItem>
                        <DropdownMenuSeparator />
                        <DropdownMenuSub>
                          <DropdownMenuSubTrigger>
                            <Bolt />
                            <span>More Options</span>
                          </DropdownMenuSubTrigger>
                          <DropdownMenuPortal>
                            <DropdownMenuSubContent>
                              <DropdownMenuItem
                                  onSelect={() =>
                                      window.open(
                                          `https://logout.world/tours/${item.slug}/`,
                                          "_blank"
                                      )
                                  }
                              >
                                {/* <MessageSquare /> */}
                                <span>Go to Event page</span>
                              </DropdownMenuItem>
                              <DropdownMenuItem
                                  onSelect={() =>
                                      window.open(
                                          `https://logout.world/tours/${item.slug}/booking/`,
                                          "_blank"
                                      )
                                  }
                              >
                                {/* <Mail /> */}
                                <span>Go to Booking Page</span>
                              </DropdownMenuItem>

                              <DropdownMenuItem
                                  onClick={() => {
                                    setIsDuplicateEventModalOpen(true);
                                  }}
                              >
                                <span>Duplicate Event</span>
                              </DropdownMenuItem>
                              <DropdownMenuItem>
                                {/* <PlusCircle /> */}
                                <span>Edit Headsup Mail</span>
                              </DropdownMenuItem>
                            </DropdownMenuSubContent>
                          </DropdownMenuPortal>
                        </DropdownMenuSub>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem onClick={()=>setIsDeleteModalOpen(true)}>
                          {/* <PlusCircle /> */}
                          <Trash />
                          <span>Delete</span>
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </button>
      {isGalleryOpen && (
        <UploadImageModal
          isOpen={isGalleryOpen}
          setIsOpen={setIsGalleryOpen}
          eventID={item.id}
        />
      )}
      {isCouponModalOpen && (
        <EditCoupon
          isOpen={isCouponModalOpen}
          couponLevel={"event"}
          setIsOpen={setIsCouponModalOpen}
          eventID={item.slug}
          apiUrl={`${import.meta.env.VITE_APP_API_URL}/v3/get-coupons/${
            item.id
          }/?q=event`}
        />
      )}
      <PaymentsModal
        isOpen={paymentsModalOpen}
        setIsOpen={setPaymentsModalOpen}
        booknowCode={booknowCode && booknowCode}
      />
      <DuplicateEventModal
        isModalOpen={isDuplicateEventModalOpen}
        setisModalOpen={setIsDuplicateEventModalOpen}
        event={item}
        companyslug={companyslug}
        refetchEvent={refetchEvent}
      />
      {isAddBatchesOpen && (
        <AddBatchesModal
          isOpen={isAddBatchesOpen}
          setIsOpen={setIsAddBatchesOpen}
          eventSlug={item.slug}
        />
      )}
      <DeleteEventModal
      isOpen={isDeleteModalOpen}
      setIsOpen={setIsDeleteModalOpen}
      event={item}
      companyslug={companyslug}
      refetchEvent={refetchEvent}
      />
      {
        isWhatsAppOpen && <WhatsAppAutomation isOpen={isWhatsAppOpen} event={item} setIsOpen={setIsWhatsAppOpen} />
      }
      </div>
  );
};

export default EventCard;

const PaymentsModal = ({ isOpen, setIsOpen, booknowCode }) => {
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Copy "Book Now" Button</DialogTitle>
          <div className="py-4">
            <CopyLink code={booknowCode} />
            <Textarea className="h-72 mt-4" readOnly>
              {booknowCode}
            </Textarea>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

const DeleteEventModal = ({ isOpen, setIsOpen, event, companyslug, refetchEvent }) => {
  const {toast} = useToast();
  const deleteEvent = function(){
    toast({
      title: "Deleting event...",
      variant: "loading",
      duration: 5000,
    });
  try {
      axios.delete(
        `${
          import.meta.env.VITE_APP_API_URL
        }/v3/events/${companyslug}/${event.id}/`,
      ).then((response)=>{
          toast({
              title: "Event Deleted",
              variant: "success",
              duration: 2000,
            });
          refetchEvent();
          setIsOpen(false);
      }
      );
    } catch (err) {
      toast({
        title: err.response.data.message || "Try again after sometime",
        variant: "error",
        duration: 2000,
      });
    }
  }
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete Event</DialogTitle>
        </DialogHeader>
        Are you sure want to delete :
          <div className="space-y-2 align-middle justify-center font-bold">
            {event.name}
          </div>
        <div className="space-y-2 flex gap-2 justify-end items-center space-x-6">
          <Button className="bg-red-500" onClick={deleteEvent}>Delete</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
