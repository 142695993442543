import EventList from "@/components/events/event-list";
import CardInfo from "@/components/card-info";
import React, { useEffect, useState, useCallback, useRef } from "react";
import useFetch from "@/hooks/useFetchLSD";
import { useAuthStore } from "@/store/store";
import { Card } from "@/components/ui/card";
import AnimationPage from "@/components/ui/AnimatioPage";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import GeneratePaymentLink from "@/components/generate-payment-link";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { CreditCard } from "lucide-react";
import { ListFilter, Search } from "lucide-react";
import { debounce } from "lodash";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

import MyForm from "@/components/manual-booking/MainForm";
import { Skeleton } from "@/components/ui/skeleton";
import { Button } from "@/components/ui/button";
import { useCompanyStore } from "@/store/companyStore";
import ManualBookingForm from "@/components/manual-booking/MainForm";

const EventPage = () => {
  const { companyslug } = useAuthStore();
  const categories = useCompanyStore().companyData.categories.data;
  const [allevents, setAllEvents] = useState({});
  const ref = useRef(null);
  const [loadedMore, setLoadedMore] = useState(false);
  const [isPaymentLinkOpen, setIsPaymentLinkOpen] = useState(false);
  const [url, setUrl] = useState(
    `${import.meta.env.VITE_APP_API_URL}/v3/events/${companyslug}/`
  );
  const batchRef = useRef("upcoming");
  const statusRef = useRef("live");
  const [searchText, setSearchText] = useState("");
  const [haveBatches, setHaveBatches] = useState(true);
  const [publish, setPublish] = useState(true);
  const [category, setCategory] = useState("");
  const [appliedFilters, setAppliedFilters] = useState({
    q: "",
    have_batches: true,
    publish: true,
    category: "",
  });

  const { data, error, isFetching, refetch } = useFetch(
    `${import.meta.env.VITE_APP_API_URL}/v3/events/${companyslug}/`,
    appliedFilters,
    {
      // loadedMore: loadedMore,
      queryOptions: {
        refetchOnWindowFocus: false,
        retry: 2,
        enabled: false,
        keepPreviousData: true,
      },
    }
  );

  const {
    data: postResponse,
    error: postError,
    isFetching: postFetching,
    refetch: loadMoreFetch,
  } = useFetch(url, appliedFilters, {
    queryOptions: {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: false,
      keepPreviousData: true,
    },
  });

  useEffect(() => {
    setAllEvents(data);
  }, [data]);

  const debouncedSearch = useCallback(
    debounce((newSearch) => {
      setAppliedFilters((prev) => {
        return { ...prev, q: newSearch };
      });
      setSearchText(newSearch);
      setTimeout(() => {
        refetch();
      }, 0);
    }, 500), // Adjust debounce delay as needed
    []
  );

  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
  };

  useEffect(() => {
    setAllEvents((prevEvents) => {
      const combinedResults = [
        ...(prevEvents?.results || []),
        ...(postResponse?.results || []),
      ];

      // Remove duplicates by a unique key (e.g., id)
      const uniqueResults = Array.from(
        new Map(combinedResults.map((item) => [item.id, item])).values()
      );

      return {
        ...prevEvents,
        ...postResponse,
        results: uniqueResults,
      };
    });
  }, [postResponse]);

  useEffect(() => {
    refetch();
  }, [appliedFilters]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    loadMoreFetch();
  }, [url]);

  return (
    <div>
      <div className="flex items-center gap-2 pb-4">
        {/* <div className="section_heading_image">
          <img
            src="/leads_photo.jpg"
            alt="leads"
            className="w-full h-full object-cover absolute top-0 left-0"
          />
        </div> */}
        <h2 className="section_heading">My Events</h2>
      </div>
      <CardInfo />
      <div className="grid grid-cols-12">
        <div className="flex flex-col col-span-12 md:col-span-9 flex-grow">
          <div className="flex gap-2 items-center pb-4">
            <div className="bg-background/95 w-full backdrop-blur supports-[backdrop-filter]:bg-background/60">
              <form>
                <div className="relative">
                  <Search className="absolute left-2 top-2 h-4 w-4 text-muted-foreground" />
                  <Input
                    onChange={handleSearchChange}
                    placeholder="Search"
                    className="pl-8 h-8"
                  />
                </div>
              </form>
            </div>

            <Popover modal={true} align="end">
              <PopoverTrigger>
                <Button ref={ref} className="h-8 text-sm" variant="outline">
                  <ListFilter size={14} />
                  <span className="text-sm hidden md:flex">Filters</span>
                </Button>
              </PopoverTrigger>
              <PopoverContent className="">
                <div className="grid gap-y-4 grid-cols-1">
                  <div className="flex flex-col gap-1">
                    <Label>Status</Label>
                    <Select
                      value={statusRef.current}
                      onValueChange={(e) => {
                        console.log(e);
                        setPublish(
                          e == "live" ? true : e == "draft" ? false : ""
                        );
                        statusRef.current = e;
                      }}
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue>
                          {statusRef.current == "live"
                            ? "Live"
                            : statusRef.current == "draft"
                            ? "Draft"
                            : "All"}
                        </SelectValue>
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="all">All Events</SelectItem>
                        <SelectItem value="live">Live</SelectItem>
                        <SelectItem value="draft">Draft</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="flex flex-col gap-1">
                    <Label>Categories</Label>
                    <Select
                      value={category}
                      onValueChange={(e) => {
                        setCategory(e);
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select Category" />
                      </SelectTrigger>
                      <SelectContent>
                      <SelectItem value={'all'}>
                            All
                        </SelectItem>
                        {categories?.map((category) => (
                          <SelectItem value={category.slug} key={category.slug}>
                            {category.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="flex flex-col gap-1">
                    <Label>Slot Filter</Label>
                    <Select
                      value={batchRef.current}
                      onValueChange={(e) => {
                        console.log(e);
                        setHaveBatches(
                          e == "upcoming"
                            ? true
                            : ""
                        );
                        batchRef.current = e;
                      }}
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue>
                          {batchRef.current == "upcoming"
                            ? "Upcoming Slots"
                            : "All Slots"}
                        </SelectValue>
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="all">All slots</SelectItem>
                        <SelectItem value="upcoming">
                          Upcoming slots
                        </SelectItem>
                        {/* <SelectItem value="notupcoming">
                          Without upcoming batches
                        </SelectItem> */}
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  {/* <hr className="my-1" /> */}
                  <div className=" mt-4 w-full">
                    <Button
                      onClick={() => {
                        setAppliedFilters({
                          ...appliedFilters,
                          have_batches: haveBatches,
                          publish: publish,
                          category: category!="all"?category:"",
                        });
                        ref.current.click();
                      }}
                      className="w-full"
                    >
                      Apply Filters
                    </Button>
                  </div>
                </div>
              </PopoverContent>
            </Popover>
          </div>
          <div>
            {isFetching && (
              <div className="flex flex-col gap-4">
                {[1, 2, 3].map((item, index) => (
                  <div className="w-full " key={index}>
                    <button
                      // key={item.id}
                      className={`flex relative w-full pointer-events-auto  flex-col items-start gap-3 lg:p-3 p-1 px-2 rounded-none md:rounded-md lg:border border-t-none border-r-none border-l-none border-b-[1px] text-left text-sm transition-all`}
                    >
                      <div className="flex w-full items-start gap-2">
                        <div className="flex flex-col items-center justify-center gap-1">
                          <div className="h-auto md:w-40 lg:w-48 w-[4.5rem] flex flex-col aspect-square rounded-md relative overflow-hidden">
                            <Skeleton className="w-full h-full" />
                          </div>
                        </div>
                        <div className="flex w-full flex-col md:h-[12rem] justify-between">
                          <div className="md:basis-[60px] ">
                            <Skeleton className="w-[70%] h-4 md:h-6" />
                          </div>
                          <div className="flex flex-col mt-2 gap-4">
                            <Skeleton className="w-[60%] h-4 md:h-6" />
                            <div className="hidden md:flex items-center gap-2">
                              <Skeleton className="w-20 h-12" />
                              <Skeleton className="w-20 h-12" />
                              <Skeleton className="w-20 h-12" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="absolute bottom-[4%] right-[2%]">
                        <Skeleton className="md:w-24 md:h-8 w-16 h-4" />
                      </div>
                    </button>
                  </div>
                ))}
              </div>
            )}

            {Object.keys(allevents)?.length > 0 && (
              <EventList
                data={allevents}
                filters={appliedFilters}
                refetchEvent={refetch}
              />
            )}
            {allevents?.next && (
              <div className="flex justify-center mb-4 mt-4">
                <Button
                  className="w-28"
                  onClick={() => {
                    setUrl(allevents.next);
                    console.log(allevents.next);
                    setLoadedMore(true);
                  }}
                >
                  {postFetching ? <div className="dots"></div> : "Load More"}
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="md:flex col-span-3 hidden w-full p-0 lg:pl-4 lg:mt-0 mt-6">
          <Card className="w-full max-h-[16.5rem] flex flex-col justify-center items-center">
            <div className="">
              <AnimationPage />
            </div>
            <Button
              className="mt-4 px-6 py-2 rounded-md w-[90%] flex items-center gap-2 text-center"
              onClick={() => setIsPaymentLinkOpen(true)}
            >
              Generate Payment Link
              <CreditCard size={16} />
            </Button>
          </Card>
          {isPaymentLinkOpen && (
            <GeneratePaymentLink
              open={isPaymentLinkOpen}
              setOpen={setIsPaymentLinkOpen}
            />
          )}
        </div>
      </div>
       {/* <ManualBookingForm isOpen={true} setIsOpen={false}/> */}
    </div>
  );
};

export default EventPage;
