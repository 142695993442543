import React, { useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

import { format } from "date-fns";
import useFetch from "@/hooks/useFetchLSD";
import { useAuthStore } from "@/store/store";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { DateTimePicker } from "../Custom/DateTimePicker";
import {
  Select,
  SelectContent,
  SelectTrigger,
  SelectValue,
  SelectItem,
} from "../ui/select";
import { Button } from "../ui/button";
import { Trash } from "lucide-react";
import axios from "axios";
import { useToast } from "@/hooks/use-toast";

const EditTransaction = ({ isOpen, setIsOpen, bookingId }) => {
  const {toast} = useToast();
  const { companyslug } = useAuthStore();
  const [manualTransaction, setManualTransactions] = React.useState([]);
  const [transactionIds, setTransactionIds] = React.useState([]);
  const [newTransactionsIds, setNewTransactionsIds] = React.useState([]);

  const formReducer = (state, action) => ({
    ...state,
    [action.name]: action.value,
  });

  const [formData, dispatch] = React.useReducer(formReducer, {
    timestamp: format(new Date(), "dd-MM-yyyy'T'HH:mm:ss.SSS"),
  });

  const ROOT_URL = `${
    import.meta.env.VITE_APP_API_URL
  }/v3/bookings/${companyslug}/${bookingId}/manual-transactions/`;

  const handleAddTransaction = () => {
    setManualTransactions([
      ...manualTransaction,
      {
        id: newTransactionsIds.length + 1,
        amount: "",
        mode: "",
        payment_id: "",
        remarks: "",
        time: "",
      },
    ]);
    setNewTransactionsIds([
      ...newTransactionsIds,
      newTransactionsIds.length + 1,
    ]);
  };

  const {
    data: transactionData,
    error: transactionTimelineError,
    isLoading: transactionTimelineLoading,
    refetch: refetchTransactionData,
  } = useFetch(
    ROOT_URL,
    {},
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: 1,
        enabled: true,
        keepPreviousData: true,
      },
    }
  );

  useEffect(() => {
    setManualTransactions(transactionData?.data);
  }, [transactionData]);

  const handleChange = (id, field, value) => {
    // const changedRecord = transactionTimeline.find((item) => item.id === id);
    // changedRecord[field] = value;
    console.log(id, field, value);
    setManualTransactions((prev) =>
      prev.map((item) =>
        item.id == id
          ? {
              ...item,
              [field]: value,
            }
          : item
      )
    );
  };

  const postRequest = async (url, requestData) => {
    try {
      const response = await axios.post(url, requestData);
      toast({
        title: response.data.message,
        variant:"success",
        duration: 2000
      });
      return { data: response.data, isError: false, error: null };
    } catch (err) {
      return { data: null, isError: true, error: err };
    }
  };

  const updateTransaction = function (id,isdeleted) {
    event.preventDefault();
    const transaction = manualTransaction.find((transac) => transac.id === id);
    let formData = new FormData();
    formData.append("uid", transaction.id);
    formData.append("amount", transaction.amount);
    formData.append("mode", transaction.mode);
    formData.append("payment_id", transaction.payment_id);
    formData.append("remarks", transaction.remarks);
    formData.append("image", transaction.file);
    if(isdeleted){
      formData.append("void",true)
    }

    (async () => {
      const {
        data: transactiondata,
        isError,
        error,
      } = await postRequest(
        `${
          import.meta.env.VITE_APP_API_URL
        }/v3/bookings/${companyslug}/${bookingId}/add-manual-transaction/?update=1`,
        formData
      );
    })();

    refetchTransactionData();

  };

  return (
    <div>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger>Open</DialogTrigger>
        <DialogContent
          className={
            "lg:max-w-xl overflow-y-scroll rounded-md lg:max-h-[80vh] max-h-[70vh]"
          }
        >
          <DialogHeader>
            <DialogTitle>Edit transactions</DialogTitle>
          </DialogHeader>
          <div>
            {manualTransaction?.map((item, index) => (
              <Accordion type="single" collapsible key={index}>
                <AccordionItem value="item-1">
                  <AccordionTrigger>{`${item?.timestamp || ""} (₹${(item?.amount / 100).toFixed(2)})`}</AccordionTrigger>
                  <AccordionContent>
                    <form>
                      <div className="text-left grid grid-cols-1 lg:grid-cols-2 gap-y-4 gap-x-4">
                        <div className="grid w-full max-w-sm items-center gap-1.5">
                          <Label htmlFor="email">Amount</Label>
                          <Input
                            type="number"
                            id="amount"
                            placeholder="Amount"
                            onChange={(e) => {
                              handleChange(item.id, "amount", e.target.value);
                            }}
                            value={(item?.amount / 100).toFixed(2)}
                          />
                        </div>
                        <div className="grid w-full max-w-sm items-center gap-1.5">
                          <Label htmlFor="event">Mode</Label>
                          <Select
                            onValueChange={(value) => {
                              handleChange(item.id, "mode", value);
                            }}
                            required={true}
                            value={item.mode}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Select Mode" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="Direct Bank">
                                Direct Bank
                              </SelectItem>
                              <SelectItem value="G Pay">G Pay</SelectItem>
                              <SelectItem value="Phone Pay">PhonePe</SelectItem>
                              <SelectItem value="Other UPI">
                                Other UPI
                              </SelectItem>
                              <SelectItem value="Cash">Cash</SelectItem>
                              <SelectItem value="Company QR">
                                Company QR
                              </SelectItem>
                              <SelectItem value="Cheque">Cheque</SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                        <div className="grid w-full max-w-sm items-center gap-1.5">
                          <Label htmlFor="email">Payment ID</Label>
                          <Input
                            type="text"
                            id="payment_id"
                            placeholder="Payment ID"
                            onChange={(e) => {
                              handleChange(
                                item.id,
                                "payment_id",
                                e.target.value
                              );
                            }}
                            value={item.payment_id}
                          />
                        </div>
                        <div className="grid w-full max-w-sm items-center gap-1.5">
                          <Label htmlFor="email">Remarks</Label>
                          <Input
                            type="text"
                            id="remarks"
                            placeholder="Remarks"
                            onChange={(e) => {
                              handleChange(item.id, "remarks", e.target.value);
                            }}
                            value={item.remarks}
                          />
                        </div>
                        {/* <div className="grid w-full max-w-sm items-center gap-1.5">
                          <Label htmlFor="email">Time</Label>
                          <DateTimePicker
                            onUpdate={(value) => {
                              handleChange(
                                item.id,
                                "timestamp",
                                format(value, "dd/MM/yyyy HH:mm ")
                              );
                            }}
                            defaultValue={
                              item?.timestamp &&
                              format(new Date(
                                item?.timestamp
                              ))
                            }
                            // defaultValue={ item?.timestamp && new Date(item?.timestamp)}
                          />
                        </div> */}
                        <div className="grid w-full max-w-sm items-center gap-1.5">
                          <Label htmlFor="picture">Upload Image</Label>
                          <Input
                            id="picture"
                            className="pt-1"
                            type="file"
                            onChange={(e) => {
                              handleChange(item.id, "file", e.target.files[0]);
                            }}
                          />
                          <Label>
                            <a href={item?.image}>{item.image.slice(0, 10)}</a>
                          </Label>
                        </div>
                      </div>
                      <div className="flex gap-2 items-center justify-end mt-4">
                        <span className="flex items-center underline cursor-pointer" onClick={()=>{updateTransaction(item.id,true)}}>
                          <Trash size={12} />
                          Remove
                        </span>
                        <Button
                          className=""
                          onClick={() => {
                            updateTransaction(item.id,false);
                          }}
                        >
                          Update
                        </Button>
                      </div>
                    </form>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            ))}
            <div className="mt-6">
              {newTransactionsIds.map((item, index) => (
                <form onSubmit={submitHandler} key={index}>
                  <div className="text-left grid grid-cols-1 lg:grid-cols-2 gap-y-4 gap-x-4">
                    <div className="grid w-full max-w-sm items-center gap-1.5">
                      <Label htmlFor="email">Amount</Label>
                      <Input
                        type="number"
                        id="amount"
                        placeholder="Amount"
                        onChange={(e) => {
                          handleChange(item, "amount", e.target.value);
                        }}
                      />
                    </div>
                    <div className="grid w-full max-w-sm items-center gap-1.5">
                      <Label htmlFor="event">Mode</Label>
                      <Select
                        onValueChange={(value) => {
                          handleChange(item, "mode", value);
                        }}
                        required={true}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Select Mode" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="Direct Bank">
                            Direct Bank
                          </SelectItem>
                          <SelectItem value="G Pay">G Pay</SelectItem>
                          <SelectItem value="Phone Pay">PhonePe</SelectItem>
                          <SelectItem value="Other UPI">Other UPI</SelectItem>
                          <SelectItem value="Cash">Cash</SelectItem>
                          <SelectItem value="Company QR">Company QR</SelectItem>
                          <SelectItem value="Cheque">Cheque</SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                    <div className="grid w-full max-w-sm items-center gap-1.5">
                      <Label htmlFor="email">Payment ID</Label>
                      <Input
                        type="text"
                        id="payment_id"
                        placeholder="Payment ID"
                        onChange={(e) => {
                          handleChange(item, "payment_id", e.target.value);
                        }}
                      />
                    </div>
                    <div className="grid w-full max-w-sm items-center gap-1.5">
                      <Label htmlFor="email">Remarks</Label>
                      <Input
                        type="text"
                        id="remarks"
                        placeholder="Remarks"
                        onChange={(e) => {
                          handleChange(item, "remarks", e.target.value);
                        }}
                      />
                    </div>
                    <div className="grid w-full max-w-sm items-center gap-1.5">
                      <Label htmlFor="email">Time</Label>
                      <DateTimePicker
                        onChange={(e) => {
                          handleChange(item, "time", e.target.value);
                        }}
                      />
                    </div>
                    <div className="grid w-full max-w-sm items-center gap-1.5">
                      <Label htmlFor="picture">Upload Image</Label>
                      <Input
                        id="picture"
                        className="pt-1"
                        type="file"
                        onChange={(e) => {
                          handleChange(item, "file", e.target.files[0]);
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex gap-2 items-center justify-end mt-4">
                    <span className="flex items-center underline cursor-pointer">
                      <Trash size={12} />
                      Remove
                    </span>
                    <Button className="">Update</Button>
                  </div>
                </form>
              ))}
            </div>
            <div>
              <Button className="mt-4" onClick={handleAddTransaction}>
                Add Transaction
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditTransaction;
