import React, { useEffect, useState } from "react";
import { Label } from "@/components/ui/label";
import { useCompanyStore } from "@/store/companyStore";
import { Badge } from "@/components/ui/badge";
import MultiSelectCombobox from "@/components/multi-combobox";
import { CircleX, GripVertical } from "lucide-react";
import { Separator } from "@/components/ui/separator";
import { DndContext } from "@dnd-kit/core";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import SortableItem from "./SortableCategory";
import { arrayMove } from "@dnd-kit/sortable";


const EventSelect = function ({data, updateStageForm}) {
  const [value, setValue] = React.useState();
  const [eventOptions, setEventOptions] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [textNode, setTextNode] = useState()
  const allevents = useCompanyStore().companyData.events.data
  const [events, setEvents] = useState([])
  console.log(data)

  useEffect(()=>{
      const cats = data.forms.find((node)=>node.type == 'quick_reply')
      console.log(cats)
      setEvents(cats.value)
    },[data])


  const handleEventSelect = (value) => {
    var temp = []
    value.map((val)=>{
        var cat = allevents.find((cat)=>cat.slug==val)
        if(cat){
            temp.push(cat)
        }
    })
    console.log(temp)
    const transformed = temp.map((temp)=>({
      name: temp.name,
      id: temp.slug
    }))
    setSelectedEvents(transformed)
    const transformed2 = temp.map((temp)=>({
      text: temp.name,
      payload: temp.slug
    }))
    const updated = [...data.forms]
    const text = updated.find((node)=>node.type=='quick_reply')
    text.value = transformed2
    updateStageForm(data.id, updated)
  };

  

  const reorderLinks = async (e) => {
    var newArr = [...selectedEvents];
    if (!e.over) {
      console.log("No over");
      return;
    }

    if (e.active.id !== e.over.id) {


      const oldIndex = selectedEvents.findIndex(
        (item) => item.id == e.active.id.toString()
      );
      const newIndex = selectedEvents.findIndex(
        (item) => item.id == e.over.id.toString()
      );
    console.log(oldIndex,newIndex, e.active.id)
      newArr = arrayMove(selectedEvents, oldIndex, newIndex);
      console.log(newArr)
      setSelectedEvents(newArr);
      const transformed2 = newArr.map((temp)=>({
        text: temp.name,
        payload: temp.id
      }))
      const updated = [...data.forms]
      const text = updated.find((node)=>node.type=='quick_reply')
      text.value = transformed2
      updateStageForm(data.id, updated)
    }
    // try {
    //   const res = await axios.post(
    //     `${import.meta.env.VITE_APP_API_URL}/api/set-event-ranking/`,
    //     newArr
    //   );
    // } catch (err) {
    //   console.log(err);
    // }
  };

  useEffect(() => {
    const transformedArray = allevents.map((fruit) => ({
      value: fruit.slug, // Using `id` as value
      label: fruit.name, // Using `name` as label
    }));

    setEventOptions(transformedArray);
  }, [allevents]);


  return (
    <div>
      <div className="flex flex-col gap-1">
       
        {eventOptions?.length > 0 && (
          <div className="my-4 w-full">
            <MultiSelectCombobox
              onUpdate={handleEventSelect}
              options={eventOptions}
            />
          </div>
        )}
      </div>

      <DndContext onDragEnd={reorderLinks}>
        <div className="flex px-2 flex-col gap-3">
          <SortableContext items={selectedEvents}>
            {selectedEvents && selectedEvents?.length > 0 &&
              selectedEvents?.map((val, index) => (
                <SortableItem setItems={setSelectedEvents} item={val} items={selectedEvents} data={data} updateStageForm={updateStageForm}/>
              ))}
          </SortableContext>
        </div>
      </DndContext>
    </div>
  );
};

export default EventSelect;
