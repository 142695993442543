import React, { useRef, useState, useEffect } from "react";
import { CircleX, GripVertical } from "lucide-react";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Button } from "../ui/button";
import { Label } from "../ui/label";
import { Textarea } from "../ui/textarea";
import { Input } from "../ui/input";

const SortableMessage = ({
  message,
  handleExistingReplyChange,
  handleQuickReplyChange,
  handleDeleteReply
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: message });
  const [openAccordion, setOpenAccordion] = useState();
  const [editClickedId, setEditClickedId] = useState(null);
  const [value, setValue] = useState();
  const [textValue, setTextValue] = useState();
  const inputRef = useRef();

  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     var code = event.keyCode ? event.keyCode : event.which;
  //     console.log(code);
  //     if (code == 13) {
  //       //Enter keycode
  //       alert("enter press");
  //     }
  //     if (inputRef.current && !inputRef.current.contains(event.target)) {
  //       console.log("here");
  //       setEditClickedId(null); // Close input box
  //     }
  //   }

  //   document.addEventListener("keydown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("keydown", handleClickOutside);
  //   };
  // }, []);

  const handleChange = (field, value, e) => {
    if (field) {
      handleQuickReplyChange(field, value, e);
    } else {
      handleExistingReplyChange(value, e);
    }
  };

  return (
    <div>
      <Accordion
        value={openAccordion}
        onValueChange={setOpenAccordion}
        type="single"
        collapsible
      >
        <AccordionItem value={message.text}>
          <div>
            <div
              ref={setNodeRef}
              style={{
                transform: CSS.Transform.toString(transform),
                transition: transition,
              }}
              className="flex border-b-[1px] py-2 items-center justify-between"
            >
              <div className="flex w-full flex-col gap-2">
                <div
                  onClick={() => {
                    if (
                      message.editable ||
                      message.payload.includes("CUSTOM_QUICK_REPLY")
                    )
                      setEditClickedId(message.text);
                    if (message.payload.includes("CUSTOM_QUICK_REPLY")) {
                      setOpenAccordion(message.text);
                    }
                  }}
                  className="text-sm max-w-52"
                >
                  {!(editClickedId == message.text) && message.text}
                  {editClickedId == message.text &&
                    (message.editable ||
                      message.payload.includes("CUSTOM_QUICK_REPLY")) && (
                      <Input
                        ref={inputRef}
                        defaultValue={message.text}
                        onKeyDown={(e) => {
                          var code = e.keyCode ? e.keyCode : e.which;
                          if (code == 13) {
                            //Enter keycode

                            if (
                              message.payload.includes("CUSTOM_QUICK_REPLY")
                            ) {
                              handleQuickReplyChange("text", message.text, {
                                target: { value: value },
                              });
                            } else {
                              handleExistingReplyChange(message.text, {
                                target: { value: value },
                              });
                            }
                          }
                        }}
                        onChange={(e) => {
                          setValue(e.target.value);
                        }}
                      />
                    )}
                  {/* {categories.find((value) => value.slug == category)?.name} */}
                </div>
                {/* <CircleX size={14} /> */}
                {/* <Separator /> */}
              </div>
              <div className="flex items-center gap-2">
                {/* {message.editable && <Button
                  className="h-6 text-xs"
                  onClick={() => {
                    if (openAccordion == message.text) {
                      setOpenAccordion(null);
                    } else {
                      setOpenAccordion(message.text);
                    }
                  }}
                  variant={"outline"}
                >
                  Edit
                </Button>} */}
                <div className="flex items-center gap-1">
                  {message.payload.includes('CUSTOM_QUICK_REPLY') && <CircleX size={16} onClick={()=>handleDeleteReply(message.text)} />}
                  <div {...attributes} {...listeners}>
                    <GripVertical size={16} />
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <AccordionContent>
            <div className="flex flex-col mt-2 px-2 gap-1">
              <Textarea
                onChange={(e) => setTextValue(e)}
                defaultValue={message.response}
              />
              <div className="flex ml-auto mt-2">
                <Button
                  onClick={() => {
                    handleQuickReplyChange("response", message.text, textValue);
                    setOpenAccordion(null);
                    setEditClickedId(null);
                  }}
                >
                  Update
                </Button>
              </div>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default SortableMessage;
