"use client";
import React, { useEffect } from "react";
import { useState } from "react";
import { BadgePercent, ChevronRight, Minus, Plus, Trash } from "lucide-react";
import axios from "axios";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { PhoneInput } from "@/components/ui/phone-input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { Separator } from "../ui/separator";
import CouponModal, { CouponSuccess } from "./CouponModal";
import useFetch from "@/hooks/useFetchLSD";
import { useFieldArray } from "react-hook-form";
import { Skeleton } from "../ui/skeleton";

const Tickets = ({
  form,
  billDetails,
  setBillDetails,
  amountToBeCollected,
  setAmountToBeCollected,
  fields,
  addonFields,
  ticketsFetching,
  ticketsData,
  coupons,
  setError,
  error,
}) => {
  const [paymentType, setPaymentType] = useState(
    form.getValues("recordPayment")
  );
  const [gstIncluded, setGstIncluded] = useState(true);
  const [isCouponModalOpen, setIsCouponModalOpen] = useState(false);
  const [ticketQuantities, setTicketQuantities] = useState([]);
  const [addonQuantities, setAddonQuantities] = useState([]);

  const [couponSucess, setCouponSuccess] = useState(false);

  useEffect(() => {
    if (billDetails.coupon_validity) {
      setCouponSuccess(true);
    }
  }, [billDetails]);

  useEffect(() => {
    setTicketQuantities(form.getValues("tickets"));
    setAddonQuantities(form.getValues("addons"));
  }, [form]);

  console.log(form);

  useEffect(() => {
    console.log(amountToBeCollected);
  }, [amountToBeCollected]);

  useEffect(() => {
    if (paymentType == "full" && !gstIncluded) {
      setAmountToBeCollected(billDetails?.baseprice);
      
    }

    if (paymentType == "full" && gstIncluded) {
      setAmountToBeCollected(billDetails?.finalprice);
    }
    form.setValue('gst_included',gstIncluded)
  }, [gstIncluded]);

  useEffect(() => {
    if (paymentType == "full") {
      setAmountToBeCollected(billDetails?.finalprice);
      form.setValue("amountCollected", billDetails?.finalprice);
    }
  }, [paymentType]);

  const incrementQty = async (index) => {
    const updatedTickets = [...fields];
    updatedTickets[index].quantity += 1;
    form.setValue("tickets", updatedTickets);
    setTicketQuantities(updatedTickets);
    await handleTicketUpdate(form);
  };

  const decrementQty = async (index) => {
    const updatedTickets = [...fields];
    if (updatedTickets[index].quantity > 0) {
      updatedTickets[index].quantity -= 1;
      form.setValue("tickets", updatedTickets);
      setTicketQuantities(updatedTickets);
    }

    console.log("tix", form.getValues("tickets"));
    await handleTicketUpdate(form);
  };

  const incrementAddonQty = async (index) => {
    const updatedTickets = [...addonFields];
    updatedTickets[index].quantity += 1;
    form.setValue("addons", updatedTickets);
    setAddonQuantities(updatedTickets);
    await handleTicketUpdate(form);
  };

  const decrementAddonQty = async (index) => {
    const updatedTickets = [...addonFields];
    if (updatedTickets[index].quantity > 0) {
      updatedTickets[index].quantity -= 1;
      form.setValue("addons", updatedTickets);
      setAddonQuantities(updatedTickets);
      await handleTicketUpdate(form);
    }
  };

  const handleTicketUpdate = async () => {
    const data = {
      addon_prices: addonFields,
      event_price_types: fields,
      buyerEmail: form.getValues("email"),
      gst_percentage: ticketsData?.data?.gst_percentage,
      numberOfGuest: fields.reduce((acc, curr) => acc + curr.quantity, 0),
      paisa: ticketsData?.data?.paisa,
      couponCode: form.getValues("couponCode"),
    };

    const res = await axios.post(
      `${
        import.meta.env.VITE_APP_API_URL
      }/v3/booking-calc-batch-api-manual/${form.getValues("batch")}/`,
      JSON.stringify(data)
    );
    setBillDetails(res.data);
    // form.setValues('amountCollected')
  };

  return (
    <div className="flex md:h-auto pt-4 px-4 flex-col gap-6">
      <div>
        <h2 className="text-sm">Select Ticket(s)</h2>
        <Separator className="mt-1 mb-3" />
        {ticketsFetching && (
          <div className="flex flex-col gap-2 mt-4">
            <Skeleton className="w-36 h-4"></Skeleton>
            <Skeleton className="w-36 h-4"></Skeleton>
          </div>
        )}
        <div className="flex flex-col gap-2">
          {fields?.map((field, index) => (
            <div className="flex items-center justify-between mt-1" key={index}>
              <div className="font-medium text-sm">
                {field.attribute} - &#8377;{(field.price / 100).toFixed(2)}
              </div>
              <div className="flex items-center gap-4">
                <span
                  onClick={() => decrementQty(index)}
                  className="cursor-pointer w-6 h-6 flex items-center justify-center bg-primary rounded-full text-lg"
                >
                  <Minus className="w-4 text-white h-4" />
                </span>
                <span name={`tickets.${index}.qty`}>
                  {ticketQuantities?.length > 0 &&
                    ticketQuantities[index]?.quantity}
                </span>
                <span
                  onClick={() => incrementQty(index)}
                  className="cursor-pointer w-6 h-6 flex items-center justify-center bg-primary rounded-full text-lg"
                >
                  <Plus className="w-4 text-white h-4" />
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      {addonFields?.length > 0 && (
        <div>
          <h2 className="text-sm">Select Addon(s)</h2>
          <Separator className="mt-1 mb-3" />
          {ticketsFetching && (
            <div className="flex flex-col gap-2 mt-4">
              <Skeleton className="w-36 h-4"></Skeleton>
              <Skeleton className="w-36 h-4"></Skeleton>
            </div>
          )}
          <div className="flex flex-col gap-2">
            {addonFields?.map((field, index) => (
              <div
                className="flex items-center justify-between mt-1 "
                key={index}
              >
                <div className="font-medium text-sm">
                  {field.attribute} - &#8377;{(field.price / 100).toFixed(2)}
                </div>
                <div className="flex items-center gap-4">
                  <span
                    onClick={() => decrementAddonQty(index)}
                    className="cursor-pointer w-6 h-6 flex items-center justify-center bg-primary rounded-full text-lg"
                  >
                    <Minus className="w-4 text-white h-4" />
                  </span>
                  <span>
                    {addonQuantities?.length > 0 &&
                      addonQuantities[index].quantity}
                  </span>
                  <span
                    onClick={() => incrementAddonQty(index)}
                    className="cursor-pointer w-6 h-6 flex items-center justify-center bg-primary rounded-full text-lg"
                  >
                    <Plus className="w-4 text-white h-4" />
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {coupons && (
        <div onClick={() => setIsCouponModalOpen(true)} className="flex w-full">
          <Button
            variant="outline"
            type="button"
            className="w-full justify-between"
          >
            <div className="flex items-center gap-1">
              <BadgePercent size={18} />
              {billDetails.coupon_validity ? (
                <span className="flex items-center gap-1">
                  <span className="font-semibold">
                    {form.getValues("couponCode")}
                  </span>{" "}
                  applied
                </span>
              ) : (
                "Apply Coupon"
              )}
            </div>
            {billDetails.coupon_validity ? (
              <span
                onClick={async (e) => {
                  e.stopPropagation();
                  form.setValue("couponCode", "");
                  await handleTicketUpdate();
                }}
                className="flex underline items-center text-xs gap-1"
              >
                <Trash size={12} />
                Remove
              </span>
            ) : (
              <ChevronRight className="w-5 h-5" />
            )}
          </Button>
        </div>
      )}
      {isCouponModalOpen && (
        <CouponModal
          coupons={coupons}
          isOpen={isCouponModalOpen}
          onClose={setIsCouponModalOpen}
          handleTicketUpdate={handleTicketUpdate}
          form={form}
        />
      )}
      {billDetails.coupon_validity && (
        <CouponSuccess
          isOpen={couponSucess}
          setIsOpen={setCouponSuccess}
          form={form}
        />
      )}
      <FormField
        control={form.control}
        name="recordPayment"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Record Payment</FormLabel>
            <Select
              className="w-full"
              onValueChange={(value) => {
                field.onChange(value);
                setPaymentType(value);
                if (value == "full") setGstIncluded(true);
              }}
              defaultValue={paymentType}
            >
              <FormControl>
                <SelectTrigger className="w-full max-w-full">
                  <SelectValue placeholder="" />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                <SelectItem value="--">--</SelectItem>
                <SelectItem value="full">Collect Full Amount</SelectItem>
                <SelectItem value="partial">Collect Partial Amount</SelectItem>
              </SelectContent>
            </Select>

            <FormMessage />
          </FormItem>
        )}
      />
      {paymentType && paymentType != "--" && (
        <>
          <FormField
            control={form.control}
            name="gst_included"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center justify-between rounded-lg">
                <div className="space-y-0.5">
                  <FormLabel>Include GST</FormLabel>
                </div>
                <FormControl>
                  <Switch
                    {...field}
                    checked={gstIncluded}
                    onCheckedChange={(value) => {
                      field.onChange(value);
                      setGstIncluded(value);
                    }}
                    //   disabled
                    //   aria-readonly
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="amountCollected"
            render={({ field }) => (
              <FormItem>
                <div className="flex justify-between">
                  <FormLabel>Amount Collected</FormLabel>
                  <FormControl>
                    <Input
                      className={`max-w-24 ${error ? "text-red-700" : ""}`}
                      placeholder=""
                      type="number"
                      disabled={paymentType == "full"}
                      value={amountToBeCollected / 100}
                      onChange={(e) => {
                        setAmountToBeCollected(e.target.value * 100);
                        form.setValue("amountCollected", e.target.value * 100);
                      }}
                    />
                  </FormControl>
                </div>
                {/* <FormMessage /> */}
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="discount"
            render={({ field }) => (
              <FormItem>
                <div className="flex justify-between">
                  <FormLabel>Discount</FormLabel>
                  <FormControl>
                    {/* <Input
                      className="max-w-24"
                      placeholder=""
                      type="number"
                      onChange={(e) => {
                        const newValue = parseInt(e.target.value || 0, 10); // Convert input to number
                        const oldValue = parseInt(field.value || 0, 10); // Previous field value
                        const difference = (newValue - oldValue) * 100; // Calculate difference
                        console.log("diff",newValue, oldValue, difference)
                        setAmountToBeCollected((prev) => prev - difference);
                      
                        console.log(e.target.value,billDetails.total_payable_amount_with_gst)
                        if(e.target.value > billDetails.total_payable_amount_with_gst/100){
                          setError(true)
                          
                        }
                        else{
                          field.onChange(e.target.value);
                          form.setValue("amountCollected", amountToBeCollected);
                        }
                      }}
                      // {...field}
                    /> */}
                    <Input
                      className="max-w-24"
                      placeholder=""
                      type="number"
                      defaultValue={form.getValues('discount')}
                      onChange={(e) => {
                        const newValue = parseInt(e.target.value || 0, 10); // Convert input to number
                        const oldValue = parseInt(field.value || 0, 10); // Previous field value
                        const difference = (newValue - oldValue) * 100; // Calculate difference

                        console.log("diff", newValue, oldValue, difference);

                        setAmountToBeCollected((prev) => {
                          const updatedAmount = prev - difference;
                          form.setValue("amountCollected", updatedAmount); // Update form value after setting state
                          return updatedAmount;
                        });

                        console.log(
                          e.target.value,
                          billDetails.total_payable_amount_with_gst
                        );

                        if (
                          newValue >
                          billDetails.total_payable_amount_with_gst / 100
                        ) {
                          setError(true);
                        } else {
                          field.onChange(newValue); // Update form field value
                        }
                      }}
                    />
                  </FormControl>
                </div>
                {/* <FormMessage /> */}
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="paymentMethod"
            render={({ field }) => (
              <FormItem>
                <div className="flex justify-between">
                  <FormLabel>Payment Method</FormLabel>
                  <Select
                    className="max-w-24"
                    onValueChange={(value) => field.onChange(value)}
                    defaultValue={"--"}
                    value={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className="max-w-24">
                        <SelectValue placeholder="" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="--">--</SelectItem>
                      <SelectItem value="upi">UPI</SelectItem>
                      <SelectItem value="cash">Cash</SelectItem>
                      <SelectItem value="debit">Debit Card</SelectItem>
                      <SelectItem value="credit">Credit Card</SelectItem>
                      <SelectItem value="other">Other</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                {/* <FormMessage /> */}
              </FormItem>
            )}
          />
          <Separator />
          <FormField
            control={form.control}
            name="referenceId"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Reference ID</FormLabel>
                <FormControl>
                  <Input placeholder="" type="text" {...field} />
                </FormControl>

                {/* <FormMessage /> */}
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="remarks"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Remarks</FormLabel>
                <FormControl>
                  <Input placeholder="" type="text" {...field} />
                </FormControl>

                {/* <FormMessage /> */}
              </FormItem>
            )}
          />
        </>
      )}
    </div>
  );
};

export default Tickets;
