import React, { useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { cn } from "@/lib/utils";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { Check } from "lucide-react";
// import AnimatedCheck from "./svg/AnimatedCheck";



const AnimatedStepper = ({
    steps,
    currentStep,
    onStepClick,
}) => {
    const scrollAreaRef = useRef(null);

    const scrollToCurrentStep = (stepIndex) => {
        const scrollArea = scrollAreaRef.current;
        if (scrollArea && scrollArea.children.length) {
            const stepElement = scrollArea.children[stepIndex]

            if (stepElement) {
                stepElement.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "center",
                });
            }
        }
    };

    useEffect(() => {
        scrollToCurrentStep(currentStep - 1);
    }, [currentStep]);

    return (
        <div
            className="rounded-md"
            dir="ltr"
        >
            <div className="flex items-center" ref={scrollAreaRef}>
                {steps.map((step, index) => (
                    <div
                        key={step.number}
                        className="flex flex-col items-center"
                    >
                        <div className="flex items-center">
                            <div className="flex flex-col items-center justify-center">
                                <motion.div
                                    onClick={() => onStepClick(step.number)}
                                    className={cn(
                                        "w-4 relative z-10 h-4 rounded-full flex items-center justify-center text-sm font-bold cursor-pointer",
                                        step.number === currentStep
                                            ? "bg-[#1ac870] text-primary-foreground"
                                            : step.number < currentStep
                                            ? "bg-[#1ac870] text-primary-foreground"
                                            : "bg-[#add8e6] text-secondary-foreground"
                                    )}
                                    initial={{ scale: 1 }}
                                   
                                  
                                >
                                    {step.number < currentStep ? (
                                        <motion.div
                                            initial={{ opacity: 0, scale: 0 }}
                                            animate={{ opacity: 1, scale: 1 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                           <Check color="#ffffff" className="w-3 h-3" />
                                        </motion.div>
                                    ) : (
                                        ''
                                    )}
                                </motion.div>
                                <motion.div
                                    className={cn(
                                        "mt-2 text-xs text-center w-10",
                                        step.number === currentStep
                                            ? "text-black font-bold"
                                            : "text-black"
                                    )}
                                    initial={{ opacity: 0.5 }}
                                    animate={{
                                        opacity: step.number === currentStep ? 1 : 0.5,
                                        transition: { duration: 0.3 }
                                    }}
                                >
                                    {/* {step.label} */}
                                </motion.div>
                            </div>
                            {index < steps.length - 1 && (
                                <motion.div
                                    className="w-6 h-[2px] mb-2 -mx-5"
                                    initial={{ backgroundColor: "#1ac870" }}
                                    animate={{
                                        backgroundColor: step.number < currentStep ? "#1ac870" : "hsl(var(--muted))",
                                        transition: { duration: 0.3 }
                                    }}
                                />
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {/* <ScrollBar orientation="horizontal" /> */}
        </div>
    );
};

export default AnimatedStepper;