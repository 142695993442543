import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Edit,
  RefreshCw,
  PenSquare,
  Trash2,
  RotateCw,
  Plus,
} from "lucide-react";
import useFetch from "@/hooks/useFetchLSD";
import { useAuthStore } from "@/store/store";
import axios from "axios";
import { Skeleton } from "./ui/skeleton";

const MobilePickupPoint = ({ item }) => (
  <div className="p-4 border rounded-lg mb-3 bg-white">
    <div className="flex justify-between items-start mb-2">
      <div className="font-medium">{item.locationName}</div>
      <div className="text-sm text-blue-600">{item.event}</div>
    </div>
    <div className="text-sm text-gray-500 mb-3 break-all">
      {item.googleMapLink}
    </div>
    <div className="text-xs text-gray-400">#{item.id}</div>
  </div>
);

const PickupPointsTable = () => {
  const { companyslug } = useAuthStore();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [pickupPoints, setPickupPoints] = useState([]);
  const [selectedPickupPoint, setSelectedPickupPoint] = useState({});
  const [addPickupPoint, setAddPickupPoint] = useState(false);
  const {
    data: pickupPointsData,
    error: pickupPointserror,
    isLoading: pickupisLoading,
    refetch: refetchPickupPoints,
    isFetching: isPickupPointsFetching,
  } = useFetch(
    `${import.meta.env.VITE_APP_API_URL}/v3/pickup-points/${companyslug}`,
    {},
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: 2,
        enabled: true,
        keepPreviousData: true,
      },
    }
  );

  useEffect(() => {
    setPickupPoints(pickupPointsData);
  });

  const submitHandler = function (e) {
    e.preventDefault();
    const data = {
      id: e.target[0].value,
      location_name: e.target[1].value,
      map_link: e.target[2].value,
    };
    postRequest(data);
    console.log(data);
  };

  const postRequest = async (requestData) => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_APP_API_URL}/v3/pickup-points/${companyslug}/`,
        requestData
      );
      setIsDialogOpen(false);
      refetchPickupPoints();
      return { data: response.data, isError: false, error: null };
    } catch (err) {
      return { data: null, isError: true, error: err };
    }
  };

  const deletePickupPoints = async (id) => {
    try {
      const response = await axios.delete(
        `${
          import.meta.env.VITE_APP_API_URL
        }/v3/pickup-points/${companyslug}/${id}/`
      );
      refetchPickupPoints();
      return { data: response.data, isError: false, error: null };
    } catch (err) {
      return { data: null, isError: true, error: err };
    }
  };

  return (
    <div className="w-full space-y-4">
      <div className="lg:flex justify-between gap-2">
        <div>
          <h2 className="text-2xl mb-2">Pickup Points</h2>
        </div>
        <div className="flex gap-2">
          <Button
            variant="default"
            className="flex items-center gap-2"
            onClick={() => {
              setIsDialogOpen(true);
              setSelectedPickupPoint("");
            }}
          >
            <Edit className="w-4 h-4" />
            Add Pickup Points
          </Button>
          {/* <Button
            variant="outline"
            className="flex items-center gap-2"
            onClick={() => {
              refetchPickupPoints();
            }}
          >
            <RefreshCw className="w-4 h-4" />
            Refresh
          </Button> */}
        </div>
      </div>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="max-w-2xl">
          <DialogHeader>
            <DialogTitle>Add/Edit Pickup Points</DialogTitle>
          </DialogHeader>
          <form onSubmit={submitHandler}>
            <div className="space-y-6">
              <div className="space-y-4">
                <input
                  id="pickuppoint_id"
                  value={selectedPickupPoint?.id}
                  type="hidden"
                />
                <div className="grid gap-3">
                  <div className="space-y-2">
                    <Label>Location Name</Label>
                    <Input
                      className="bg-gray-50"
                      id="attribute"
                      placeholder="Enter location name"
                      type="text"
                      defaultValue={selectedPickupPoint?.attribute}
                      required={true}
                    />
                  </div>

                  <div className="space-y-2">
                    <Label>Google Map Link</Label>
                    <Input
                      className="bg-gray-50"
                      placeholder="Enter Google Maps URL"
                      type="url"
                      id="map_location"
                      value={selectedPickupPoint?.mapslocation}
                    />
                    <p className="text-xs text-gray-500">
                      link with "http://" or "https://"
                    </p>
                  </div>
                </div>
              </div>

              <Button
                variant="outline"
                className="w-full"
                onClick={() => setAddPickupPoint(true)}
              >
                Save
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      <div className="hidden md:block">
        <Table className="border rounded-lg bg-white">
          <TableHeader>
            <TableRow className="bg-blue-50">
              <TableHead className="w-16">#</TableHead>
              <TableHead>Location Name</TableHead>
              <TableHead>Google Map Link</TableHead>
              <TableHead>Event</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {pickupPoints &&
              pickupPoints.map((point) => (
                <TableRow key={point.id}>
                  <TableCell>{point.id}</TableCell>
                  <TableCell className="font-medium">
                    {point.attribute}
                  </TableCell>
                  <TableCell className="text-blue-600">
                    {point.mapslocation || "-"}
                  </TableCell>
                  <TableCell>{point.eventname_name || "-"}</TableCell>
                  <TableCell>
                    <div className="flex justify-end gap-2">
                      <Button
                        variant="secondary"
                        className="flex items-center gap-2"
                        onClick={() => {
                          setIsDialogOpen(true);
                          setSelectedPickupPoint(point);
                        }}
                      >
                        <PenSquare className="h-4 w-4" />
                        Edit
                      </Button>
                      <Button
                        variant="secondary"
                        className="flex items-center gap-2 bg-red-100 hover:bg-red-200 text-red-600"
                        onClick={() => deletePickupPoints(point.id)}
                      >
                        <Trash2 className="h-4 w-4" />
                        Delete
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            {isPickupPointsFetching && (
              [1,2,3,4].map((item)=>{
                return (
                <TableRow key={item}>
                  <TableCell>
                  {" "}
                  <Skeleton className="w-4 h-4" />
                </TableCell>
                <TableCell>
                {" "}
                <Skeleton className="w-28 h-4" />
              </TableCell>
              <TableCell>
                {" "}
                <Skeleton className="w-28 h-4" />
              </TableCell>
              <TableCell>
                {" "}
                <Skeleton className="w-28 h-4" />
              </TableCell>
                </TableRow>
              )
              
              })
            )}
          </TableBody>
        </Table>
      </div>

      <div className="md:hidden">
        {pickupPoints &&
          pickupPoints.map((point) => (
            <MobilePickupPoint key={point.id} item={point} />
          ))}
      </div>
    </div>
  );
};

export default PickupPointsTable;
