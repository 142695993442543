import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { CalendarIcon, InfoIcon, Download, ChevronDown } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { format } from "date-fns";
import { useAuthStore } from "@/store/store";
import useFetch from "@/hooks/useFetchLSD";
import { Search } from "lucide-react";
import axios from "axios";
import { useToast } from "@/hooks/use-toast";
import { Skeleton } from "./ui/skeleton";

const MobileCard = ({ item }) => (
  <div className="p-3 border rounded-lg mb-3">
    <div className="flex justify-between items-start">
      <div>
        <div className="text-sm text-gray-600">
          {format(new Date(item.created_at), "dd/MM/yyyy")}
        </div>
        <div className="font-medium">{item.settlement_id}</div>
      </div>
    </div>
    <div className="space-y-2 mt-2">
      <div>
        <div className="text-sm text-gray-500">Type</div>
        <div className="text-gray-600">{item.booking_settlement_type}</div>
      </div>
      <div className="flex justify-between">
        <div>
          <div className="text-sm text-gray-500">Gross Settlement</div>
          <div className="text-gray-600">
            &#8377;{(item.settlement_amount / 100).toFixed(2)}
          </div>
        </div>
        <div className="text-right">
          <div className="text-sm text-gray-500">Net Settlement</div>
          <div className="font-medium">
            &#8377;
            {item.net_amount
              ? (item.net_amount / 100).toFixed(2)
              : ((item.settlement_amount - item.deducted_amount) / 100).toFixed(
                  2
                )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export function SettlementInfo() {
  const { toast } = useToast();
  const { companyslug } = useAuthStore();
  const [isDownloadOpen, setIsDownloadOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [invoiceFilters, setInvoiceFilters] = useState({});
  const [settlementData, setSettlementData] = useState([])
  const { data, error, isLoading, refetch, isFetching } = useFetch(
    `${import.meta.env.VITE_APP_API_URL}/v3/settlements/${companyslug}/`,
    {},
    {
      queryOptions: {
        refetchOnWindowFocus: false,
        retry: 1,
        enabled: true,
      },
    }
  );

  useEffect(() => {
    setSettlementData(data)
  },[data])

  const months = [
    { 1: "January" },
    { 2: "February" },
    { 3: "March" },
    { 4: "April" },
    { 5: "May" },
    { 6: "June" },
    { 7: "July" },
    { 8: "August" },
    { 9: "September" },
    { 10: "October" },
    { 11: "November" },
    { 12: "December" },
  ];

  const selectionYears = ["2025","2024","2023"];

  const currentMonth = new Date().getMonth()+1;
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 5 }, (_, i) => currentYear - i);

  const handleSearchChange = (e) => {
    const filteredData = settlementData.filter((item) => {
      return item.settlement_id.includes(e.target.value) || item.deductetion_note.includes(e.target.value);
    })
    setSettlementData(filteredData)
  }


  const getInvoice = async function () {
    if (selectedMonth === "") {
      toast({
        title: "Please select a valid month",
        variant: "loading",
        duration: 2000,
      });
      return;
    }
    if (selectedYear === "") {
      toast({
        title: "Please select a valid year",
        variant: "loading",
        duration: 2000,
      });
      return;
    }

    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/v3/download-month-invoice/${companyslug}/?month=${selectedMonth}&year=${selectedYear}&type=bookings`
      );
      if(response){
        window.open(response.data.url,"_blank");
      }

    } catch (error) {
      console.error(
        "Error fetching invoice:",
        error.response ? error.response.data : error.message
      );

      toast({
        title: error?.response?.data?.message || "Please try again after sometime !!",
        variant: "error",
        duration: 2000,
      });
    }
    
    
  };


  const downloadSettlementReport = async function () {
    const response = await axios.get(
      `${
        import.meta.env.VITE_APP_API_URL
      }/v3/settlements/${companyslug}/?download=1`,
      {
        responseType: "blob", // Ensure response is treated as a file
      }
    );
    // Create a blob from the response
    const blob = new Blob([response.data], { type: "text/csv" });

    // Create a temporary URL
    const url = window.URL.createObjectURL(blob);

    // Create an anchor element and trigger a download
    const a = document.createElement("a");
    a.href = url;
    a.download = `Settlement_Report_${new Date().toISOString()}.csv`;
    document.body.appendChild(a);
    a.click();

    // Cleanup
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="w-full">
      <div className="mb-3">
        <div className="flex gap-6 justify-between">
          <h2 className="section_heading">Settlements</h2>
          {/* <div className="flex gap-2">
            <Button className="bg-black hover:bg-black text-white">Search</Button>
            <Button variant="outline">Clear</Button>
          </div> */}
        </div>
        <div className="flex gap-4 items-center">
          <div className="bg-background/95 py-4 w-full backdrop-blur supports-[backdrop-filter]:bg-background/60">
            <form>
              <div className="relative">
                <Search className="absolute left-2 top-2 h-4 w-4 text-muted-foreground" />
                <Input
                  placeholder="Search"
                  onChange={handleSearchChange}
                  className="pl-8 h-8 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
                />
              </div>
            </form>
          </div>
          <div>
            <DropdownMenu>
              <DropdownMenuTrigger>
                <Button>Downloads</Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem onClick={downloadSettlementReport}>
                  Download Settlements
                </DropdownMenuItem>

                <DropdownMenuItem
                  onClick={() => {
                    setIsDownloadOpen(true);
                  }}
                >
                  Download Invoice
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
      <Dialog open={isDownloadOpen} onOpenChange={setIsDownloadOpen}>
        <DialogContent
          className={"lg:max-w-md rounded-md lg:max-h-[80vh] max-h-[70vh]"}
        >
          <DialogHeader>
            <DialogTitle>Download Invoice</DialogTitle>
          </DialogHeader>
          <div className="flex items-center gap-2">
            <Select onValueChange={(value) => setSelectedMonth(value)}>
              <SelectTrigger className="focus:ring-0 focus:ring-offset-0">
                <SelectValue placeholder="Month" value={selectedMonth} />
              </SelectTrigger>
              <SelectContent side="bottom">
                {months.map((month, index) => {
                  const [key, value] = Object.entries(month)[0];
                  return(
                  <SelectItem
                    disabled={selectedYear!=="2025"? false : parseInt(key) >= parseInt(currentMonth)}
                    key={key}
                    value={key}
                  >
                    {value}
                  </SelectItem>)
                })}
              </SelectContent>
            </Select>
            <Select onValueChange={(value) => setSelectedYear(value)}>
              <SelectTrigger className="focus:ring-0 focus:ring-offset-0">
                <SelectValue placeholder="Year" value={selectedYear} />
              </SelectTrigger>
              <SelectContent side="bottom">
                {selectionYears.map((year) => (
                  <SelectItem key={year} value={year}>
                    {year}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Button onClick={getInvoice}>
              <Download size={14} />
              Download
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <div className="hidden text-xs md:block border rounded-lg">
        <Table>
          <TableHeader>
            <TableRow className="bg-gray-50">
              <TableHead className="font-medium">#</TableHead>
              <TableHead className="font-medium">Settlement ID</TableHead>
              <TableHead className="font-medium">Created on</TableHead>
              <TableHead className="font-medium">Type</TableHead>
              <TableHead className="font-medium text-center">
                Gross Settlement
              </TableHead>
              <TableHead className="font-medium text-center">
                No. of Bookings
              </TableHead>
              <TableHead className="font-medium text-center">
                Deduction
              </TableHead>
              <TableHead className="font-medium text-center">
                Net Settlement
              </TableHead>
              <TableHead className="font-medium w-40">Status</TableHead>
              <TableHead className="font-medium w-40">Deduction Note</TableHead>
              <TableHead className="font-medium w-32">Remarks</TableHead>
              {/* <TableHead className="font-medium">Actions</TableHead> */}
            </TableRow>
          </TableHeader>
          <TableBody>
            {settlementData?.length>0 &&
              settlementData?.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell className="">{index + 1}</TableCell>
                  <TableCell className="">{item.settlement_id}</TableCell>
                  <TableCell className="">
                    {format(new Date(item.created_at), "dd/MM/yyyy")}
                    <br />
                    {format(new Date(item.created_at), "hh:mm aa")}
                  </TableCell>
                  <TableCell className="">
                    {item.booking_settlement_type}
                  </TableCell>
                  <TableCell className="text-center">
                    &#8377;{(item.settlement_amount / 100).toFixed(2)}
                  </TableCell>
                  <TableCell className="text-center">
                    {item.transaction_count}
                  </TableCell>
                  <TableCell className="text-center">
                    &#8377;{(item.deducted_amount / 100).toFixed(2)}
                  </TableCell>
                  <TableCell className="text-center">
                    &#8377;
                    {item.net_amount
                      ? (item.net_amount / 100).toFixed(2)
                      : (
                          (item.settlement_amount - item.deducted_amount) /
                          100
                        ).toFixed(2)}
                  </TableCell>
                  <TableCell>
                    <span className="inline-flex items-center px-2 py-1 rounded-full bg-green-50 text-green-600 text-xs">
                      <span className="w-1.5 h-1.5 rounded-full bg-green-600 text-xs mr-1.5"></span>
                      {item.settlement_status == "SUCCESS" ? "Processed" : ""}
                    </span>
                  </TableCell>
                  <TableCell className="w-40">
                    {item.deductetion_note || "-"}
                  </TableCell>
                  <TableCell className="text-gray-600 w-32">
                    {item.remarks || "-"}
                  </TableCell>
                </TableRow>
              ))}
              {isFetching && (
                Array.from({ length: 10 }).map((_, rowIndex) => ( // Repeat rows
                  <TableRow key={rowIndex}>
                    {Array.from({ length: 10 }).map((_, cellIndex) => ( // Repeat cells inside each row
                      <TableCell key={cellIndex}>
                        <Skeleton className="w-20 h-4" />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              )}
              {
                !isFetching && data?.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={11} className="text-center">
                      No Results
                    </TableCell>
                  </TableRow>
                ) 
              }
          </TableBody>
        </Table>
      </div>

      <div className="md:hidden">
        {data && data.map((item) => <MobileCard key={item.id} item={item} />)}
      </div>
    </div>
  );
}
