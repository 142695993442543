import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Label } from "../ui/label";
import { DateTimePicker } from "../Custom/DateTimePicker";
import { Separator } from "../ui/separator";
import { Input } from "../ui/input";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Checkbox } from "../ui/checkbox";
import {
  CirclePlus,
  EllipsisVertical,
  Info,
  TicketPlus,
  Trash,
} from "lucide-react";
import { Button } from "../ui/button";
import { Switch } from "../ui/switch";
import { Plus } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import { Ticket } from "lucide-react";
import { MultiSelect } from "../ui/multi-select";
import { Textarea } from "../ui/textarea";
import { DatePicker } from "../Custom/DatePicker";
import { ComboboxDemo } from "../Custom/ComboboxInput";
import useEventStore from "@/store/eventStore";
import axios from "axios";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { format } from "date-fns";
import EditMultipleBatches from "./edit-multiple-batches";
import { useQueryClient } from "react-query";
import { useCompanyStore } from "@/store/companyStore";

const PricingModal = ({ open, setIsOpen, fetchBatches, filteredBatches, setFilteredBatches }) => {
  const [openDropdownId, setOpenDropdownId] = React.useState(null);
  const [isAddonDropdownOpen, setIsAddonDropdownOpen] = React.useState(false);
  const [batchData, setBatchData] = React.useState();
  const { eventBatches, selectedBatch, setSelectedBatch, selectedEvent, setEventBatches } = useEventStore();
  const [tickets, setTickets] = useState([]);
  const [newTickets, setNewTickets] = useState([]);
  const [newAddons, setNewAddons] = useState([]);
  const [buttonText, setButtonText] = useState("Update");
  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const [addons, setAddons] = useState([]);
  const [teamMates, setTeamMates] = useState([]);
  const [selectedTeamMates, setSelectedTeamMates] = useState([]);

  const team_mates = useCompanyStore().companyData.teamMembers.data.team_mates

  React.useEffect(() => {
    setBatchData(selectedBatch);
    setTickets(selectedBatch?.eventbatchprice);
    setAddons(selectedBatch?.addonbatchprice || []);
  }, [selectedBatch]);

  useEffect(() => {
    console.log("Team Mates:", team_mates, );
    if(team_mates){
      const transformedMates = team_mates.map(mate => ({
        value: `${mate.peopleid}`,
        label: mate.username
      }));
      console.log("Transformed Mates:", transformedMates);
      setTeamMates(transformedMates);
    }
  },[team_mates])


  const handleChange = (id, field, value, type) => {
    console.log("Field:", field, "Value:", value);
    if (type == "tickets") {
      setTickets((prevCoupons) =>
        prevCoupons.map((coupon) =>
          coupon.id === id
            ? {
                ...coupon,
                [field]: value,
              }
            : coupon
        )
      );
    } else {
      setAddons((prevAddons) =>
        prevAddons.map((addon) =>
          addon.id === id
            ? {
                ...addon,
                [field]: value,
              }
            : addon
        )
      );
    }
  };

  useEffect(()=>{
    console.log("Selected Team Mates:", selectedTeamMates);
    setBatchData((prevState) => ({
      ...prevState,
      hosts: selectedTeamMates
    }))
  },[selectedTeamMates])

  const handleAddTicket = () => {
    setNewTickets((prevTickets) => [...prevTickets, prevTickets.length + 1]);
    setTickets((prevTickets) => [
      ...prevTickets,
      {
        id: prevTickets.length + 1,
        attribute: "",
        price: "",
        inventory: "",
        is_eligible_for_coupon_discount: false,
        soldOut: false,
      },
    ]);
  };

  const handleAddAddon = () => {
    setNewAddons((prevAddons) => [...prevAddons, prevAddons.length + 1]);
    setAddons((prevAddons) => [
      ...prevAddons,
      {
        id: prevAddons.length + 1,
        attribute: "",
        price: "",
      },
    ]);
  };

  const handleRemoveAddon = (id) => {
    setAddons((prevAddons) => prevAddons.filter((addon) => addon.id !== id));
  };

  const handleRemoveTicket = (id) => {
    setTickets((prevTickets) =>
      prevTickets.filter((ticket) => ticket.id !== id)
    );
  };

  useEffect(() => {
    console.log(filteredBatches)
  },[filteredBatches])

  const options = [
    { value: "event1", label: "Event 1 Event 1 Event 1 Event 1 Event 1" },
    { value: "event2", label: "Event 2" },
    { value: "event3", label: "Event 3 Event 3 Event 3 Event 3 Event 3" },
    { value: "event4", label: "Event 4" },
  ];

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setBatchData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleDateChange = (key, value) => {
    console.log("Key:", key, "Value:", value);
    setBatchData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const editBatch = async () => {
    setButtonText(<div className="dots"></div>);
    console.log("Batch Data:", tickets);
    tickets.forEach((ticket) => {
      if (newTickets.includes(ticket.id)) {
        delete ticket.id;
      }
    });
    setTickets(tickets);

    addons.forEach((addon) => {
      if (newAddons.includes(addon.id)) {
        delete addon.id;
      }
    });
    setAddons(addons);
    try {
      const updatedBatchData = {
        ...batchData,
        eventbatchprice: tickets,
        addonbatchprice: addons,
      };
      setBatchData((prevState) => ({
        ...prevState,
        eventbatchprice: tickets,
        addonbatchprice: addons,
        // hosts: selectedTeamMates
      }));
      const response = await axios.post(
        `${import.meta.env.VITE_APP_API_URL}/v3/edit-batch/${batchData.id}/`,
        { onebatch: updatedBatchData }
      );
      setIsOpen(false);
      if (response.status == 200) {
        setButtonText("Updated");
        const updatedBatches = filteredBatches.map((batch) =>
          batch.id == updatedBatchData.id ? {...batch,...updatedBatchData} : batch
        );
        setFilteredBatches(updatedBatches);
        
        setIsOpen(false);
        setTimeout(() => {
          setButtonText("Update");
        }, 3000);
      }
      // setLoading(false)
      console.log("API Response:", response.data);
    } catch (error) {
      console.error("Error updating item status:", error);
    }
  };

  return (
    <div>
      <Dialog open={open} onOpenChange={setIsOpen}>
        <DialogContent
          className={
            "lg:max-w-2xl p-4 overflow-y-scroll rounded-md lg:max-h-[80vh] max-h-[70vh]"
          }
        >
          <DialogHeader>
            <DialogTitle>Edit Pricing & Dates</DialogTitle>
          </DialogHeader>
          <div className="w-full">
            <Tabs defaultValue="current" className="w-full">
              <TabsList className="w-full">
                <TabsTrigger className="w-full" value="current">
                  Edit Current Batch
                </TabsTrigger>
                {/* <TabsTrigger className="w-full" value="multiple">
                  Edit Multiple Batches
                </TabsTrigger> */}
              </TabsList>
              <TabsContent value="current">
                <div className="py-4 flex flex-col gap-4">
                  <div className="timing_section grid grid-cols-1 gap-y-2">
                    <div className="flex justify-between">
                      <Label htmlFor="startdate">Starts On</Label>
                      <div className="max-w-sm">
                        {selectedBatch?.startdate ? (
                          <DateTimePicker
                            onUpdate={(date) => {
                              handleDateChange(
                                "startdate",
                                format(date, "yyyy-MM-dd")
                              );
                              handleDateChange(
                                "starttime",
                                format(date, "HH:mm")
                              );
                            }}
                            defaultValue={
                              selectedBatch.starttime
                                ? new Date(
                                    `${
                                      selectedBatch?.startdate.split("T")[0]
                                    }T${selectedBatch?.starttime
                                      ?.split("T")[1]
                                      .slice(0, 8)}`
                                  )
                                : new Date(
                                    `${selectedBatch?.startdate.split("T")[0]}`
                                  )
                            }
                            id="startdate"
                          />
                        ) : null}
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <Label htmlFor="enddate">Ends On</Label>
                      <div className="max-w-sm">
                        {selectedBatch?.enddate ? (
                          <DateTimePicker
                            onUpdate={(date) => {
                              handleDateChange(
                                "enddate",
                                format(date, "yyyy-MM-dd")
                              );
                              handleDateChange(
                                "endtime",
                                format(date, "HH:mm")
                              );
                            }}
                            defaultValue={
                              selectedBatch.endtime
                                ? new Date(
                                    `${
                                      selectedBatch?.enddate.split("T")[0]
                                    }T${selectedBatch?.endtime
                                      ?.split("T")[1]
                                      .slice(0, 8)}`
                                  )
                                : new Date(
                                    `${selectedBatch?.enddate.split("T")[0]}`
                                  )
                            }
                            id="enddate"
                          />
                        ) : null}
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <Label htmlFor="batch_size">Batch Size</Label>
                      <Input
                        className="max-w-24"
                        id="batch_size"
                        type="number"
                        value={batchData?.batch_size}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="flex items-start gap-2 justify-between">
                      <Label
                        className="flex items-center gap-1"
                        htmlFor="price"
                      >
                        <span>Base Price</span>
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger>
                              <Info size={14} />
                            </TooltipTrigger>
                            <TooltipContent className="w-48">
                              <div>
                                <div className="justify-between flex items-center">
                                  <span className="text-sm">
                                    Event Base Price
                                  </span>
                                  <span className="text-sm font-semibold">
                                    &#8377;{selectedEvent.price}
                                  </span>
                                </div>
                              </div>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </Label>
                      <div className="grid grid-cols-2 gap-x-4">
                        <div className="flex flex-1 items-center gap-1">
                          <span>&#8377;</span>
                          <Input
                            id="price"
                            className="max-w-24"
                            type="number"
                            value={batchData?.price}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="flex flex-1 w-full items-center gap-1">
                          <span>$</span>
                          <Input
                            id="price_in_usd"
                            className="max-w-24"
                            type="number"
                            value={batchData?.price_in_usd}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <Label htmlFor="min_booking_price max-w-xs">
                        Booking Amount
                      </Label>
                      <div className="grid grid-cols-2 gap-x-4">
                        <div className="flex flex-1 items-center gap-1">
                          <span>&#8377;</span>
                          <Input
                            id="min_booking_price"
                            className="max-w-24"
                            type="number"
                            value={batchData?.min_booking_price}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="flex flex-1 w-full items-center gap-1">
                          <span>$</span>
                          <Input
                            id="min_booking_price_in_usd"
                            className="max-w-24"
                            type="number"
                            value={batchData?.min_booking_price_in_usd}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tickets_section">
                    <Accordion className="mb-4" type="single" collapsible>
                      <AccordionItem value="tickets">
                        <AccordionTrigger className="pb-1 pt-0">
                          <div className="flex items-center gap-1">
                            <Ticket size={16} />
                            Tickets
                          </div>
                        </AccordionTrigger>
                        <AccordionContent>
                          {tickets?.map((ticket, index) => (
                            <>
                              <div className="flex pt-2 flex-col px-1 w-full gap-4">
                                <div className="grid grid-cols-12 items-end gap-x-3">
                                  <div className="flex col-span-6 flex-col gap-1">
                                    <Label
                                      className="flex items-end justify-between w-full"
                                      htmlFor="event_name"
                                    >
                                      Name
                                    </Label>
                                    <Input
                                      onChange={(e) =>
                                        handleChange(
                                          ticket.id,
                                          "attribute",
                                          e.target.value,
                                          "tickets"
                                        )
                                      }
                                      id={`name-${ticket.id}`}
                                      type="text"
                                      value={ticket?.attribute}
                                    />
                                  </div>
                                  <div className="flex col-span-3 flex-col gap-1">
                                    <Label htmlFor="event_value">Price</Label>
                                    <Input
                                      onChange={(e) =>
                                        handleChange(
                                          ticket.id,
                                          "price",
                                          e.target.value,
                                          "tickets"
                                        )
                                      }
                                      id={`price-${ticket.id}`}
                                      type="number"
                                      value={ticket?.price}
                                    />
                                  </div>
                                  <div className="flex col-span-2 flex-col gap-1">
                                    <Label htmlFor="event_inventory">Qty</Label>
                                    <Input
                                      onChange={(e) =>
                                        handleChange(
                                          ticket.id,
                                          "inventory",
                                          e.target.value,
                                          "tickets"
                                        )
                                      }
                                      id={`inventory-${ticket.id}`}
                                      type="number"
                                      value={ticket?.inventory}
                                    />
                                  </div>
                                
                                  <Popover
                                   key={ticket.id}
                                    open={openDropdownId == ticket.id}
                                    // onOpenChange={()=>{setOpenDropdownId((prev) => (prev == ticket.id ? null : ticket.id));}}
                                  >
                                    <PopoverTrigger asChild>
                                      <Button
                                        onClick={() => {console.log(ticket.id);setOpenDropdownId(ticket.id)}}
                                        className="w-full px-2"
                                        variant="outline"
                                      >
                                        <EllipsisVertical size={16} />
                                      </Button>
                                    </PopoverTrigger>
                                    <PopoverContent
                                      className="w-48"
                                      align="end"
                                      onInteractOutside={() =>
                                        setOpenDropdownId(null)
                                      }
                                    >
                                      <div className="flex flex-col space-y-2">
                                        <div className="flex justify-between items-center w-full">
                                          <span className="text-xs">
                                            Coupon Applicable
                                          </span>
                                          <Switch
                                            defaultChecked={
                                              ticket.is_eligible_for_coupon_discount
                                            }
                                            onCheckedChange={(value) =>
                                              handleChange(
                                                ticket.id,
                                                "is_eligible_for_coupon_discount",
                                                value,
                                                "tickets"
                                              )
                                            }
                                            className="h-5"
                                            id="Sale"
                                          />
                                        </div>
                                        <div className="flex justify-between items-center w-full">
                                          <span className="text-xs">
                                            Ticket Soldout
                                          </span>
                                          <Switch
                                            className="h-5"
                                            id="batchconfirm"
                                          />
                                        </div>
                                      </div>
                                    </PopoverContent>
                                  </Popover>
                                </div>
                              </div>
                              <div className="flex gap-2 justify-end items-center">
                                {index == tickets.length - 1 && (
                                  <div
                                    onClick={handleAddTicket}
                                    className="text-sm justify-end cursor-pointer flex items-center gap-1 mt-2"
                                  >
                                    <CirclePlus size={16} />
                                    <span className="underline">
                                      Add another
                                    </span>
                                  </div>
                                )}
                                <div
                                  onClick={() => {
                                    handleRemoveTicket(ticket.id);
                                  }}
                                  className="text-sm justify-end cursor-pointer flex items-center gap-1 mt-2"
                                >
                                  <Trash size={16} />
                                  <span className="underline">Remove</span>
                                </div>
                              </div>
                            </>
                          ))}
                          {(tickets == null || tickets.length == 0) && (
                            <div className="flex gap-2 justify-end items-center">
                              <div
                                onClick={handleAddTicket}
                                className="text-sm justify-end cursor-pointer flex items-center gap-1 mt-2"
                              >
                                <CirclePlus size={16} />
                                <span className="underline">Add Prices</span>
                              </div>
                            </div>
                          )}
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </div>
                  <div className="addons_section">
                    <Accordion className="mb-4" type="single" collapsible>
                      <AccordionItem value="addons">
                        <AccordionTrigger className="pb-1 pt-0">
                          <div className="flex items-center gap-1">
                            <TicketPlus size={16} />
                            Addons
                          </div>
                        </AccordionTrigger>
                        <AccordionContent>
                          {addons?.map((addon) => (
                            <>
                              <div className="flex pt-2 px-1 flex-col w-full gap-4">
                                <div className="grid grid-cols-12 items-end gap-x-3">
                                  <div className="flex col-span-8 flex-col gap-1">
                                    <Label
                                      className="flex items-end justify-between w-full"
                                      htmlFor="attribute"
                                    >
                                      Name
                                    </Label>
                                    <Input
                                      id="attribute"
                                      type="text"
                                      onChange={(e) =>
                                        handleChange(
                                          addon.id,
                                          "attribute",
                                          e.target.value,
                                          "addon"
                                        )
                                      }
                                      value={addon?.attribute}
                                    />
                                  </div>
                                  <div className="flex col-span-3 flex-col gap-1">
                                    <Label htmlFor="price">Price</Label>
                                    <Input
                                      id="price"
                                      type="number"
                                      onChange={(e) =>
                                        handleChange(
                                          addon.id,
                                          "price",
                                          e.target.value,
                                          "addon"
                                        )
                                      }
                                      value={addon?.price}
                                    />
                                  </div>

                                  <DropdownMenu
                                    className="col-span-1"
                                    open={isAddonDropdownOpen}
                                    onOpenChange={setIsAddonDropdownOpen}
                                  >
                                    <DropdownMenuTrigger>
                                      <Button
                                        onClick={() =>
                                          setIsAddonDropdownOpen(true)
                                        }
                                        className="w-full px-1"
                                        variant="outline"
                                      >
                                        <EllipsisVertical size={16} />
                                      </Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent
                                      onInteractOutside={() =>
                                        setIsAddonDropdownOpen(false)
                                      }
                                    >
                                      <DropdownMenuItem className="text-xs">
                                        Remove
                                      </DropdownMenuItem>
                                    </DropdownMenuContent>
                                  </DropdownMenu>
                                </div>
                              </div>
                              <div className="flex gap-2 justify-end items-center">
                                <div
                                  className="text-sm justify-end cursor-pointer flex items-center gap-1 mt-2"
                                  onClick={handleAddAddon}
                                >
                                  <CirclePlus size={16} />
                                  <span className="underline">Add another</span>
                                </div>
                                <div
                                  className="text-sm justify-end cursor-pointer flex items-center gap-1 mt-2"
                                  onClick={() => handleRemoveAddon(addon?.id)}
                                >
                                  <Trash size={16} />
                                  <span className="underline">Remove</span>
                                </div>
                              </div>
                            </>
                          ))}
                          {(addons == null || addons.length == 0) && (
                            <div className="flex gap-2 justify-end items-center">
                              <div
                                onClick={handleAddAddon}
                                className="text-sm justify-end cursor-pointer flex items-center gap-1 mt-2"
                              >
                                <CirclePlus size={16} />
                                <span className="underline">Add Addons</span>
                              </div>
                            </div>
                          )}
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </div>
                  <div className="more_options">
                    <Accordion type="single" collapsible>
                      <AccordionItem value="item-1">
                        <AccordionTrigger className="pb-0 pt-0">
                          More Options
                        </AccordionTrigger>
                        <AccordionContent className="px-1">
                         
                          <div className="py-2">
                            <div className="py-2">
                              <div className="flex justify-between">
                                <Label
                                  className="flex"
                                  htmlFor="whatsapp_group_link"
                                >
                                  WhatsApp Group Link
                                </Label>
                                <Input
                                  className="max-w-48"
                                  id="whatsapp_group_link"
                                  type="text"
                                  value={batchData?.whatsapp_group_link}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="py-2">
                              <div className="flex justify-between">
                                <Label
                                  className="flex"
                                  htmlFor="google_drive_link"
                                >
                                  Google Drive Link
                                </Label>
                                <Input
                                  className="max-w-48"
                                  id="google_drive_link"
                                  type="text"
                                  value={batchData?.google_drive_link}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="py-2">
                              <div className="flex justify-between">
                                <Label className="flex " htmlFor="vehicle_type">
                                  Nickname
                                </Label>
                                <Input
                                  className="max-w-48"
                                  id="vehicle_type"
                                  type="text"
                                  value={batchData?.vehicle_type}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="py-2">
                            <div className="flex flex-col gap-1">
                              <Label className="">Who will go</Label>
                              {teamMates && <MultiSelect
                                
                                options={teamMates}
                                defaultValue={batchData?.hosts}
                                onValueChange={(value) => {setSelectedTeamMates(value)}}
                              />}
                            </div>
                          </div>
                            <div className="flex py-2 flex-col gap-1">
                              <Label
                                className="flex items-end justify-between w-full"
                                htmlFor="comment"
                              >
                                Comment
                              </Label>
                              <Textarea
                                id="comment"
                                value={batchData?.comment}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </div>
                  <div className="flex justify-end mt-2">
                    <Button onClick={editBatch}  className=" w-24 ">{buttonText}</Button>
                  </div>
                </div>
                {/* <Button className="h-8" >
                  Submit
                </Button> */}
              </TabsContent>
              {/* <TabsContent value="multiple">
                <EditMultipleBatches
                  teamOptions={teamMates} setTeamMates={setSelectedTeamMates} fetchBatches={fetchBatches}
                  setIsOpen={setIsOpen}
                  eventSlug={selectedEvent.slug}
                  batchId={selectedBatch?.id}
                  selectedBatch={selectedBatch}
                />
              </TabsContent> */}
            </Tabs>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PricingModal;
