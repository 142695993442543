import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { useAuthStore } from "@/store/store";
import oneinboxlogo from "../../assets/oneinbox_logo.png";
import whatsapp from "../../assets/WhatsApp.svg";
import axios from "axios";
import { useUserStore } from "@/store/userStore";
import { Button } from "../ui/button";
import { Label } from "../ui/label";
import { useCompanyStore } from "@/store/companyStore";
import { Input } from "../ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Trash } from "lucide-react";
import { Separator } from "../ui/separator";
import { Skeleton } from "../ui/skeleton";

const LeadChatModal = ({ isOpen, setIsOpen, row }) => {
  const [loading, setLoading] = useState(false)
  const [socialAppExists, setSocialAppExists] = useState(false);
  const [whatsappConvoId, setWhatsappConvoId] = React.useState(null);
  const { accessToken, companyslug } = useAuthStore();
  const user = useUserStore().userData.user.user;
  const [isWhatsappOpen, setIsWhatsappOpen] = useState(false);
  const getSocialApp = async (mobile) => {
    setLoading(true)
    const res = await axios.get(
      `${import.meta.env.VITE_APP_API_URL}/api/socialapp/${companyslug}`
    );
    const data = res.data;
    if (data.socialApp) {
      setSocialAppExists(true);
    }
  };

  const getConversation = async () => {
    const res = await axios.get(
      `${
        import.meta.env.VITE_APP_API_URL
      }/api/conversations/search/${companyslug}/?search=${
        row.customer_profile.mobile
      }`
    );
    const data = res.data;
    if (data.length > 0) {
      setWhatsappConvoId(data[0].id);
    }
    setLoading(false)
  };

  const createConversation = async () => {
    const res = await axios.get(
      `${import.meta.env.VITE_APP_API_URL}/api/conversations/${companyslug}/${
        row.customer_profile.mobile
      }/`
    );
    // const res = await axios.get(`http://localhost:3011/api/conversations/${companyslug}/${row.customer_profile.mobile}/`);
    const data = res.data;
    if (data.length > 0) {
      setWhatsappConvoId(data[0].id);

      var redirectUrl =
        "http://localhost:3011/app/auth/token/" +
        accessToken +
        "/" +
        data[0].id +
        "/";
      window.open(redirectUrl, "_blank");
    }
  };

  useEffect(() => {
    getSocialApp();
    getConversation(row.customer_profile.mobile);
  }, []);

  const handleClick = () => {
    console.log("clicked", whatsappConvoId, socialAppExists);
    if (whatsappConvoId && socialAppExists) {
      // window.open(
      //   `${
      //     import.meta.env.VITE_APP_API_URL
      //   }/app/auth/token/${accessToken}/${whatsappConvoId}`,
      //   "_blank"
      // );
      window.open(
        `http://localhost:3011/app/auth/token/${accessToken}/${whatsappConvoId}`,
        "_blank"
      );
    }
    if (!whatsappConvoId && socialAppExists) {
      createConversation();
    }
  };

  return (
    <div>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="lg:max-w-md">
          <DialogHeader>
            <DialogTitle>Select Platform</DialogTitle>
          </DialogHeader>
          <div>
            {loading ? <div className="flex items-center gap-4 justify-center">
              <Skeleton className={'md:w-36 md:h-40 w-36 h-40 '} />
              <Skeleton className={'md:w-36 md:h-40 w-36 h-40'} />
            </div> : <div className="flex items-center justify-center gap-4">
              <div
                onClick={() => {
                  setIsWhatsappOpen(true);
                }}
                className="cursor-pointer flex flex-col gap-4 items-center justify-center px-[35px] py-8 border border-gray-300 rounded-md"
              >
                <img src={whatsapp} className="w-16 h-16" alt="whatsapp" />
                <h2>WhatsApp</h2>
              </div>
              {socialAppExists && (
                <div
                  onClick={handleClick}
                  className="cursor-pointer flex flex-col gap-4 items-center justify-center px-10 py-8 border border-gray-300 rounded-md"
                >
                  <img
                    src={oneinboxlogo}
                    className="w-16 h-16"
                    alt="whatsapp"
                  />
                  <h2>OneInbox</h2>
                </div>
              )}
            </div>}
          </div>
        </DialogContent>
      </Dialog>
      {isWhatsappOpen && (
        <WhatsAppChatModal
          whatsapp_number={user.contact}
          isOpen={isWhatsappOpen}
          setIsOpen={setIsWhatsappOpen}
          user={user}
          row={row}
        />
      )}
    </div>
  );
};

export default LeadChatModal;

const WhatsAppChatModal = ({ isOpen, setIsOpen, whatsapp_number, user, row }) => {
  const [templateList, setTemplateList] = useState([]);
  const [editTemplateList, setEditTemplateList] = useState([]);
  const [openAccordion, setOpenAccordion] = useState(null);
  const [newTemplates, setNewTemplates] = useState([]);
  const { companyslug } = useAuthStore();
  const allevents = useCompanyStore().companyData.events.data;
  const getTemplates = async () => {
    const templates = await axios.get(
      `${
        import.meta.env.VITE_APP_API_URL
      }/v3/list-people-message-template/${companyslug}`
    );
    setTemplateList(templates.data);
  };

  useEffect(() => {
    getTemplates();
  }, []);

  const handleChange = (id, field, value) => {
    const values = [...editTemplateList];
    if (field == "event") {
      const toBeEdited = values.find((o) => o.id == id);
      toBeEdited[field] = value;
      return setEditTemplateList([...values]);
    }
    const toBeEdited = values.find((o) => o.id == id);
    toBeEdited[field] = value;
    setEditTemplateList([...values]);
    console.log(id, field, value);
  };

  const handleNewChange = (index, field, value) => {
    const values = [...newTemplates];
    if (field == "event") {
      value = allevents.find((o) => o.slug == value);
      values[index][field] = value;
      return setNewTemplates([...values]);
    }
    values[index][field] = value;
    setNewTemplates([...values]);
  };

  const addNewTemplate = () => {
    setNewTemplates([...newTemplates, { title: "", message: "", event: {} }]);
  };

  const handleSubmit = async (event, id, index) => {
    event.preventDefault();
    var updatedData = {};
    if (!id) {
      updatedData = {
        title: newTemplates[index].title,
        message: newTemplates[index].message,
        pdf_event_slug: newTemplates[index].event!='--'?newTemplates[index].event:'',
      };
    } else {
      updatedData = {
        id: id,
        title: editTemplateList.find((o) => o.id == id).title,
        message: editTemplateList.find((o) => o.id == id).message,
        pdf_event_slug: editTemplateList.find((o) => o.id == id).event!="--"?editTemplateList.find((o) => o.id == id).event:'',
      };
    }
    const res = await axios.post(
      `${
        import.meta.env.VITE_APP_API_URL
      }/v3/add-people-message-template/${companyslug}/`,
      updatedData
    );
    if (res.status == 200) {
      setOpenAccordion(null);
      newTemplates.splice(index, 1);
      setNewTemplates([...newTemplates]);
      getTemplates();
    }
  };

  const handleSend = (id) => {
    const toBeSend = templateList.find((o) => o.id == id);
    var encoded_message = ''
    if (toBeSend.event) {
      let slug = toBeSend.event.slug;
      let pdf_message = `<p>Download PDF 👇:</p><p>https://logout.world/tours/${slug}/pdf/?download=1</p>`;
      encoded_message = encodeURIComponent(
        toBeSend.message +
          `\nDownload PDF 👇:\n${import.meta.env.VITE_APP_API_URL}/tours/${slug}/pdf/?download=1`
      );
    } else {
      encoded_message = encodeURIComponent(toBeSend.message);
    }
    var url = `https://wa.me/91${row.customer_profile.mobile}?text=${encoded_message}`;
    window.open(url);
  };

  useEffect(() => {
    setEditTemplateList(templateList);
  }, [templateList]);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="lg:max-w-md overflow-y-scroll rounded-md lg:max-h-[80vh] max-h-[70vh]">
        <DialogHeader>
          <DialogTitle>
            {!whatsapp_number ? "Update WhatsApp Number" : "Templates"}
          </DialogTitle>
        </DialogHeader>
        {whatsapp_number ? (
          <div>
            <div>
              <Accordion
                type="single"
                collapsible
                value={openAccordion}
                onValueChange={setOpenAccordion}
              >
                {templateList?.map((template) => {
                  return (
                    <AccordionItem key={template.id} value={template.id}>
                      <div className="flex items-center justify-between py-2">
                        {template.title}
                        <div className="flex items-center gap-2">
                          <Button
                            variant={"outline"}
                            onClick={() => {
                              if (openAccordion === template.id) {
                                setOpenAccordion(null);
                              } else {
                                setOpenAccordion(template.id);
                              }
                            }}
                          >
                            Edit
                          </Button>
                          <Button onClick={()=>handleSend(template.id)}>Send</Button>
                        </div>
                      </div>
                      {/* <AccordionTrigger>{template.title}</AccordionTrigger> */}
                      <AccordionContent className="px-1">
                        {/* <div>
                          <h2>Preview</h2>
                          <Separator className="mt-1 mb-2" />
                          <div>
                            <h3>{template.message}</h3>
                            
                          </div>
                        </div> */}
                        <form
                          onSubmit={(e) => handleSubmit(e, template.id)}
                          className="flex flex-col gap-4"
                        >
                          <div className="grid w-full max-w-sm items-center gap-1.5">
                            <Label htmlFor="email">Title</Label>
                            <Input
                              type="text"
                              id="title"
                              placeholder="Title"
                              onChange={(e) => {
                                handleChange(
                                  template.id,
                                  "title",
                                  e.target.value
                                );
                              }}
                              value={template.title}
                            />
                          </div>
                          <div className="grid w-full max-w-sm items-center gap-1.5">
                            <Label htmlFor="email">Customer Message</Label>
                            <Input
                              type="text"
                              id="message"
                              placeholder="Customer Message"
                              onChange={(e) => {
                                handleChange(
                                  template.id,
                                  "message",
                                  e.target.value
                                );
                              }}
                              defaultValue={template.message}
                            />
                          </div>
                          <div className="grid w-full max-w-sm items-center gap-1.5">
                            <Label htmlFor="email">Event PDF</Label>
                            <Select
                              className={"w-full max-w-full"}
                              value={template.event ? template.event.slug : ''}
                              onValueChange={(e) => {
                                handleChange(template.id, "event", e);
                              }}
                            >
                              <SelectTrigger className={"w-full max-w-full"}>
                                <SelectValue placeholder="Select Event" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem
                                  
                                  value={"--"}
                                >
                                  Select Event
                                </SelectItem>
                                {allevents.map((event) => {
                                  return (
                                    <SelectItem
                                      key={event.slug}
                                      value={event.slug ? event.slug : "--"}
                                    >
                                      {event.name}
                                    </SelectItem>
                                  );
                                })}
                              </SelectContent>
                            </Select>
                          </div>
                          <div className="flex justify-end">
                            <Button>Update</Button>
                          </div>
                        </form>
                      </AccordionContent>
                    </AccordionItem>
                  );
                })}
              </Accordion>
              <div className="mt-4">
                {newTemplates?.map((template, index) => {
                  return (
                    <div key={index}>
                      <form
                        onSubmit={(e) => handleSubmit(e, null, index)}
                        className="flex flex-col gap-4"
                      >
                        <div className="grid w-full max-w-sm items-center gap-1.5">
                          <Label htmlFor="email">Title</Label>
                          <Input
                            type="text"
                            id="title"
                            placeholder="Title"
                            onChange={(e) => {
                              handleNewChange(index, "title", e.target.value);
                            }}
                            value={template.title}
                          />
                        </div>
                        <div className="grid w-full max-w-sm items-center gap-1.5">
                          <Label htmlFor="email">Customer Message</Label>
                          <Input
                            type="text"
                            id="message"
                            placeholder="Customer Message"
                            onChange={(e) => {
                              handleNewChange(index, "message", e.target.value);
                            }}
                            defaultValue={template.message}
                          />
                        </div>
                        <div className="grid w-full max-w-sm items-center gap-1.5">
                          <Label htmlFor="email">Event PDF</Label>
                          <Select
                            className={"w-full max-w-full"}
                            value={template.event.slug}
                            onValueChange={(e) => {
                              handleNewChange(index, "event", e);
                            }}
                          >
                            <SelectTrigger className={"w-full max-w-full"}>
                              <SelectValue placeholder="Select Event" />
                            </SelectTrigger>
                            <SelectContent>
                              {allevents.map((event) => {
                                return (
                                  <SelectItem
                                    key={event.slug}
                                    value={event.slug ? event.slug : "--"}
                                  >
                                    {event.name}
                                  </SelectItem>
                                );
                              })}
                            </SelectContent>
                          </Select>
                        </div>
                        <div className="flex justify-end gap-4">
                          <span className="flex text-sm underline items-center gap-1">
                            <Trash size={14} />
                            Remove
                          </span>
                          <Button>Update</Button>
                        </div>
                      </form>
                    </div>
                  );
                })}
              </div>
              <div className="flex justify-end pt-4">
                <Button onClick={() => addNewTemplate()}>Add template</Button>
              </div>
            </div>
          </div>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};
