import * as React from "react";


import { NavMain } from "@/components/nav-main";
import { NavProjects } from "@/components/nav-projects";
import { NavUser } from "@/components/nav-user";
import { TeamSwitcher } from "@/components/team-switcher";
import { useMemo } from "react";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
} from "@/components/ui/sidebar";

import {
  hasAdminAccess,
  hasFinanceAccess,
  hasOperationAccess,
  hasSalesAccess,
  hasSalesAdminAccess,
  hasVendorAccess,
} from "@/permission";

const ACCESS = {
  vendor: "vendor_access",
  full: "full_access",
  sales: "sales_access",
  operation: "operation_access",
  finance: "finance_access",
  sales_admin: "sales_admin_access",
};

const ACCESS_CHECKS = {
  [ACCESS.vendor]: hasVendorAccess,
  [ACCESS.full]: hasAdminAccess,
  [ACCESS.sales]: hasSalesAccess,
  [ACCESS.operation]: hasOperationAccess,
  [ACCESS.finance]: hasFinanceAccess,
  [ACCESS.sales_admin]: hasSalesAdminAccess,
};

import { useAuthStore } from "@/store/store";
import {useUserStore} from "@/store/userStore";
import { FaWhatsapp } from "react-icons/fa";
import { Skeleton } from "./ui/skeleton";
import { set } from "lodash";

// const items = [
//   {
//     title: "Home",
//     url: "#",
//     icon: Home,
//   },
//   {
//     title: "Inbox",
//     url: "#",
//     icon: Inbox,
//   },
//   {
//     title: "Calendar",
//     url: "#",
//     icon: Calendar,
//   },
//   {
//     title: "Search",
//     url: "#",
//     icon: Search,
//   },
//   {
//     title: "Settings",
//     url: "#",
//     icon: Settings,
//   },
// ]

export function AppSidebar({ ...props }) {
  const {accessToken,companyslug} = useAuthStore();
  const [navItems, setNavItems] = React.useState([]);
  // This is sample data.

  React.useEffect (() => {
    setNavItems(props.navItems)
  },[props.navItems])
 
  
  function checkAccess(requiredAccess) {
    // If no access is specified, allow by default
    if (!requiredAccess || requiredAccess.length === 0) return true;

    // Check if ANY of the required access types are granted
    return requiredAccess.some((accessType) => {
      const checkFunction = ACCESS_CHECKS[accessType];
      return checkFunction ? checkFunction() : false;
    });
  }

  

  const {userData, tabs} = useUserStore();

  const userDetails = userData?.user;

  // React.useEffect(() => {
  //   setNavItems(filterNavItems(data.navMain, tabs));
  // },[tabs])
  // Filter navigation items
  // const accessibleNavItems = filterNavItems(data.navMain, tabs);


  return (
    <Sidebar collapsible="icon" {...props}>
      <SidebarHeader>
        <TeamSwitcher teams={props.teams} />
      </SidebarHeader>
      <SidebarContent>
        {
          navItems?.length == 0 ?  <div className="p-4 flex flex-col gap-4">
            {
              [...Array(13).keys()].map(()=>(

                <Skeleton className='w-52 h-6' />
              ))
            }
          </div>
          :   <NavMain items={navItems} />
        }
      
        {/* <NavProjects projects={data.projects} /> */}
        {/* { <SidebarMenu>
              {items.map((item) => (
                <SidebarMenuItem key={item.title}>
                  <SidebarMenuButton asChild>
                    <a href={item.url}>
                      <item.icon />
                      <span>{item.title}</span>
                    </a>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              ))}
            </SidebarMenu> } */}
      </SidebarContent>
      <SidebarFooter>
        <NavUser user={userDetails?.user} />
      </SidebarFooter>
    </Sidebar>
  );
}
