import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAuthStore } from '@/store/store';

const TokenHandler = () => {
 const {slug,token} = useParams();
 const {setCompanySlug,setUser} = useAuthStore();
 console.log("heee")   

 useEffect(()=>{
    setCompanySlug(slug);
 },[slug])

    useEffect(()=>{
    setUser(token);
    window.location.href = `${window.origin}/dashboard/${slug}/`;
    },[token])
  return (
    <div>
      
    </div>
  )
}

export default TokenHandler
