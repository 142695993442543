import {create} from 'zustand';

const useLeadsStore = create((set) => ({
  teamMates: [],
  updateEnquiryField: {},
  enquiries: [],
  refetchApi: null,

  setTeamMates: (teamMates) => set({ teamMates: teamMates }),
  setUpdateEnquiryField: (field) => set({ updateEnquiryField: field }),
  setEnquiries: (enquiries) => set({ enquiries: enquiries }),
  setRefetchApi: (apiFunc) => set({ refetchApi: apiFunc }),
}));

export default useLeadsStore;