import axios from "axios";

export const changeAvailability = async (id, companyslug) => {
  const data = { user_id: id };
  let isLoading = true;

  try {
    const response = await axios.post(
      `${
        import.meta.env.VITE_APP_API_URL
      }/v3/team/${companyslug}/change-user-availability/`,
      data
    );
    return {
      data: response.data,
      isLoading: false,
      isError: false,
      error: null,
    };
  } catch (err) {
    return { data: null, isLoading: false, isError: true, error: err };
  }
};

export const removeTeamMember = async (id, team_type,companyslug) => {
  const data = { user_id: id, teammate_type:team_type };
  let isLoading = true;

  try {
    const response = await axios.post(
      `${
        import.meta.env.VITE_APP_API_URL
      }/v3/team/${companyslug}/remove-member/?team=${team_type}`,
      data
    );
    return {
      data: response.data,
      isLoading: false,
      isError: false,
      error: null,
    };
  } catch (err) {
    return { data: null, isLoading: false, isError: true, error: err };
  }
};


export const searchTeamMembers = async (search_text,companyslug,team) => {
  let isLoading = true;

  try {
    const response = await axios.get(
      `${
        import.meta.env.VITE_APP_API_URL
      }/v3/team/${companyslug}/find-user/?q=${search_text}&team=${team}`,
    );
    console.log(response)
    return {
      data: response.data,
      isLoading: false,
      isError: false,
      error: null,
    };
  } catch (err) {
    return { data: null, isLoading: false, isError: true, error: err };
  }
};


export const addTeamMember = async (id, team_type,companyslug) => {
  var data = { user_id: id, teammate_type:team_type };
  if(team_type == 'admin'){
    data = {user_id: id, teammate_type:'team-mate'}
  }
  let isLoading = true;

  try {
    const response = await axios.post(
      `${
        import.meta.env.VITE_APP_API_URL
      }/v3/team/${companyslug}/add-member/`,
      data
    );
    return {
      data: response.data,
      isLoading: false,
      isError: false,
      error: null,
    };
  } catch (err) {
    return { data: null, isLoading: false, isError: true, error: err };
  }
};