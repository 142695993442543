"use client";

import { Badge } from "@/components/ui/badge";
import { Checkbox } from "@/components/ui/checkbox";
import React from "react";
import { CheckCheck, SquareArrowOutUpRight } from "lucide-react";
import { DataTableColumnHeader } from "@/components/ui/data-table-column-header";
import { Button } from "@/components/ui/button";
import { Calendar } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { DetailsDrawer } from "@/components/ui/data-table";
import EditGuestDetails from "../editGuestModal";
import InitiateRefundModal from "../initiateRefundModal";
import { useIsMobile } from "@/hooks/use-mobile";
import { endOfWeek, format } from "date-fns";
import {
  sendBookingConfirmation,
  sendWhatsAppConfirmation,
} from "@/apiService/bookings";
import { useCompanyStore } from "@/store/companyStore";
import { useAuthStore } from "@/store/store";

export const columns = [
  {
    accessorKey: "id",
    size: 60,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ID" />
    ),
    cell: ({ row }) => {
      const [isDetailsOpen, setIsDetailsOpen] = React.useState(false);
      return (
        <div className="text-xs items-center cursor-pointer flex flex-col gap-1">
          <span
            onClick={() => {
              setIsDetailsOpen(true);
            }}
            className="underline"
          >
            {row.getValue("id")}
          </span>
          <Badge variant="outline" className={"text-[0.6rem] rounded-md py-0 "}>
            {row.original.manually_booked ? "Manual" : "Online"}
          </Badge>
          {isDetailsOpen && (
            <DetailsDrawer
              isDetailsOpen={isDetailsOpen}
              setIsDetailsOpen={setIsDetailsOpen}
              heading={"bookings"}
              selected={row.original}
            />
          )}
        </div>
      );
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "eventDetails",
    size: 150,
    enableSorting: false,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Event Details" />
    ),
    cell: ({ row }) => {
      const name = row.original.event_name;
      const date = row.original.batch_startdate;
      const time = row.original.batch_starttime;

      const eventDetails = useCompanyStore.getState().companyData.events;
      const findEventImage = function (name) {
        const event = eventDetails?.data.find((event) => event.name === name);
        return `${import.meta.env.VITE_APP_API_URL}${event.image}`;
      };

      return (
        <div className="flex cursor-pointer gap-2 text-xs">
          <div className="w-8 h-8 rounded-md relative overflow-hidden">
            <img
              src={findEventImage(name)}
              className="absolute top-0 left-0 w-full h-full object-cover"
            />
          </div>
          <div className="flex flex-col">
            <span className="max-w-36">{name}</span>
            <span className="flex items-center gap-1">
              {format(new Date(date), "dd/MM/yyyy")}
              <SquareArrowOutUpRight onClick={()=>window.open(`${import.meta.env.VITE_APP_API_URL}/dashboard/batch-details/${row.original.eventbatches_id}`)} size={12} />
            </span>
          </div>
        </div>
      );
    },
  },
  {
    accessorKey: "bookingDetails",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Booking Details" />
    ),
    size: 120,
    enableSorting: false,
    cell: ({ row }) => {
      const bookedBy = row.original.name;
      const numberOfPeople = row.original.numberofpeople;
      const contact = row.original.number;
      const email = row.original.email;
      return (
        <div className="text-xs">
          <h2 className="block font-semibold">
            <span>{numberOfPeople} Booking(s)</span> | {bookedBy}
          </h2>
          <div>
            <span>
              {format(
                new Date(row.original.timestamp),
                "d MMM, yyyy | hh:mm a"
              )}
            </span>
          </div>
          <div>
            <span>{contact}</span>
          </div>
          {/* <h2>{email}</h2> */}
          {/* <Badge variant="outline" className={'text-[0.6rem] rounded-md mr-1 py-0'}>Bookings: 2</Badge> */}
          <Badge
            variant="outline"
            className={"text-[0.6rem] mt-1 rounded-md py-0"}
          >
            Instagram
          </Badge>
          {/* <h2>For: 2 bookings, Source: Instagram</h2> */}
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "discountDetails",
    size: 80,
    enableSorting: false,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Discount" />
    ),
    cell: ({ row }) => {
      const code = row.original.couponcode;
      const discount = (row.original.discount / 100).toFixed(2);

      return (
        <div className=" text-xs">
          <h2 className="font-medium">{code}</h2>
          <h2>&#8377;{discount}</h2>
        </div>
      );
    },
    // filterFn: (row, id, value) => {
    //   return value.includes(row.getValue(id))
    // },
  },
  {
    accessorKey: "transactionAmount",
    size: 60,
    enableSorting: false,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Paid" />
    ),
    cell: ({ row }) => {
      let transactionAmount = 0;
      if (row.original.manually_booked) {
        transactionAmount = row.original.manual_amount;
      } else {
        transactionAmount = row.original.amount;
      }
      return (
        <div className=" text-xs">
          <span>&#8377;{(transactionAmount / 100).toFixed(2)}</span>
        </div>
      );
    },
    // filterFn: (row, id, value) => {
    //   return value.includes(row.getValue(id))
    // },
  },
  {
    accessorKey: "fees",
    size: 60,
    enableSorting: false,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Fees" />
    ),
    cell: ({ row }) => {
      return (
        <div className=" text-xs">
          <span>&#8377;{(row.original.fees / 100).toFixed(2)}</span>
        </div>
      );
    },
  },
  {
    accessorKey: "refunded",
    size: 60,
    enableSorting: false,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Refund" />
    ),
    cell: ({ row }) => {
      return (
        <div
          className={`text-xs ${
            row.original?.amount_refunded > 0 ? "text-red-500" : ""
          }`}
        >
          <span>&#8377;{(row.original.amount_refunded / 100).toFixed(2)}</span>
        </div>
      );
    },
    // filterFn: (row, id, value) => {
    //   return value.includes(row.getValue(id))
    // },
  },
  {
    accessorKey: "pending",
    size: 60,
    enableSorting: false,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Pending" />
    ),
    cell: ({ row }) => {
      console.log(row.original)
      return (
        <div className=" text-xs">
          {/* <span>&#8377;{"-"}</span> */}
          <span>{"-"}</span>
        </div>
      );
    },
    // filterFn: (row, id, value) => {
    //   return value.includes(row.getValue(id))
    // },
  },
  {
    accessorKey: "settlement",
    enableSorting: false,
    size: 60,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Settlement" />
    ),
    cell: ({ row }) => {
      console.log(row);
      return (
        <div className=" text-xs">
          <span>
           {row.original.settlement_amount && (row.original.settlement_amount == '-' ? '--' :  `₹${(row.original.settlement_amount / 100).toFixed(2)}`)}
          </span>
          <br />
          {row.original.settled && (
            <span className="inline-flex mt-1 items-center px-2 py-1 rounded-full bg-green-50 text-green-600 text-xs">
              <span className="w-[0.35rem] h-[0.35rem] rounded-full bg-green-600 text-xs mr-1.5"></span>
              Settled
            </span>
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "actionsButtons",
    size: 80,
    enableSorting: false,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Actions" />
    ),
    cell: ({ row }) => {
      const [isEditDialogOpen, setIsEditDialogOpen] = React.useState(false);
      const [isEditSheetOpen, setIsEditSheetOpen] = React.useState(false);
      const [isRefundOpen, setIsRefundOpen] = React.useState(false);
      const {companyslug} = useAuthStore()
      const isMobile = useIsMobile();
      return (
        <div className=" text-xs">
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Button>Actions</Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem
                onClick={() => {
                  !isMobile
                    ? setIsEditDialogOpen(true)
                    : setIsEditSheetOpen(true);
                }}
              >
                Edit Details
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  window.open(
                    `${
                      import.meta.env.VITE_APP_API_URL
                    }/dashboard/event-booking-invoice/${row.original.id}/`,
                    "_blank"
                  );
                }}
              >
                Preview Invoice
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  window.open(
                    `${
                      import.meta.env.VITE_APP_API_URL
                    }/dashboard/event-booking-invoice/${
                      row.original.id
                    }/?download=1`,
                    "_blank"
                  );
                }}
              >
                Download Invoice
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  sendBookingConfirmation(row.original.id);
                }}
              >
                Send Booking Confirmation Mail
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  sendWhatsAppConfirmation(row.original.id);
                }}
              >
                Send WhatsApp Confirmation
              </DropdownMenuItem>
              {companyslug=='logoutworld' && row.original.payment_id && row.original.paid && ((row.original.amount - row.original.amount_refunded) > 0) && <DropdownMenuItem
              onClick={() => {
                setIsRefundOpen(true);
              }}
              >
                Initiate Refund
              </DropdownMenuItem>}
            </DropdownMenuContent>
          </DropdownMenu>
          {isEditDialogOpen && (
            <EditGuestDetails
              isEditDialogOpen={isEditDialogOpen}
              setIsEditDialogOpen={setIsEditDialogOpen}
              isEditSheetOpen={isEditSheetOpen}
              setIsEditSheetOpen={setIsEditSheetOpen}
              booking={row.original}
            />
          )}
          {isRefundOpen && 
            <InitiateRefundModal
              isOpen={isRefundOpen}
              setIsOpen={setIsRefundOpen}
              item={row.original}
            />
          }
        </div>
      );
    },
    // filterFn: (row, id, value) => {
    //   return value.includes(row.getValue(id))
    // },
  },
];
